import * as React from 'react';

export const Bonnet = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="BONNET"
		stroke="#000"
		d="M159.037 198.818v-2.296m0 0v-56.824c4.309-35.816 32.321-47.066 42.557-48.214h47.944c31.46 7.806 38.247 35.778 38.786 48.788v56.25c-4.31-4.401-15.407-13.546-25.319-14.924h-75.956c-7.183.766-22.841 4.822-28.012 14.924z"
		{...props}
	/>
);
