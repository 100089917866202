import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import SvgAlert from '../Icons/WarningCircleRed';
import SvgWarning from '../Icons/WarningTriangleOrange';
import { Text } from '../Text';

export enum NotificationLevel {
	None,
	Warning,
	Alert,
}

export interface Notification {
	readonly text: string;
	readonly level: NotificationLevel;
	readonly threshold: number;
}

export interface NotificationProps {
	readonly text: string;
	readonly level: NotificationLevel;
}

const NotificationWrapper = styled(Box)`
	padding: 2px 8px;
	background-color: #f5f6f7;
	border-radius: 12px;
`;

const icons: { [key: string]: any } = {
	[NotificationLevel.Warning]: SvgWarning,
	[NotificationLevel.Alert]: SvgAlert,
};

export const Notification: React.FC<NotificationProps> = ({ text, level }) => {
	const Icon = icons[level];

	return (
		<NotificationWrapper gap={4} alignItems="center">
			<Icon />
			<Text typography="micro" color="#3D4852">
				{text}
			</Text>
		</NotificationWrapper>
	);
};
