import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ValidFlag } from '@tractable/estimating-local-constants';
import { calculateTotals } from '@tractable/estimating-local-estimate-parsing';

import { useClientConfigCtx } from '../../hooks/useClientConfigCtx';
import { PageLayout } from '../shared';
import { Box } from '../shared/Box';
import { DelightedSurveyFeedback } from '../shared/DelightedSurveyFeedback/DelightedSurveyFeedback';
import { NavIcon } from '../shared/Navbar';
import { EditQuattroruoteEstimateButton } from './Estimatics';
import {
	ClaimDetails,
	EditAction,
	EstimateViewWrapper,
	EstimaticsExporter,
	GTMotiveExporter,
	Images,
	LoadingState,
	PdfDownloader,
	PdfViewWrapper,
} from './components';
import { SettlementActions } from './components/Actions';
import { useEditing } from './hooks/useEditing';
import { useGetClaim } from './hooks/useGetClaim';
import { useGetValidFlags } from './hooks/useGetValidFlags';
import { useLocale } from './hooks/useLocale';
import { useUpdateField } from './hooks/useUpdateField';

const Edit: React.FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	// TODO: think about better way to use config
	const { currency } = useLocale();
	const { config } = useClientConfigCtx();
	const { data, loading } = useGetClaim(id);
	const {
		data: validFlags,
		loading: loadingValidFlags,
		// error
	} = useGetValidFlags(id);

	const { startEditing, cancelEditing, saveEditing, isEditing, isSaving, setIsDirty } = useEditing();
	const { update } = useUpdateField({
		setIsDirty,
	});
	const policyConfig = config?.policies;
	const showDelightedSurvey = !!config.ui?.showDelightedSurveyEditPage;

	// Loading state until data is retrieved from API
	if (loading || loadingValidFlags || !policyConfig || !data?.claim?.estimate?.info || !validFlags) {
		return <LoadingState />;
	}

	const {
		id: claimId,
		claimDetails: { estimateStatus },
	} = data.claim;

	const totals = calculateTotals(
		data.claim.estimate,
		config.clientId,
		validFlags.getValidFlags.filter((f: { type: string }) => f.type === 'estimate') as ValidFlag[]
	);

	const isPdfPresent = !!data.claim.claimDetails.validAsset && !!data.claim.claimDetails?.isPublishedStage;
	// UI Showing Flags
	const showPdfView = !!config.ui?.showEstimatePdfView && isPdfPresent;
	const showEstimate = !!config.ui?.showEstimate;
	const oosRenderEstimate = config.ui?.oosRenderEstimate;
	const showEstimateFeatures = (showEstimate && !showPdfView) || config?.enablePayeeInfo;
	const showQuattroruoteEstimateURL = !!config.ui?.showQuattroruoteEstimateLink;

	const estimateIsAvailable =
		(estimateStatus === 'SHOW_ESTIMATE' && data.claim.estimate) ||
		oosRenderEstimate?.find((reason) => reason === data.claim.processabilityTriageOutcome);

	const estimaticsExportEnabled = !!config.ui.estimaticsExport?.enabled;
	const estimaticsExportEndpoint = config.ui?.estimaticsExport?.endpoint || '';

	const showGTMotiveExporter = !!config.ui?.showGTMotiveExporter;

	return (
		<PageLayout
			navigationIcon={NavIcon.BACK}
			navigationHeader={data?.claim?.claimDetails?.claimId}
			navigationOnClick={() => navigate('/')}
			header={null}
		>
			{/* Buttons group in heading */}
			{estimateIsAvailable && (
				<Box position="absolute" right="50px" top="16px" gap={32} alignItems="center">
					{!isEditing && (
						<>
							{/* Estimatics exporter buttons */}
							{estimaticsExportEnabled && <EstimaticsExporter claimId={claimId} endpoint={estimaticsExportEndpoint} />}

							{/* GTMotive export & get estimate button */}
							{showGTMotiveExporter && <GTMotiveExporter claimId={claimId} />}

							{/* PDF Download button */}
							{isPdfPresent && <PdfDownloader claimId={claimId} />}
						</>
					)}
					{showEstimateFeatures && (
						<SettlementActions
							claimId={id}
							isEditing={isEditing}
							editButton={
								!config.enablePayeeInfo && (
									<EditAction
										isEditing={isEditing}
										isSaving={isSaving}
										setIsEditing={() => startEditing(data.claim)}
										saveEditing={() => saveEditing(data.claim)}
										cancelEdits={() => cancelEditing(data.claim)}
									/>
								)
							}
						/>
					)}
					{estimateIsAvailable && showQuattroruoteEstimateURL && (
						<EditQuattroruoteEstimateButton clientId={data.claim.clientId} claimId={id} />
					)}
				</Box>
			)}
			<ClaimDetails
				claim={data.claim}
				currency={currency}
				config={config}
				totals={totals}
				settlement={data.claim?.settlement}
			/>
			<Box flex="1">
				{/* Page Left box */}
				{/* Estimate ( Table View OR PDF) */}
				{showPdfView ? (
					<PdfViewWrapper claim={data.claim} isPdfPresent={isPdfPresent} />
				) : (
					<EstimateViewWrapper
						claim={data.claim}
						update={update}
						isEditing={isEditing}
						estimateIsAvailable={estimateIsAvailable}
						config={config}
						estimateStatus={estimateStatus}
					/>
				)}

				{/* Page Right box */}
				<Box width="50%" orientation="vertical" flex="1" p="16px 0 0 16px" gap={16}>
					{/* Photo Gallery */}
					<Images claim={data.claim} />
				</Box>
			</Box>
			<ToastContainer />
			{showDelightedSurvey && <DelightedSurveyFeedback language={config.locale.symbol} clientId={config.clientId} />}
		</PageLayout>
	);
};

export { Edit };
