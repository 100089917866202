import * as React from 'react';

import { Stage } from '@tractable/estimating-local-constants';

import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { EstimateReviewButton } from './EstimateReview';
import { NegotiationButton } from './Negotiation';
import { useGetEditableClaimQuery } from './generated/useGetEditableClaim.generated';

export type SettlementActionsProps = {
	claimId: string;
	editButton: React.ReactNode;
	isEditing: boolean;
};

export const SettlementActions = ({ claimId, editButton, isEditing }: SettlementActionsProps) => {
	const { data } = useGetEditableClaimQuery({ variables: { id: claimId }, pollInterval: 1000 });

	const awaitingAsyncClaimAction = data?.claim?.nextStage && data?.claim?.nextStage !== data?.claim?.stage;

	const {
		config: { featureFlags, enablePayeeInfo },
	} = useClientConfigCtx();

	const isInEstimateReview =
		data?.claim?.stage === Stage.EXTERNAL_ESTIMATE_EDITING || data?.claim?.stage === Stage.ESTIMATE_DECISION;
	if (isInEstimateReview) {
		return (
			<EstimateReviewButton
				isEditing={isEditing}
				claimId={claimId}
				estimateDecision={data?.claim?.estimateDecision}
				editButton={editButton}
				loading={awaitingAsyncClaimAction}
				onlyAccept={enablePayeeInfo ?? false}
			/>
		);
	}

	const isInNegotiation = data?.claim?.stage === Stage.NEGOTIATION_EDITING || data?.claim?.stage === Stage.NEGOTIATION;
	if (featureFlags.negotiation && isInNegotiation && !enablePayeeInfo) {
		return (
			<NegotiationButton
				isEditing={isEditing}
				claimId={claimId}
				negotiationStatus={data?.claim?.negotiationStatus}
				editButton={editButton}
				loading={awaitingAsyncClaimAction}
			/>
		);
	}

	return null;
};
