import { Alert, Text, createStyles } from '@mantine/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationStatus } from '@tractable/estimating-local-constants';

import { BoxProps } from '../shared';
import { GetClaimNotificationsQuery } from './Actions/generated/useGetClaimNotifications.generated';

const useStyles = createStyles(() => ({
	alertText: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '14px',
	},
	icon: {
		color: '#286846',
		fontSize: '14px !important',
		paddingRight: '5px',
	},
}));

interface IProps {
	notifications?: GetClaimNotificationsQuery['claimNotifications']['notifications'];
}
type SMSStatusProps = IProps & BoxProps;

export const SMSStatus: React.FC<SMSStatusProps> = ({ notifications = [] }) => {
	const { t } = useTranslation('EstimatingPortal');
	const { classes } = useStyles();
	const isSmsSent = notifications && notifications?.find((x) => x.status === NotificationStatus.SENDING_SUCCEEDED);
	return isSmsSent ? (
		<Alert color="green">
			<Text classNames={{ root: classes.alertText }}>
				<span className={'material-icons ' + classes.icon}>check_circle_outline</span>
				{t('SMS Sent')}
			</Text>
		</Alert>
	) : null;
};
