import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '../../shared/Box';
import { Text } from '../../shared/Text';
import { Spinner } from './Spinner';

export interface EditActionProps {
	isEditing: boolean;
	isSaving: boolean;
	setIsEditing: () => void;
	saveEditing: () => void;
	cancelEdits: () => void;
}

const Button = styled.button`
	height: 32px;
	width: 174px;
	background-color: white;
	color: #3b25c4;
	border: 1px solid #3b25c4;
	border-radius: 3px;
	cursor: pointer;
	&:disabled {
		border: 1px solid #d2d1d1;
		background-color: #f2f2f2;
		color: #c2c1c1;
		cursor: not-allowed;
	}
`;

const CancelButton = styled.button`
	background-color: white;
	border: none;
	color: #3b25c4;
	cursor: pointer;
`;

export const EditAction: React.FC<EditActionProps> = ({
	isEditing,
	isSaving,
	setIsEditing,
	saveEditing,
	cancelEdits,
}) => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<Box justifyContent="flex-end" gap={16}>
			{isEditing ? (
				<>
					{!isSaving && (
						<CancelButton type="button" onClick={cancelEdits} data-test-id="cancel-edit-button">
							<Text typography="standard">{t('Cancel')}</Text>
						</CancelButton>
					)}
					<Button type="button" disabled={isSaving} onClick={saveEditing} data-test-id="save-edit-button">
						{isSaving ? <Spinner width="20px" /> : <Text typography="standard">{t('Save Edits')}</Text>}
					</Button>
				</>
			) : (
				<Button type="button" onClick={setIsEditing} data-test-id="edit-estimate-button">
					<Text typography="standard">{t('Edit Estimate')}</Text>
				</Button>
			)}
		</Box>
	);
};
