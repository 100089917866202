import * as React from 'react';

import { PhEstimateDecision } from '@tractable/estimating-local-constants';

import { Box } from '../../Box';
import { Text } from '../../Text';
import { TextWithStatusIcon } from './TextWithStatusIcon';
import { StatusIcons } from './types';

const statusMapping: { [key: string]: any } = {
	[PhEstimateDecision.ACCEPTED_OFFER]: StatusIcons.Check,
	[PhEstimateDecision.DECLINED_OFFER]: StatusIcons.Cross,
	[PhEstimateDecision.NOT_YET_COMPLETE]: StatusIcons.Awaiting,
	[PhEstimateDecision.OFFER_UNAVAILABLE]: StatusIcons.Unavailable,
	[PhEstimateDecision.NEGOTIATED_OFFER]: StatusIcons.Negotiated,
};

export interface PhEstimateDecisionProps {
	readonly title: string;
	readonly decision: PhEstimateDecision;
	readonly counterOffer?: number;
}

export const PhDecisionIndicator: React.FC<PhEstimateDecisionProps> = ({ title, decision, counterOffer }) => {
	return (
		<Box orientation="horizontal" alignItems="center" gap={4}>
			<TextWithStatusIcon icon={statusMapping[decision]} title={title} id="speedy-payment-status" />
			{counterOffer && (
				<Box background="#EDF5FF">
					<Text typography="standard" color="#0050D8" p="0 5px" id="ph-estimate-decision-counter-offer">
						{counterOffer}
					</Text>
				</Box>
			)}
		</Box>
	);
};
