import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Spinner } from '../';
import { PageLayout } from '../../../shared';
import { Box } from '../../../shared/Box';
import { NavIcon } from '../../../shared/Navbar';

export const LoadingState: React.FC = () => {
	const { t } = useTranslation('EstimatingPortal');
	const navigate = useNavigate();

	return (
		<PageLayout
			navigationIcon={NavIcon.BACK}
			navigationHeader={t('Tractable Auto Estimator')}
			navigationOnClick={() => navigate('/')}
			header={null}
		>
			<Box height="440px" justifyContent="center">
				<Spinner width="50px" />
			</Box>
			<ToastContainer />
		</PageLayout>
	);
};
