import i18n from 'i18next';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

const initi18n = (
	lng: string,
	{ loadPath, projectId, apiKey = '' }: { loadPath: string; projectId: string; apiKey?: string }
) =>
	i18n
		.use(Backend)
		.use(initReactI18next)
		.init({
			backend: {
				loadPath,
				projectId,
				referenceLng: 'en',
				apiKey,
			},
			fallbackLng: 'en',
			contextSeparator: '?',
			lng,
			load: 'currentOnly',
			saveMissing: process.env.NODE_ENV === 'development',
			keySeparator: false,
			react: {
				transSupportBasicHtmlNodes: true,
				transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
			},
			interpolation: {
				escapeValue: false,
			},
		});

export { initi18n };

export default i18n;
