import { HTMLSelect, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import { PolicyConfig } from '../../../../../common/types/config';
import useClientTranslations from '../../../../hooks/useClientTranslations';
import { FieldProps } from '../types';
import { required } from '../utils';
import { TractableFormGroupSmall } from './Shared';

interface PolicyProps {
	readonly policies: PolicyConfig[];
	readonly clientName: string;
}

interface IOptionProps {
	label: string;
	value: string;
}

const ClaimPolicy: React.FC<PolicyProps> = ({ policies, clientName }) => {
	const { t } = useClientTranslations();

	// Convert policies array of strings to be an array of object containing the key value and the label displayed with translate
	const policiesOptions: IOptionProps[] = policies.reduce((acc: any, policyConfig: PolicyConfig) => {
		const policyObj = {
			label: t(`${clientName}_${policyConfig.policy}`),
			value: policyConfig.policy,
		};
		return [...acc, policyObj];
	}, []);
	return (
		<Field component="select" name="policy" validate={required(t('Claim Policy'), t)}>
			{({ input, meta }: FieldProps) => (
				<TractableFormGroupSmall
					label={t('Claim Policy')}
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && meta.error}
				>
					<HTMLSelect {...input}>
						<option disabled value="" style={{ display: 'none' }}>
							{' '}
						</option>
						{policiesOptions.map((option, index) => (
							<option value={option.value} key={index}>
								{option.label}
							</option>
						))}
					</HTMLSelect>
				</TractableFormGroupSmall>
			)}
		</Field>
	);
};

export { ClaimPolicy };
