import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useClientConfigCtx } from '../../hooks/useClientConfigCtx';

interface UseSummary {
	id: string;
	phone?: string;
	interval?: number;
}

const GET_CLAIM_SUMMARY = gql`
	query getClaim_Summary($id: String) {
		claim(id: $id) {
			id
			status
			statusHistory {
				id
				fromStage
				fromStatus
				toStage
				toStatus
			}
			claimDetails {
				claimId
				dateOfAccident
				nextStageUrl
			}
			vehicle {
				make
				model
				year
				vin
				mileage
				registration
				name
			}
		}
	}
`;

const GET_LINK = gql`
	query getLink($id: String, $phone: String) {
		webappLink(id: $id, phone: $phone) {
			link
		}
	}
`;

enum SummaryStageStatus {
	'disabled' = 'disabled',
	'finished' = 'finished',
}

const useSummary = ({ id, phone, interval = 5000 }: UseSummary) => {
	const navigate = useNavigate();
	const { config } = useClientConfigCtx();
	const [linkGenerated, setLinkGenerated] = useState<SummaryStageStatus>(SummaryStageStatus.disabled);
	const [linkOpened, setLinkOpened] = useState<SummaryStageStatus>(SummaryStageStatus.disabled);
	const [photosUploaded, setPhotosUploaded] = useState<SummaryStageStatus>(SummaryStageStatus.disabled);
	const { showDeleteButton } = config.ui;
	const { data: getLinkData, error: linkError } = useQuery(GET_LINK, {
		variables: { id, ...(phone ? { phone: phone } : {}) },
	});

	const {
		data: getClaimData,
		error: ClaimSummaryError,
		loading,
		startPolling,
		stopPolling,
	} = useQuery(GET_CLAIM_SUMMARY, {
		variables: { id },
	});

	useEffect(() => {
		startPolling(interval);
		return () => stopPolling();
	}, []);

	useEffect(() => {
		if (getLinkData?.webappLink?.link) setLinkGenerated(SummaryStageStatus.finished);
	}, [getLinkData]);

	useEffect(() => {
		if (linkGenerated === SummaryStageStatus.finished && getClaimData?.claim) {
			const transitionToTriage = !!find(getClaimData.claim.statusHistory, {
				// HACK: this is so nextStageUrl works
				fromStage: 'POLICYHOLDER_INPUT',
				fromStatus: 'DONE',
			});
			const phOpenedApp = !!find(getClaimData.claim.statusHistory, {
				toStage: 'POLICYHOLDER_INPUT',
			});
			if (phOpenedApp) setLinkOpened(SummaryStageStatus.finished);
			if (transitionToTriage) {
				stopPolling();
				setPhotosUploaded(SummaryStageStatus.finished);
			}
		}
	}, [getClaimData, linkGenerated]);

	const getWebappLinkProps = (props: any = {}) => ({
		status: linkGenerated,
		link: getLinkData?.webappLink?.link,
		...props,
	});

	const getLinkOpenedProps = (props: any = {}) => ({
		status: linkOpened,
		...props,
	});

	const getPhotosUploadedProps = (props: any = {}) => ({
		status: photosUploaded,
		...props,
	});

	const getSubmitProps = (props: any = {}) => ({
		disabled: photosUploaded !== SummaryStageStatus.finished,
		onClick: () => navigate(getClaimData.claim.claimDetails.nextStageUrl),
		...props,
	});

	return {
		getWebappLinkProps,
		getLinkOpenedProps,
		getPhotosUploadedProps,
		getSubmitProps,
		showDeleteButton: showDeleteButton && photosUploaded !== SummaryStageStatus.finished,
		error: linkError || ClaimSummaryError,
		loading,
		claim: getClaimData?.claim,
	};
};

export { useSummary };
