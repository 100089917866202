"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFailureStatus = exports.Status = void 0;
var Status;
(function (Status) {
    Status["CREATED"] = "CREATED";
    Status["PROCESSING_IMAGE_RESULTS"] = "PROCESSING_IMAGE_RESULTS";
    Status["PROCESSED_IMAGE_RESULTS"] = "PROCESSED_IMAGE_RESULTS";
    Status["PROCESSING_DAMAGED_PARTS"] = "PROCESSING_DAMAGED_PARTS";
    Status["PROCESSED_DAMAGED_PARTS"] = "PROCESSED_DAMAGED_PARTS";
    Status["PROCESSING_ESTIMATE"] = "PROCESSING_ESTIMATE";
    Status["PROCESSED_ESTIMATE"] = "PROCESSED_ESTIMATE";
    Status["EDITING_ESTIMATE"] = "EDITING_ESTIMATE";
    Status["WAITING_FOR_INPUT"] = "WAITING_FOR_INPUT";
    Status["PENDING"] = "PENDING";
    Status["IN_PROGRESS"] = "IN_PROGRESS";
    Status["DONE"] = "DONE";
    Status["READY"] = "READY";
    Status["FAILED"] = "FAILED";
    Status["FAILED_ESTIMATING"] = "FAILED_ESTIMATING";
    Status["FAILED_INSUFFICIENT_PHOTOS"] = "FAILED_INSUFFICIENT_PHOTOS";
    Status["FAILED_OUT_OF_SCOPE"] = "FAILED_OUT_OF_SCOPE";
    Status["FAILED_SEVERE_DAMAGE"] = "FAILED_SEVERE_DAMAGE";
    Status["FAILED_OBVIOUS_TOTAL_LOSS"] = "FAILED_OBVIOUS_TOTAL_LOSS";
    Status["FAILED_OUT_OF_HOURS"] = "FAILED_OUT_OF_HOURS";
    Status["FAILED_NON_PROCESSABLE"] = "FAILED_NON_PROCESSABLE";
    Status["FAILED_INSUFFICIENT_DATA"] = "FAILED_INSUFFICIENT_DATA";
})(Status = exports.Status || (exports.Status = {}));
const isFailureStatus = (status) => {
    return status?.startsWith('FAILED');
};
exports.isFailureStatus = isFailureStatus;
