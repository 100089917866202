"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageCategory = void 0;
var ImageCategory;
(function (ImageCategory) {
    ImageCategory["DAMAGE"] = "DAMAGE";
    ImageCategory["CORNER"] = "CORNER";
    ImageCategory["UNKNOWN"] = "UNKNOWN";
    ImageCategory["ADDITIONAL"] = "ADDITIONAL";
})(ImageCategory = exports.ImageCategory || (exports.ImageCategory = {}));
