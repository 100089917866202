import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import { Text } from '../Text';
import { Currency, formatCurrency } from './Currency';

interface TotalCostProps {
	readonly grandTotal: number;
	readonly grandTotalText?: string;
	readonly currency?: Currency;
}

const TCWrapper = styled.div`
  display: flex;
  gap: 4px;
  background: #f9f9f9;
  border-radius: 3px;
  padding 8px 16px;
  align-items: center;
`;

export const TotalCost: React.FC<TotalCostProps> = ({ grandTotal, grandTotalText, currency }) => {
	return (
		<Box>
			<TCWrapper>
				<Text typography="standard" color="#0B1F2C">
					{`${grandTotalText}:`}
				</Text>
				<Text typography="large" color="#0B1F2C">
					{formatCurrency(grandTotal, currency)}
				</Text>
			</TCWrapper>
		</Box>
	);
};
