import * as React from 'react';

function SvgDocument(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.7 5.858a.92.92 0 00-.042-.189v-.063a.751.751 0 00-.133-.196l-4.2-4.2a.75.75 0 00-.196-.133.224.224 0 00-.063 0A.616.616 0 008.835 1H4.6a2.1 2.1 0 00-2.1 2.1v9.8c0 1.16.94 2.1 2.1 2.1h7a2.1 2.1 0 002.1-2.1V5.858zm-7 .742H6a.7.7 0 110-1.4h.7a.7.7 0 110 1.4zM5.3 8.7A.7.7 0 016 8h4.2a.7.7 0 110 1.4H6a.7.7 0 01-.7-.7zm6.013-3.5L9.5 3.387V4.5a.7.7 0 00.7.7h1.113zm.987 7.7a.7.7 0 01-.7.7h-7a.7.7 0 01-.7-.7V3.1a.7.7 0 01.7-.7h3.5v2.1c0 1.16.94 2.1 2.1 2.1h2.1v6.3zM6 10.8h4.2a.7.7 0 110 1.4H6a.7.7 0 110-1.4z"
				fill="#45677E"
				opacity={0.6}
			/>
		</svg>
	);
}

export default SvgDocument;
