import { TaxStrategies } from '../../enum';
import { EstimateOperationType } from '../../enum';
import { EstimateOperationTriggerType } from '../../enum';
import { EstimateAdjustmentType } from '../../enum';
import { EstimateAdjustmentTrigger } from '../../enum';
import { AdjustmentStrategyName } from '../../enum';
import { GraphQLResolveInfo } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type EnumResolverSignature<T, AllowedValues = any> = { [key in keyof T]?: AllowedValues };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export { AdjustmentStrategyName };

export type Asset = {
  __typename?: 'Asset';
  bucket?: Maybe<Scalars['String']>;
  clientAssetId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type AuxPart = IEstimatePart & {
  __typename?: 'AuxPart';
  adjustments?: Maybe<Array<Maybe<EstimateAdjustment>>>;
  belongsTo?: Maybe<Scalars['String']>;
  customerDisplayName?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  displayPartIdentifier?: Maybe<Scalars['String']>;
  internalDisplayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oePartIdentifier?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  paintLaborHours?: Maybe<Scalars['Float']>;
  paintLevel?: Maybe<Scalars['String']>;
  paintMaterialCost?: Maybe<Scalars['Float']>;
  paintPartMaterial?: Maybe<Scalars['String']>;
  partCategory?: Maybe<Scalars['String']>;
  partManufacturer?: Maybe<Scalars['String']>;
  partOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  partSelection?: Maybe<Scalars['String']>;
  partSupplier?: Maybe<Scalars['String']>;
  partType?: Maybe<Scalars['String']>;
  repairLaborHours?: Maybe<Scalars['Float']>;
  replacementPartCost?: Maybe<Scalars['Float']>;
  stripRefitLaborHours?: Maybe<Scalars['Float']>;
  undeductedStripRefitLaborHours?: Maybe<Scalars['Float']>;
};

export type AuxPartInput = {
  adjustments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  appliedAdjustments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  belongsTo?: InputMaybe<Scalars['String']>;
  customerDisplayName?: InputMaybe<Scalars['String']>;
  dataSource?: InputMaybe<Scalars['String']>;
  displayPartIdentifier?: InputMaybe<Scalars['String']>;
  internalDisplayName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oePartIdentifier?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  paintLaborHours?: InputMaybe<Scalars['Float']>;
  paintLevel?: InputMaybe<Scalars['String']>;
  paintMaterialCost?: InputMaybe<Scalars['Float']>;
  paintPartMaterial?: InputMaybe<Scalars['String']>;
  partCategory?: InputMaybe<Scalars['String']>;
  partManufacturer?: InputMaybe<Scalars['String']>;
  partOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partSelection?: InputMaybe<Scalars['String']>;
  partSupplier?: InputMaybe<Scalars['String']>;
  partType?: InputMaybe<Scalars['String']>;
  repairLaborHours?: InputMaybe<Scalars['Float']>;
  replacementPartCost?: InputMaybe<Scalars['Float']>;
  stripRefitLaborHours?: InputMaybe<Scalars['Float']>;
  undeductedStripRefitLaborHours?: InputMaybe<Scalars['Float']>;
};

export type BodyShop = {
  __typename?: 'BodyShop';
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type CarOwner = {
  __typename?: 'CarOwner';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Carwert = {
  __typename?: 'Carwert';
  crwFile?: Maybe<Scalars['String']>;
};

export type Claim = {
  __typename?: 'Claim';
  additionalDamage?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  bodyShop?: Maybe<BodyShop>;
  carOwner?: Maybe<CarOwner>;
  circumstances?: Maybe<Scalars['String']>;
  claimDetails?: Maybe<ClaimDetails>;
  clientClaimId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  coaEstimateDisplay?: Maybe<CoaEstimateDisplay>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  estimate?: Maybe<Estimate>;
  estimateDecision?: Maybe<EstimateDecision>;
  estimatesByType?: Maybe<Array<Maybe<Estimate>>>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Image>>>;
  maximumRepairSpend?: Maybe<Scalars['Float']>;
  negotiationStatus?: Maybe<Scalars['String']>;
  nextStage?: Maybe<Scalars['String']>;
  nextStageUrl?: Maybe<NextStageUrl>;
  partMappings?: Maybe<Array<Maybe<Part>>>;
  pointsOfImpact?: Maybe<Array<Maybe<Scalars['String']>>>;
  policy?: Maybe<Scalars['String']>;
  policyMaxSpend?: Maybe<Scalars['Float']>;
  policyholderName?: Maybe<Scalars['String']>;
  processabilityTriageOutcome?: Maybe<Scalars['String']>;
  settlement?: Maybe<Settlement>;
  shinkaAmount?: Maybe<Scalars['Int']>;
  stage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusHistory?: Maybe<Array<Maybe<StatusHistory>>>;
  tlIndicator?: Maybe<Scalars['Boolean']>;
  tlStatus?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleDetails>;
  videos?: Maybe<Array<Maybe<Video>>>;
  vinVerified?: Maybe<Scalars['Boolean']>;
  vrmCheck?: Maybe<VrmCheck>;
  yesNo?: Maybe<Array<Maybe<YesNoQuestion>>>;
};

export type ClaimDetails = {
  __typename?: 'ClaimDetails';
  assignee?: Maybe<Scalars['String']>;
  carValue?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  dateOfAccident?: Maybe<Scalars['String']>;
  estimateStatus?: Maybe<Scalars['String']>;
  estimatedRepairValue?: Maybe<Scalars['Float']>;
  isPublishedStage?: Maybe<Scalars['Boolean']>;
  nextStageUrl?: Maybe<Scalars['String']>;
  phDecision?: Maybe<PhDecision>;
  poi?: Maybe<Scalars['String']>;
  stage?: Maybe<ClaimStage>;
  status?: Maybe<ClaimStatus>;
  user?: Maybe<ClaimUser>;
  validAsset?: Maybe<Asset>;
};

export type ClaimDetailsInput = {
  stage?: InputMaybe<ClaimStageInput>;
  status?: InputMaybe<ClaimStatusInput>;
};

export type ClaimInput = {
  brand?: InputMaybe<Scalars['String']>;
  circumstances?: InputMaybe<Scalars['String']>;
  clientClaimId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  dateOfAccident?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  labourRates?: InputMaybe<Array<InputMaybe<LabourRate>>>;
  mileage?: InputMaybe<Scalars['Int']>;
  notifiedUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phone?: InputMaybe<Scalars['String']>;
  pointOfImpact?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  policyMaxSpend?: InputMaybe<Scalars['Float']>;
  policyholderName?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<Scalars['String']>;
  shinkaAmount?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<VehicleInput>;
};

export type ClaimNotifications = {
  __typename?: 'ClaimNotifications';
  id?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
};

export type ClaimPartMappingsInput = {
  description?: InputMaybe<Scalars['String']>;
  part?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ClaimStage = {
  __typename?: 'ClaimStage';
  linkText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ClaimStageInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ClaimStageUpdateInput = {
  partMappings?: InputMaybe<Array<InputMaybe<ClaimPartMappingsInput>>>;
  stage?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type ClaimStatus = {
  __typename?: 'ClaimStatus';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ClaimStatusInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ClaimUser = {
  __typename?: 'ClaimUser';
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
};

export type ClaimsQueryResult = {
  __typename?: 'ClaimsQueryResult';
  claims?: Maybe<Array<Maybe<Claim>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type CoaEstimateDisplay = {
  __typename?: 'CoaEstimateDisplay';
  result?: Maybe<Scalars['Boolean']>;
};

export type Currency = {
  __typename?: 'Currency';
  options?: Maybe<CurrencyOptions>;
  symbol?: Maybe<Scalars['String']>;
};

export type CurrencyOptions = {
  __typename?: 'CurrencyOptions';
  currency?: Maybe<Scalars['String']>;
  currencyDisplay?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

export type Estimate = {
  __typename?: 'Estimate';
  additionalParts?: Maybe<Array<Maybe<EstimateAdditionalParts>>>;
  adjustments?: Maybe<Array<Maybe<EstimateAdjustment>>>;
  id?: Maybe<Scalars['String']>;
  info?: Maybe<EstimateInfo>;
  operations?: Maybe<Array<Maybe<EstimateOperation>>>;
  paint?: Maybe<EstimatePaint>;
  parts?: Maybe<Array<Maybe<EstimatePart>>>;
};

export type EstimateAdditionalCost = IEstimateAdditionalCost & {
  __typename?: 'EstimateAdditionalCost';
  cost?: Maybe<Scalars['Float']>;
  descriptor?: Maybe<Scalars['String']>;
};

export type EstimateAdditionalCostInput = {
  cost?: InputMaybe<Scalars['Float']>;
  descriptor?: InputMaybe<Scalars['String']>;
};

export type EstimateAdditionalHours = IEstimateAdditionalHours & {
  __typename?: 'EstimateAdditionalHours';
  descriptor?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
};

export type EstimateAdditionalHoursInput = {
  descriptor?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['Float']>;
};

export type EstimateAdditionalParts = {
  __typename?: 'EstimateAdditionalParts';
  belongsTo?: Maybe<Scalars['String']>;
  labourHours?: Maybe<Scalars['Float']>;
  maxCost?: Maybe<Scalars['Float']>;
  minCost?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type EstimateAdditionalPartsInput = {
  belongsTo?: InputMaybe<Scalars['String']>;
  labourHours?: InputMaybe<Scalars['Float']>;
  maxCost?: InputMaybe<Scalars['Float']>;
  minCost?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EstimateAdjustment = {
  __typename?: 'EstimateAdjustment';
  appliesTo?: Maybe<Scalars['String']>;
  delta: Scalars['Float'];
  strategy?: Maybe<EstimateAdjustmentStrategy>;
  triggerId?: Maybe<Scalars['String']>;
  triggerType?: Maybe<EstimateAdjustmentTrigger>;
  type?: Maybe<EstimateAdjustmentType>;
};

export type EstimateAdjustmentInput = {
  appliesTo?: InputMaybe<Scalars['String']>;
  delta: Scalars['Float'];
  strategy?: InputMaybe<EstimateAdjustmentStrategyInput>;
  triggerId?: InputMaybe<Scalars['String']>;
  triggerType?: InputMaybe<EstimateAdjustmentTrigger>;
  type?: InputMaybe<EstimateAdjustmentType>;
};

export type EstimateAdjustmentStrategy = {
  __typename?: 'EstimateAdjustmentStrategy';
  minimum?: Maybe<Scalars['Float']>;
  name: AdjustmentStrategyName;
  percentage?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type EstimateAdjustmentStrategyInput = {
  minimum?: InputMaybe<Scalars['Float']>;
  name: AdjustmentStrategyName;
  percentage?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['Float']>;
};

export { EstimateAdjustmentTrigger };

export { EstimateAdjustmentType };

export enum EstimateDecision {
  Accepted = 'ACCEPTED',
  NotYetComplete = 'NOT_YET_COMPLETE',
  RejectedOos = 'REJECTED_OOS',
  RejectedVin = 'REJECTED_VIN'
}

export type EstimateInfo = {
  __typename?: 'EstimateInfo';
  additionalDamage?: Maybe<Scalars['Boolean']>;
  amountIncludingTax?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  customerLanguage?: Maybe<Scalars['String']>;
  estimateType?: Maybe<Scalars['String']>;
  flags?: Maybe<Array<Scalars['String']>>;
  internalDamage?: Maybe<Scalars['String']>;
  internalLanguage?: Maybe<Scalars['String']>;
  paintLaborRate?: Maybe<Scalars['Float']>;
  repairDataRegion?: Maybe<RepairDataRegion>;
  repairLaborRate?: Maybe<Scalars['Float']>;
  stripRefitLaborRate?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxStrategy?: Maybe<TaxStrategy>;
};

export type EstimateInfoInput = {
  amountIncludingTax?: InputMaybe<Scalars['Float']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  customerLanguage?: InputMaybe<Scalars['String']>;
  estimateType?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Array<Scalars['String']>>;
  internalDamage?: InputMaybe<Scalars['String']>;
  internalLanguage?: InputMaybe<Scalars['String']>;
  paintLaborRate?: InputMaybe<Scalars['Float']>;
  repairDataRegion?: InputMaybe<RepairDataRegionInput>;
  repairLaborRate?: InputMaybe<Scalars['Float']>;
  stripRefitLaborRate?: InputMaybe<Scalars['Float']>;
  taxRate?: InputMaybe<Scalars['Float']>;
  taxStrategy?: InputMaybe<TaxStrategyInput>;
};

export type EstimateInput = {
  additionalCosts?: InputMaybe<Array<InputMaybe<EstimateAdditionalCostInput>>>;
  additionalHours?: InputMaybe<Array<InputMaybe<EstimateAdditionalHoursInput>>>;
  additionalParts?: InputMaybe<Array<InputMaybe<EstimateAdditionalPartsInput>>>;
  adjustments?: InputMaybe<Array<InputMaybe<EstimateAdjustmentInput>>>;
  id?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<EstimateInfoInput>;
  operations?: InputMaybe<Array<InputMaybe<EstimateOperationInput>>>;
  paint?: InputMaybe<EstimatePaintInput>;
  parts?: InputMaybe<Array<InputMaybe<EstimatePartInput>>>;
};

export type EstimateOperation = {
  __typename?: 'EstimateOperation';
  cost?: Maybe<Scalars['Float']>;
  displayDescription?: Maybe<Scalars['String']>;
  labor?: Maybe<OperationLabor>;
  quantity: Scalars['Float'];
  rowType?: Maybe<Scalars['String']>;
  triggerType: EstimateOperationTriggerType;
  triggers?: Maybe<Array<Maybe<EstimateOperationTrigger>>>;
  type: EstimateOperationType;
};

export type EstimateOperationInput = {
  cost?: InputMaybe<Scalars['Float']>;
  displayDescription?: InputMaybe<Scalars['String']>;
  labor?: InputMaybe<OperationLaborInput>;
  quantity: Scalars['Float'];
  rowType?: InputMaybe<Scalars['String']>;
  triggerType: EstimateOperationTriggerType;
  triggers?: InputMaybe<Array<InputMaybe<EstimateOperationTriggerInput>>>;
  type: EstimateOperationType;
};

export type EstimateOperationTrigger = {
  __typename?: 'EstimateOperationTrigger';
  operation?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
};

export type EstimateOperationTriggerInput = {
  operation?: InputMaybe<Scalars['String']>;
  part?: InputMaybe<Scalars['String']>;
};

export { EstimateOperationTriggerType };

export { EstimateOperationType };

export type EstimatePaint = IEstimatePaint & {
  __typename?: 'EstimatePaint';
  additionalLaborHours?: Maybe<Scalars['Float']>;
  additionalMaterialCost?: Maybe<Scalars['Float']>;
};

export type EstimatePaintInput = {
  additionalLaborHours?: InputMaybe<Scalars['Float']>;
  additionalMaterialCost?: InputMaybe<Scalars['Float']>;
};

export type EstimatePart = IEstimatePart & {
  __typename?: 'EstimatePart';
  adjustments?: Maybe<Array<Maybe<EstimateAdjustment>>>;
  auxParts?: Maybe<Array<Maybe<AuxPart>>>;
  belongsTo?: Maybe<Scalars['String']>;
  customerDisplayName?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  displayPartIdentifier?: Maybe<Scalars['String']>;
  internalDisplayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oePartIdentifier?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  paintLaborHours?: Maybe<Scalars['Float']>;
  paintLevel?: Maybe<Scalars['String']>;
  paintMaterialCost?: Maybe<Scalars['Float']>;
  paintPartMaterial?: Maybe<Scalars['String']>;
  partCategory?: Maybe<Scalars['String']>;
  partManufacturer?: Maybe<Scalars['String']>;
  partOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  partSelection?: Maybe<Scalars['String']>;
  partSupplier?: Maybe<Scalars['String']>;
  partType?: Maybe<Scalars['String']>;
  repairLaborHours?: Maybe<Scalars['Float']>;
  replacementPartCost?: Maybe<Scalars['Float']>;
  stripRefitLaborHours?: Maybe<Scalars['Float']>;
  undeductedStripRefitLaborHours?: Maybe<Scalars['Float']>;
};

export type EstimatePartInput = {
  adjustments?: InputMaybe<Array<InputMaybe<EstimateAdjustmentInput>>>;
  appliedAdjustments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  auxParts?: InputMaybe<Array<InputMaybe<AuxPartInput>>>;
  belongsTo?: InputMaybe<Scalars['String']>;
  customerDisplayName?: InputMaybe<Scalars['String']>;
  dataSource?: InputMaybe<Scalars['String']>;
  displayPartIdentifier?: InputMaybe<Scalars['String']>;
  internalDisplayName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oePartIdentifier?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  paintLaborHours?: InputMaybe<Scalars['Float']>;
  paintLevel?: InputMaybe<Scalars['String']>;
  paintMaterialCost?: InputMaybe<Scalars['Float']>;
  paintPartMaterial?: InputMaybe<Scalars['String']>;
  partCategory?: InputMaybe<Scalars['String']>;
  partManufacturer?: InputMaybe<Scalars['String']>;
  partOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partSelection?: InputMaybe<Scalars['String']>;
  partSupplier?: InputMaybe<Scalars['String']>;
  partType?: InputMaybe<Scalars['String']>;
  repairLaborHours?: InputMaybe<Scalars['Float']>;
  replacementPartCost?: InputMaybe<Scalars['Float']>;
  stripRefitLaborHours?: InputMaybe<Scalars['Float']>;
  undeductedStripRefitLaborHours?: InputMaybe<Scalars['Float']>;
};

export type EstimaticsExportGtMotiveInput = {
  clientClaimId: Scalars['String'];
  clientId: Scalars['String'];
  estimate: EstimateInput;
  id: Scalars['String'];
  policy: Scalars['String'];
  vehicle?: InputMaybe<EstimaticsExportGtMotiveVehicleInput>;
};

export type EstimaticsExportGtMotiveVehicleInput = {
  dateOfFirstRegistration?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  modelSubtypeCode?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<Scalars['String']>;
  repairDataIdentifiers?: InputMaybe<RepairDataIdentifiers>;
  valuation?: InputMaybe<Scalars['Float']>;
  vehicleIdentifier?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type EstimaticsExportInput = {
  clientClaimId?: InputMaybe<Scalars['String']>;
  estimate?: InputMaybe<EstimateInput>;
  id?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<EstimaticsExportVehicleInput>;
};

export type EstimaticsExportVehicleInput = {
  dateOfFirstRegistration?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  modelSubtypeCode?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<Scalars['String']>;
  repairDataIdentifiers?: InputMaybe<RepairDataIdentifiers>;
  valuation?: InputMaybe<Scalars['Float']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type FilterInput = {
  id?: InputMaybe<Scalars['String']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};

export type GtMotiveEstimateDoneResponse = {
  __typename?: 'GTMotiveEstimateDoneResponse';
  gtMotiveEstimateAccessUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mismatchedParts?: Maybe<Scalars['Boolean']>;
};

export type GtMotiveEstimateReceivedResponse = {
  __typename?: 'GTMotiveEstimateReceivedResponse';
  gtMotiveRequestUid?: Maybe<Scalars['String']>;
};

export type IEstimateAdditionalCost = {
  cost?: Maybe<Scalars['Float']>;
  descriptor?: Maybe<Scalars['String']>;
};

export type IEstimateAdditionalHours = {
  descriptor?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
};

export type IEstimatePaint = {
  additionalLaborHours?: Maybe<Scalars['Float']>;
  additionalMaterialCost?: Maybe<Scalars['Float']>;
};

export type IEstimatePart = {
  adjustments?: Maybe<Array<Maybe<EstimateAdjustment>>>;
  belongsTo?: Maybe<Scalars['String']>;
  customerDisplayName?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  displayPartIdentifier?: Maybe<Scalars['String']>;
  internalDisplayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oePartIdentifier?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  paintLaborHours?: Maybe<Scalars['Float']>;
  paintLevel?: Maybe<Scalars['String']>;
  paintMaterialCost?: Maybe<Scalars['Float']>;
  paintPartMaterial?: Maybe<Scalars['String']>;
  partCategory?: Maybe<Scalars['String']>;
  partManufacturer?: Maybe<Scalars['String']>;
  partOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  partSelection?: Maybe<Scalars['String']>;
  partSupplier?: Maybe<Scalars['String']>;
  partType?: Maybe<Scalars['String']>;
  repairLaborHours?: Maybe<Scalars['Float']>;
  replacementPartCost?: Maybe<Scalars['Float']>;
  stripRefitLaborHours?: Maybe<Scalars['Float']>;
  undeductedStripRefitLaborHours?: Maybe<Scalars['Float']>;
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['String']>;
  imageCategory?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type LabourRate = {
  estimateType?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type Link = {
  __typename?: 'Link';
  link?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createClaim?: Maybe<Claim>;
  deleteClaim?: Maybe<Scalars['Boolean']>;
  exportToGTMotive?: Maybe<GtMotiveEstimateReceivedResponse>;
  saveEdits?: Maybe<Scalars['Boolean']>;
  sendNotification?: Maybe<ClaimNotifications>;
  setEstimateDecision?: Maybe<Claim>;
  setEstimateEditing?: Maybe<StageAndStatus>;
  setEstimaticsExport?: Maybe<Carwert>;
  setNegotiationStatus?: Maybe<Claim>;
  setPartOperation?: Maybe<RdapiResponse>;
  startEdits?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateClaimArgs = {
  data?: InputMaybe<ClaimInput>;
};


export type MutationDeleteClaimArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationExportToGtMotiveArgs = {
  claimId?: InputMaybe<Scalars['String']>;
  exportInput?: InputMaybe<EstimaticsExportGtMotiveInput>;
};


export type MutationSaveEditsArgs = {
  changes?: InputMaybe<EstimateInput>;
  claimId?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
};


export type MutationSendNotificationArgs = {
  channel?: InputMaybe<Scalars['String']>;
  claimId?: InputMaybe<Scalars['String']>;
  messageBody?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationSetEstimateDecisionArgs = {
  claimId?: InputMaybe<Scalars['String']>;
  estimateDecision?: InputMaybe<EstimateDecision>;
};


export type MutationSetEstimateEditingArgs = {
  claimId?: InputMaybe<Scalars['String']>;
};


export type MutationSetEstimaticsExportArgs = {
  claimId?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  exportInput?: InputMaybe<EstimaticsExportInput>;
};


export type MutationSetNegotiationStatusArgs = {
  claimId?: InputMaybe<Scalars['String']>;
  negotiationStatus?: InputMaybe<Scalars['String']>;
};


export type MutationSetPartOperationArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  partOperationInput?: InputMaybe<PartOperationInput>;
};


export type MutationStartEditsArgs = {
  claimId?: InputMaybe<Scalars['String']>;
};

export type NextStageUrl = {
  __typename?: 'NextStageUrl';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type NextStageUrlData = {
  __typename?: 'NextStageUrlData';
  stage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NextStageUrlInput = {
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export enum NoTotalLossDecisionReason {
  MissingPolicyMaxSpend = 'MISSING_POLICY_MAX_SPEND',
  MissingVehicleMake = 'MISSING_VEHICLE_MAKE',
  MissingVehicleValuation = 'MISSING_VEHICLE_VALUATION'
}

export type Notification = {
  __typename?: 'Notification';
  channel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type OperationLabor = {
  __typename?: 'OperationLabor';
  hours: Scalars['Float'];
  rate: Scalars['Float'];
};

export type OperationLaborInput = {
  hours: Scalars['Float'];
  rate: Scalars['Float'];
};

export enum OperationType {
  Blend = 'BLEND',
  Repair = 'REPAIR',
  RepairPaint = 'REPAIR_PAINT',
  Replace = 'REPLACE'
}

export enum OutOfScopeReason {
  FailedToRetrieveData = 'FAILED_TO_RETRIEVE_DATA',
  Fraud = 'FRAUD',
  FrontWindshield = 'FRONT_WINDSHIELD',
  HighRepairValue = 'HIGH_REPAIR_VALUE',
  InsufficientData = 'INSUFFICIENT_DATA',
  InsufficientPhotos = 'INSUFFICIENT_PHOTOS',
  InsufficientVideo = 'INSUFFICIENT_VIDEO',
  InteriorDamage = 'INTERIOR_DAMAGE',
  InternalDamage = 'INTERNAL_DAMAGE',
  InvalidVehicle = 'INVALID_VEHICLE',
  MechanicalDamage = 'MECHANICAL_DAMAGE',
  MultipleVehicles = 'MULTIPLE_VEHICLES',
  NotEnoughImages = 'NOT_ENOUGH_IMAGES',
  OutOfHours = 'OUT_OF_HOURS',
  RelevantDamage = 'RELEVANT_DAMAGE',
  TechnicalIssues = 'TECHNICAL_ISSUES',
  ValuationUnavailable = 'VALUATION_UNAVAILABLE',
  Vehicles = 'VEHICLES',
  VehicleNotDecoded = 'VEHICLE_NOT_DECODED'
}

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Part = {
  __typename?: 'Part';
  description?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PartOperationInput = {
  calculateOverlap?: InputMaybe<Scalars['Boolean']>;
  carAge?: InputMaybe<Scalars['Float']>;
  estimate?: InputMaybe<EstimateInput>;
  flagIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<OperationType>;
  optionIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  panel?: InputMaybe<Scalars['String']>;
  partId?: InputMaybe<Scalars['String']>;
  remove?: InputMaybe<Scalars['Boolean']>;
  vehicleIdentifier?: InputMaybe<Scalars['String']>;
};

export type PartsInput = {
  country?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  panel?: InputMaybe<Scalars['String']>;
  vehicleIdentifier?: InputMaybe<Scalars['String']>;
};

export type Payee = {
  __typename?: 'Payee';
  iban?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
};

export type PhDecision = {
  __typename?: 'PhDecision';
  counterOffer?: Maybe<Scalars['Float']>;
  decision?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  ccpClaims?: Maybe<ClaimsQueryResult>;
  claim?: Maybe<Claim>;
  claimNotifications?: Maybe<ClaimNotifications>;
  claims?: Maybe<ClaimsQueryResult>;
  downloadImages?: Maybe<Scalars['String']>;
  getGTMotiveEstimate?: Maybe<GtMotiveEstimateDoneResponse>;
  getParts?: Maybe<Array<Maybe<RdapiPart>>>;
  getValidFlags?: Maybe<Array<Maybe<ValidFlag>>>;
  latestClaimUsers?: Maybe<Array<Maybe<User>>>;
  payee?: Maybe<Payee>;
  quattroruoteLink?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
  webappLink?: Maybe<Link>;
};


export type QueryCcpClaimsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Array<InputMaybe<SearchInput>>>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryClaimArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryClaimNotificationsArgs = {
  claimId?: InputMaybe<Scalars['String']>;
};


export type QueryClaimsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Array<InputMaybe<SearchInput>>>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadImagesArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetGtMotiveEstimateArgs = {
  claimId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
};


export type QueryGetPartsArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  partsInput?: InputMaybe<PartsInput>;
};


export type QueryGetValidFlagsArgs = {
  claimId?: InputMaybe<Scalars['String']>;
  rdpEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPayeeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryQuattroruoteLinkArgs = {
  claimId?: InputMaybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  emailQuery?: InputMaybe<Scalars['String']>;
  showInternalUsers?: InputMaybe<Scalars['Boolean']>;
};


export type QueryWebappLinkArgs = {
  id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type RdapiCombination = {
  __typename?: 'RDAPICombination';
  friends?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
};

export type RdapiOption = {
  __typename?: 'RDAPIOption';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type RdapiPart = {
  __typename?: 'RDAPIPart';
  combinations?: Maybe<Array<Maybe<RdapiCombination>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<RdapiOption>>>;
  partCategory?: Maybe<Scalars['String']>;
};

export type RdapiResponse = {
  __typename?: 'RDAPIResponse';
  id?: Maybe<Scalars['String']>;
  paint?: Maybe<EstimatePaint>;
  parts?: Maybe<Array<Maybe<EstimatePart>>>;
};

export type RepairDataIdentifiers = {
  autovista?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RepairDataRegion = {
  __typename?: 'RepairDataRegion';
  countryCode?: Maybe<Scalars['String']>;
};

export type RepairDataRegionInput = {
  countryCode?: InputMaybe<Scalars['String']>;
};

export type SearchInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type Settlement = {
  __typename?: 'Settlement';
  decision?: Maybe<SettlementDecision>;
  noTotalLossDecisionReason?: Maybe<NoTotalLossDecisionReason>;
  outOfScopeReason?: Maybe<OutOfScopeReason>;
};

export enum SettlementDecision {
  InProgress = 'IN_PROGRESS',
  NoDecision = 'NO_DECISION',
  OutOfScope = 'OUT_OF_SCOPE',
  Repairable = 'REPAIRABLE',
  TotalLoss = 'TOTAL_LOSS'
}

export type StageAndStatus = {
  __typename?: 'StageAndStatus';
  stage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type StageAndStatusInput = {
  stage?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type StatusHistory = {
  __typename?: 'StatusHistory';
  changedBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  fromStage?: Maybe<Scalars['String']>;
  fromStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  toStage?: Maybe<Scalars['String']>;
  toStatus?: Maybe<Scalars['String']>;
};

export type TaxBand = {
  __typename?: 'TaxBand';
  from: Scalars['Float'];
  rate: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
};

export type TaxBandInput = {
  from: Scalars['Int'];
  rate: Scalars['Int'];
  to?: InputMaybe<Scalars['Int']>;
};

export { TaxStrategies };

export type TaxStrategy = {
  __typename?: 'TaxStrategy';
  bands?: Maybe<Array<Maybe<TaxBand>>>;
  rate?: Maybe<Scalars['Float']>;
  strategy: TaxStrategies;
};

export type TaxStrategyInput = {
  bands?: InputMaybe<Array<InputMaybe<TaxBandInput>>>;
  rate?: InputMaybe<Scalars['Int']>;
  strategy: TaxStrategies;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ValidFlag = {
  __typename?: 'ValidFlag';
  id?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Float']>;
  object?: Maybe<Scalars['String']>;
  overwrite?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type VehicleDetails = {
  __typename?: 'VehicleDetails';
  dateOfFirstRegistration?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  modelSubtypeCode?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  registration?: Maybe<Scalars['String']>;
  valuation?: Maybe<Scalars['Float']>;
  vehicleIdentifier?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type VehicleInput = {
  dateOfFirstRegistration?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  modelSubtypeCode?: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<Scalars['String']>;
  registrationYearMonth?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type Video = {
  __typename?: 'Video';
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type VrmCheck = {
  __typename?: 'VrmCheck';
  outcome?: Maybe<VrmCheckOutcome>;
};

export enum VrmCheckOutcome {
  Matched = 'MATCHED',
  NotMatched = 'NOT_MATCHED',
  UnableToAssess = 'UNABLE_TO_ASSESS'
}

export type YesNoQuestion = {
  __typename?: 'YesNoQuestion';
  question?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  typicalAnswer?: Maybe<Scalars['String']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Address: ResolverTypeWrapper<Address>;
  AdjustmentStrategyName: AdjustmentStrategyName;
  Asset: ResolverTypeWrapper<Asset>;
  AuxPart: ResolverTypeWrapper<AuxPart>;
  AuxPartInput: AuxPartInput;
  BodyShop: ResolverTypeWrapper<BodyShop>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CarOwner: ResolverTypeWrapper<CarOwner>;
  Carwert: ResolverTypeWrapper<Carwert>;
  Claim: ResolverTypeWrapper<Claim>;
  ClaimDetails: ResolverTypeWrapper<ClaimDetails>;
  ClaimDetailsInput: ClaimDetailsInput;
  ClaimInput: ClaimInput;
  ClaimNotifications: ResolverTypeWrapper<ClaimNotifications>;
  ClaimPartMappingsInput: ClaimPartMappingsInput;
  ClaimStage: ResolverTypeWrapper<ClaimStage>;
  ClaimStageInput: ClaimStageInput;
  ClaimStageUpdateInput: ClaimStageUpdateInput;
  ClaimStatus: ResolverTypeWrapper<ClaimStatus>;
  ClaimStatusInput: ClaimStatusInput;
  ClaimUser: ResolverTypeWrapper<ClaimUser>;
  ClaimsQueryResult: ResolverTypeWrapper<ClaimsQueryResult>;
  CoaEstimateDisplay: ResolverTypeWrapper<CoaEstimateDisplay>;
  Currency: ResolverTypeWrapper<Currency>;
  CurrencyOptions: ResolverTypeWrapper<CurrencyOptions>;
  Estimate: ResolverTypeWrapper<Estimate>;
  EstimateAdditionalCost: ResolverTypeWrapper<EstimateAdditionalCost>;
  EstimateAdditionalCostInput: EstimateAdditionalCostInput;
  EstimateAdditionalHours: ResolverTypeWrapper<EstimateAdditionalHours>;
  EstimateAdditionalHoursInput: EstimateAdditionalHoursInput;
  EstimateAdditionalParts: ResolverTypeWrapper<EstimateAdditionalParts>;
  EstimateAdditionalPartsInput: EstimateAdditionalPartsInput;
  EstimateAdjustment: ResolverTypeWrapper<EstimateAdjustment>;
  EstimateAdjustmentInput: EstimateAdjustmentInput;
  EstimateAdjustmentStrategy: ResolverTypeWrapper<EstimateAdjustmentStrategy>;
  EstimateAdjustmentStrategyInput: EstimateAdjustmentStrategyInput;
  EstimateAdjustmentTrigger: EstimateAdjustmentTrigger;
  EstimateAdjustmentType: EstimateAdjustmentType;
  EstimateDecision: EstimateDecision;
  EstimateInfo: ResolverTypeWrapper<EstimateInfo>;
  EstimateInfoInput: EstimateInfoInput;
  EstimateInput: EstimateInput;
  EstimateOperation: ResolverTypeWrapper<EstimateOperation>;
  EstimateOperationInput: EstimateOperationInput;
  EstimateOperationTrigger: ResolverTypeWrapper<EstimateOperationTrigger>;
  EstimateOperationTriggerInput: EstimateOperationTriggerInput;
  EstimateOperationTriggerType: EstimateOperationTriggerType;
  EstimateOperationType: EstimateOperationType;
  EstimatePaint: ResolverTypeWrapper<EstimatePaint>;
  EstimatePaintInput: EstimatePaintInput;
  EstimatePart: ResolverTypeWrapper<EstimatePart>;
  EstimatePartInput: EstimatePartInput;
  EstimaticsExportGTMotiveInput: EstimaticsExportGtMotiveInput;
  EstimaticsExportGTMotiveVehicleInput: EstimaticsExportGtMotiveVehicleInput;
  EstimaticsExportInput: EstimaticsExportInput;
  EstimaticsExportVehicleInput: EstimaticsExportVehicleInput;
  FilterInput: FilterInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GTMotiveEstimateDoneResponse: ResolverTypeWrapper<GtMotiveEstimateDoneResponse>;
  GTMotiveEstimateReceivedResponse: ResolverTypeWrapper<GtMotiveEstimateReceivedResponse>;
  IEstimateAdditionalCost: ResolversTypes['EstimateAdditionalCost'];
  IEstimateAdditionalHours: ResolversTypes['EstimateAdditionalHours'];
  IEstimatePaint: ResolversTypes['EstimatePaint'];
  IEstimatePart: ResolversTypes['AuxPart'] | ResolversTypes['EstimatePart'];
  Image: ResolverTypeWrapper<Image>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  LabourRate: LabourRate;
  Link: ResolverTypeWrapper<Link>;
  Mutation: ResolverTypeWrapper<{}>;
  NextStageUrl: ResolverTypeWrapper<NextStageUrl>;
  NextStageUrlData: ResolverTypeWrapper<NextStageUrlData>;
  NextStageUrlInput: NextStageUrlInput;
  NoTotalLossDecisionReason: NoTotalLossDecisionReason;
  Notification: ResolverTypeWrapper<Notification>;
  OperationLabor: ResolverTypeWrapper<OperationLabor>;
  OperationLaborInput: OperationLaborInput;
  OperationType: OperationType;
  OutOfScopeReason: OutOfScopeReason;
  PaginationInput: PaginationInput;
  Part: ResolverTypeWrapper<Part>;
  PartOperationInput: PartOperationInput;
  PartsInput: PartsInput;
  Payee: ResolverTypeWrapper<Payee>;
  PhDecision: ResolverTypeWrapper<PhDecision>;
  Query: ResolverTypeWrapper<{}>;
  RDAPICombination: ResolverTypeWrapper<RdapiCombination>;
  RDAPIOption: ResolverTypeWrapper<RdapiOption>;
  RDAPIPart: ResolverTypeWrapper<RdapiPart>;
  RDAPIResponse: ResolverTypeWrapper<RdapiResponse>;
  RepairDataIdentifiers: RepairDataIdentifiers;
  RepairDataRegion: ResolverTypeWrapper<RepairDataRegion>;
  RepairDataRegionInput: RepairDataRegionInput;
  SearchInput: SearchInput;
  Settlement: ResolverTypeWrapper<Settlement>;
  SettlementDecision: SettlementDecision;
  StageAndStatus: ResolverTypeWrapper<StageAndStatus>;
  StageAndStatusInput: StageAndStatusInput;
  StatusHistory: ResolverTypeWrapper<StatusHistory>;
  String: ResolverTypeWrapper<Scalars['String']>;
  TaxBand: ResolverTypeWrapper<TaxBand>;
  TaxBandInput: TaxBandInput;
  TaxStrategies: TaxStrategies;
  TaxStrategy: ResolverTypeWrapper<TaxStrategy>;
  TaxStrategyInput: TaxStrategyInput;
  User: ResolverTypeWrapper<User>;
  ValidFlag: ResolverTypeWrapper<ValidFlag>;
  VehicleDetails: ResolverTypeWrapper<VehicleDetails>;
  VehicleInput: VehicleInput;
  Video: ResolverTypeWrapper<Video>;
  VrmCheck: ResolverTypeWrapper<VrmCheck>;
  VrmCheckOutcome: VrmCheckOutcome;
  YesNoQuestion: ResolverTypeWrapper<YesNoQuestion>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Address: Address;
  Asset: Asset;
  AuxPart: AuxPart;
  AuxPartInput: AuxPartInput;
  BodyShop: BodyShop;
  Boolean: Scalars['Boolean'];
  CarOwner: CarOwner;
  Carwert: Carwert;
  Claim: Claim;
  ClaimDetails: ClaimDetails;
  ClaimDetailsInput: ClaimDetailsInput;
  ClaimInput: ClaimInput;
  ClaimNotifications: ClaimNotifications;
  ClaimPartMappingsInput: ClaimPartMappingsInput;
  ClaimStage: ClaimStage;
  ClaimStageInput: ClaimStageInput;
  ClaimStageUpdateInput: ClaimStageUpdateInput;
  ClaimStatus: ClaimStatus;
  ClaimStatusInput: ClaimStatusInput;
  ClaimUser: ClaimUser;
  ClaimsQueryResult: ClaimsQueryResult;
  CoaEstimateDisplay: CoaEstimateDisplay;
  Currency: Currency;
  CurrencyOptions: CurrencyOptions;
  Estimate: Estimate;
  EstimateAdditionalCost: EstimateAdditionalCost;
  EstimateAdditionalCostInput: EstimateAdditionalCostInput;
  EstimateAdditionalHours: EstimateAdditionalHours;
  EstimateAdditionalHoursInput: EstimateAdditionalHoursInput;
  EstimateAdditionalParts: EstimateAdditionalParts;
  EstimateAdditionalPartsInput: EstimateAdditionalPartsInput;
  EstimateAdjustment: EstimateAdjustment;
  EstimateAdjustmentInput: EstimateAdjustmentInput;
  EstimateAdjustmentStrategy: EstimateAdjustmentStrategy;
  EstimateAdjustmentStrategyInput: EstimateAdjustmentStrategyInput;
  EstimateInfo: EstimateInfo;
  EstimateInfoInput: EstimateInfoInput;
  EstimateInput: EstimateInput;
  EstimateOperation: EstimateOperation;
  EstimateOperationInput: EstimateOperationInput;
  EstimateOperationTrigger: EstimateOperationTrigger;
  EstimateOperationTriggerInput: EstimateOperationTriggerInput;
  EstimatePaint: EstimatePaint;
  EstimatePaintInput: EstimatePaintInput;
  EstimatePart: EstimatePart;
  EstimatePartInput: EstimatePartInput;
  EstimaticsExportGTMotiveInput: EstimaticsExportGtMotiveInput;
  EstimaticsExportGTMotiveVehicleInput: EstimaticsExportGtMotiveVehicleInput;
  EstimaticsExportInput: EstimaticsExportInput;
  EstimaticsExportVehicleInput: EstimaticsExportVehicleInput;
  FilterInput: FilterInput;
  Float: Scalars['Float'];
  GTMotiveEstimateDoneResponse: GtMotiveEstimateDoneResponse;
  GTMotiveEstimateReceivedResponse: GtMotiveEstimateReceivedResponse;
  IEstimateAdditionalCost: ResolversParentTypes['EstimateAdditionalCost'];
  IEstimateAdditionalHours: ResolversParentTypes['EstimateAdditionalHours'];
  IEstimatePaint: ResolversParentTypes['EstimatePaint'];
  IEstimatePart: ResolversParentTypes['AuxPart'] | ResolversParentTypes['EstimatePart'];
  Image: Image;
  Int: Scalars['Int'];
  LabourRate: LabourRate;
  Link: Link;
  Mutation: {};
  NextStageUrl: NextStageUrl;
  NextStageUrlData: NextStageUrlData;
  NextStageUrlInput: NextStageUrlInput;
  Notification: Notification;
  OperationLabor: OperationLabor;
  OperationLaborInput: OperationLaborInput;
  PaginationInput: PaginationInput;
  Part: Part;
  PartOperationInput: PartOperationInput;
  PartsInput: PartsInput;
  Payee: Payee;
  PhDecision: PhDecision;
  Query: {};
  RDAPICombination: RdapiCombination;
  RDAPIOption: RdapiOption;
  RDAPIPart: RdapiPart;
  RDAPIResponse: RdapiResponse;
  RepairDataIdentifiers: RepairDataIdentifiers;
  RepairDataRegion: RepairDataRegion;
  RepairDataRegionInput: RepairDataRegionInput;
  SearchInput: SearchInput;
  Settlement: Settlement;
  StageAndStatus: StageAndStatus;
  StageAndStatusInput: StageAndStatusInput;
  StatusHistory: StatusHistory;
  String: Scalars['String'];
  TaxBand: TaxBand;
  TaxBandInput: TaxBandInput;
  TaxStrategy: TaxStrategy;
  TaxStrategyInput: TaxStrategyInput;
  User: User;
  ValidFlag: ValidFlag;
  VehicleDetails: VehicleDetails;
  VehicleInput: VehicleInput;
  Video: Video;
  VrmCheck: VrmCheck;
  YesNoQuestion: YesNoQuestion;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdjustmentStrategyNameResolvers = EnumResolverSignature<{ ABSOLUTE?: any, PERCENTAGE?: any, PERCENTAGE_EXCLUDING_MINIMUM?: any }, ResolversTypes['AdjustmentStrategyName']>;

export type AssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']> = {
  bucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientAssetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuxPartResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuxPart'] = ResolversParentTypes['AuxPart']> = {
  adjustments?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimateAdjustment']>>>, ParentType, ContextType>;
  belongsTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayPartIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internalDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oePartIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paintLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paintLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paintMaterialCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paintPartMaterial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partManufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  partSelection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partSupplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repairLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  replacementPartCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stripRefitLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  undeductedStripRefitLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BodyShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['BodyShop'] = ResolversParentTypes['BodyShop']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarOwner'] = ResolversParentTypes['CarOwner']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarwertResolvers<ContextType = any, ParentType extends ResolversParentTypes['Carwert'] = ResolversParentTypes['Carwert']> = {
  crwFile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimResolvers<ContextType = any, ParentType extends ResolversParentTypes['Claim'] = ResolversParentTypes['Claim']> = {
  additionalDamage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  assets?: Resolver<Maybe<Array<Maybe<ResolversTypes['Asset']>>>, ParentType, ContextType>;
  bodyShop?: Resolver<Maybe<ResolversTypes['BodyShop']>, ParentType, ContextType>;
  carOwner?: Resolver<Maybe<ResolversTypes['CarOwner']>, ParentType, ContextType>;
  circumstances?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  claimDetails?: Resolver<Maybe<ResolversTypes['ClaimDetails']>, ParentType, ContextType>;
  clientClaimId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coaEstimateDisplay?: Resolver<Maybe<ResolversTypes['CoaEstimateDisplay']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimate?: Resolver<Maybe<ResolversTypes['Estimate']>, ParentType, ContextType>;
  estimateDecision?: Resolver<Maybe<ResolversTypes['EstimateDecision']>, ParentType, ContextType>;
  estimatesByType?: Resolver<Maybe<Array<Maybe<ResolversTypes['Estimate']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['Image']>>>, ParentType, ContextType>;
  maximumRepairSpend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negotiationStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextStage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextStageUrl?: Resolver<Maybe<ResolversTypes['NextStageUrl']>, ParentType, ContextType>;
  partMappings?: Resolver<Maybe<Array<Maybe<ResolversTypes['Part']>>>, ParentType, ContextType>;
  pointsOfImpact?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  policy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  policyMaxSpend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  policyholderName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  processabilityTriageOutcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  settlement?: Resolver<Maybe<ResolversTypes['Settlement']>, ParentType, ContextType>;
  shinkaAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusHistory?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatusHistory']>>>, ParentType, ContextType>;
  tlIndicator?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tlStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<ResolversTypes['VehicleDetails']>, ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<Maybe<ResolversTypes['Video']>>>, ParentType, ContextType>;
  vinVerified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  vrmCheck?: Resolver<Maybe<ResolversTypes['VrmCheck']>, ParentType, ContextType>;
  yesNo?: Resolver<Maybe<Array<Maybe<ResolversTypes['YesNoQuestion']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimDetails'] = ResolversParentTypes['ClaimDetails']> = {
  assignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  claimId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  dateOfAccident?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimateStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimatedRepairValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isPublishedStage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  nextStageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phDecision?: Resolver<Maybe<ResolversTypes['PhDecision']>, ParentType, ContextType>;
  poi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<Maybe<ResolversTypes['ClaimStage']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ClaimStatus']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['ClaimUser']>, ParentType, ContextType>;
  validAsset?: Resolver<Maybe<ResolversTypes['Asset']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimNotificationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimNotifications'] = ResolversParentTypes['ClaimNotifications']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['Notification']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimStageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimStage'] = ResolversParentTypes['ClaimStage']> = {
  linkText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimStatus'] = ResolversParentTypes['ClaimStatus']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimUser'] = ResolversParentTypes['ClaimUser']> = {
  familyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimsQueryResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimsQueryResult'] = ResolversParentTypes['ClaimsQueryResult']> = {
  claims?: Resolver<Maybe<Array<Maybe<ResolversTypes['Claim']>>>, ParentType, ContextType>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoaEstimateDisplayResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoaEstimateDisplay'] = ResolversParentTypes['CoaEstimateDisplay']> = {
  result?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Currency'] = ResolversParentTypes['Currency']> = {
  options?: Resolver<Maybe<ResolversTypes['CurrencyOptions']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyOptions'] = ResolversParentTypes['CurrencyOptions']> = {
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Estimate'] = ResolversParentTypes['Estimate']> = {
  additionalParts?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimateAdditionalParts']>>>, ParentType, ContextType>;
  adjustments?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimateAdjustment']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['EstimateInfo']>, ParentType, ContextType>;
  operations?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimateOperation']>>>, ParentType, ContextType>;
  paint?: Resolver<Maybe<ResolversTypes['EstimatePaint']>, ParentType, ContextType>;
  parts?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimatePart']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateAdditionalCostResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateAdditionalCost'] = ResolversParentTypes['EstimateAdditionalCost']> = {
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  descriptor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateAdditionalHoursResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateAdditionalHours'] = ResolversParentTypes['EstimateAdditionalHours']> = {
  descriptor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateAdditionalPartsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateAdditionalParts'] = ResolversParentTypes['EstimateAdditionalParts']> = {
  belongsTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labourHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateAdjustment'] = ResolversParentTypes['EstimateAdjustment']> = {
  appliesTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delta?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  strategy?: Resolver<Maybe<ResolversTypes['EstimateAdjustmentStrategy']>, ParentType, ContextType>;
  triggerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  triggerType?: Resolver<Maybe<ResolversTypes['EstimateAdjustmentTrigger']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EstimateAdjustmentType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateAdjustmentStrategyResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateAdjustmentStrategy'] = ResolversParentTypes['EstimateAdjustmentStrategy']> = {
  minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['AdjustmentStrategyName'], ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateAdjustmentTriggerResolvers = EnumResolverSignature<{ BUSINESS_RULE?: any, MANUAL?: any, POLICY?: any, VISUAL_PART_ASSESSMENT?: any }, ResolversTypes['EstimateAdjustmentTrigger']>;

export type EstimateAdjustmentTypeResolvers = EnumResolverSignature<{ NORMALIA?: any, OVERLAP_DEDUCATION?: any, PAINT_MATERIAL_COST_DISCOUNT?: any, PARTIAL_REFINISH?: any, PART_PRICE_DISCOUNT?: any, STRIP_REFIT_MANUAL_OVERRIDE?: any }, ResolversTypes['EstimateAdjustmentType']>;

export type EstimateInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateInfo'] = ResolversParentTypes['EstimateInfo']> = {
  additionalDamage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  amountIncludingTax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimateType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  internalDamage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internalLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paintLaborRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  repairDataRegion?: Resolver<Maybe<ResolversTypes['RepairDataRegion']>, ParentType, ContextType>;
  repairLaborRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stripRefitLaborRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taxStrategy?: Resolver<Maybe<ResolversTypes['TaxStrategy']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateOperationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateOperation'] = ResolversParentTypes['EstimateOperation']> = {
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  displayDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labor?: Resolver<Maybe<ResolversTypes['OperationLabor']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rowType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  triggerType?: Resolver<ResolversTypes['EstimateOperationTriggerType'], ParentType, ContextType>;
  triggers?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimateOperationTrigger']>>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EstimateOperationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateOperationTriggerResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimateOperationTrigger'] = ResolversParentTypes['EstimateOperationTrigger']> = {
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  part?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateOperationTriggerTypeResolvers = EnumResolverSignature<{ CLIENT?: any, MANUAL?: any, PART_OPERATION?: any, VEHICLE?: any }, ResolversTypes['EstimateOperationTriggerType']>;

export type EstimateOperationTypeResolvers = EnumResolverSignature<{ AIM_HEADLAMPS?: any, CAR_COVER?: any, CUSTOM?: any, EPC_HAZARDOUS_WASTE?: any, FLEX_ADDITIVE?: any, GLASS_KIT?: any, POST_REPAIR_SCAN?: any, PRE_REPAIR_SCAN?: any, RESTORE_CORROSION_PROTECTION?: any, ROPE_GLASS?: any, TYRE_DISPOSAL?: any, TYRE_MOUNT_AND_BALANCE?: any, WHEEL_ALIGNMENT?: any }, ResolversTypes['EstimateOperationType']>;

export type EstimatePaintResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimatePaint'] = ResolversParentTypes['EstimatePaint']> = {
  additionalLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  additionalMaterialCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimatePartResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstimatePart'] = ResolversParentTypes['EstimatePart']> = {
  adjustments?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimateAdjustment']>>>, ParentType, ContextType>;
  auxParts?: Resolver<Maybe<Array<Maybe<ResolversTypes['AuxPart']>>>, ParentType, ContextType>;
  belongsTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayPartIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internalDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oePartIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paintLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paintLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paintMaterialCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paintPartMaterial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partManufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  partSelection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partSupplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repairLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  replacementPartCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stripRefitLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  undeductedStripRefitLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GtMotiveEstimateDoneResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GTMotiveEstimateDoneResponse'] = ResolversParentTypes['GTMotiveEstimateDoneResponse']> = {
  gtMotiveEstimateAccessUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mismatchedParts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GtMotiveEstimateReceivedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GTMotiveEstimateReceivedResponse'] = ResolversParentTypes['GTMotiveEstimateReceivedResponse']> = {
  gtMotiveRequestUid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEstimateAdditionalCostResolvers<ContextType = any, ParentType extends ResolversParentTypes['IEstimateAdditionalCost'] = ResolversParentTypes['IEstimateAdditionalCost']> = {
  __resolveType: TypeResolveFn<'EstimateAdditionalCost', ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  descriptor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type IEstimateAdditionalHoursResolvers<ContextType = any, ParentType extends ResolversParentTypes['IEstimateAdditionalHours'] = ResolversParentTypes['IEstimateAdditionalHours']> = {
  __resolveType: TypeResolveFn<'EstimateAdditionalHours', ParentType, ContextType>;
  descriptor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
};

export type IEstimatePaintResolvers<ContextType = any, ParentType extends ResolversParentTypes['IEstimatePaint'] = ResolversParentTypes['IEstimatePaint']> = {
  __resolveType: TypeResolveFn<'EstimatePaint', ParentType, ContextType>;
  additionalLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  additionalMaterialCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
};

export type IEstimatePartResolvers<ContextType = any, ParentType extends ResolversParentTypes['IEstimatePart'] = ResolversParentTypes['IEstimatePart']> = {
  __resolveType: TypeResolveFn<'AuxPart' | 'EstimatePart', ParentType, ContextType>;
  adjustments?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimateAdjustment']>>>, ParentType, ContextType>;
  belongsTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayPartIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internalDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oePartIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paintLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paintLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paintMaterialCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paintPartMaterial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partManufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  partSelection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partSupplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repairLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  replacementPartCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stripRefitLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  undeductedStripRefitLaborHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link']> = {
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createClaim?: Resolver<Maybe<ResolversTypes['Claim']>, ParentType, ContextType, Partial<MutationCreateClaimArgs>>;
  deleteClaim?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationDeleteClaimArgs>>;
  exportToGTMotive?: Resolver<Maybe<ResolversTypes['GTMotiveEstimateReceivedResponse']>, ParentType, ContextType, Partial<MutationExportToGtMotiveArgs>>;
  saveEdits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSaveEditsArgs>>;
  sendNotification?: Resolver<Maybe<ResolversTypes['ClaimNotifications']>, ParentType, ContextType, Partial<MutationSendNotificationArgs>>;
  setEstimateDecision?: Resolver<Maybe<ResolversTypes['Claim']>, ParentType, ContextType, Partial<MutationSetEstimateDecisionArgs>>;
  setEstimateEditing?: Resolver<Maybe<ResolversTypes['StageAndStatus']>, ParentType, ContextType, Partial<MutationSetEstimateEditingArgs>>;
  setEstimaticsExport?: Resolver<Maybe<ResolversTypes['Carwert']>, ParentType, ContextType, Partial<MutationSetEstimaticsExportArgs>>;
  setNegotiationStatus?: Resolver<Maybe<ResolversTypes['Claim']>, ParentType, ContextType, Partial<MutationSetNegotiationStatusArgs>>;
  setPartOperation?: Resolver<Maybe<ResolversTypes['RDAPIResponse']>, ParentType, ContextType, Partial<MutationSetPartOperationArgs>>;
  startEdits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationStartEditsArgs>>;
};

export type NextStageUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['NextStageUrl'] = ResolversParentTypes['NextStageUrl']> = {
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NextStageUrlDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['NextStageUrlData'] = ResolversParentTypes['NextStageUrlData']> = {
  stage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperationLaborResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationLabor'] = ResolversParentTypes['OperationLabor']> = {
  hours?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartResolvers<ContextType = any, ParentType extends ResolversParentTypes['Part'] = ResolversParentTypes['Part']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  part?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payee'] = ResolversParentTypes['Payee']> = {
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ssn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhDecisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhDecision'] = ResolversParentTypes['PhDecision']> = {
  counterOffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decision?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  ccpClaims?: Resolver<Maybe<ResolversTypes['ClaimsQueryResult']>, ParentType, ContextType, Partial<QueryCcpClaimsArgs>>;
  claim?: Resolver<Maybe<ResolversTypes['Claim']>, ParentType, ContextType, Partial<QueryClaimArgs>>;
  claimNotifications?: Resolver<Maybe<ResolversTypes['ClaimNotifications']>, ParentType, ContextType, Partial<QueryClaimNotificationsArgs>>;
  claims?: Resolver<Maybe<ResolversTypes['ClaimsQueryResult']>, ParentType, ContextType, Partial<QueryClaimsArgs>>;
  downloadImages?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<QueryDownloadImagesArgs>>;
  getGTMotiveEstimate?: Resolver<Maybe<ResolversTypes['GTMotiveEstimateDoneResponse']>, ParentType, ContextType, Partial<QueryGetGtMotiveEstimateArgs>>;
  getParts?: Resolver<Maybe<Array<Maybe<ResolversTypes['RDAPIPart']>>>, ParentType, ContextType, Partial<QueryGetPartsArgs>>;
  getValidFlags?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValidFlag']>>>, ParentType, ContextType, Partial<QueryGetValidFlagsArgs>>;
  latestClaimUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  payee?: Resolver<Maybe<ResolversTypes['Payee']>, ParentType, ContextType, Partial<QueryPayeeArgs>>;
  quattroruoteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<QueryQuattroruoteLinkArgs>>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, Partial<QueryUsersArgs>>;
  webappLink?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType, Partial<QueryWebappLinkArgs>>;
};

export type RdapiCombinationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RDAPICombination'] = ResolversParentTypes['RDAPICombination']> = {
  friends?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RdapiOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RDAPIOption'] = ResolversParentTypes['RDAPIOption']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RdapiPartResolvers<ContextType = any, ParentType extends ResolversParentTypes['RDAPIPart'] = ResolversParentTypes['RDAPIPart']> = {
  combinations?: Resolver<Maybe<Array<Maybe<ResolversTypes['RDAPICombination']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['RDAPIOption']>>>, ParentType, ContextType>;
  partCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RdapiResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RDAPIResponse'] = ResolversParentTypes['RDAPIResponse']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paint?: Resolver<Maybe<ResolversTypes['EstimatePaint']>, ParentType, ContextType>;
  parts?: Resolver<Maybe<Array<Maybe<ResolversTypes['EstimatePart']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepairDataRegionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RepairDataRegion'] = ResolversParentTypes['RepairDataRegion']> = {
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettlementResolvers<ContextType = any, ParentType extends ResolversParentTypes['Settlement'] = ResolversParentTypes['Settlement']> = {
  decision?: Resolver<Maybe<ResolversTypes['SettlementDecision']>, ParentType, ContextType>;
  noTotalLossDecisionReason?: Resolver<Maybe<ResolversTypes['NoTotalLossDecisionReason']>, ParentType, ContextType>;
  outOfScopeReason?: Resolver<Maybe<ResolversTypes['OutOfScopeReason']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StageAndStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['StageAndStatus'] = ResolversParentTypes['StageAndStatus']> = {
  stage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatusHistory'] = ResolversParentTypes['StatusHistory']> = {
  changedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fromStage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fromStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toStage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxBandResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxBand'] = ResolversParentTypes['TaxBand']> = {
  from?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxStrategiesResolvers = EnumResolverSignature<{ FLAT?: any, TIERED?: any }, ResolversTypes['TaxStrategies']>;

export type TaxStrategyResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxStrategy'] = ResolversParentTypes['TaxStrategy']> = {
  bands?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaxBand']>>>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strategy?: Resolver<ResolversTypes['TaxStrategies'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidFlagResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidFlag'] = ResolversParentTypes['ValidFlag']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  multiplier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  object?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overwrite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehicleDetails'] = ResolversParentTypes['VehicleDetails']> = {
  dateOfFirstRegistration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mileage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modelSubtypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valuation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vehicleIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VrmCheckResolvers<ContextType = any, ParentType extends ResolversParentTypes['VrmCheck'] = ResolversParentTypes['VrmCheck']> = {
  outcome?: Resolver<Maybe<ResolversTypes['VrmCheckOutcome']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YesNoQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['YesNoQuestion'] = ResolversParentTypes['YesNoQuestion']> = {
  question?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  questionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typicalAnswer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Address?: AddressResolvers<ContextType>;
  AdjustmentStrategyName?: AdjustmentStrategyNameResolvers;
  Asset?: AssetResolvers<ContextType>;
  AuxPart?: AuxPartResolvers<ContextType>;
  BodyShop?: BodyShopResolvers<ContextType>;
  CarOwner?: CarOwnerResolvers<ContextType>;
  Carwert?: CarwertResolvers<ContextType>;
  Claim?: ClaimResolvers<ContextType>;
  ClaimDetails?: ClaimDetailsResolvers<ContextType>;
  ClaimNotifications?: ClaimNotificationsResolvers<ContextType>;
  ClaimStage?: ClaimStageResolvers<ContextType>;
  ClaimStatus?: ClaimStatusResolvers<ContextType>;
  ClaimUser?: ClaimUserResolvers<ContextType>;
  ClaimsQueryResult?: ClaimsQueryResultResolvers<ContextType>;
  CoaEstimateDisplay?: CoaEstimateDisplayResolvers<ContextType>;
  Currency?: CurrencyResolvers<ContextType>;
  CurrencyOptions?: CurrencyOptionsResolvers<ContextType>;
  Estimate?: EstimateResolvers<ContextType>;
  EstimateAdditionalCost?: EstimateAdditionalCostResolvers<ContextType>;
  EstimateAdditionalHours?: EstimateAdditionalHoursResolvers<ContextType>;
  EstimateAdditionalParts?: EstimateAdditionalPartsResolvers<ContextType>;
  EstimateAdjustment?: EstimateAdjustmentResolvers<ContextType>;
  EstimateAdjustmentStrategy?: EstimateAdjustmentStrategyResolvers<ContextType>;
  EstimateAdjustmentTrigger?: EstimateAdjustmentTriggerResolvers;
  EstimateAdjustmentType?: EstimateAdjustmentTypeResolvers;
  EstimateInfo?: EstimateInfoResolvers<ContextType>;
  EstimateOperation?: EstimateOperationResolvers<ContextType>;
  EstimateOperationTrigger?: EstimateOperationTriggerResolvers<ContextType>;
  EstimateOperationTriggerType?: EstimateOperationTriggerTypeResolvers;
  EstimateOperationType?: EstimateOperationTypeResolvers;
  EstimatePaint?: EstimatePaintResolvers<ContextType>;
  EstimatePart?: EstimatePartResolvers<ContextType>;
  GTMotiveEstimateDoneResponse?: GtMotiveEstimateDoneResponseResolvers<ContextType>;
  GTMotiveEstimateReceivedResponse?: GtMotiveEstimateReceivedResponseResolvers<ContextType>;
  IEstimateAdditionalCost?: IEstimateAdditionalCostResolvers<ContextType>;
  IEstimateAdditionalHours?: IEstimateAdditionalHoursResolvers<ContextType>;
  IEstimatePaint?: IEstimatePaintResolvers<ContextType>;
  IEstimatePart?: IEstimatePartResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NextStageUrl?: NextStageUrlResolvers<ContextType>;
  NextStageUrlData?: NextStageUrlDataResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  OperationLabor?: OperationLaborResolvers<ContextType>;
  Part?: PartResolvers<ContextType>;
  Payee?: PayeeResolvers<ContextType>;
  PhDecision?: PhDecisionResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RDAPICombination?: RdapiCombinationResolvers<ContextType>;
  RDAPIOption?: RdapiOptionResolvers<ContextType>;
  RDAPIPart?: RdapiPartResolvers<ContextType>;
  RDAPIResponse?: RdapiResponseResolvers<ContextType>;
  RepairDataRegion?: RepairDataRegionResolvers<ContextType>;
  Settlement?: SettlementResolvers<ContextType>;
  StageAndStatus?: StageAndStatusResolvers<ContextType>;
  StatusHistory?: StatusHistoryResolvers<ContextType>;
  TaxBand?: TaxBandResolvers<ContextType>;
  TaxStrategies?: TaxStrategiesResolvers;
  TaxStrategy?: TaxStrategyResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  ValidFlag?: ValidFlagResolvers<ContextType>;
  VehicleDetails?: VehicleDetailsResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
  VrmCheck?: VrmCheckResolvers<ContextType>;
  YesNoQuestion?: YesNoQuestionResolvers<ContextType>;
};

