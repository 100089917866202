import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import { Create } from './Component';

const CREATE_CLAIM = gql`
	mutation createClaim($data: ClaimInput) {
		createClaim(data: $data) {
			id
		}
	}
`;

export default compose(graphql(CREATE_CLAIM))(Create);
