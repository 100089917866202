import * as Types from '../../../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetNegotiationStatusMutationVariables = Types.Exact<{
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
  negotiationStatus?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SetNegotiationStatusMutation = { __typename?: 'Mutation', setNegotiationStatus?: { __typename?: 'Claim', id?: string | null, stage?: string | null, status?: string | null, negotiationStatus?: string | null, nextStage?: string | null } | null };


export const SetNegotiationStatusDocument = gql`
    mutation setNegotiationStatus($claimId: String, $negotiationStatus: String) {
  setNegotiationStatus(claimId: $claimId, negotiationStatus: $negotiationStatus) {
    id
    stage
    status
    negotiationStatus
    nextStage
  }
}
    `;
export type SetNegotiationStatusMutationFn = Apollo.MutationFunction<SetNegotiationStatusMutation, SetNegotiationStatusMutationVariables>;

/**
 * __useSetNegotiationStatusMutation__
 *
 * To run a mutation, you first call `useSetNegotiationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNegotiationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNegotiationStatusMutation, { data, loading, error }] = useSetNegotiationStatusMutation({
 *   variables: {
 *      claimId: // value for 'claimId'
 *      negotiationStatus: // value for 'negotiationStatus'
 *   },
 * });
 */
export function useSetNegotiationStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetNegotiationStatusMutation, SetNegotiationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNegotiationStatusMutation, SetNegotiationStatusMutationVariables>(SetNegotiationStatusDocument, options);
      }
export type SetNegotiationStatusMutationHookResult = ReturnType<typeof useSetNegotiationStatusMutation>;
export type SetNegotiationStatusMutationResult = Apollo.MutationResult<SetNegotiationStatusMutation>;
export type SetNegotiationStatusMutationOptions = Apollo.BaseMutationOptions<SetNegotiationStatusMutation, SetNegotiationStatusMutationVariables>;