import styled from 'styled-components';

import { Box } from '../Box';

export {
	ClaimHeader,
	ShinkaIndicator,
	PhDecisionIndicator,
	DecisionIndicator,
	TextWithStatusIcon,
	Pill,
	IndicatorsWrapper,
	VrmCheckIndicator,
} from './ClaimHeader';

export { Detail } from './Detail';
export { Question } from './Question';

export const DetailSection = styled(Box)`
	padding: 8px;
	background: #f9f9f9;
	border-radius: 3px;
`;
