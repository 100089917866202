import * as React from 'react';
import { useEffect, useState } from 'react';

interface Params {
	value: string;
	decimalSymbol?: string;
	updateField?: (value: string) => void;
	freetext: boolean;
}

export const useUpdateInput = ({ value, decimalSymbol, updateField, freetext }: Params) => {
	const [isEditing, setIsEditing] = useState(false);
	const [currentValue, setCurrentValue] = useState('');

	useEffect(() => {
		freetext ? setCurrentValue(value) : decimalSymbol && setCurrentValue(value.replace('.', decimalSymbol));
	}, [value]);

	const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => e.target.select();

	const handleCancel = () => {
		freetext ? setCurrentValue(value) : decimalSymbol && setCurrentValue(value.replace('.', decimalSymbol));
		setIsEditing(false);
	};

	const handleSave = () => {
		setIsEditing(false);
		if (currentValue !== value && updateField) {
			if (freetext) {
				setCurrentValue(currentValue);
				updateField(currentValue);
			} else if (currentValue === '') {
				setCurrentValue('0');
				updateField('0');
			} else {
				setCurrentValue(currentValue);
				decimalSymbol && updateField(currentValue.replace(decimalSymbol, '.'));
			}
		}
	};

	const handleOnChange = (newValue: string) => {
		setCurrentValue(newValue);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (isEditing) {
			if (e.key === 'Escape') {
				handleCancel();
			}
		}
	};

	return {
		isEditing,
		currentValue,
		handleSave,
		onFocus,
		handleKeyDown,
		setIsEditing,
		handleOnChange,
	};
};
