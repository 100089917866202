import * as React from 'react';

export const FRWing = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="FRWING"
		stroke="#000"
		d="M355.265 95.289c-6.237 6.959-19.495 45.851-20.327 104.394 12.89-24.154 60.662-1.637 60.662-1.637v-12.691c-32.85 2.456-39.087-21.289-38.256-30.295 1.582-17.124 14.97-31.933 39.087-27.43.832-19.65-5.821-35.207-5.821-35.616-10.978-1.31-21.068.136-24.118.818l-6.653 30.295-7.484 9.416 2.91-37.664"
		{...props}
	/>
);
