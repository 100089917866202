import styled from 'styled-components';

interface IWrapper {
	predicted?: boolean;
}

const Wrapper = styled.p<IWrapper>`
	font-weight: bold;
	.title {
		margin-right: 0.4rem;
	}
	.value {
		display: block;
		margin: 0.4rem 0;
		font-weight: ${(props) => !props.predicted && 'normal'};
		font-size: ${(props) => props.predicted && '150%'};
	}
`;

export { Wrapper };
