import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			vehicle {
				make
				model
				year
				vin
				mileage
				registration
				name
			}
			vinVerified
		}
	}
`;

const useVehicle = (id: string) => {
	const { config } = useClientConfigCtx();
	const [details, setDetails] = useState(null);
	const { loading, data } = useQuery(GET_CLAIM, {
		variables: { id },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		const { showVinVerified } = config.ui;
		const blueprint = showVinVerified
			? {
					Registration: (data: any) => data.registration,
					VIN: (data: any) => data.vin,
					'VIN verified': (data: any) => (data.vinVerified ? 'Yes' : 'No'),
					Make: (data: any) => data.make,
					Model: (data: any) => data.model,
					Year: (data: any) => data.year,
					Mileage: (data: any) => data.mileage && data.mileage.toLocaleString('en-US'),
			  }
			: {
					Registration: (data: any) => data.registration,
					VIN: (data: any) => data.vin,
					Make: (data: any) => data.make,
					Model: (data: any) => data.model,
					Year: (data: any) => data.year,
					Mileage: (data: any) => data.mileage && data.mileage.toLocaleString('en-US'),
			  };

		if (!loading) {
			const vehicle = {
				...data.claim.vehicle,
				vinVerified: data.claim.vinVerified,
			};
			const transformedDetails = map(blueprint, (transformer: any, label: string) => ({
				key: v4(),
				label,
				value: transformer(vehicle),
			}));
			setDetails(transformedDetails);
		}
	}, [loading, data]);

	return {
		loading,
		details,
	};
};

export { useVehicle };
