import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { pick } from 'lodash';

export interface IGetClaimsConfigProps {
	search: {
		value: string;
	}[];
	filters: {
		id: string;
		title?: string;
		selected: string;
	}[];
	pagination: {
		offset: number;
		limit: number;
	};
}

const GET_QUERY_OPTIONS = gql`
	query getQueryOptions @client {
		search {
			value
		}
		pagination {
			limit
			offset
		}
		filters {
			id
			title
			selected
		}
	}
`;

export const withQueryOptions = graphql<object, IGetClaimsConfigProps, object, IGetClaimsConfigProps>(
	GET_QUERY_OPTIONS,
	{
		props: ({ data }) => {
			const pagination = pick(data.pagination, ['offset', 'limit']);
			const filters = data.filters.map((filter) => pick(filter, ['id', 'selected']));
			const search = data.search.map((tag) => pick(tag, ['value']));

			return {
				filters,
				pagination,
				search,
			};
		},
	}
);
