import * as React from 'react';

export const FWinshield = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="FWINDSHIELD"
		stroke="#000"
		d="M266.428 247.308l13-44.78-111.596-.537 14.596 45.809c26.5-6.889 39.5-14.27 84-.492z"
		{...props}
	/>
);
