"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPrePublishedStage = exports.isPublishedStage = exports.isPolicyholderStage = exports.Stage = void 0;
var Stage;
(function (Stage) {
    // If adding a new stage make sure it is mapped in the portal
    Stage["CLAIM_CREATION"] = "CLAIM_CREATION";
    Stage["POLICYHOLDER_INPUT"] = "POLICYHOLDER_INPUT";
    Stage["AI_IMAGE_CLASSIFICATION"] = "AI_IMAGE_CLASSIFICATION";
    Stage["AI_DAMAGED_PARTS_OUTPUT"] = "AI_DAMAGED_PARTS_OUTPUT";
    Stage["DECODE_VEHICLE"] = "DECODE_VECHICLE";
    Stage["AI_ESTIMATE_GENERATION"] = "AI_ESTIMATE_GENERATION";
    Stage["AI_ESTIMATE_EDITING"] = "AI_ESTIMATE_EDITING";
    Stage["HUMAN_TRIAGE"] = "HUMAN_TRIAGE";
    Stage["AUTO_TRIAGE"] = "AUTO_TRIAGE";
    Stage["HUMAN_ESTIMATE_GENERATION"] = "HUMAN_ESTIMATE_GENERATION";
    Stage["ESTIMATE_MAPPING"] = "ESTIMATE_MAPPING";
    Stage["ESTIMATE_REVIEW"] = "ESTIMATE_REVIEW";
    Stage["AUTO_ESTIMATE_REVIEW"] = "AUTO_ESTIMATE_REVIEW";
    Stage["ESTIMATE_PUBLISHING"] = "ESTIMATE_PUBLISHING";
    Stage["PH_ESTIMATE_DECISION"] = "PH_ESTIMATE_DECISION";
    Stage["CLAIM_DELETION"] = "CLAIM_DELETION";
    Stage["EXTERNAL_ESTIMATE_EDITING"] = "EXTERNAL_ESTIMATE_EDITING";
    Stage["TEP_ESTIMATE_EDITING"] = "TEP_ESTIMATE_EDITING";
    Stage["ESTIMATE_DECISION"] = "ESTIMATE_DECISION";
    Stage["NEGOTIATION_EDITING"] = "NEGOTIATION_EDITING";
    Stage["NEGOTIATION"] = "NEGOTIATION";
    Stage["ESTIMATE_AUTO_PROCESS"] = "ESTIMATE_AUTO_PROCESS";
})(Stage = exports.Stage || (exports.Stage = {}));
function isPolicyholderStage(stage) {
    return [
        Stage.CLAIM_CREATION,
        Stage.POLICYHOLDER_INPUT,
        Stage.AI_IMAGE_CLASSIFICATION,
        Stage.AI_DAMAGED_PARTS_OUTPUT,
    ].includes(stage);
}
exports.isPolicyholderStage = isPolicyholderStage;
// includes non-processing/failures cases
function isPublishedStage(stage) {
    return [
        Stage.ESTIMATE_PUBLISHING,
        Stage.NEGOTIATION,
        Stage.NEGOTIATION_EDITING,
        Stage.EXTERNAL_ESTIMATE_EDITING,
        Stage.TEP_ESTIMATE_EDITING,
        Stage.ESTIMATE_DECISION,
    ].includes(stage);
}
exports.isPublishedStage = isPublishedStage;
function isPrePublishedStage(stage) {
    return !isPublishedStage(stage) && stage !== Stage.CLAIM_DELETION;
}
exports.isPrePublishedStage = isPrePublishedStage;
