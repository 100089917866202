import * as React from 'react';

import { Image } from './styles';

interface PhotoProps {
	src: string;
}

const Photo: React.FC<PhotoProps> = ({ src }) => <Image src={src} />;

export { Photo };
