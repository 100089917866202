export enum AssetType {
	TRACTABLE_ESTIMATE_PDF = 'TRACTABLE_ESTIMATE_PDF',
	EXTERNAL_ESTIMATE_PDF = 'EXTERNAL_ESTIMATE_PDF',
	ANIA_ESTIMATE_PDF = 'ANIA_ESTIMATE_PDF',
	QR_PORTAL_LINK = 'QR_PORTAL_LINK',
}

export interface Asset {
	path: string;
	type: AssetType;
	valid: boolean;
}

export function isAssetOfType(asset: Asset, type: AssetType, checkValidity = false) {
	const isAsset = asset.type === type;

	if (checkValidity) {
		return isAsset && asset.valid;
	}

	return isAsset;
}

export function isTractableEstimatePdf(asset: Asset) {
	return isAssetOfType(asset, AssetType.TRACTABLE_ESTIMATE_PDF);
}

export function isAniaEstimatePdf(asset: Asset) {
	return isAssetOfType(asset, AssetType.ANIA_ESTIMATE_PDF);
}

export function isValidExternalEstimatePdf(asset: Asset) {
	return isAssetOfType(asset, AssetType.EXTERNAL_ESTIMATE_PDF, true);
}

export function isValidQuattroruoteLink(asset: Asset) {
	return isAssetOfType(asset, AssetType.QR_PORTAL_LINK, true);
}
