import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericErrorPage } from '../../GenericError';
import { Viewer } from '../../shared';
import { Box, Image, Text } from '../../shared/Primitives';
import { SimpleWidget } from '../../shared/Widget';
import { NonIdealState } from './NotIdealState';
import * as estimateGenerated from './estimate-generated.png';
import { useAssetViewer } from './useAssetViewer';

interface AssetViewerProps {
	readonly id: string;
	readonly selectedAsset: any;
	readonly widget: any;
	readonly showEstimate?: boolean;
	readonly hidePdfRendering?: boolean;
}

const AssetViewer: React.FC<AssetViewerProps> = ({ id, selectedAsset, showEstimate, widget, hidePdfRendering }) => {
	const { t } = useTranslation('EstimatingPortal');
	const { asset, getStatusProps, error } = useAssetViewer(id);

	if (error) return <GenericErrorPage error={error} />;

	// When hiding estimate config is on, we only show selected assets which are images only.
	if (!showEstimate) {
		return <SimpleWidget {...widget}>{selectedAsset && <Viewer selectedAsset={selectedAsset} />}</SimpleWidget>;
	}

	if (hidePdfRendering) {
		return (
			<SimpleWidget {...widget}>
				<Box display="flex" alignItems="center" flexDirection="column" my={6} mx={4} color="grays.1">
					<Image src={estimateGenerated} width="150px" mb={5} />
					<Text mx={4} fontSize={3} textAlign="center">
						{t('The estimate PDF is ready')}
					</Text>
				</Box>
			</SimpleWidget>
		);
	}

	return (
		<SimpleWidget {...widget}>
			{asset || selectedAsset ? (
				<Viewer selectedAsset={selectedAsset || asset} />
			) : (
				<NonIdealState {...getStatusProps()} />
			)}
		</SimpleWidget>
	);
};

export { AssetViewer };
