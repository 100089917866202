import { Box, Button, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import gql from 'graphql-tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationMark } from 'tabler-icons-react';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { useGetClaim } from '../../hooks/useGetClaim';
import { VehicleResource } from '../EstimaticsExporter';
import { useExportToGtMotiveMutation, useGetGtMotiveEstimateQuery } from './generated/GTMotiveExporter.generated';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: used for gql hook generation
export const EXPORT_TO_GTMOTIVE = gql`
	mutation exportToGTMotive($claimId: String, $exportInput: EstimaticsExportGTMotiveInput) {
		exportToGTMotive(claimId: $claimId, exportInput: $exportInput) {
			gtMotiveRequestUid
		}
	}
`;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: used for gql hook generation
export const GET_GTMOTIVE_ESTIMATE_URL = gql`
	query getGTMotiveEstimate($clientId: String, $claimId: String) {
		getGTMotiveEstimate(clientId: $clientId, claimId: $claimId) {
			message
			gtMotiveEstimateAccessUrl
			mismatchedParts
		}
	}
`;
interface Props {
	claimId: string;
}

interface VehiclePortalInput extends VehicleResource {
	vehicleIdentifier: string;
}

interface GTMotiveVehicleResource extends Omit<VehicleResource, 'dateOfFirstRegistration'> {
	mileage: number;
	dateOfFirstRegistration: string;
}

const transformVehicleInput = (portalVehicle: VehiclePortalInput): GTMotiveVehicleResource => {
	return {
		...portalVehicle,
		registration: portalVehicle.registration
			? portalVehicle.registration.length > 12
				? null
				: portalVehicle.registration
			: null,
		dateOfFirstRegistration: portalVehicle.dateOfFirstRegistration
			? typeof portalVehicle.dateOfFirstRegistration === 'string'
				? portalVehicle.dateOfFirstRegistration
				: portalVehicle.dateOfFirstRegistration.toISOString()
			: new Date().toISOString(),
		mileage: portalVehicle.distanceTravelled || 0,
		repairDataIdentifiers: {
			autovista: [portalVehicle.vehicleIdentifier || ''],
		},
	};
};

export const GTMotiveExporter: React.FC<Props> = ({ claimId }) => {
	const { t } = useTranslation('EstimatingPortal');
	const [initialPollingStarted, setInitialPollingStarted] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [hasExported, setHasExported] = React.useState(false);
	const [hasEstimateFailed, setHasEstimateFailed] = React.useState(false);
	const { loading: claimLoading, data: claimData } = useGetClaim(claimId);

	const {
		data: gtmotiveEstimateData,
		error: errorGTMotiveEstimate,
		stopPolling,
		startPolling,
		refetch,
	} = useGetGtMotiveEstimateQuery({
		variables: { clientId: claimData?.claim.clientId, claimId },
		notifyOnNetworkStatusChange: true,
		errorPolicy: 'all',
	});

	const [exportToGTMotive] = useExportToGtMotiveMutation({
		onCompleted: () => {
			setHasExported(true);
			startPolling(3000);
		},
	});

	React.useEffect(() => {
		if (!initialPollingStarted && !errorGTMotiveEstimate && !isLoading) {
			startPolling(5000);
			setInitialPollingStarted(true);
			stopPolling();
		}
	}, [errorGTMotiveEstimate, initialPollingStarted, isLoading, startPolling, stopPolling]);

	React.useEffect(() => {
		if (
			initialPollingStarted &&
			(errorGTMotiveEstimate?.graphQLErrors[0]?.extensions?.response as any)?.body?.error?.message?.isFailed
		) {
			setHasEstimateFailed(true);
			setHasExported(false);
			stopPolling();
			setIsLoading(false);
		}
	}, [errorGTMotiveEstimate?.graphQLErrors, initialPollingStarted, stopPolling]);

	React.useEffect(() => {
		if (gtmotiveEstimateData && gtmotiveEstimateData?.getGTMotiveEstimate?.gtMotiveEstimateAccessUrl) {
			stopPolling();
			setIsLoading(false);
			setInitialPollingStarted(true);
			setHasExported(true);
		}
	}, [gtmotiveEstimateData, stopPolling]);

	if (claimLoading) return null;
	if (hasEstimateFailed) {
		showNotification({
			id: 'gtmotive_estimate_failed',
			icon: <ExclamationMark />,
			color: 'red',
			autoClose: 4000,
			message: t('gtmotive_estimate_failed'),
		});
	}

	const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		logEvent('Edit - export estimate to GT motive', { claimId });
		setHasEstimateFailed(false);
		if (!hasExported) {
			event.preventDefault();
			const { clientClaimId, policy, estimate, vehicle, clientId } = claimData.claim;
			const gtEstimate = {
				...estimate,
				parts: estimate?.parts.map((part) => ({ ...part, appliedAdjustments: part.adjustments || [] })) || [],
			};

			try {
				setIsLoading(true);
				await exportToGTMotive({
					variables: {
						claimId,
						exportInput: {
							id: claimId,
							clientClaimId,
							policy,
							clientId,
							estimate: gtEstimate as any,
							vehicle: transformVehicleInput(vehicle as unknown as VehiclePortalInput),
						},
					},
				});
			} catch (e: any) {
				setIsLoading(false);
				console.warn(e);
				showNotification({
					icon: <ExclamationMark />,
					color: 'red',
					autoClose: 4000,
					message: t('gtmotive_error_export'),
				});
			}
		} else if (
			hasExported &&
			gtmotiveEstimateData &&
			gtmotiveEstimateData?.getGTMotiveEstimate?.gtMotiveEstimateAccessUrl
		) {
			setIsLoading(true);
			try {
				const { data: refetchedEstimateData } = await refetch();
				window.open(refetchedEstimateData.getGTMotiveEstimate.gtMotiveEstimateAccessUrl, '_blank')?.focus();
			} catch (e) {
				logEvent('Edit - open GT Motive Estimate refetch failed', { claimId });
				console.warn(e);
				window.open(gtmotiveEstimateData.getGTMotiveEstimate.gtMotiveEstimateAccessUrl, '_blank')?.focus();
			}
			logEvent('Edit - open GT Motive Estimate', { claimId });
			setIsLoading(false);
		}
	};

	return (
		<Box>
			{hasExported && gtmotiveEstimateData?.getGTMotiveEstimate?.mismatchedParts && (
				<Text mr="16px" component="span" size="sm" color="orange">
					{t('gtmotive_warning_message')}
				</Text>
			)}
			<Button
				onClick={handleClick}
				loading={isLoading}
				data-test-id={hasExported ? 'gtmotive-button-open' : 'gtmotive-button-export'}
			>
				{hasExported ? t('gtmotive_button_open') : t('gtmotive_button_export')}
			</Button>
		</Box>
	);
};
