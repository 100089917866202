import * as React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';

export interface PercentageDisplayProps {
	readonly repairCost: number;
	readonly maxSpend: number;
}

export const PercentageDisplay: React.FC<PercentageDisplayProps> = ({ repairCost, maxSpend }) => {
	const percentage = Math.round((repairCost / maxSpend) * 100);

	return (
		<Box>
			<Text typography="standard" color="#12191F">
				{`${percentage} %`}
			</Text>
		</Box>
	);
};
