import { TagInput } from '@blueprintjs/core';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 2.4rem;
	padding-bottom: 2.4rem;
	margin: 0;
	background: white;
`;

const SearchBar = styled(TagInput)`
	min-width: 42rem;
	float: left;
`;

export { Wrapper, SearchBar };
