import * as React from 'react';

export const LRockerpanel = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="LROCKERPANEL"
		stroke="#000"
		d="M56.135 310.912l3.957 29.431-7.518.432-.837-155.374h4.398v125.511z"
		{...props}
	/>
);
