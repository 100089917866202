import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { map } from 'lodash';
import { useEffect, useState } from 'react';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			videos {
				id
				path
			}
		}
	}
`;

const useVideosGallery = (id: string): { videos: any[] } => {
	const [videos, setVideos] = useState(null);
	const { loading, data } = useQuery(GET_CLAIM, {
		variables: { id },
	});

	useEffect(() => {
		if (!loading && data?.claim?.videos) {
			const videosProps = map(data.claim.videos, (video: any) => () => ({
				key: video.path,
				url: video.path,
				src: video.path,
				// TODO decide the exact behaviour of the video gallery
				//   onClick: () =>
				//     onMouseOver(() =>
				//       setSelectedAsset({ path: video.path, type: 'video' })
				//     ),
				//   onMouseOut: () => onMouseOut(() => console.log('bla')),
			}));
			setVideos(videosProps);
		}
	}, [loading, data]);

	return {
		videos,
	};
};

export { useVideosGallery };
