"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTaxableFields = void 0;
const client_config_1 = require("./client-config");
function getTaxableFields(client) {
    switch (client) {
        case 'axafrance':
            return client_config_1.AXA_FRANCE;
        default:
            return null;
    }
}
exports.getTaxableFields = getTaxableFields;
