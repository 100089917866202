import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const minLength = 2;

export const required = (field: string, t: any) => (value: any) => {
	if (!value) {
		return t('{{field}} is required', { field });
	}

	if (value.length < minLength) {
		return t('Should be more than {{minLength}} characters long', {
			minLength,
		});
	}

	if (!/^[a-zA-Z0-9-]+$/.test(value)) {
		return t(`Should be alphanumeric (A-Z or 0-9) or - characters only`);
	}

	return undefined;
};

export const normalize = (value: any) => value.toUpperCase();

const ModelSubtypeCodeLong: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="modelSubtypeCodeLong" validate={required(t('Frame type'), t)} parse={normalize}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Frame type')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder={t('e.g. ZVW50')} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { ModelSubtypeCodeLong, minLength };
