import * as React from 'react';

function SvgCheckCircle() {
	return (
		<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.49967 0.333496C3.81967 0.333496 0.833008 3.32016 0.833008 7.00016C0.833008 10.6802 3.81967 13.6668 7.49967 13.6668C11.1797 13.6668 14.1663 10.6802 14.1663 7.00016C14.1663 3.32016 11.1797 0.333496 7.49967 0.333496ZM7.49967 12.3335C4.55967 12.3335 2.16634 9.94016 2.16634 7.00016C2.16634 4.06016 4.55967 1.66683 7.49967 1.66683C10.4397 1.66683 12.833 4.06016 12.833 7.00016C12.833 9.94016 10.4397 12.3335 7.49967 12.3335ZM10.5597 4.0535L6.16634 8.44683L4.43967 6.72683L3.49967 7.66683L6.16634 10.3335L11.4997 5.00016L10.5597 4.0535Z"
				fill="#2E8367"
			/>
		</svg>
	);
}

export default SvgCheckCircle;
