import styled from 'styled-components';

const CheckBox = styled.input`
	accent-color: #3b25c4;
`;

const StyledFilter = styled.label`
	display: flex;
	font-weight: 600;
	align-items: center;
	font-size: 1.4rem;
	cursor: pointer;
	text-transform: uppercase;
	margin-right: 2em;
	color: #464646;

	> input[type='checkbox'] {
		margin-right: 5px;
	}
`;

export { StyledFilter, CheckBox };
