"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YesNoQuestions = void 0;
exports.YesNoQuestions = [
    {
        questionId: 'is-drivable',
        questionText: 'Is your vehicle driveable?',
        typicalAnswer: true,
    },
    {
        questionId: 'windshield-shattered',
        questionText: 'Is the windscreen shattered?',
        typicalAnswer: false,
    },
    {
        questionId: 'airbags-deployed',
        questionText: 'Have any airbags deployed?',
        typicalAnswer: false,
    },
    {
        questionId: 'agree-using-green-parts',
        questionText: 'Is agreed using green parts?',
        typicalAnswer: false,
    },
    {
        questionId: 'is-steering-damaged',
        questionText: 'Is your steering wheel difficult to turn now?',
        typicalAnswer: false,
    },
];
