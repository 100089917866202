import { Spinner } from '@blueprintjs/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '../../../shared';

const Processing: React.FC = () => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<Box display="flex">
			<Spinner size={24} />
			<Text fontSize={4} fontWeight={5} m={2}>
				{t('Processing')}
			</Text>
		</Box>
	);
};

export { Processing };
