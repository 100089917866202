import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { EstimateOperation } from '../../../../generated/graphql';
import { ErrorNotification } from '../../shared/ErrorNotification';
import { removeAuxPartFromParts } from '../utils/removeAuxPartFromParts';
import { GET_CLAIM } from './useGetClaim';

const SAVE_EDITS = gql`
	mutation saveEdits($claimId: String, $changes: EstimateInput, $stage: String) {
		saveEdits(claimId: $claimId, changes: $changes, stage: $stage)
	}
`;

const START_EDITS = gql`
	mutation startEdits($claimId: String) {
		startEdits(claimId: $claimId)
	}
`;

export const useEditing = () => {
	const { t } = useTranslation('EstimatingPortal');
	const client = useApolloClient();
	const [saveEdits, { loading: isSaving, error }] = useMutation(SAVE_EDITS, {
		refetchQueries: ['getClaim'],
	});
	const [startEdits] = useMutation(START_EDITS); // TODO: Loading and error state handling

	const [isEditing, setIsEditing] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [initialEstimate, setInitialEstimate] = useState(false);

	useEffect(() => {
		if (error) {
			toast(<ErrorNotification text={t('Unable to save edits. Please try again.')} />, {
				position: 'bottom-left',
				draggable: false,
				hideProgressBar: true,
				autoClose: 4000,
			});
		}
	}, [error]);

	const sendEdits = (claim: any) => {
		logEvent('Edit - save', { claimId: claim.id });

		return saveEdits({
			variables: {
				claimId: claim.id,
				changes: {
					...claim.estimate,
					//HACK: to support the fact that we need to distinguish between additional costs/hours by using nulls
					//We also don't want to save operations with a total cost of 0
					operations: claim.estimate.operations
						?.filter((x: EstimateOperation) => x.cost || x.labor.hours)
						?.map((op: EstimateOperation) => {
							return {
								...op,
								labor: {
									rate: op.labor.rate,
									// hours could have been null
									hours: op.labor.hours ?? 0,
								},
								// cost could have been null
								cost: op.cost ?? 0,
							};
						}),
					parts: removeAuxPartFromParts(claim.estimate.parts),
				},
				stage: claim.stage,
			},
		});
	};

	const finishEditing = () => {
		setIsDirty(false);
		setIsEditing(false);
	};

	const saveEditing = (claim: any) => {
		sendEdits(claim).then(() => finishEditing());
	};

	const cancelEditing = (claim: any) => {
		finishEditing();

		client.writeQuery({
			query: GET_CLAIM,
			data: {
				claim: {
					...claim,
					estimate: initialEstimate,
				},
			},
		});
	};

	const openEditing = async (claim: any) => {
		try {
			await startEdits({
				variables: {
					claimId: claim.id,
				},
			});
		} catch (err) {
			// Do nothing on failure
		}
	};

	const startEditing = (claim: any) => {
		openEditing(claim);
		setIsEditing(true);
		setInitialEstimate(claim.estimate);
		logEvent('Edit - start', { claimId: claim.id });
	};

	return {
		startEditing,
		cancelEditing,
		saveEditing,
		isEditing,
		isSaving,
		isDirty,
		setIsDirty,
	};
};
