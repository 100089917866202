import * as Types from '../../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEditableClaimQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetEditableClaimQuery = { __typename?: 'Query', claim?: { __typename?: 'Claim', id?: string | null, stage?: string | null, status?: string | null, negotiationStatus?: string | null, estimateDecision?: Types.EstimateDecision | null, nextStage?: string | null } | null };


export const GetEditableClaimDocument = gql`
    query getEditableClaim($id: String) {
  claim(id: $id) {
    id
    stage
    status
    negotiationStatus
    estimateDecision
    nextStage
  }
}
    `;

/**
 * __useGetEditableClaimQuery__
 *
 * To run a query within a React component, call `useGetEditableClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditableClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditableClaimQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditableClaimQuery(baseOptions?: Apollo.QueryHookOptions<GetEditableClaimQuery, GetEditableClaimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditableClaimQuery, GetEditableClaimQueryVariables>(GetEditableClaimDocument, options);
      }
export function useGetEditableClaimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditableClaimQuery, GetEditableClaimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditableClaimQuery, GetEditableClaimQueryVariables>(GetEditableClaimDocument, options);
        }
export type GetEditableClaimQueryHookResult = ReturnType<typeof useGetEditableClaimQuery>;
export type GetEditableClaimLazyQueryHookResult = ReturnType<typeof useGetEditableClaimLazyQuery>;
export type GetEditableClaimQueryResult = Apollo.QueryResult<GetEditableClaimQuery, GetEditableClaimQueryVariables>;