import * as React from 'react';

import { Table } from '..';

interface SimpleTableProps {
	header?: boolean;
	children: any;
}

const SimpleTable: React.FC<SimpleTableProps> = ({ header = false, children, ...restProps }) => {
	const headers = header && children[0];
	const rows = !header ? children : children.slice(1);
	return (
		<Table {...restProps}>
			<thead>{headers}</thead>
			<tbody>{rows}</tbody>
		</Table>
	);
};

export { SimpleTable };
