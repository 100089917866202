import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { NegotiationStatus } from '@tractable/estimating-local-constants';

import { NegotiationState, SettlementAction } from '../SettlementAction';
import { GetEditableClaimQuery } from '../generated/useGetEditableClaim.generated';
import { useSetNegotiationStatusMutation } from './generated/useSetNegotiationStatus.generated';
import { getOptions } from './options';

export type NegotiationButtonProps = {
	claimId: string;
	negotiationStatus: GetEditableClaimQuery['claim']['negotiationStatus'];
	editButton: React.ReactNode;
	loading?: boolean;
	isEditing?: boolean;
};

export const NegotiationButton = ({
	claimId,
	negotiationStatus,
	editButton,
	loading = false,
	isEditing = false,
}: NegotiationButtonProps) => {
	const status = negotiationStatus || NegotiationStatus.NOT_YET_COMPLETE;

	const { t } = useTranslation('EstimatingPortal');
	const [setNegotiationStatus, { loading: mutationLoading }] = useSetNegotiationStatusMutation();
	const buttonOptions = getOptions(t, setNegotiationStatus, claimId);

	const negotiationStatesMap = {
		[NegotiationStatus.NOT_YET_COMPLETE]: {
			text: t('Finalize negotiation'),
			state: NegotiationState.NEGOTIATION_READY,
			options: buttonOptions.finalize,
			dataTestId: 'negotiation-ready-button',
		},
		[NegotiationStatus.REJECTED]: {
			text: t('Rejected settlement'),
			state: NegotiationState.NEGOTIATION_REJECTED,
			options: buttonOptions.rejected,
			dataTestId: 'negotiation-rejected-button',
		},
		[NegotiationStatus.ACCEPTED]: {
			text: t('Accepted settlement'),
			state: NegotiationState.NEGOTIATION_ACCEPTED,
			options: buttonOptions.accepted,
			dataTestId: 'negotiation-accepted-button',
		},
	};

	const isLoading = loading || mutationLoading;

	return (
		<>
			{!isLoading && status === NegotiationStatus.NOT_YET_COMPLETE && editButton}
			{!isEditing && <SettlementAction loading={isLoading} {...negotiationStatesMap[status as NegotiationStatus]} />}
		</>
	);
};
