"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.taxBandSortComparator = void 0;
const taxBandSortComparator = (a, b) => {
    if (a.from > b.from) {
        return 1;
    }
    if (a.from < b.from) {
        return -1;
    }
    return 0;
};
exports.taxBandSortComparator = taxBandSortComparator;
