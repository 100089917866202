import * as React from 'react';

import { Box } from '../../Box';
import SvgWrench from '../../Icons/Wrench';
import { Text } from '../../Text';

export enum ClaimDecision {
	'IN_PROGRESS' = 'IN_PROGRESS',
	'REPAIRABLE' = 'REPAIRABLE',
	'TOTALLOSS' = 'TOTALLOSS',
	'NO_DECISION' = 'NO_DECISION',
}

export interface ClaimDecisionProps {
	readonly title: string;
	readonly decision: ClaimDecision;
}

export const DecisionIndicator: React.FC<ClaimDecisionProps> = ({ title, decision }) => {
	if (decision === ClaimDecision.IN_PROGRESS) return null;

	return (
		<Box orientation="horizontal" alignItems="center" gap={4}>
			<SvgWrench />
			<Text typography="large">{title}</Text>
		</Box>
	);
};
