import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Claim } from '../../../../../generated/graphql';
import { GenericErrorPage } from '../../../GenericError';
import { Box } from '../../../shared/Box';
import { PdfViewer } from './PdfViewer';
import { useGetPdfAsset } from './useGetPdfAsset';

export const PdfViewWrapper = ({ claim, isPdfPresent }: { claim: Claim; isPdfPresent: boolean }) => {
	const { id } = useParams();

	const { asset, error } = useGetPdfAsset(id);
	// isPublishedStage indicates that the PDF is not being re-generated
	const pdfPublished = !!claim.claimDetails?.isPublishedStage && asset;

	if (error) return <GenericErrorPage error={error} />;

	return pdfPublished ? (
		<Box width="50%" orientation="vertical" borderRight="1px solid #D7E3EB" p="16px 16px 0 0" gap={16}>
			<PdfViewer asset={asset} isPdfPresent={isPdfPresent} />
		</Box>
	) : null;
};
