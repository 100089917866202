import { ApolloClient, useApolloClient } from '@apollo/client';
import { findIndex } from 'lodash';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import {
	AdjustmentStrategyName,
	EstimateAdjustment,
	EstimateAdjustmentTrigger,
	EstimateAdjustmentType,
	EstimateOperationInput,
	EstimateOperationTriggerType,
	EstimateOperationType,
	EstimatePartInput,
} from '../../../../generated/graphql';
import { RowOperationType } from '../../../../server/graphql/resolvers/claim/estimateDetails';
import { GET_CLAIM } from './useGetClaim';

interface Params {
	setIsDirty: (f: boolean) => void;
}

export const useUpdateField = ({ setIsDirty }: Params) => {
	const client = useApolloClient();

	const getClaim = (id: string) => {
		setIsDirty(true);
		return client.readQuery({
			query: GET_CLAIM,
			variables: { id },
		});
	};

	const addCustomOperation = (id: string) => (type: RowOperationType) => {
		const data = getClaim(id);
		const { operations } = data.claim.estimate;
		const { estimate } = data.claim;
		logEvent('Edit - add operation', { claimId: id });

		const operation: EstimateOperationInput = {
			displayDescription: '',
			cost: type === RowOperationType.COST ? 0 : null,
			labor: {
				hours: type === RowOperationType.HOURS ? 0 : null,
				rate: estimate.info.stripRefitLaborRate,
			},
			quantity: 1.0,
			triggers: null,
			triggerType: EstimateOperationTriggerType.MANUAL,
			type: EstimateOperationType.CUSTOM,
			rowType: type,
		};

		const dataToUpdate = {
			estimate: {
				...estimate,
				operations: [...operations, operation],
			},
		};

		saveClaim(
			{
				...data.claim,
				...dataToUpdate,
			},
			client
		);
	};

	const deleteOperation = (id: string, index: number) => () => {
		const data = getClaim(id);

		const operations = [
			...data.claim.estimate.operations.slice(0, index),
			...data.claim.estimate.operations.slice(index + 1),
		];

		saveClaim(
			{
				...data.claim,
				estimate: {
					...data.claim.estimate,
					operations,
				},
			},
			client
		);
	};

	type OperationField = keyof EstimateOperationInput;

	const updateOperationLabor = (id: string, index: number, hours: number) => {
		const { claim } = getClaim(id);
		const original = claim.estimate.operations[index];

		return updateOperationField(id, index, 'labor', { ...original.labor, hours });
	};

	const updateOperationField = (id: string, index: number, field: OperationField, value: any) => {
		const data = getClaim(id);
		const { estimate } = data.claim;
		const { operations } = estimate;

		const target = operations[index];

		//TODO: maybe think about validation here?

		logEvent('Edit - update operation line', {
			...target,
			field,
			claimId: id,
		});

		const dataToUpdate = {
			estimate: {
				...estimate,
				operations: [
					...operations.slice(0, index),
					{
						...target,
						[field]: value,
					},
					...operations.slice(index + 1),
				],
			},
		};

		saveClaim(
			{
				...data.claim,
				...dataToUpdate,
			},
			client
		);
	};

	const updateOperation = (id: string) => (index: number) => {
		return {
			displayDescription: (description: string) => updateOperationField(id, index, 'displayDescription', description),
			hours: (hours: string) => updateOperationLabor(id, index, parseFloat(hours)),
			cost: (cost: string) => updateOperationField(id, index, 'cost', parseFloat(cost)),
			// quantity: (quantity: string) => updateOperationField(id, index, 'quantity', parseFloat(quantity)),
		};
	};

	const updateRate = (id: string) => (value: string) => {
		const data = getClaim(id);
		const { info } = data.claim.estimate;
		const { estimate } = data.claim;
		const rate = parseFloat(value);

		const operations = estimate.operations?.map((op: EstimateOperationInput): EstimateOperationInput => {
			return {
				...op,
				labor: {
					...op.labor,
					rate: rate,
				},
			};
		});

		const dataToUpdate = {
			estimate: {
				...estimate,
				info: {
					...info,
					stripRefitLaborRate: rate,
					paintLaborRate: rate,
					repairLaborRate: rate,
				},
				operations,
			},
		};

		saveClaim(
			{
				...data.claim,
				...dataToUpdate,
			},
			client
		);
	};

	const updateAdditionalCost = (id: string) => (index: number) => (field: string) => (value: string) => {
		//HACK: backwards compatibility
		if (field === 'cost') {
			updateOperation(id)(index).cost(value);
			return;
		} else if (field === 'descriptor') {
			updateOperation(id)(index).displayDescription(value);
			return;
		}

		throw new Error(`Unknown field to update: ${field}`);
	};

	const updateAdditionalHours = (id: string) => (index: number) => (field: string) => (value: string) => {
		//HACK: backwards compatibility
		if (field === 'hours') {
			updateOperation(id)(index).hours(value);
			return;
		} else if (field === 'descriptor') {
			updateOperation(id)(index).displayDescription(value);
			return;
		}

		throw new Error(`Unknown field to update: ${field}`);
	};

	const updatePaint = (id: string) => (field: string) => (value: string) => {
		let dataToUpdate = {};
		const data = getClaim(id);
		const { paint } = data.claim.estimate;
		const { estimate } = data.claim;

		logEvent('Edit - update paint preparation', { field, value, claimId: id });
		if (field === 'hours') {
			dataToUpdate = {
				estimate: {
					...estimate,
					paint: {
						...paint,
						additionalLaborHours: parseFloat(value),
					},
				},
			};
		} else if (field === 'cost') {
			dataToUpdate = {
				estimate: {
					...estimate,
					paint: {
						...paint,
						additionalMaterialCost: parseFloat(value),
					},
				},
			};
		}

		saveClaim(
			{
				...data.claim,
				...dataToUpdate,
			},
			client
		);
	};

	// TODO: why do we use this currying/FP pattern here?
	const updateField = (id: string) => (name: string) => (property: string) => () => (propValue: string) => {
		let dataToUpdate = {};
		const data = getClaim(id);

		const value = parseFloat(propValue);
		const { parts } = data.claim.estimate;
		const partIndex = findIndex(parts, { name });
		logEvent('Edit - update part line', {
			value,
			line: property,
			claimId: id,
		});

		const additionalOverrides: EstimatePartInput = {};

		// We need to keep track of manual changes to strip/refit labor hours, so that we do not override it in RDP
		if (property === 'stripRefitLaborHours') {
			const hasStripOverridden = parts[partIndex].adjustments?.find(
				(x: EstimateAdjustment) => x.type === EstimateAdjustmentType.STRIP_REFIT_MANUAL_OVERRIDE
			);

			if (!hasStripOverridden) {
				additionalOverrides['adjustments'] = [
					...parts[partIndex].adjustments,
					{
						type: EstimateAdjustmentType.STRIP_REFIT_MANUAL_OVERRIDE,
						delta: value,
						appliesTo: property,
						triggerId: 'strip-manual-override',
						triggerType: EstimateAdjustmentTrigger.MANUAL,
						strategy: {
							name: AdjustmentStrategyName.ABSOLUTE,
							value,
							percentage: null, //HACK: because we are not using fragments / polymorphism
							minimum: null,
						},
					},
				];
			}
		}

		dataToUpdate = {
			estimate: {
				...data.claim.estimate,
				parts: [
					...parts.slice(0, partIndex),
					{
						...parts[partIndex],
						...additionalOverrides,
						[property]: value,
					},
					...parts.slice(partIndex + 1),
				],
			},
		};

		saveClaim(
			{
				...data.claim,
				...dataToUpdate,
			},
			client
		);
	};

	const update = (id: string) => ({
		rate: updateRate(id),
		field: updateField(id),
		paint: updatePaint(id),
		additionalCost: updateAdditionalCost(id),
		additionalHours: updateAdditionalHours(id),
	});

	return {
		update,
		updateField,
		updateRate,
		updatePaint,
		updateAdditionalCost,
		updateAdditionalHours,
		updateOperation,
		deleteOperation,
		addCustomOperation,
	};
};

export const saveClaim = (data: any, client: ApolloClient<object>) => {
	client.writeQuery({
		query: GET_CLAIM,
		data: {
			claim: data,
		},
	});
};
