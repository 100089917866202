import * as React from 'react';

import { Text } from '../Text';
import { TBody, THead, THeader, TRow, Table } from './Table';

export interface EstimateDetailsProps {
	headers: string[];
	widthsOverrides?: {
		[key: number]: string;
	};
}

const MAX_HEADERS = 8;

export const EstimateDetails: React.FC<EstimateDetailsProps> = ({ headers, widthsOverrides = {}, children }) => {
	if (headers.length > MAX_HEADERS - 2) {
		throw new Error('Too many headers passed in');
	}

	// number of columns not used by the headers. there should always be 8 columns in the table (5 that can be used by headers)
	const unusedColumns = MAX_HEADERS - headers.length - 2;

	return (
		<Table>
			<THead>
				<TRow>
					<THeader width="28px"></THeader>
					<THeader colSpan={unusedColumns} />
					{headers.map((header, index) => {
						return (
							<THeader key={`header-${index}`} width={widthsOverrides[index] ?? '80px'} align="right">
								<Text typography="standard" bold>
									{header}
								</Text>
							</THeader>
						);
					})}
					<THeader width="32px"></THeader>
				</TRow>
			</THead>
			<TBody>{children}</TBody>
		</Table>
	);
};
