import * as React from 'react';

import { Box } from '../../Box';
import SvgAwaiting from '../../Icons/Awaiting';
import SvgCheck from '../../Icons/Check';
import SvgCross from '../../Icons/Cross';
import SvgNegotiated from '../../Icons/Negotiated';
import SvgUnavailable from '../../Icons/Unavailable';
import { Text, TextProps } from '../../Text';
import { StatusIcons } from './types';

const icons: { [key: string]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element } = {
	[StatusIcons.Check]: SvgCheck,
	[StatusIcons.Cross]: SvgCross,
	[StatusIcons.Awaiting]: SvgAwaiting,
	[StatusIcons.Unavailable]: SvgUnavailable,
	[StatusIcons.Negotiated]: SvgNegotiated,
};

type TextWithStatusIconProps = {
	title: string;
	icon: StatusIcons;
	id?: string;
	typography?: 'micro' | 'small' | 'standard' | 'large' | 'x-large';
	textProps?: TextProps;
};

export const TextWithStatusIcon: React.FC<TextWithStatusIconProps> = ({
	title,
	icon,
	id,
	typography = 'standard',
	textProps = {},
}) => {
	const Icon = icons[icon];

	return (
		<Box orientation="horizontal" alignItems="center" gap={4}>
			<Icon />
			<Text {...textProps} typography={typography} id={id}>
				{title}
			</Text>
		</Box>
	);
};
