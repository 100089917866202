import * as React from 'react';
import { Children, useState } from 'react';
import { useHover, useLayer } from 'react-laag';
import styled from 'styled-components';

import { Box } from '../../../shared/Box';
import { Text } from '../../../shared/Text';
import * as addHover from './addHover.png';
import * as add from './addNew.png';

interface Props {
	readonly text?: string;
	children: React.ReactNode;
}

const Wrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
`;

export const AddGeneralLineSelect: React.FC<Props> = ({ text, children }) => {
	const [isOver, hoverProps] = useHover();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const count = Children.count(children);
	const { triggerProps, layerProps, renderLayer } = useLayer({
		isOpen,
		onOutsideClick: () => setIsOpen(false),
		placement: 'top-start',
		triggerOffset: -48 * count,
	});
	return (
		<>
			<Box
				alignItems="centre"
				gap={12}
				{...triggerProps}
				{...hoverProps}
				onClick={() => setIsOpen(true)}
				cursor="pointer"
			>
				<img src={isOver ? addHover : add} width="24px" height="24px" alt="" />
				{isOver && (
					<Text typography="standard" color="#3B25C4">
						{text}
					</Text>
				)}
			</Box>
			{isOpen && renderLayer(<Wrapper {...layerProps}>{children}</Wrapper>)}
		</>
	);
};
