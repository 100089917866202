import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../../../shared/Box';
import { Text } from '../../../shared/Text';
import * as add from './add.png';

interface Props {
	text: string;
	onClick: () => void;
}

const Wrapper = styled(Box)`
	cursor: pointer;
`;

export const AddGeneralLineButton: React.FC<Props> = ({ text, onClick }) => (
	<Wrapper onClick={onClick} gap={4} alignItems="center" data-test-id="add-general-line-button">
		<img src={add} width="16px" height="16px" alt="" />
		<Text typography="small" color="#3B25C4">
			{text}
		</Text>
	</Wrapper>
);
