import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const validate = (field: string, length: number, t: any) => (value: any) => {
	if (!value) {
		return t(`{{field}} is required`, { field });
	}

	if (value.length !== length) {
		return t(`{{field}} needs to be {{length}} characters long`, {
			field,
			length,
		});
	}

	return undefined;
};

const Vin: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="vin" validate={validate(t('VIN number'), 17, t)}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('VIN number')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder={t('VinExample')} maxLength={17} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { Vin };
