import * as copy from 'copy-to-clipboard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { Box, BoxProps, Text } from '../../shared';

interface IProps {
	link: string;
}

type WebappLinkProps = IProps & BoxProps;

const Link = styled(Text as any)`
	cursor: pointer;
`;

const linkProps = {
	fontSize: 0,
	p: 2,
	border: '1px solid #E9ECF0',
	backgroundColor: '#E9ECF0',
	borderRadius: '2px',
};

const WebappLink: React.FC<WebappLinkProps> = ({ link: webappLink, ...restProps }) => {
	const { t } = useTranslation('EstimatingPortal');

	const copyToClipboard = () => {
		copy(webappLink);
	};

	return (
		<Box {...restProps}>
			<Link data-tip={t('Link copied!')} {...linkProps} data-test-id="ph-app-link">
				{webappLink}
			</Link>
			<ReactTooltip event="click" eventOff="mouseleave" delayHide={500} afterShow={() => copyToClipboard()} />
		</Box>
	);
};

export { WebappLink };
