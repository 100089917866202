import { ImageCategory } from '@tractable/estimating-local-constants';

export function sortImages(images: any[]) {
	const sortedImages = [...images];
	return sortedImages.sort((a, b) => {
		if (a.imageCategory === b.imageCategory) {
			return 0;
		}
		if (
			(a.imageCategory === ImageCategory.CORNER || a.imageCategory === ImageCategory.DAMAGE) &&
			(b.imageCategory === ImageCategory.CORNER || b.imageCategory === ImageCategory.DAMAGE)
		) {
			return 0;
		}
		if (a.imageCategory === ImageCategory.UNKNOWN) {
			return 0;
		}
		if (a.imageCategory === ImageCategory.ADDITIONAL) {
			return 1;
		}
		return -1;
	});
}
