import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import * as React from 'react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { AmplitudeInit } from '@tractable/estimating-local-amplitude-logging';

import { PrivateClientConfig } from '../common';
import { App } from './App';
import { GenericErrorPage } from './App/GenericError';
import { GlobalStyle } from './App/styles';
import { initAuth, logoutCloseToRefreshExpiry } from './auth';
import { getPrivateClientConfig, getPublicClientConfig } from './config';
import { initi18n } from './i18n';

declare let module: any;

const renderApplication = (privateConfig: PrivateClientConfig) => {
	const container = document.getElementById('root');
	const root = createRoot(container);
	root.render(
		<Suspense fallback={null}>
			<App privateConfig={privateConfig} />
		</Suspense>
	);
};

const renderErrorPage = (error: Error) => {
	const container = document.getElementById('root');
	const root = createRoot(container);
	root.render(
		<Suspense fallback={null}>
			<GlobalStyle />
			<Router>
				<GenericErrorPage error={{ error }} />
			</Router>
		</Suspense>
	);
};

(async () => {
	const publicConfig = await getPublicClientConfig();

	initi18n(publicConfig.language, {
		loadPath: publicConfig.languageLoadPath,
		projectId: publicConfig.locizeProjectId,
	});
	await initAuth(publicConfig);
	await logoutCloseToRefreshExpiry();
	try {
		const privateConfig = await getPrivateClientConfig();
		AmplitudeInit(privateConfig.amplitudeApiKey);
		renderApplication(privateConfig);
		if (module.hot) {
			module.hot.accept();
		}
	} catch (error) {
		renderErrorPage(error);
	}
})();
