"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEstimatePart = exports.createEstimate = exports.EstimateAdjustmentTrigger = exports.AdjustmentStrategyName = exports.EstimateAdjustmentType = exports.EstimateInfoProperty = exports.InternalDamageStrategy = exports.PartSection = exports.mapPartCategory = exports.PartCategory = exports.isEstimateType = exports.EstimateType = void 0;
/**
 * @enum {string} - whether the estimate will contain part detail (supplier, name, number etc)
 */
var EstimateType;
(function (EstimateType) {
    // (Legacy) EstimateType; Also used for estimateGranularity in node-flexible-api
    EstimateType["DETAILED"] = "DETAILED";
    EstimateType["SIMPLIFIED"] = "SIMPLIFIED";
    // (Current) EstimateType
    EstimateType["ANIA"] = "ANIA";
    EstimateType["ASO"] = "ASO";
    EstimateType["LOW_MEDIUM_HIGH_REPLACE_DETAILED"] = "LOW_MEDIUM_HIGH_REPLACE_DETAILED";
    EstimateType["TRACTABLE"] = "TRACTABLE_ESTIMATE";
    EstimateType["MITCHELL"] = "MITCHELL";
    EstimateType["MITCHELL_V2"] = "MITCHELL_V2";
    EstimateType["MITCHELL_V3"] = "MITCHELL_V3";
})(EstimateType = exports.EstimateType || (exports.EstimateType = {}));
function isEstimateType(estimateType) {
    return Object.values(EstimateType).includes(estimateType);
}
exports.isEstimateType = isEstimateType;
/**
 * @enum {string} - whether the part should be treated as a main or auxiliary part
 */
var PartCategory;
(function (PartCategory) {
    PartCategory["MAIN"] = "MAIN";
    PartCategory["AUX"] = "AUX";
})(PartCategory = exports.PartCategory || (exports.PartCategory = {}));
function mapPartCategory(part) {
    return part.belongsTo ? PartCategory.AUX : PartCategory.MAIN;
}
exports.mapPartCategory = mapPartCategory;
// Allowed values for the 'section' column in the estimate_assessment_lines table
exports.PartSection = {
    PRIMARY_PART: 'PRIMARY_PART',
    ADDITIONAL_PART: 'ADDITIONAL_PART',
    PAINT: 'PAINT',
};
/**
 * @enum {string} - whether the estimate use internal damage
 */
var InternalDamageStrategy;
(function (InternalDamageStrategy) {
    InternalDamageStrategy["NONE"] = "NONE";
    InternalDamageStrategy["MIN"] = "MIN";
    InternalDamageStrategy["MAX"] = "MAX";
    InternalDamageStrategy["AVERAGE"] = "AVERAGE";
})(InternalDamageStrategy = exports.InternalDamageStrategy || (exports.InternalDamageStrategy = {}));
/**
 * @enum {string} Field/Key of the EstimateInfo section which can be targeted for adjustment
 */
var EstimateInfoProperty;
(function (EstimateInfoProperty) {
    EstimateInfoProperty["TOTAL"] = "TOTAL";
})(EstimateInfoProperty = exports.EstimateInfoProperty || (exports.EstimateInfoProperty = {}));
/**
 * @enum {string} known adjustment types/categories
 */
var EstimateAdjustmentType;
(function (EstimateAdjustmentType) {
    /* Poland-specific surcharge based on replacement part costs */
    EstimateAdjustmentType["NORMALIA"] = "NORMALIA";
    /* Deduction of paint required on a part due to minimal size of affected area */
    EstimateAdjustmentType["PARTIAL_REFINISH"] = "PARTIAL_REFINISH";
    /* Discount of part prices (typically due to client-specific partnerships) */
    EstimateAdjustmentType["PART_PRICE_DISCOUNT"] = "PART_PRICE_DISCOUNT";
    /* Discount of paint material prices (typically due to client-specific partnerships) */
    EstimateAdjustmentType["PAINT_MATERIAL_COST_DISCOUNT"] = "PAINT_MATERIAL_COST_DISCOUNT";
    /* Manual override of Strip / Refit labor hours */
    // Should we include this?
    EstimateAdjustmentType["STRIP_REFIT_MANUAL_OVERRIDE"] = "STRIP_REFIT_MANUAL_OVERRIDE";
    /* Reduction of removal time due to overlapping operations */
    EstimateAdjustmentType["OVERLAP_DEDUCTION"] = "OVERLAP_DEDUCTION";
})(EstimateAdjustmentType = exports.EstimateAdjustmentType || (exports.EstimateAdjustmentType = {}));
/**
 * @enum {enum} identifier of the Adjustment form/strategy
 */
var AdjustmentStrategyName;
(function (AdjustmentStrategyName) {
    /* percentage-based adjusment */
    AdjustmentStrategyName["PERCENTAGE"] = "PERCENTAGE";
    /* direct value-based adjustment */
    AdjustmentStrategyName["ABSOLUTE"] = "ABSOLUTE";
    /*  percentage-based adjustment on anything above a minimum value */
    AdjustmentStrategyName["PERCENTAGE_EXCLUDING_MINIMUM"] = "PERCENTAGE_EXCLUDING_MINIMUM";
})(AdjustmentStrategyName = exports.AdjustmentStrategyName || (exports.AdjustmentStrategyName = {}));
/**
 * @enum {string} what triggered the adjustment
 */
var EstimateAdjustmentTrigger;
(function (EstimateAdjustmentTrigger) {
    /* Due to Visual AI based decision on the part */
    EstimateAdjustmentTrigger["VISUAL_PART_ASSESSMENT"] = "VISUAL_ASSESSMENT";
    /* Due to rules-based decision on the part */
    EstimateAdjustmentTrigger["BUSINESS_RULE"] = "BUSINESS_RULE";
    /* Due to the claim's policy (flag system) */
    EstimateAdjustmentTrigger["POLICY"] = "POLICY";
    /* Added manually by a person */
    EstimateAdjustmentTrigger["MANUAL"] = "MANUAL";
})(EstimateAdjustmentTrigger = exports.EstimateAdjustmentTrigger || (exports.EstimateAdjustmentTrigger = {}));
/**
 * Factory function that helps create an estimate from a partial one. Note! Overrides/partial only works at the top-level. E.g.
 * - if you want to override `paint.additionalLaborHours`, you have to supply the whole `paint` object, not just a "deep-partial" of just `paint.additionalLaborHours`.
 *
 * It will initialise values as "most empty" based on typing (inc. numeric values are defaulted to 0).
 *
 * Does not initialise optional top-level fields if not provided:
 * - adjustments
 * - operations
 * - additionalCosts
 * - additionalHours
 * - createdAt
 *
 * @param base - estimate partial to use for default values
 * @returns valid estimate object using the values of base and "empty" initialisers
 */
function createEstimate(base = {}) {
    return {
        info: {},
        paint: {
            additionalLaborHours: 0,
            additionalMaterialCost: 0,
        },
        parts: [],
        additionalParts: [],
        ...base,
    };
}
exports.createEstimate = createEstimate;
/**
 * Factory function that helps create an estimate part from a partial one. Note! Overrides/partial only works at the top-level.
 * It will initialise values as "most empty" based on typing, inc:
 * - numeric values set to 0
 * - lists set to empty lists
 * - Main part (belongs to itself)
 * - operation = NONE
 * Does not initialise optional top-level fields if not provided
 *
 * @param base - estimate part partial to use for default values
 * @returns valid estimate part object using the values of base and "empty" initialisers
 */
function createEstimatePart(name, base = {}) {
    return {
        name,
        belongsTo: name,
        operation: 'NONE',
        stripRefitLaborHours: 0,
        paintLaborHours: 0,
        repairLaborHours: 0,
        replacementPartCost: 0,
        paintMaterialCost: 0,
        partCategory: PartCategory.MAIN,
        ...base,
    };
}
exports.createEstimatePart = createEstimatePart;
