/* eslint-disable react/prop-types */
import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { PrivateClientConfig } from '../../common';
import { GenericErrorPage } from '../App/GenericError';
import { Loading } from '../App/shared';
import { getPrivateClientConfig } from '../config';

export type ClientConfigCtxType = {
	config: PrivateClientConfig;
};

export const ClientConfigCtx = createContext({} as ClientConfigCtxType);

interface Props {
	// eslint-disable-next-line react/require-default-props
	privateConfig?: PrivateClientConfig;
}

export const ClientConfigProvider = ({ children, privateConfig }: React.PropsWithChildren<Props>) => {
	const [config, setConfig] = useState<PrivateClientConfig | undefined>(privateConfig);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (config) return;
		const controller = new AbortController();
		try {
			getPrivateClientConfig(controller).then((clientConfig) => {
				setConfig(clientConfig);
			});
		} catch (e) {
			setError(true);
		}
		return () => controller.abort();
	}, []);

	if (error) {
		return <GenericErrorPage error={{}} />;
	}

	if (!config) {
		return <Loading />;
	}

	return <ClientConfigCtx.Provider value={{ config }}>{children}</ClientConfigCtx.Provider>;
};

export const useClientConfigCtx = () => useContext(ClientConfigCtx);
