import { Text } from '@mantine/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../shared';

// @todo - combine with the SendAppLinkSMS component
const SendAppLinkSMSInstructions: React.FC = () => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<Box mb={2}>
			<Text size="sm">
				{t(
					'Enter a phone number to send the app link via SMS. If SMS is not available, copy the app link below to share it.'
				)}
			</Text>
		</Box>
	);
};

export default SendAppLinkSMSInstructions;
