import { PolicyConfig } from '../../../../common/types/config';

/**
 * Function that decides which estimate details to use based on the chosen client policy.
 * @param policyConfig - An array of configurations for policies which can be used for the client
 * @param claim - Current claim details
 * @param total - totalIncludingTax calculated for the tractable estimate, used for the default
 */

export const getAdditionalEstimate = (policyConfig: PolicyConfig[], claim: any, total: number) => {
	const claimPolicy = claim.policy;
	let estimateType = 'default';
	let grandTotal = total;
	let grandTotalText = 'Grand total';
	let useAdditionalEstimateFlag = false;
	const policy: PolicyConfig = policyConfig.find((e: PolicyConfig) => e.policy === claimPolicy);
	if (policy?.totalLossEstimate) {
		// Policy selected defines a different estimate type for total loss calculations, set these new values
		estimateType = policy.totalLossEstimate;
		const estimate = claim.estimatesByType.find((e: any) => e?.info?.estimateType === estimateType);
		grandTotal = estimate?.info?.amountIncludingTax;
		grandTotalText = `${estimateType}_grand total`;
		useAdditionalEstimateFlag = true;
	}
	return {
		estimateType,
		grandTotal,
		grandTotalText,
		useAdditionalEstimateFlag,
	};
};
