import * as React from 'react';

import { EstimateDecision } from '../../../../../../generated/graphql';
import useClientTranslations from '../../../../../hooks/useClientTranslations';
import { NegotiationState, SettlementAction, SettlementActionProps } from '../SettlementAction';
import { GetEditableClaimQuery } from '../generated/useGetEditableClaim.generated';
import { useSetEstimateDecisionMutation } from './generated/useSetEstimateDecision.generated';
import { getOptions } from './options';

export type EstimateReviewButtonProps = {
	claimId: string;
	estimateDecision: GetEditableClaimQuery['claim']['estimateDecision'];
	editButton: React.ReactNode;
	loading?: boolean;
	isEditing?: boolean;
	onlyAccept?: boolean;
};

export const EstimateReviewButton = ({
	claimId,
	estimateDecision,
	editButton,
	loading = false,
	isEditing = false,
	onlyAccept = false,
}: EstimateReviewButtonProps) => {
	const decision = estimateDecision || EstimateDecision.NotYetComplete;

	const { t } = useClientTranslations();
	const [setEstimateDecision, { loading: mutationLoading }] = useSetEstimateDecisionMutation();
	const buttonOptions = getOptions(t, setEstimateDecision, claimId, onlyAccept);

	const estimateStatesMap: Record<EstimateDecision, SettlementActionProps> = {
		[EstimateDecision.NotYetComplete]: {
			text: t('Approve estimate'),
			state: NegotiationState.ESTIMATE_READY,
			options: buttonOptions.review,
			dataTestId: 'estimate-ready-button',
		},
		[EstimateDecision.RejectedOos]: {
			text: t('Rejected'),
			state: NegotiationState.ESTIMATE_REJECTED,
			options: [],
			dataTestId: 'estimate-rejected-button',
		},
		[EstimateDecision.RejectedVin]: {
			text: t('Rejected'),
			state: NegotiationState.ESTIMATE_REJECTED,
			options: [],
			dataTestId: 'estimate-rejected-button',
		},
		[EstimateDecision.Accepted]: {
			text: t('Accepted'),
			state: NegotiationState.ESTIMATE_ACCEPTED,
			options: [],
			dataTestId: 'estimate-accepted-button',
		},
	};

	const isLoading = loading || mutationLoading;

	const props = isLoading ? estimateStatesMap[EstimateDecision.NotYetComplete] : estimateStatesMap[decision];

	return (
		<>
			{!isLoading && decision === EstimateDecision.NotYetComplete && editButton}
			{!isEditing && <SettlementAction loading={isLoading} {...props} />}
		</>
	);
};
