"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MissedRequirementsReasonEnum = exports.OOSReasonEnum = exports.TechIssueReasonEnum = exports.ProcessabilityTriageOutcome = void 0;
var ProcessabilityTriageOutcome;
(function (ProcessabilityTriageOutcome) {
    ProcessabilityTriageOutcome["PROCESSABLE"] = "PROCESSABLE";
    // was TECHNICAL_ISSUES
    ProcessabilityTriageOutcome["NON_PROCESSABLE_TECH_ISSUE"] = "NON_PROCESSABLE_TECH_ISSUE";
    // new category
    ProcessabilityTriageOutcome["NON_PROCESSABLE_OOS"] = "NON_PROCESSABLE_OOS";
    // new category
    ProcessabilityTriageOutcome["NON_PROCESSABLE_MISSED_REQUIREMENTS"] = "NON_PROCESSABLE_MISSED_REQUIREMENTS";
    /**
     * Old values for backward compatibility
     * TODO: get rid of non supported outcomes
     * https://tractable.atlassian.net/browse/EST-14080
     */
    // equal to one of MissedRequirementsReasonEnum
    ProcessabilityTriageOutcome["INSUFFICIENT_PHOTOS"] = "INSUFFICIENT_PHOTOS";
    ProcessabilityTriageOutcome["OUT_OF_HOURS"] = "OUT_OF_HOURS";
    // equal to one of OOSReasonEnum
    ProcessabilityTriageOutcome["OBVIOUS_TOTAL_LOSS"] = "OBVIOUS_TOTAL_LOSS";
    ProcessabilityTriageOutcome["SEVERE_DAMAGE"] = "SEVERE_DAMAGE";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE"] = "OUT_OF_SCOPE";
    ProcessabilityTriageOutcome["TECHNICAL_ISSUES"] = "TECHNICAL_ISSUES";
    ProcessabilityTriageOutcome["FAILED_TO_RETRIEVE_DATA"] = "FAILED_TO_RETRIEVE_DATA";
    ProcessabilityTriageOutcome["NON_PROCESSABLE"] = "NON_PROCESSABLE";
    ProcessabilityTriageOutcome["NOT_ENOUGH_IMAGES"] = "NOT_ENOUGH_IMAGES";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_MULTIPLE_VEHICLES"] = "OUT_OF_SCOPE_MULTIPLE_VEHICLES";
    // categories from Hil
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_VEHICLES"] = "OUT_OF_SCOPE_VEHICLES";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_INVALID_VEHICLE"] = "OUT_OF_SCOPE_INVALID_VEHICLE";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_INTERNAL_DAMAGE"] = "OUT_OF_SCOPE_INTERNAL_DAMAGE";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_CAUSE"] = "OUT_OF_SCOPE_CAUSE";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_RELEVANT_DAMAGE"] = "OUT_OF_SCOPE_RELEVANT_DAMAGE";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_HIGH_REPAIR_VALUE"] = "OUT_OF_SCOPE_HIGH_REPAIR_VALUE";
    ProcessabilityTriageOutcome["OUT_OF_SCOPE_INTERIOR_DAMAGE"] = "OUT_OF_SCOPE_INTERIOR_DAMAGE";
    // dropped as not supported
    // HIGH_REPAIR_VALUE = 'HIGH_REPAIR_VALUE',											// 0 records
    // OUT_OF_SCOPE_REPAIR_DATA_UNAVAILABLE = 'OUT_OF_SCOPE_REPAIR_DATA_UNAVAILABLE',	// 0 records
    // OUT_OF_SCOPE_VALUATION_UNAVAILABLE = 'OUT_OF_SCOPE_VALUATION_UNAVAILABLE',		// last record dated Nov 2022, demo + 1 record for DA
    // INSUFFICIENT_VIDEO = 'INSUFFICIENT_VIDEO',										// last record dated June 2022
    // INSUFFICIENT_DATA = 'INSUFFICIENT_DATA',											// last record dated June 2020
    // OUT_OF_SCOPE_FRONT_WINDSHIELD = 'OUT_OF_SCOPE_FRONT_WINDSHIELD', 				// last record dated Sep 2020
    // OUT_OF_SCOPE_FRAUD = 'OUT_OF_SCOPE_FRAUD',										// last record dated Nov 2020
    // OUT_OF_SCOPE_OTHER = 'OUT_OF_SCOPE_OTHER',										// last record dated Feb 2021
    // OUT_OF_SCOPE_VEHICLE_NOT_DECODED = 'OUT_OF_SCOPE_VEHICLE_NOT_DECODED',			// 1 record dated Feb 2023
})(ProcessabilityTriageOutcome = exports.ProcessabilityTriageOutcome || (exports.ProcessabilityTriageOutcome = {}));
var TechIssueReasonEnum;
(function (TechIssueReasonEnum) {
    // was TECHNICAL_ISSUES_VEHICLE_NOT_DECODED_FLAG
    TechIssueReasonEnum["VEHICLE_NOT_DECODED"] = "VEHICLE_NOT_DECODED";
    // was TECHNICAL_ISSUES_MISSING_YEAR_FLAG
    TechIssueReasonEnum["MISSING_YEAR"] = "MISSING_YEAR";
    // was TECHNICAL_ISSUES_VEHICLE_NOT_DECODED_FLAG
    TechIssueReasonEnum["MISSING_NATIONAL_VEHICLE_CODE"] = "MISSING_NATIONAL_VEHICLE_CODE";
    // was TECHNICAL_ISSUES_VEHICLE_MISSING_VALUATION
    TechIssueReasonEnum["MISSING_VALUATION"] = "MISSING_VALUATION";
    // was TECHNICAL_ISSUES_MISSING_REPAIR_DATA
    TechIssueReasonEnum["MISSING_REPAIR_DATA"] = "MISSING_REPAIR_DATA";
    TechIssueReasonEnum["FAILED_TO_RETRIEVE_DATA"] = "FAILED_TO_RETRIEVE_DATA";
})(TechIssueReasonEnum = exports.TechIssueReasonEnum || (exports.TechIssueReasonEnum = {}));
var OOSReasonEnum;
(function (OOSReasonEnum) {
    OOSReasonEnum["NOT_SUPPORTED_VEHICLE"] = "NOT_SUPPORTED_VEHICLE";
    OOSReasonEnum["MECHANICAL_DAMAGE"] = "MECHANICAL_DAMAGE";
    OOSReasonEnum["NOT_SUPPORTED_DAMAGE"] = "NOT_SUPPORTED_DAMAGE";
    OOSReasonEnum["OBVIOUS_TOTAL_LOSS"] = "OBVIOUS_TOTAL_LOSS";
    OOSReasonEnum["SEVERE_DAMAGE"] = "SEVERE_DAMAGE";
    OOSReasonEnum["MULTIPLE_VEHICLES"] = "MULTIPLE_VEHICLES";
    OOSReasonEnum["DAMAGE_INCONSISTENT_WITH_POI"] = "DAMAGE_INCONSISTENT_WITH_POI";
    OOSReasonEnum["NON_COLLISION_DAMAGE"] = "NON_COLLISION_DAMAGE";
    OOSReasonEnum["HIGH_REPAIR_VALUE"] = "HIGH_REPAIR_VALUE";
    OOSReasonEnum["OTHER"] = "OTHER";
})(OOSReasonEnum = exports.OOSReasonEnum || (exports.OOSReasonEnum = {}));
var MissedRequirementsReasonEnum;
(function (MissedRequirementsReasonEnum) {
    MissedRequirementsReasonEnum["INSUFFICIENT_PHOTOS"] = "INSUFFICIENT_PHOTOS";
    MissedRequirementsReasonEnum["TOTAL_BELOW_CLIENT_THRESHOLD"] = "TOTAL_BELOW_CLIENT_THRESHOLD";
    MissedRequirementsReasonEnum["OUT_OF_HOURS"] = "OUT_OF_HOURS";
})(MissedRequirementsReasonEnum = exports.MissedRequirementsReasonEnum || (exports.MissedRequirementsReasonEnum = {}));
