import * as React from 'react';

export const RLWheel = (props: React.SVGProps<SVGEllipseElement>) => (
	<ellipse
		id="RLWHEEL"
		cx={57.45}
		cy={370.2}
		stroke="#000"
		rx={24.24}
		ry={25.07}
		transform="rotate(90 57.454 370.205)"
		{...props}
	/>
);
