import styled from 'styled-components';

const StyledHeader = styled.tr`
	background: #f2f2f2;
	height: 48px;
	th:first-of-type {
		width: 50px;
	}
`;

export { StyledHeader };
