"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointOfImpact = void 0;
var PointOfImpact;
(function (PointOfImpact) {
    PointOfImpact["FRONT_CENTER"] = "Front Center";
    PointOfImpact["LEFT_FRONT_CORNER"] = "Left Front Corner";
    PointOfImpact["RIGHT_FRONT_CORNER"] = "Right Front Corner";
    PointOfImpact["REAR_CENTER"] = "Rear Center";
    PointOfImpact["LEFT_REAR_CORNER"] = "Left Rear Corner";
    PointOfImpact["RIGHT_REAR_CORNER"] = "Right Rear Corner";
    PointOfImpact["LEFT_SIDE"] = "Left Side";
    PointOfImpact["RIGHT_SIDE"] = "Right Side";
})(PointOfImpact = exports.PointOfImpact || (exports.PointOfImpact = {}));
