import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { List } from '../../shared';
import { SimpleWidget } from '../../shared/Widget';
import { Question } from './Question';
import { useYesNoQuestions } from './useYesNoQuestions';

interface YesNoQuestionsProps {
	id: string;
	widget: any;
}

const YesNoQuestions: React.FC<YesNoQuestionsProps> = ({ id, widget }) => {
	const { t } = useTranslation('EstimatingPortal');
	const { loading, questions } = useYesNoQuestions(id);

	if (loading) return null;
	if (!questions || questions.length === 0) {
		return (
			<SimpleWidget {...widget}>
				<span>{t('No questions')}</span>
			</SimpleWidget>
		);
	}

	return (
		<SimpleWidget {...widget}>
			<List items={questions} itemRenderer={(itemProps: any) => <Question {...itemProps} />} />
		</SimpleWidget>
	);
};

export { YesNoQuestions };
