import * as React from 'react';
import styled from 'styled-components';

const EmbededPdf = styled.embed`
	display: block;
	width: 100%;
	height: 100vh;
`;

interface PdfProps {
	src: string;
}

const Pdf: React.FC<PdfProps> = ({ src }) => <EmbededPdf src={`${src}#view=FitH,top`} />;

export { Pdf };
