import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Operation, OperationItem, OperationText } from '../../shared/EstimateDetails';
import { OperationType } from '../../shared/operationToFill';

export interface OperationSelectorProps {
	loading: boolean;
	panel: string;
	operation: OperationType;
	partOptions: string[];
	partSelection: string;
	isEditing: boolean;
	setOperation: (panel: string, operation: OperationType, partOptions: string[], partSelection: string) => void;
}

const OPERATIONS: OperationType[] = ['REPAIR', 'REPLACE', 'BLEND', 'REPAIR_PAINT'];

export const OperationSelector: React.FC<OperationSelectorProps> = ({
	loading,
	panel,
	isEditing,
	operation,
	partOptions,
	partSelection,
	setOperation,
}) => {
	const { t } = useTranslation('EstimatingPortal');

	if (loading) return <OperationText operation={operation}>{t('Loading...')}</OperationText>;

	return isEditing ? (
		<Operation operation={operation} operationText={t(operation)}>
			{(close) => (
				<>
					{OPERATIONS.map((o) => (
						<OperationItem
							key={o}
							text={t(o)}
							isSelected={operation === o}
							onClick={() => {
								setOperation(panel, o, partOptions, partSelection);
								close();
							}}
						/>
					))}
				</>
			)}
		</Operation>
	) : (
		<OperationText operation={operation}>{t(operation)}</OperationText>
	);
};
