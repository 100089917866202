import * as React from 'react';

import { Box } from '../Box';
import WarningRoundRed from '../Icons/WarningRoundRed';
import { Text } from '../Text';

export interface Props {
	readonly question: {
		question: string;
		response: string;
		typicalAnswer: string;
	};
}

export const Question: React.FC<Props> = ({ question }) => {
	if (!question) {
		return null;
	}

	const isTypical = question.typicalAnswer === question.response;

	return (
		<Box gap={8} justifyContent="space-between">
			<Text typography="small" bold color="#45677E">
				{question.question}:
			</Text>
			<Box gap={4}>
				{!isTypical && <WarningRoundRed />}
				<Text typography="small" color="#45677E" bold={!isTypical}>
					{question.response}
				</Text>
			</Box>
		</Box>
	);
};
