import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps, Image, Text } from '../../../shared';
import * as processing from './processing.png';
import * as tl from './tl.png';

interface IProps {
	icon: any;
	description: string;
}

type NonIdealStateProps = IProps & BoxProps;

const NonIdealState: React.FC<NonIdealStateProps> = ({ icon, description, ...restProps }) => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<Box {...restProps}>
			<Image src={icon === 'TL' ? tl : processing} width="150px" mb={5} />
			<Text mx={4} fontSize={3} textAlign="center">
				{t(description)}
			</Text>
		</Box>
	);
};

NonIdealState.defaultProps = {
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	my: 6,
	mx: 4,
	color: 'grays.1',
};

export { NonIdealState };
