import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { PrivateClientConfig } from '../../../common';
import { useClientConfigCtx } from '../../hooks/useClientConfigCtx';
import { EditQuattroruoteEstimateButton } from '../Edit/Estimatics';
import { GenericErrorPage } from '../GenericError';
import { DeleteClaimButton, Header, PageLayout } from '../shared';
import { isAniaEstimatePdf, isTractableEstimatePdf, isValidExternalEstimatePdf } from '../shared/AssetType';
import { NavIcon } from '../shared/Navbar';
import { AssetViewer } from './AssetViewer';
import { CarDetails } from './CarDetails';
import { ClaimDetails } from './ClaimDetails';
import { FNOLIndicator } from './FNOLIndicator';
import { ImagesGallery } from './ImagesGallery';
import { PointOfImpact } from './PointOfImpact';
import { VideosGallery } from './VideosGallery';
import { YesNoQuestions } from './YesNoQuestions';
import { Wrapper } from './styles';

interface ID {
	readonly id: string;
	readonly status?: string;
}

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			stage
			status
			claimDetails {
				claimId
			}
			assets {
				path
				type
				valid
			}
		}
	}
`;

const DeleteButton: React.FC<ID> = ({ id, status }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<DeleteClaimButton id={id} status={status}>
			{t('Delete request')}
		</DeleteClaimButton>
	);
};

interface LinkProps {
	readonly path: string;
}

const DownloadPDFButton: React.FC<LinkProps> = ({ path }) => {
	const { t } = useTranslation('EstimatingPortal');
	return <a href={path}>{t('Download Estimate PDF')}</a>;
};

const Review: React.FC = () => {
	const { config } = useClientConfigCtx();
	const { t } = useTranslation('EstimatingPortal');
	const { id } = useParams();
	const navigate = useNavigate();
	const { data, loading, error } = useQuery(GET_CLAIM, {
		variables: { id },
	});

	const [selectedAsset, setSelectedAsset] = useState(null);

	const showDeleteButton = !!config.ui?.deleteButtonWhileInProgress;
	const showDownloadPdfButton = !!config.ui?.downloadPdfButton;
	const showPOI = !!config.ui?.showPOI;
	const showEstimate = !!config.ui?.showEstimate;
	const showQuattroruoteEstimateURL = !!config.ui?.showQuattroruoteEstimateLink;
	const defaultPdfType: PrivateClientConfig['defaultPdfType'] | null = config.defaultPdfType || null;

	if (error) return <GenericErrorPage error={error} />;
	if (loading) return null;
	logEvent('View claim', {
		claimStatus: data.claim.status,
		claimId: id,
	});

	const sections: JSX.Element[] = [];
	if (showDeleteButton) {
		sections.push(<DeleteButton id={id} status={data.claim.status} />);
	}

	let pdfAsset;

	const hidePdfRendering =
		(data.claim.stage === 'NEGOTIATION' ||
			(data.claim.stage === 'ESTIMATE_PUBLISHING' && data.claim.status === 'DONE')) &&
		showDownloadPdfButton;

	const tractableEstimatePdf = data.claim.assets.find(isTractableEstimatePdf);
	const aniaEstimatePdf = data.claim.assets.find(isAniaEstimatePdf);
	const validExternalEstimatePdf = data.claim.assets.find(isValidExternalEstimatePdf);

	const defaultEstimatePdf = defaultPdfType === 'ania' ? aniaEstimatePdf : tractableEstimatePdf;
	if (data.claim.stage === 'ESTIMATE_PUBLISHING' && data.claim.status === 'DONE') {
		pdfAsset = defaultEstimatePdf;
	} else if (data.claim.stage === 'EXTERNAL_ESTIMATE_EDITING' && data.claim.status === 'DONE') {
		pdfAsset = validExternalEstimatePdf;
	} else if (data.claim.stage === 'NEGOTIATION') {
		pdfAsset = validExternalEstimatePdf || defaultEstimatePdf;
	}

	if (showDownloadPdfButton && pdfAsset) {
		sections.push(<DownloadPDFButton path={pdfAsset.path} />);
	}

	if (showQuattroruoteEstimateURL) {
		sections.push(<EditQuattroruoteEstimateButton clientId={data.claim.clientId} claimId={id} />);
	}

	return (
		<PageLayout
			navigationIcon={NavIcon.BACK}
			navigationHeader=""
			navigationOnClick={() => navigate('/')}
			header={() => <Header title={`${t('Request')} - ${data.claim.claimDetails.claimId}`} sections={sections} />}
		>
			<Wrapper>
				<FNOLIndicator id={id} widget={{ gridArea: 'tl', stretch: true }} />
				<CarDetails id={id} widget={{ gridArea: 'f1', stretch: true }} />
				<ClaimDetails id={id} widget={{ gridArea: 'f2', stretch: true }} />
				<YesNoQuestions id={id} widget={{ gridArea: 'f3', stretch: true }} />
				{showPOI && <PointOfImpact id={id} widget={{ gridArea: 'f4', stretch: true, padding: false }} />}
				<ImagesGallery
					id={id}
					setSelectedAsset={setSelectedAsset}
					widget={{ gridArea: showPOI ? 'f5' : 'f4', padding: false }}
				/>
				<VideosGallery id={id} widget={{ gridArea: showPOI ? 'f6' : 'f5', padding: false }} />
				<AssetViewer
					id={id}
					selectedAsset={selectedAsset}
					widget={{ sticky: '60px', gridArea: 'r', padding: false }}
					hidePdfRendering={hidePdfRendering}
					showEstimate={showEstimate}
				/>
			</Wrapper>
		</PageLayout>
	);
};

export { Review };
