export interface Currency {
	readonly symbol: string;
	readonly options: {
		readonly style: string;
		readonly currencyDisplay: string;
		readonly currency: string;
	};
}

export function formatCurrency(num: number, currencyObject?: Currency): string {
	if (!currencyObject) return num.toFixed(2);

	const {
		symbol,
		options: { style, currencyDisplay, currency },
	} = currencyObject;

	return new Intl.NumberFormat(symbol, {
		style,
		currencyDisplay,
		currency,
	}).format(num);
}
