import { get } from 'lodash';
import styled from 'styled-components';

export const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	border-style: hidden;
	border-bottom: 1px solid #d7e3eb;
`;
export const THead = styled.thead``;
export const TBody = styled.tbody``;

interface TRowProps {
	cursor?: string;
	height?: string;
	readonly bgc?: string;
}
export const TRow = styled.tr<TRowProps>`
	background-color: ${(props) => props.bgc && get(props.theme.colors, props.bgc, props.bgc)};
	cursor: ${(props) => props.cursor};
	height: ${(props) => props.height || '44px'};
`;

interface THeaderProps {
	width?: string;
	align?: string;
}
export const THeader = styled.th<THeaderProps>`
	width: ${(props) => props.width};
	text-align: ${(props) => props.align || 'left'};
	border-top: 1px solid #d7e3eb;
	border-bottom: 1px solid #d7e3eb;
`;

interface TDataProps {
	align?: string;
}
export const TData = styled.td<TDataProps>`
	border-top: 1px solid #d7e3eb;
	border-bottom: 1px solid #d7e3eb;
	text-align: ${(props) => props.align || 'left'};
`;

export const TTopData = styled(TData)`
	border-bottom: none;
	vertical-align: bottom;
`;

export const TBottomData = styled(TData)`
	border-top: none;
	vertical-align: top;
`;
