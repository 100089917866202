import * as React from 'react';
import styled from 'styled-components';

const color = '#464646';

const Wrapper = styled.div`
	padding-left: 50px;
	justify-content: space-between;
	padding-right: 50px;
	height: 80px;
	background-color: #f2f2f2;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
`;

const Title = styled.h1`
	font-weight: 300;
	font-size: 28px;
	line-height: 28px;
	vertical-align: middle;
	margin-bottom: 0;
	margin-top: 0;
	letter-spacing: 0;
	margin-right: 20px;
	color: ${color};
	display: flex;
	flex: 2;
`;

const Section = styled.div`
	margin: 0 1rem;
`;

interface HeaderProps {
	title: string;
	sections?: any;
}

const Header: React.FC<HeaderProps> = ({ title, sections }) => (
	<Wrapper>
		<Title>{title}</Title>
		{sections && sections.map((section: any, index: string) => <Section key={index}>{section}</Section>)}
	</Wrapper>
);

export { Header };
