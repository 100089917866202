import { Icon } from '@blueprintjs/core';
import * as React from 'react';

import { Box, BoxProps, Text } from '..';

interface IProps {
	title: string;
	status?: string;
}

type SummaryCardProps = IProps & BoxProps;

const SummaryCard: React.FC<SummaryCardProps> = ({ title, status, children, ...restProps }) => (
	<Box opacity={status === 'disabled' ? 0.5 : 1} {...restProps}>
		<Box display="flex" justifyContent="space-between">
			<Text fontWeight={6}>{title}</Text>
			{status !== 'disabled' && <Icon icon="tick-circle" />}
		</Box>
		{status !== 'disabled' && children && <Box mt={2}>{children}</Box>}
	</Box>
);

SummaryCard.defaultProps = {
	status: 'active',
	bg: 'grays.0',
	p: 3,
	my: 2,
};

export { SummaryCard, SummaryCardProps };
