import { Button, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { DeleteClaimProps, withDeleteClaim } from '../containers';

interface DeleteButtonProps {
	id: string;
	status: string;
	children?: React.ReactNode;
}

type DeleteButtonPropsInner = DeleteButtonProps & DeleteClaimProps;

export const DeleteClaimButton = compose<DeleteButtonPropsInner, DeleteButtonProps>(withDeleteClaim)(
	({ id, status, mutate, children }) => {
		const navigate = useNavigate();
		return (
			<Button
				id="deleteClaim"
				onClick={async () => {
					logEvent('Delete request', { claimStatus: status, claimId: id });
					await mutate({ variables: { id } });
					navigate('/');
				}}
				intent={Intent.DANGER}
				minimal
			>
				{children}
			</Button>
		);
	}
);
