import { H5 } from '@blueprintjs/core';
import * as React from 'react';
import styled from 'styled-components';

interface CallToActionProps {
	title?: string;
	text?: string;
	children: React.ReactNode;
}

const Wrapper = styled.div`
	.cta {
		display: flex;
		justify-content: flex-end;
	}
`;

const CallToAction: React.FC<CallToActionProps> = ({ title, text, children }) => (
	<Wrapper>
		{title && <H5>{title}</H5>}
		{text && <p>{text}</p>}
		<section className="cta">{children}</section>
	</Wrapper>
);

export { CallToAction };
