import gql from 'graphql-tag';
import { useEffect } from 'react';

import { useGetClaimQuery } from './generated/useGetClaim.generated';

export const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			clientId
			clientClaimId
			stage
			status
			tlIndicator
			maximumRepairSpend
			policyholderName
			policyMaxSpend
			policy
			negotiationStatus
			estimateDecision
			additionalDamage
			circumstances
			shinkaAmount
			images {
				id
				path
				imageCategory
			}
			assets {
				path
				type
				valid
			}
			yesNo {
				question
				response
				typicalAnswer
				questionId
			}
			claimDetails {
				estimateStatus
				phDecision {
					decision
					counterOffer
				}
				claimId
				dateOfAccident
				poi
				isPublishedStage
				validAsset {
					path
					valid
					type
				}
			}
			vehicle {
				name
				vin
				registration
				mileage
				valuation
				dateOfFirstRegistration
				vehicleIdentifier
				make
				model
				year
				month
				modelSubtypeCode
			}
			estimate {
				id
				info {
					taxRate
					taxStrategy {
						strategy
						rate
						bands {
							from
							to
							rate
						}
					}
					estimateType
					repairLaborRate
					stripRefitLaborRate
					paintLaborRate
					customerLanguage
					internalLanguage
					flags
					repairDataRegion {
						countryCode
					}
					internalDamage
				}
				paint {
					additionalLaborHours
					additionalMaterialCost
				}
				parts {
					name
					belongsTo
					operation
					paintMaterialCost
					stripRefitLaborHours
					undeductedStripRefitLaborHours
					paintLaborHours
					repairLaborHours
					replacementPartCost
					partCategory
					oePartIdentifier
					partType
					partSupplier
					displayPartIdentifier
					partManufacturer
					dataSource
					internalDisplayName
					customerDisplayName
					partOptions
					partSelection
					paintLevel
					paintPartMaterial
					adjustments {
						type
						triggerType
						appliesTo
						delta
						triggerId
						strategy {
							name
							value
							percentage
							minimum
						}
					}
					auxParts {
						name
						belongsTo
						operation
						paintMaterialCost
						stripRefitLaborHours
						undeductedStripRefitLaborHours
						paintLaborHours
						repairLaborHours
						replacementPartCost
						partCategory
						oePartIdentifier
						partType
						partSupplier
						displayPartIdentifier
						partManufacturer
						adjustments {
							type
							triggerType
							appliesTo
							delta
							triggerId
							strategy {
								name
								value
								percentage
								minimum
							}
						}
						dataSource
						internalDisplayName
						customerDisplayName
						partOptions
						partSelection
						paintLevel
						paintPartMaterial
					}
				}
				additionalParts {
					name
					belongsTo
					minCost
					maxCost
					labourHours
				}
				operations {
					displayDescription
					type
					triggerType
					triggers {
						part
						operation
					}
					quantity
					cost
					labor {
						hours
						rate
					}
					rowType
				}
				adjustments {
					type
					triggerType
					appliesTo
					delta
					triggerId
					strategy {
						name
						value
						percentage
						minimum
					}
				}
			}
			estimatesByType {
				id
				info {
					estimateType
					amountIncludingTax
				}
			}
			processabilityTriageOutcome
			coaEstimateDisplay {
				result
			}
			settlement {
				decision
				outOfScopeReason
				noTotalLossDecisionReason
			}
			vrmCheck {
				outcome
			}
		}
	}
`;

/**
 * useGetClaim gets a claim by polling
 *
 *  We initailly start by setting the poll interval to 5 seconds.
 *
 *  At every response we check if the estimateStatus is one of 'SHOW_ESTIMATE', 'WILL_NOT_ESTIMATE', 'CANNOT_ESTIMATE'.
 *  If this is the case then we stop polling.
 *
 * @param id the claim id
 */
export const useGetClaim = (id: string) => {
	const { data, loading, error, stopPolling, startPolling } = useGetClaimQuery({ variables: { id } });

	useEffect(() => {
		startPolling(5000);
		return () => stopPolling();
	}, [startPolling, stopPolling]);

	useEffect(() => {
		if (['SHOW_ESTIMATE', 'WILL_NOT_ESTIMATE', 'CANNOT_ESTIMATE'].includes(data?.claim?.claimDetails?.estimateStatus)) {
			stopPolling();
		}
	}, [data, stopPolling]);

	return { data, loading, error };
};
