import * as React from 'react';

export const FLDoor = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="FLDOOR"
		stroke="#000"
		d="M112.733 199.287c26.109 30.593 32.499 71.872 32.223 88.687l-52.053-7.73c-11.843.407-35.777 1.221-36.768 1.221v-85.432c35.529-12.612 49.575-8.137 56.598 3.254z"
		{...props}
	/>
);
