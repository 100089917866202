import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';
import { containsValidCharacters, format, parse, validateDate } from './date';

interface Props {
	dateFormat: string;
}

const DateOfFirstRegistration: React.FC<Props> = ({ dateFormat }) => {
	const { t } = useClientTranslations();

	return (
		<Field
			name="dateOfFirstRegistration"
			validate={validateDate(t('Please enter a valid date'), dateFormat)}
			parse={parse(dateFormat)}
			format={format(dateFormat)}
		>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Date of first registration')}
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && meta.error}
				>
					<InputGroup
						{...input}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							containsValidCharacters(e.target.value) && input.onChange(e);
						}}
						placeholder={t('DD/MM/YYYY')}
					/>
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { DateOfFirstRegistration };
