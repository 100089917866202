import styled from 'styled-components';
import {
	BorderProps,
	ColorProps,
	LayoutProps,
	SpaceProps,
	TypographyProps,
	border,
	color,
	layout,
	space,
	typography,
} from 'styled-system';

type ImageProps = SpaceProps & LayoutProps & TypographyProps & ColorProps & BorderProps;

const Image = styled.img<ImageProps>`
	width: 100%;
	line-height: 1;
	${space}
	${layout}
  ${typography}
  ${color}
  ${border}
`;

export { Image, ImageProps };
