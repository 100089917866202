"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sumPartsByKey = exports.reduceSections = exports.sumAuxpartsKey = exports.calculateTotals = void 0;
const estimating_local_constants_1 = require("@tractable/estimating-local-constants");
const calculate_estimate_adjustments_1 = require("./calculate-estimate-adjustments");
const calculate_operations_1 = require("./calculate-operations");
const calculate_tax_1 = require("./calculate-tax");
const get_taxable_fields_1 = require("./get-taxable-fields");
const calculateTotals = (estimate, // Estimate @TODO: make this use a type!
clientId, validFlags) => {
    if (!estimate ||
        !estimate.info ||
        !estimate.info.repairLaborRate ||
        !estimate.info.stripRefitLaborRate ||
        !estimate.info.paintLaborRate) {
        console.log('Cannot calculate totals, missing estimate.info and/or rates');
        return {
            currency: '',
            locale: '',
            stripRefitRepair: {
                cost: 0,
                hours: 0,
                rate: 0,
            },
            panelRepair: {
                cost: 0,
                hours: 0,
                rate: 0,
            },
            paintRepair: {
                cost: 0,
                hours: 0,
                rate: 0,
            },
            replacementParts: {
                cost: 0,
            },
            partCostAdjustmentsTotal: 0,
            paintMaterial: {
                cost: 0,
            },
            totalLabor: {
                cost: 0,
            },
            additionalCostsTotal: 0,
            additionalHoursCostTotal: 0,
            operationsTotal: 0,
            totalExcludingTax: 0,
            tax: 0,
            totalIncludingTax: 0,
            partCostAdjustments: [],
            additiveAdjustmentsTotal: 0,
            subtractiveAdjustmentsTotal: 0,
            deductibleTotals: {
                deductible: 0,
                totalExcludingTax: 0,
                tax: 0,
                totalIncludingTax: 0,
            },
            additionalPartsMaxCostTotal: 0,
            additionalPartsMinCostTotal: 0,
            additionalPartsAveCostTotal: 0,
            additionalPartsDisplayCostTotal: 0,
        };
    }
    const partSections = estimate.parts ?? [];
    const info = estimate.info ?? {};
    const paint = estimate.paint ?? {};
    const deductible = estimate.info.deductible ?? 0;
    const additionalParts = estimate.additionalParts ?? [];
    // Calculate total part costs for all parts
    const replacementParts = {
        cost: (0, exports.reduceSections)('replacementPartCost', partSections),
    };
    // Calculate repairLabor total cost for all parts
    const repairLaborRate = info.repairLaborRate || 0;
    const repairLaborTotalHours = (0, exports.reduceSections)('repairLaborHours', partSections);
    const panelRepair = {
        hours: repairLaborTotalHours,
        cost: repairLaborRate * repairLaborTotalHours,
        rate: repairLaborRate,
    };
    // Calculate stripRefitLabor total cost for all parts
    const stripRefitLaborRate = info.stripRefitLaborRate || 0;
    const stripRefitLaborTotalHours = (0, exports.reduceSections)('stripRefitLaborHours', partSections);
    const stripRefitRepair = {
        hours: stripRefitLaborTotalHours,
        cost: stripRefitLaborRate * stripRefitLaborTotalHours,
        rate: stripRefitLaborRate,
    };
    // Calculate all paint labour and hours cost (without including paint material and additional paint material costs)
    const paintLaborRate = info.paintLaborRate || 0;
    const additionalPaint = sumPaintSectionTotals(paint, paintLaborRate);
    const paintLaborHours = (0, exports.reduceSections)('paintLaborHours', partSections);
    const paintRepair = {
        hours: paintLaborHours + additionalPaint.hours,
        cost: paintLaborRate * paintLaborHours + additionalPaint.cost,
        rate: paintLaborRate,
    };
    const totalLabor = {
        cost: panelRepair.cost + stripRefitRepair.cost + paintRepair.cost,
    };
    // Calculate paint material cost for all parts and additional paint material costs
    const paintMaterial = {
        cost: (0, exports.reduceSections)('paintMaterialCost', partSections) + paint.additionalMaterialCost,
    };
    // Calculate additional parts totals
    const additionalPartsMaxCostTotal = additionalParts.reduce((acc, additionalPart) => acc + additionalPart.maxCost, 0);
    const additionalPartsMinCostTotal = additionalParts.reduce((acc, additionalPart) => acc + additionalPart.minCost, 0);
    const additionalPartsAveCostTotal = (additionalPartsMaxCostTotal + additionalPartsMinCostTotal) / 2;
    const additionalPartsDisplayCostTotal = additionalPartsMaxCostTotal;
    const useInternalDamage = estimate.info?.internalDamage && estimate.info?.internalDamage !== estimating_local_constants_1.InternalDamageStrategy.NONE ? true : false;
    //TODO: EST-13329 remove validFlags
    const { additiveAdjustmentsTotal, subtractiveAdjustmentsTotal, partCostAdjustments, partCostAdjustmentsTotal } = (0, calculate_estimate_adjustments_1.calculateEstimateAdjustments)(estimate, validFlags ?? []);
    const { additionalCostsTotal, additionalHoursCostTotal, operationsTotal } = (0, calculate_operations_1.calculateOperations)(estimate, stripRefitLaborRate); //strip refit labor rate chosen as default rate for backwards compatibility
    const totalExcludingTax = panelRepair.cost +
        stripRefitRepair.cost +
        paintRepair.cost +
        paintMaterial.cost +
        replacementParts.cost +
        partCostAdjustmentsTotal +
        operationsTotal +
        (useInternalDamage ? additionalPartsDisplayCostTotal : 0);
    const totalExcludingTaxWithDeductible = totalExcludingTax - deductible;
    let taxableTotal = totalExcludingTax;
    let taxableTotalWithDeductible = totalExcludingTaxWithDeductible;
    const clientSpecificFields = (0, get_taxable_fields_1.getTaxableFields)(clientId);
    if (clientSpecificFields) {
        const totals = {
            panelRepairCost: panelRepair.cost,
            stripRefitRepairCost: stripRefitRepair.cost,
            paintRepairCost: paintRepair.cost,
            paintMaterialCost: paintMaterial.cost,
            replacementPartsCost: replacementParts.cost,
            partCostAdjustmentsTotal,
            additionalCostsTotal,
        };
        taxableTotal = calculateTaxableTotal(totals, clientSpecificFields);
        taxableTotalWithDeductible = taxableTotal - deductible;
    }
    const taxStrategy = info.taxStrategy ?? { strategy: estimating_local_constants_1.TaxStrategies.FLAT, rate: info.taxRate ?? 0 };
    const tax = (0, calculate_tax_1.calculateTax)(taxableTotal, taxStrategy);
    const taxWithDeductible = (0, calculate_tax_1.calculateTax)(taxableTotalWithDeductible, taxStrategy);
    const totalIncludingTax = totalExcludingTax + tax;
    const totalIncludingTaxWithDeductible = totalExcludingTaxWithDeductible + taxWithDeductible;
    const deductibleTotals = {
        deductible,
        totalExcludingTax: totalExcludingTaxWithDeductible,
        tax: taxWithDeductible,
        totalIncludingTax: totalIncludingTaxWithDeductible,
    };
    return {
        locale: estimate.info.customerLanguage,
        currency: estimate.info.currencyCode,
        stripRefitRepair,
        panelRepair,
        replacementParts,
        partCostAdjustmentsTotal,
        paintRepair,
        paintMaterial,
        additionalCostsTotal,
        additionalHoursCostTotal,
        operationsTotal,
        partCostAdjustments,
        totalLabor,
        totalExcludingTax,
        tax,
        totalIncludingTax,
        deductibleTotals,
        additionalPartsMaxCostTotal,
        additionalPartsMinCostTotal,
        additionalPartsAveCostTotal,
        additionalPartsDisplayCostTotal,
        additiveAdjustmentsTotal,
        subtractiveAdjustmentsTotal,
    };
};
exports.calculateTotals = calculateTotals;
const sumAuxpartsKey = (key, auxParts) => auxParts ? auxParts.reduce((acc, p) => acc + (p[key] ?? 0), 0) : 0;
exports.sumAuxpartsKey = sumAuxpartsKey;
const reduceSections = (key, parts) => parts.reduce((acc, p) => {
    const secondaryAndAuxParts = [...(p.auxParts ?? []), ...(p.secondaryMainParts ?? [])];
    return acc + (p[key] ?? 0) + (0, exports.sumPartsByKey)(key, secondaryAndAuxParts ?? []);
}, 0);
exports.reduceSections = reduceSections;
const sumPartsByKey = (key, parts) => {
    if (!parts)
        return 0;
    return parts.reduce((acc, secondaryPart) => acc + (secondaryPart[key] ?? 0), 0);
};
exports.sumPartsByKey = sumPartsByKey;
const calculateTaxableTotal = (totals, taxableClientValues) => {
    let taxableTotal = 0;
    taxableClientValues.forEach((value) => {
        taxableTotal += totals[value];
    });
    return taxableTotal;
};
const sumPaintSectionTotals = (paint, paintLaborRate) => {
    if (!paint) {
        return { cost: 0, hours: 0, rate: paintLaborRate };
    }
    const paintTotalCost = Object.entries(paint)
        .map(([name, value]) => {
        if (name.endsWith('Cost') && !name.endsWith('MaterialCost')) {
            return value || 0;
        }
        else if (name.endsWith('Hours')) {
            return (value || 0) * paintLaborRate;
        }
        else {
            return 0;
        }
    })
        .reduce((v1, v2) => v1 + v2);
    const paintTotalHours = Object.entries(paint)
        .map(([name, value]) => {
        if (name.endsWith('Hours')) {
            return value || 0;
        }
        else {
            return 0;
        }
    })
        .reduce((v1, v2) => v1 + v2);
    return { cost: paintTotalCost, hours: paintTotalHours, rate: paintLaborRate };
};
