import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { Text } from '../../shared/Text';

interface Props {
	claimId: string;
}

export const PdfDownloader: React.FC<Props> = ({ claimId }) => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<Text
			as="a"
			href={window.location.href.replace('edit', 'pdf')}
			target="_blank"
			rel="noreferrer noopener"
			typography="standard"
			color="#3B25C4"
			p="0 8px"
			onClick={() => {
				logEvent('Edit - download estimate', { claimId });
			}}
			data-test-id="download-pdf-button"
		>
			{t('Download PDF')}
		</Text>
	);
};
