import * as React from 'react';

function SvgCross(): JSX.Element {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.8327 1.3415L10.6577 0.166504L5.99935 4.82484L1.34102 0.166504L0.166016 1.3415L4.82435 5.99984L0.166016 10.6582L1.34102 11.8332L5.99935 7.17484L10.6577 11.8332L11.8327 10.6582L7.17435 5.99984L11.8327 1.3415Z"
				fill="#BF332E"
			/>
		</svg>
	);
}

export default SvgCross;
