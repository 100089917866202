import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';
import { format, parse, validateDate } from './date';

// Common, sane date formats [ISO 8601]
const STANDARD_DATE_FORMATS = ['YYYYMMDD', 'YYYY/MM/DD'];

interface Props {
	dateFormat: string;
}

export const required = (field: string, t: any, dateFormat: string) => (value: any) => {
	if (!value) {
		return t('Date of accident is required', { field });
	}
	return validateDate(t('Please enter a valid date'), dateFormat)(value);
};

const DateOfAccidentNoPicker: React.FC<Props> = ({ dateFormat }) => {
	const { t } = useClientTranslations();

	return (
		<Field
			name="dateOfAccident"
			validate={required(t('Please enter a valid date'), t, dateFormat)}
			parse={parse([dateFormat, ...STANDARD_DATE_FORMATS])}
			format={format(dateFormat)}
		>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Date of accident')}
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && meta.error}
					labelFor="dateOfAccident"
				>
					<InputGroup
						{...input}
						id="dateOfAccident"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							input.onChange(e);
						}}
						placeholder={t('DD/MM/YYYY')}
						onPaste={(e) => {
							e.preventDefault();
							input.onChange(parse([dateFormat, ...STANDARD_DATE_FORMATS])(e.clipboardData.getData('Text')));
						}}
					/>
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { DateOfAccidentNoPicker };
