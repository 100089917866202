import { Button, Intent } from '@blueprintjs/core';
import createDecorator from 'final-form-focus';
import * as React from 'react';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';
import { RegionalLabourRateConfig, getRegionalLabourRates } from '@tractable/estimating-local-regional-labour-rate';

import { DynamicCreateClaimField } from '../../../../common';
import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';
import useClientTranslations from '../../../hooks/useClientTranslations';
import { Header, PageLayout, Widget } from '../../shared';
import { NavIcon } from '../../shared/Navbar';
import { Text } from '../../shared/Primitives';
import { fields, transform } from './Fields';
import { RegionOption } from './Fields/ClaimRegion';
import { FieldType } from './Fields/FieldType';
import { requestErrorHandler } from './requestErrorHandler';
import { getCurrencySymbolCharacter } from './utils';

interface CreateProps {
	mutate: (props: any) => any;
}

const focusOnError = createDecorator();

const Wrapper = styled.div`
	display: block;
	margin: 40px auto;
	max-width: 800px;
	min-width: 400px;
`;

const WidgetForm = Widget(Form);

function transformRegionLabourRateToOptions(regionalLabourRateConfig: RegionalLabourRateConfig): RegionOption[] {
	return Object.entries(regionalLabourRateConfig).map((entry) => ({
		regionKey: entry[0],
		displayLabel: entry[1].displayLabel,
	}));
}

const Create: React.FC<CreateProps> = ({ mutate }) => {
	const navigate = useNavigate();
	const { config } = useClientConfigCtx();
	const [selectedPolicy, setSelectedPolicy] = useState<string>('');
	const { t } = useClientTranslations();

	const clientName = config.clientId;
	const brands = config.brands?.length > 0 ? config.brands : [''];

	const { symbol, options } = config.locale;
	const currency = getCurrencySymbolCharacter(symbol, options.currency.currency);
	const regionalLabourRate = getRegionalLabourRates(config.clientId);
	const regions: RegionOption[] = regionalLabourRate ? transformRegionLabourRateToOptions(regionalLabourRate) : [];

	const clientHasMoreThanOnePolicyType = config?.policies?.length >= 2;

	let selectedPolicyConfig;
	if (clientHasMoreThanOnePolicyType) {
		selectedPolicyConfig = config.policies.find((item: any) => item.policy === selectedPolicy);
	} else if (config?.policies && config?.policies[0]) {
		selectedPolicyConfig = config.policies[0];
	}

	// We shouldn't need this if the config for clients doesn't include CLAIM_POLICY with only one policy
	// This would need investigation before being removed
	// Also why do clients all need a default policy anyway....
	const createClaimFields = clientHasMoreThanOnePolicyType
		? config?.ui?.createClaimFields ?? []
		: (config?.ui?.createClaimFields ?? []).filter((field: string) => field !== 'CLAIM_POLICY');

	const selectedPolicyConfigDynamicFields = selectedPolicyConfig?.dynamicCreateClaimFields ?? [];
	const fieldsToUse = [
		...createClaimFields,
		...selectedPolicyConfigDynamicFields.map((e: DynamicCreateClaimField) => e.field),
	];

	const selectedPolicyAdditionalEstimateType = selectedPolicyConfig?.dynamicCreateClaimFields?.find(
		(f) => f.field === 'LABOUR_RATE_FOR_ADDITIONAL_ESTIMATE'
	)?.estimateType;

	return (
		<PageLayout
			navigationIcon={NavIcon.BACK}
			navigationHeader=""
			navigationOnClick={() => navigate('/')}
			header={() => <Header title={t('Create new request')} />}
		>
			<Wrapper>
				<WidgetForm
					gridArea="f2"
					padding="3rem"
					initialValues={{ brand: brands[0], policy: '' }}
					onSubmit={(values: any) => {
						const transformedValues = transform(values, fieldsToUse as FieldType[], selectedPolicyConfigDynamicFields);
						return mutate({
							variables: {
								data: transformedValues,
							},
						})
							.then(
								({
									data: {
										createClaim: { id },
									},
								}: any) => {
									logEvent('Submit request', { claimId: id });
									navigate(`/summary/${id}`, {
										state: {
											data: transformedValues,
										},
									});
								}
							)
							.catch((e: any) => requestErrorHandler(e));
					}}
					decorators={[focusOnError]}
				>
					{({ handleSubmit, submitting, submitErrors }: any) => (
						<form
							onSubmit={handleSubmit}
							onChange={(event: any) => {
								if (event.target.name === 'policy') {
									setSelectedPolicy(event.target.value.toLowerCase());
								}
							}}
						>
							{fieldsToUse.map((field: FieldType) => {
								const Field = fields[field].component;
								return (
									<Field
										key={field}
										brands={brands}
										regions={regions}
										clientName={clientName}
										dateFormat={config.locale.dateFormat}
										policies={config.policies}
										currencySymbol={currency}
										estimateType={selectedPolicyAdditionalEstimateType}
									/>
								);
							})}
							<Button
								id="formSubmit"
								type="submit"
								disabled={submitting}
								intent={Intent.PRIMARY}
								text={t('Submit')}
								data-test-id="submit-claim-button"
								style={{ backgroundColor: '#3B25C4' }}
							/>
							{submitErrors && submitErrors.ooh && (
								<Text fontSize="14px" color="#c23030" my={2}>
									{t(submitErrors.ooh)}
								</Text>
							)}
							{submitErrors && submitErrors.invalidRequest && (
								<Text fontSize="14px" color="#c23030" my={2}>
									{t(submitErrors.invalidRequest)}
								</Text>
							)}
							{submitErrors && submitErrors.unexpectedServerError && (
								<Text fontSize="14px" color="#c23030" my={2}>
									{t(submitErrors.unexpectedServerError)}
								</Text>
							)}
						</form>
					)}
				</WidgetForm>
			</Wrapper>
		</PageLayout>
	);
};

export { Create };
