import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../Box';

interface ProgressProps {
	readonly width: string;
	readonly bgc: string;
	readonly animate?: boolean;
}

interface ProgressBarProps {
	readonly repairCost: number;
	readonly maxSpend: number;
}

const Progress = styled.div<ProgressProps>`
	width: ${(props) => props.width};
	height: 8px;
	background-color: ${(props) => props.bgc};
	transition: width 0.5s ease-out;
`;

function colorSelector({ repairCost, maxSpend }: ProgressBarProps): string {
	const WARNING_THRESHOLD = maxSpend * 0.85;
	if (repairCost >= maxSpend) {
		return '#D85349';
	} else if (repairCost >= WARNING_THRESHOLD) {
		return '#FA9441';
	} else {
		return '#34A37E';
	}
}

function progressWidth({ repairCost, maxSpend }: ProgressBarProps): string {
	if (repairCost >= maxSpend) {
		return '100%';
	} else {
		return `${(repairCost / maxSpend) * 100}%`;
	}
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ repairCost, maxSpend }) => {
	return (
		<Box flex="1">
			<Progress bgc="#E7EEF3" width="100%">
				<Progress
					bgc={colorSelector({ repairCost, maxSpend })}
					width={progressWidth({ repairCost, maxSpend })}
					animate
				/>
			</Progress>
		</Box>
	);
};
