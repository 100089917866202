import { compose, withHandlers, withState } from 'recompose';

import { Status } from './Component';

export default compose(
	withState('tag', 'setTag', ''),
	withHandlers({
		updateTag:
			({ setTag }) =>
			(value: string) =>
				setTag(value),
	})
)(Status);
