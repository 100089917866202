import * as React from 'react';

import { Pdf } from './Pdf';
import { Photo } from './Photo';
import { Video } from './Video';
import { Wrapper } from './styles';

interface ViewerProps {
	gridArea?: string;
	selectedAsset: {
		path: string;
		type: string;
	};
}

const selectViewer = (selectedAsset: any) => {
	switch (selectedAsset.type) {
		case 'video':
			return <Video src={selectedAsset.path} />;
		case 'image':
			return <Photo src={selectedAsset.path} />;
		case 'DETAILED_ESTIMATE_PDF':
		case 'TRACTABLE_ESTIMATE_PDF':
		case 'EXTERNAL_ESTIMATE_PDF':
		case 'ANIA_ESTIMATE_PDF':
		case 'pdf':
			return <Pdf src={selectedAsset.path} />;
	}
};

const Viewer: React.FC<ViewerProps> = ({ gridArea, selectedAsset }) => (
	<Wrapper gridArea={gridArea}>{selectedAsset && selectViewer(selectedAsset)}</Wrapper>
);

export { Viewer };
