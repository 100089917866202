import { PartWithAux } from '../Edit/utils/calculate-detailed-section';

const isValid = (panels: string[], name: string): boolean => !!panels.find((panel) => panel.includes(name));

const getPanel = (panels: string[], part: PartWithAux): string => {
	const nameToCheck = part.belongsTo ?? part.name;
	// Panel is a string without '_', and is the first prefix of a part.
	// e.g. part 'FLWHEEL_COVER' belongs to 'FLWHEEL'
	return isValid(panels, nameToCheck) ? nameToCheck.split('_')[0] : 'NO_PANEL_SELECTOR_AVAILABLE_ON_TEP';
};

/**
 * Sad hack until panels are available as selectors on TEP :(
 * There are panels that are unavailable under Portal's config ("selectors")
 * which throws a FE error. Until selectors for these panels are built,
 * we are mapping the parts to use known panels.
 */
export function filterValidEstimateParts(panels: string[], parts: PartWithAux[]): PartWithAux[] {
	return (
		parts
			?.map((part: PartWithAux) => ({
				...part,
				belongsTo: getPanel(panels, part),
			}))
			.filter((part: PartWithAux) => part.belongsTo !== 'NO_PANEL_SELECTOR_AVAILABLE_ON_TEP') || []
	);
}
