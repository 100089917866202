import { TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useClientConfigCtx } from './useClientConfigCtx';

type UseClientTranslationsReturnType = {
	t: (key: string, options?: string | TOptions<Record<string, string>>) => string;
};

const useClientTranslations = (): UseClientTranslationsReturnType => {
	const {
		config: { clientId = '' },
	} = useClientConfigCtx();
	const { t: locizeTranslations } = useTranslation('EstimatingPortal');

	const t = (key: string, options?: string | TOptions<Record<string, string>>): string => {
		const clientKeyPrefix = clientId ? `${clientId}.` : '';
		const clientSpecifiedKey = `${clientKeyPrefix}${key}`;

		const clientSpecifiedEntry = locizeTranslations(clientSpecifiedKey, options);

		const clientCopyExists =
			clientSpecifiedEntry !== clientSpecifiedKey && !clientSpecifiedEntry.startsWith(clientKeyPrefix);

		return clientCopyExists ? locizeTranslations(clientSpecifiedKey, options) : locizeTranslations(key, options);
	};

	return {
		t,
	};
};

export default useClientTranslations;
