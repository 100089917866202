"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhEstimateDecision = void 0;
var PhEstimateDecision;
(function (PhEstimateDecision) {
    PhEstimateDecision["NOT_YET_COMPLETE"] = "NOT_YET_COMPLETE";
    PhEstimateDecision["ACCEPTED_OFFER"] = "ACCEPTED_OFFER";
    PhEstimateDecision["OFFER_UNAVAILABLE"] = "OFFER_UNAVAILABLE";
    PhEstimateDecision["DECLINED_OFFER"] = "DECLINED_OFFER";
    PhEstimateDecision["NEGOTIATED_OFFER"] = "NEGOTIATED_OFFER";
})(PhEstimateDecision = exports.PhEstimateDecision || (exports.PhEstimateDecision = {}));
