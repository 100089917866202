import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { LabourRateProps } from '../types';
import { containsValidCharacters, required } from '../utils';
import { InputFieldSuffix, TractableFormGroupSmall } from './Shared';

// TODO: Make a higher order component for labour rate components
const LabourRateForCashSettlement: React.FC<LabourRateProps> = ({ currencySymbol }) => {
	const { t } = useClientTranslations();
	const currencyElement = <InputFieldSuffix>{currencySymbol}</InputFieldSuffix>;

	return (
		<Field name="labourRateForCashSettlement" validate={required(t('Labour rate'), t)}>
			{({ input, meta }) => (
				<TractableFormGroupSmall
					label={t('Labour rate for cash settlement')}
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && t(meta.error)}
				>
					<InputGroup
						{...input}
						rightElement={currencyElement}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							containsValidCharacters(e.target.value) && input.onChange(e);
						}}
					/>
				</TractableFormGroupSmall>
			)}
		</Field>
	);
};

export { LabourRateForCashSettlement };
