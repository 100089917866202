export type OperationType = 'NOOP' | 'BLEND' | 'REPAIR' | 'REPLACE' | 'REPLACE_AUXILIARY' | 'REPAIR_PAINT' | 'R&I';

interface Fill {
	background: string;
	color: string;
}

const createFill = (background: string, color: string): Fill => ({
	background,
	color,
});

export function operationToFill(d: OperationType): Fill {
	switch (d) {
		case 'NOOP':
			return createFill('#FFFFFF', '#FFFFFF');
		case 'BLEND':
			return createFill('#FFB93F', '#FFFFFF');
		case 'REPAIR':
			return createFill('#ED870D', '#FFFFFF');
		case 'REPLACE':
			return createFill('#D25459', '#FFFFFF');
		case 'REPLACE_AUXILIARY':
			return createFill('#FAE5E6', '#B34247');
		case 'REPAIR_PAINT':
			return createFill('#FFB93F', '#FFFFFF');
		default:
			return createFill('#FFB93F', '#FFFFFF');
	}
}
