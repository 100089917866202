import * as React from 'react';

function SvgBackArrow() {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
			<path d="m12 20 1.41-1.41L7.83 13H20v-2H7.83l5.58-5.59L12 4l-8 8 8 8Z" fill="#12191F" />
		</svg>
	);
}

export default SvgBackArrow;
