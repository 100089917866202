import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const NoData: React.FC = () => {
	const { t } = useTranslation('EstimatingPortal');
	const title = t("Can't find any requests");
	const description = t('Please create a new request');
	return <NonIdealState icon="search-template" title={title} description={description} />;
};

export { NoData };
