import * as React from 'react';

export const Bootlid = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="BOOTLID"
		stroke="#000"
		d="M181.024 431.103v-39.258s11.955 14.224 42.656 14.509c30.702.284 44.248-14.371 44.248-14.371v39.367c-34.105 7.611-54.396 7.483-86.904-.247z"
		{...props}
	/>
);
