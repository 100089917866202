"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateEstimateAdjustments = void 0;
const calculate_total_1 = require("./calculate-total");
/**
 * Calculate estimate-level discounts incoming from estimate params flags (e.g. Normalia). Legacy, TODO: EST-13323
 * @param estimate - estimate to sum up
 * @param validFlags - which legacy estimate adjustments to apply
 * @returns
 */
function calculateLegacyAdjustments(estimate, validFlags //TODO: EST-13329 remove when able
) {
    const replacementParts = {
        cost: (0, calculate_total_1.reduceSections)('replacementPartCost', estimate.parts),
    };
    const partCostAdjustmentFlags = validFlags.filter((f) => f.object === 'part' && f.subject === 'cost' && f.type === 'estimate');
    const partCostAdjustments = partCostAdjustmentFlags.map((f) => ({
        description: `${f.type}-${f.object}-${f.subject}-${f.id}`,
        cost: replacementParts.cost * (f.multiplier ?? 1),
    }));
    let additiveAdjustmentsTotal = 0;
    let subtractiveAdjustmentsTotal = 0;
    let partCostAdjustmentsTotal = 0;
    partCostAdjustments.forEach(({ cost }) => {
        if (cost > 0) {
            additiveAdjustmentsTotal += cost;
        }
        else {
            subtractiveAdjustmentsTotal += cost;
        }
        partCostAdjustmentsTotal += cost;
    });
    /* See if info.flags and valid flags are always the same */
    return {
        additiveAdjustmentsTotal,
        subtractiveAdjustmentsTotal,
        partCostAdjustments,
        partCostAdjustmentsTotal,
    };
}
/**
 * Calculates the total impact of the non-part in the Estimatee
 * @param estimate: estimate to sum up
 * @param validFlags - LEGACY (EST-13329) which estimate-level flags need to be applied
 * @returns object containing totals related to estimate adjustments
 */
function calculateEstimateAdjustments(estimate, validFlags //TODO: EST-13329 remove when able
) {
    const { adjustments } = estimate;
    // Legacy code path
    if (!adjustments?.length) {
        return calculateLegacyAdjustments(estimate, validFlags);
    }
    let additiveAdjustmentsTotal = 0;
    let subtractiveAdjustmentsTotal = 0;
    adjustments.forEach(({ delta }) => {
        if (delta > 0) {
            additiveAdjustmentsTotal += delta;
        }
        else if (delta < 0) {
            subtractiveAdjustmentsTotal += delta;
        }
    });
    //Think about this modelling?
    const partCostAdjustments = adjustments.map((adj) => ({ description: adj.type, cost: adj.delta })) ?? [];
    return {
        additiveAdjustmentsTotal,
        subtractiveAdjustmentsTotal,
        partCostAdjustments,
        partCostAdjustmentsTotal: additiveAdjustmentsTotal + subtractiveAdjustmentsTotal,
    };
}
exports.calculateEstimateAdjustments = calculateEstimateAdjustments;
