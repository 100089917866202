import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './Button';
import { StyledPagination } from './styles';

interface PaginationProps {
	hasMore: boolean;
}

const Pagination: React.FC<PaginationProps> = ({ hasMore }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<StyledPagination>
			<td colSpan={100}>
				<Button dir="previous" hasMore={hasMore}>
					{t('Previous')}
				</Button>
				<Button dir="next" hasMore={hasMore}>
					{t('Next')}
				</Button>
			</td>
		</StyledPagination>
	);
};

export { Pagination };
