import styled from 'styled-components';

interface IProps {
	columns?: number;
	rows?: number;
	customHeight?: string;
}

const Wrapper = styled.div<IProps>`
	display: grid;
	grid-template-columns: ${({ columns }: any) => `repeat(${columns}, 1fr)`};
	grid-template-rows: ${({ rows, customHeight }: any) => `repeat(${rows}, ${customHeight || '100px'})`};
	grid-gap: 1px;
	background: #f2f2f2;
`;

export { Wrapper };
