import { InputGroup, Intent } from '@blueprintjs/core';
import { TFunction } from 'i18next';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const required = (field: string, t: TFunction) => (value: any) => {
	if (!value) {
		return t('{{field}} is required', { field });
	}
	const japanDateRegex = /^(昭和|平成|令和)\d{1,2}年(0?[1-9]|1[012])月$/gm;
	const matchesDateFormat = value.match(japanDateRegex);
	// Check if input matches the accepted Japan date format
	if (!matchesDateFormat) {
		return t('Please enter the registration year month in the format “平成Y年M月”');
	}

	return undefined;
};

const RegistrationYearMonth: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="registrationYearMonth" validate={required(t('Registration year month'), t)}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Registration year month')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder={t('RegistrationYearMonthExample')} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { RegistrationYearMonth };
