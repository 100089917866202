import { includes } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { SimpleWidget } from '../../shared';
import * as poiBottomCOff from './assets/poi-bottom-c-off@2x.png';
import * as poiBottomCOn from './assets/poi-bottom-c-on@2x.png';
// POI bottom row
import * as poiBottomLOff from './assets/poi-bottom-l-off@2x.png';
import * as poiBottomLOn from './assets/poi-bottom-l-on@2x.png';
import * as poiBottomROff from './assets/poi-bottom-r-off@2x.png';
import * as poiBottomROn from './assets/poi-bottom-r-on@2x.png';
// POI mid row
import * as poiCenterLOff from './assets/poi-centre-l-off@2x.png';
import * as poiCenterLOn from './assets/poi-centre-l-on@2x.png';
import * as poiCenterROff from './assets/poi-centre-r-off@2x.png';
import * as poiCenterROn from './assets/poi-centre-r-on@2x.png';
import * as poiCenter from './assets/poi-centre@2x.png';
import * as poiTopCOff from './assets/poi-top-c-off@2x.png';
import * as poiTopCOn from './assets/poi-top-c-on@2x.png';
// POI top row
import * as poiTopLOff from './assets/poi-top-l-off@2x.png';
import * as poiTopLOn from './assets/poi-top-l-on@2x.png';
import * as poiTopROff from './assets/poi-top-r-off@2x.png';
import * as poiTopROn from './assets/poi-top-r-on@2x.png';
import { usePoi } from './usePoi';

interface PointOfImpactProps {
	id: string;
	widget: any;
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 29.87% 40.26% 29.87%;
	grid-template-rows: auto auto auto;
`;

const Image = styled.img`
	width: 100%;
	background-size: cover;
`;

const PointOfImpact: React.FC<PointOfImpactProps> = ({ id, widget }) => {
	const { loading, pois } = usePoi(id);

	if (loading) return null;

	const isSelected = (target: string) => includes(pois, target);

	return (
		<SimpleWidget {...widget}>
			<Wrapper>
				<Image src={isSelected('Left Rear Corner') ? poiBottomLOn : poiBottomLOff} />
				<Image src={isSelected('Left Side') ? poiCenterLOn : poiCenterLOff} />
				<Image src={isSelected('Left Front Corner') ? poiTopLOn : poiTopLOff} />
				<Image src={isSelected('Rear Center') ? poiBottomCOn : poiBottomCOff} />
				<Image src={poiCenter} />
				<Image src={isSelected('Front Center') ? poiTopCOn : poiTopCOff} />
				<Image src={isSelected('Right Rear Corner') ? poiBottomROn : poiBottomROff} />
				<Image src={isSelected('Right Side') ? poiCenterROn : poiCenterROff} />
				<Image src={isSelected('Right Front Corner') ? poiTopROn : poiTopROff} />
			</Wrapper>
		</SimpleWidget>
	);
};

export { PointOfImpact };
