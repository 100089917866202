import * as React from 'react';

import { Box } from '../Box';
import { Currency } from './Currency';
import { RepairCost } from './RepairCost';

export interface CostBarProps {
	readonly repairCost: number;
	readonly repairCostText: string;
	readonly currency?: Currency;
}

export const Costbar: React.FC<CostBarProps> = ({ repairCost, repairCostText, currency, children }) => {
	return (
		<Box width="100%" gap={16}>
			<RepairCost repairCost={repairCost} repairCostText={repairCostText} currency={currency} />
			<Box width="100%" p="0 0 0 16px" borderLeft="1px solid #DADFE3">
				{children}
			</Box>
		</Box>
	);
};
