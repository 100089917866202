import { map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeader } from '..';

interface SimpleTableHeaderProps {
	values: string[];
}

const SimpleTableHeader: React.FC<SimpleTableHeaderProps> = ({ values, ...restProps }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<TableHeader {...restProps}>
			{map(values, (value, i: number) => (
				<th key={i}>{t(value)}</th>
			))}
		</TableHeader>
	);
};

export { SimpleTableHeader };
