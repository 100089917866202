import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			yesNo {
				question
				response
				questionId
			}
		}
	}
`;

const transform = ({ question, response }: { question: string; response: string }) => ({
	key: v4(),
	label: question,
	value: response,
});

const useYesNoQuestions = (id: string) => {
	const [questions, setQuestions] = useState(null);
	const { loading, data } = useQuery(GET_CLAIM, {
		variables: { id },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!loading) {
			const yesNo = data.claim.yesNo || [];
			const transformedQuestions = yesNo.map(transform);
			setQuestions(transformedQuestions);
		}
	}, [loading, data]);

	return {
		loading,
		questions,
	};
};

export { useYesNoQuestions };
