import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			pointsOfImpact
		}
	}
`;

const usePoi = (id: string) => {
	const { loading, data } = useQuery(GET_CLAIM, {
		variables: { id },
		fetchPolicy: 'network-only',
	});

	return {
		loading,
		pois: data && data.claim && data.claim.pointsOfImpact,
	};
};

export { usePoi };
