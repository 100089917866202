import * as React from 'react';

export const RLDoor = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RLDOOR"
		stroke="#000"
		d="M56.135 281.441l16.71-.392 20.669-.865 51.451 7.79c-.138 10.423-3.784 43.509-7.916 56.264l-30.343 16.013-47.493-27.266-3.078-22.04v-29.504z"
		{...props}
	/>
);
