"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTax = void 0;
const estimating_local_constants_1 = require("@tractable/estimating-local-constants");
const sort_1 = require("./sort");
const calculateFlatTaxRate = (taxableTotal, strategy) => {
    return (taxableTotal * strategy.rate) / 100;
};
const calculateTiredTaxRate = (taxableTotal, strategy) => {
    let taxAmount = 0;
    const bands = strategy?.bands?.sort(sort_1.taxBandSortComparator) ?? [];
    for (const band of bands) {
        if (band.from > taxableTotal) {
            break;
        }
        const upperThreshold = band.to ? Math.min(band.to, taxableTotal) : taxableTotal;
        const taxableBandAmount = upperThreshold - band.from;
        taxAmount += (taxableBandAmount * band.rate) / 100;
    }
    return +taxAmount.toFixed(2);
};
const calculateTax = (taxableTotal, taxStrategy) => {
    if (taxStrategy.strategy === estimating_local_constants_1.TaxStrategies.FLAT) {
        return calculateFlatTaxRate(taxableTotal, taxStrategy);
    }
    if (taxStrategy.strategy === estimating_local_constants_1.TaxStrategies.TIERED) {
        return calculateTiredTaxRate(taxableTotal, taxStrategy);
    }
    throw new Error(`Unable to calculate tax, unknown strategy ${taxStrategy.strategy}`);
};
exports.calculateTax = calculateTax;
