export const EstimatePartOutput = `
  name
  belongsTo
  operation
  paintMaterialCost
  stripRefitLaborHours
  undeductedStripRefitLaborHours
  paintLaborHours
  repairLaborHours
  replacementPartCost
  partCategory
  oePartIdentifier
  partType
  partSupplier
  displayPartIdentifier
  partManufacturer
  adjustments {
    type
    triggerType
    appliesTo
    delta
    triggerId
    strategy {
      name
      value
      percentage
      minimum
    }
  }
  dataSource
  internalDisplayName
  customerDisplayName
  partOptions
  partSelection
  paintLevel
  paintPartMaterial
`;
