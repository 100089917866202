import * as React from 'react';

export const FLWheel = (props: React.SVGProps<SVGEllipseElement>) => (
	<ellipse
		id="FLWHEEL"
		cx={60.09}
		cy={156.4}
		stroke="#000"
		rx={24.24}
		ry={25.07}
		transform="rotate(90 60.092 156.404)"
		{...props}
	/>
);
