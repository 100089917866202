import { Intent } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import * as React from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { useLocalisedDate } from '../../../../utils';
import { TractableFormGroup } from './Shared';

const required = (text: string) => (value: any) => value ? undefined : text;

const DateOfAccident: React.FC = () => {
	const { t } = useClientTranslations();
	const { formatDate, locale } = useLocalisedDate();

	return (
		<Field name="dateOfAccident" validate={required(t('Date of accident is required'))}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Date of accident')}
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && meta.error}
				>
					<DateInput
						{...input}
						placeholder={t('DD/MM/YYYY')}
						formatDate={(date) => formatDate(date.toString())}
						parseDate={(str) => new Date(str)}
						maxDate={new Date()}
						value={input.value || null}
						inputProps={{ name: 'dateOfAccident' }}
						locale={locale.symbol}
						localeUtils={MomentLocaleUtils}
					/>
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { DateOfAccident };
