"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAdditionalCostOperation = exports.isAdditionalHoursOperation = exports.filterAdditionalHoursOperations = exports.filterAdditionalCostOperations = void 0;
const EstimateOperation_1 = require("@tractable/estimating-local-constants/lib/EstimateOperation");
/**
 * Filters operations to those which represent additional costs
 * @param operations
 * @returns all operations that are additional costs
 */
const filterAdditionalCostOperations = (operations) => {
    return operations.filter((op) => (0, exports.isAdditionalCostOperation)(op));
};
exports.filterAdditionalCostOperations = filterAdditionalCostOperations;
/**
 * Filters operations to those which represent additional hours
 * @param operations
 * @returns all operations that are additional hours
 */
const filterAdditionalHoursOperations = (operations) => {
    return operations.filter((op) => (0, exports.isAdditionalHoursOperation)(op));
};
exports.filterAdditionalHoursOperations = filterAdditionalHoursOperations;
const isAdditionalHoursOperation = (op) => !!(op.type === EstimateOperation_1.EstimateOperationType.CUSTOM && op.labor?.hours && !op.cost);
exports.isAdditionalHoursOperation = isAdditionalHoursOperation;
const isAdditionalCostOperation = (op) => !!(op.type === EstimateOperation_1.EstimateOperationType.CUSTOM && op.cost && !op.labor?.hours);
exports.isAdditionalCostOperation = isAdditionalCostOperation;
