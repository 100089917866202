import * as React from 'react';

import { Box } from '../Box';
import SvgBackArrow from '../Icons/BackArrow';
import SvgTRLogo from '../Icons/trlogo';
import { Text } from '../Text';

export enum NavIcon {
	'TRLOGO' = 'TRLOGO',
	'BACK' = 'BACK',
}

export const Icons: { [key: string]: any } = {
	[NavIcon.TRLOGO]: SvgTRLogo,
	[NavIcon.BACK]: SvgBackArrow,
};

export interface NavigationProps {
	readonly icon: NavIcon;
	readonly onClick: () => void;
}

export interface SignoutProps {
	readonly text: string;
	readonly onClick: () => void;
}

export interface NavbarProps {
	readonly title: string;
	readonly navigation: NavigationProps;
	readonly signout: SignoutProps;
}

export const Navbar: React.FC<NavbarProps> = ({ title, navigation, signout }) => {
	const Icon = Icons[navigation.icon];
	return (
		<Box orientation="horizontal" alignItems="center" gap={8} p="12px 16px">
			<Box onClick={navigation.onClick}>
				<Icon />
			</Box>
			<Box flex="1">
				<Text typography="standard">{title}</Text>
			</Box>
			<Text typography="standard" cursor="pointer" color="#3B25C4" onClick={signout.onClick}>
				{signout.text}
			</Text>
		</Box>
	);
};
