import * as Types from '../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClaimNotificationsQueryVariables = Types.Exact<{
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetClaimNotificationsQuery = { __typename?: 'Query', claimNotifications?: { __typename?: 'ClaimNotifications', id?: string | null, notifications?: Array<{ __typename?: 'Notification', type?: string | null, channel?: string | null, status?: string | null } | null> | null } | null };


export const GetClaimNotificationsDocument = gql`
    query getClaimNotifications($claimId: String) {
  claimNotifications(claimId: $claimId) {
    id
    notifications {
      type
      channel
      status
    }
  }
}
    `;

/**
 * __useGetClaimNotificationsQuery__
 *
 * To run a query within a React component, call `useGetClaimNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimNotificationsQuery({
 *   variables: {
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useGetClaimNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetClaimNotificationsQuery, GetClaimNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClaimNotificationsQuery, GetClaimNotificationsQueryVariables>(GetClaimNotificationsDocument, options);
      }
export function useGetClaimNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClaimNotificationsQuery, GetClaimNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClaimNotificationsQuery, GetClaimNotificationsQueryVariables>(GetClaimNotificationsDocument, options);
        }
export type GetClaimNotificationsQueryHookResult = ReturnType<typeof useGetClaimNotificationsQuery>;
export type GetClaimNotificationsLazyQueryHookResult = ReturnType<typeof useGetClaimNotificationsLazyQuery>;
export type GetClaimNotificationsQueryResult = Apollo.QueryResult<GetClaimNotificationsQuery, GetClaimNotificationsQueryVariables>;