import { Icon } from '@blueprintjs/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '../../../shared';

const NoDecision: React.FC = () => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<Box display="flex" alignItems="center">
			<Icon icon="remove-column" iconSize={24} />
			<Text fontSize={4} fontWeight={5} m={2}>
				{t('No Decision')}
			</Text>
		</Box>
	);
};

export { NoDecision };
