import * as React from 'react';

export const FLWing = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="FLWING"
		stroke="#000"
		d="M92.096 95.289c6.237 6.959 20.375 45.851 21.207 104.394-12.891-24.154-61.542-1.637-61.542-1.637v-12.691c32.85 2.456 39.087-21.289 38.256-30.295-1.581-17.124-14.97-31.933-39.087-27.43-.832-19.65 5.821-35.207 5.821-35.616 10.978-1.31 21.069.136 24.118.818l6.653 30.295 7.311 8.607-2.737-36.855"
		{...props}
	/>
);
