interface Combination {
	id: string;
	friends: string[];
}

export interface Part {
	readonly id: string;
	readonly name: string;
	readonly combinations: Combination[];
	readonly options: Option[];
}

export interface Option {
	readonly id: string;
	readonly name: string;
	readonly price: number;
	readonly renewTime: number;
}

/**
 * Returns array containing all available options for given combinations.
 *
 * @param selected
 * Currently selected options
 *
 * @param combinations
 * Array of objects linking options to all valid combinations.
 * e.g.
 * [
 *   {id: 1, friends: [2,3]},
 *   {id: 2, friends: [1]},
 *   {id: 3, friends: [1]},
 * ]
 * Option 1 can combine with option 2 and 3. Option 2 can only combine with option 1 and Option 3, as well, can only combine with option 1.
 *
 * The algorithm first selects all of the friends for selected options. Then it finds intersection between selected friends.
 * Finnally it returns a union of currently selected options and intersection between friends.
 *
 * That union is what we need - all options that we are able to interact with based on currently selected options.
 */

export function calculateAvailableOptions(selected: string[], combinations: Combination[]) {
	if (selected.length > 0) {
		const selectedCombinations = selected.reduce<string[][]>((acc, s) => {
			const combination = combinations.find((c) => c.id === s);
			if (combination) {
				return [...acc, combination.friends];
			} else {
				return acc;
			}
		}, []);

		const combinationsIntersection = selectedCombinations.reduce((acc, combination) =>
			combination.filter(Set.prototype.has, new Set(acc))
		);
		return [...new Set([...selected, ...combinationsIntersection])];
	} else {
		return combinations.map((c) => c.id);
	}
}
