import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import { Text } from '../Text';
import { EditableField } from './EditableField';
import { Currency, formatCurrencyWithoutSymbol } from './formatCurrency';

interface Info {
	readonly stripRefitLaborRate: number;
}

interface Props {
	readonly text: string;
	readonly info: Info;
	readonly isEditable: boolean;
	readonly currency: Currency;
	readonly update: (v: string) => void;
}

const LaborRateWrapper = styled(Box)`
	width: 260px;
	padding: 8px 32px;
	justify-content: space-between;
	align-items: center;
	background-color: #f9f9f9;
`;

export const LaborRate: React.FC<Props> = ({ text, isEditable, info, currency, update }) => {
	const value = info.stripRefitLaborRate;
	return (
		<LaborRateWrapper>
			<Text typography="standard" color="black">
				{text}
			</Text>
			{value && isEditable ? (
				<EditableField
					value={formatCurrencyWithoutSymbol(value, currency)}
					updateField={update}
					localeSymbol={currency.symbol}
					width="46px"
					dataTestId="labour-rate-input"
				/>
			) : (
				<Text typography="standard" color="#0B1F2C">
					{formatCurrencyWithoutSymbol(value, currency)}
				</Text>
			)}
		</LaborRateWrapper>
	);
};
