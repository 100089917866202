import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';

import { ProcessabilityTriageOutcome, Status } from '@tractable/estimating-local-constants';

import { UI as PrivateClientConfigUI } from '../../../../common';
import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			additionalDamage
			status
			tlStatus
			tlIndicator
			processabilityTriageOutcome
			vehicle {
				name
				registration
				valuation
				vin
			}
		}
	}
`;

export enum FnolStatus {
	PROCESSING = 'PROCESSING',
	PROCESSED = 'PROCESSED',
	DONE = 'DONE',
	FAILED = 'FAILED',
	OUT_OF_SCOPE = 'OUT_OF_SCOPE',
	NO_DECISION = 'NO_DECISION',
}

export enum FnolStatusDetail {
	// From App/shared/useTriageCategory.ts
	INSUFFICIENT_PHOTOS = 'INSUFFICIENT_PHOTOS',
	OUT_OF_HOURS = 'OUT_OF_HOURS',
	OUT_OF_SCOPE_HIGH_REPAIR_VALUE = 'OUT_OF_SCOPE_HIGH_REPAIR_VALUE',
	OUT_OF_SCOPE_INTERNAL_DAMAGE = 'OUT_OF_SCOPE_INTERNAL_DAMAGE',
	OUT_OF_SCOPE_INVALID_VEHICLE = 'OUT_OF_SCOPE_INVALID_VEHICLE',
	OUT_OF_SCOPE_MULTIPLE_VEHICLES = 'OUT_OF_SCOPE_MULTIPLE_VEHICLES',
	OUT_OF_SCOPE_VALUATION_UNAVAILABLE = 'OUT_OF_SCOPE_VALUATION_UNAVAILABLE',
	TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
	// Added with EST-5218
	MISSING_OUT_OF_SCOPE_TAG = 'MISSING_OUT_OF_SCOPE_TAG',

	OUT_OF_SCOPE_VEHICLES = 'OUT_OF_SCOPE_VEHICLES',
	OUT_OF_SCOPE_REPAIR_DATA_UNAVAILABLE = 'OUT_OF_SCOPE_REPAIR_DATA_UNAVAILABLE',
	OUT_OF_SCOPE_VEHICLE_NOT_DECODED = 'OUT_OF_SCOPE_VEHICLE_NOT_DECODED',
}

export type UseFnolIndicator = {
	FnolStatus: FnolStatus;
	FnolStatusDetail?: FnolStatusDetail | ProcessabilityTriageOutcome;
	IsTotalLoss?: boolean;
	NeedsInspection?: boolean;
};

export type UseFnolIndicatorState = {
	config?: Partial<PrivateClientConfigUI>;
	claim?: Partial<{
		processabilityTriageOutcome: ProcessabilityTriageOutcome;
		status: Status;
		tlStatus: any;
		tlIndicator: any;
		additionalDamage: boolean;
		vehicle?: {
			valuation?: number;
			make?: string;
			model?: string;
			year?: number;
		};
	}>;
};

export const resolveFnolStatusOutOfScopeDetail = (
	state: UseFnolIndicatorState
): undefined | FnolStatusDetail | ProcessabilityTriageOutcome => {
	const { config, claim } = state;

	if (
		(!!config.extendedOOSTitle && !config.showTriageCategoryWithClaimStatus) ||
		(!!config.showTriageCategoryWithClaimStatus &&
			Object.keys(FnolStatusDetail).includes(claim.processabilityTriageOutcome))
	) {
		return claim.processabilityTriageOutcome;
	}
	if ((!!config.extendedOOSTitle || !!config.showTriageCategoryWithClaimStatus) && !claim.vehicle?.valuation) {
		return FnolStatusDetail.OUT_OF_SCOPE_VALUATION_UNAVAILABLE;
	}
	return undefined;
};

export const resolveFnolStatus = (state: UseFnolIndicatorState): UseFnolIndicator => {
	const { config = {}, claim = {} } = state;

	const isOutOfHours = claim.status === Status.FAILED_OUT_OF_HOURS;
	if (isOutOfHours) {
		return {
			FnolStatus: FnolStatus.FAILED,
			FnolStatusDetail: FnolStatusDetail.OUT_OF_HOURS,
		};
	}

	const isOutOfScope =
		(config.showTriageCategoryWithClaimStatus && claim.status === Status.FAILED_NON_PROCESSABLE) ||
		claim.tlStatus === 'OUT_OF_SCOPE';

	if (isOutOfScope && !config.showTriageCategoryWithClaimStatus && !config.outOfScopeTag) {
		return {
			FnolStatus: FnolStatus.OUT_OF_SCOPE,
			FnolStatusDetail: FnolStatusDetail.MISSING_OUT_OF_SCOPE_TAG,
		};
	}

	const isProcessed = (!claim.tlStatus && claim.status === Status.DONE) || claim.tlStatus === 'PROCESSED';

	const isDone =
		(isProcessed && config.repairableTotalLossIsDone) ||
		(claim.tlStatus === 'OUT_OF_SCOPE' && !config.showTriageCategoryWithClaimStatus && config.outOfScopeTag === 'DONE');

	if (
		claim.tlStatus === 'OUT_OF_SCOPE' &&
		!config.showTriageCategoryWithClaimStatus &&
		config.outOfScopeTag === 'NO_DECISION'
	) {
		return {
			FnolStatus: FnolStatus.NO_DECISION,
		};
	}

	if (isDone) {
		return {
			FnolStatus: FnolStatus.DONE,
		};
	}

	if (isProcessed) {
		return {
			FnolStatus: FnolStatus.PROCESSED,
			IsTotalLoss: !!claim.tlIndicator,
			NeedsInspection: !!config.showNeedsInspection && !!claim.additionalDamage,
		};
	}

	if (isOutOfScope) {
		const detail = resolveFnolStatusOutOfScopeDetail(state);
		return {
			FnolStatus: FnolStatus.OUT_OF_SCOPE,
			FnolStatusDetail: detail,
		};
	}

	return {
		FnolStatus: FnolStatus.PROCESSING,
	};
};

export const useFNOLIndicator = (id: string): UseFnolIndicator => {
	const { config } = useClientConfigCtx();
	const [response, setResponse] = useState<UseFnolIndicator>({
		FnolStatus: FnolStatus.PROCESSING,
	});

	const { data, stopPolling } = useQuery(GET_CLAIM, {
		variables: { id },
		fetchPolicy: 'network-only',
		pollInterval: 10000,
	});

	useEffect(() => {
		const fnol = resolveFnolStatus({
			claim: data?.claim,
			config: config.ui,
		});
		if (config.ui && fnol.FnolStatus !== FnolStatus.PROCESSING) {
			stopPolling();
		}
		setResponse(fnol);
	}, [data?.claim, config.ui]);

	return response;
};
