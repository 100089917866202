import * as React from 'react';

function SvgTRLogo() {
	return (
		<svg width="27" height="24" viewBox="0 0 27 24" fill="none">
			<g clipPath="url(#clip_tr_logo)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.163 2.199c1.514 0 3.02-.229 4.466-.679l.56-.174a10.056 10.056 0 0 0-10.053 0l.561.174c1.446.45 2.952.679 4.466.679Zm-4.893.687-1.62-.504A10.039 10.039 0 0 0 3.235 8.31l1.248 1.146a16.385 16.385 0 0 1 4.88 8.434l.367 1.624a9.957 9.957 0 0 0 6.864 0l.371-1.624a16.376 16.376 0 0 1 4.882-8.436l1.248-1.146a10.038 10.038 0 0 0-3.416-5.928l-1.619.504c-3.186.991-6.6.991-9.786 0l-.003.002Zm-5.196 7.225.432.397a14.886 14.886 0 0 1 4.456 7.7l.123.543a10.06 10.06 0 0 1-3.658-3.655 10.026 10.026 0 0 1-1.353-4.985Zm15.29 8.095-.119.543a10.065 10.065 0 0 0 3.654-3.655 10.03 10.03 0 0 0 1.353-4.983l-.43.397a14.878 14.878 0 0 0-4.458 7.698ZM6.255 2.264a3.605 3.605 0 0 0-1.03-.158c-2.877 0-4.642 3.691-2.303 5.906-.114.586-.178 1.18-.191 1.777l-.743-.684C.478 7.72.002 5.686.74 3.802 1.482 1.902 3.25.674 5.226.674a5.08 5.08 0 0 1 1.509.235l.95.296c-.505.312-.984.666-1.43 1.059Zm19.33 1.538C24.84 1.903 23.078.674 21.1.674a5.08 5.08 0 0 0-1.509.234l-.95.296c.505.312.983.666 1.428 1.059a3.605 3.605 0 0 1 1.031-.158c2.877 0 4.641 3.691 2.307 5.912.115.585.179 1.18.192 1.776l.742-.683c1.506-1.39 1.983-3.423 1.243-5.308ZM9.824 19.928c.413 1.76 1.874 2.64 3.338 2.64 1.463 0 2.925-.88 3.337-2.64.564-.186 1.11-.42 1.633-.7l-.224.987C17.398 22.479 15.49 24 13.163 24c-2.328 0-4.235-1.521-4.748-3.785l-.222-.988c.523.28 1.069.515 1.632.701Z"
					fill="#4C30FB"
				/>
			</g>
			<defs>
				<clipPath id="clip_tr_logo">
					<path fill="#fff" d="M0 0h26.227v24H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgTRLogo;
