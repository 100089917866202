import { TFunction } from 'i18next';

import { NegotiationStatus } from '@tractable/estimating-local-constants';

import { SettlementActionProps } from '../SettlementAction';
import { useSetNegotiationStatusMutation } from './generated/useSetNegotiationStatus.generated';

export const getOptions = (
	t: TFunction,
	setNegotiationStatus: ReturnType<typeof useSetNegotiationStatusMutation>[0],
	claimId: string
): Record<string, SettlementActionProps['options']> => ({
	finalize: [
		{
			text: t('Accept settlement'),
			onClick: () =>
				setNegotiationStatus({
					variables: {
						claimId,
						negotiationStatus: NegotiationStatus.ACCEPTED,
					},
				}),
			dataTestId: 'accept-negotiation-button',
		},
		{
			text: t('Reject settlement'),
			onClick: () =>
				setNegotiationStatus({
					variables: {
						claimId,
						negotiationStatus: NegotiationStatus.REJECTED,
					},
				}),
			dataTestId: 'reject-negotiation-button',
		},
	],
	rejected: [
		{
			text: t('Re-open negotiation'),
			onClick: () =>
				setNegotiationStatus({
					variables: {
						claimId,
						negotiationStatus: NegotiationStatus.NOT_YET_COMPLETE,
					},
				}),
			dataTestId: 'reopen-negotiation-from-rejected-button',
		},
	],
	accepted: [
		{
			text: t('Re-open negotiation'),
			onClick: () =>
				setNegotiationStatus({
					variables: {
						claimId,
						negotiationStatus: NegotiationStatus.NOT_YET_COMPLETE,
					},
				}),
			dataTestId: 'reopen-negotiation-from-accepted-button',
		},
	],
});
