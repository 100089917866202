import { map } from 'lodash';
import * as React from 'react';

import { Box } from '../Box';
import { Panels } from './';
import { Panel } from './svgpanels';
import { usePanels } from './usePanels';

export interface QuickviewProps {
	readonly inputPanels: Panels;
	readonly leftText: string;
	readonly rightText: string;
}

export const Quickview: React.FC<QuickviewProps> = ({ inputPanels, children, leftText, rightText }) => {
	const { panels, panelProps } = usePanels({
		inputPanels,
	});

	return (
		<Box position="relative">
			{children}
			<svg width="448" viewBox="0 0 448 525" fill="none" xmlns="http://www.w3.org/2000/svg">
				{map(panels, (panel) => (
					<Panel key={`panel-${panel}`} {...panelProps(panel)} />
				))}
				<text x="95" y="483" fill="#0B1F2C">
					{leftText}
				</text>
				<text x="344" y="483" fill="#0B1F2C">
					{rightText}
				</text>
			</svg>
		</Box>
	);
};
