import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import * as moment from 'moment';

import { ValidFlag } from '@tractable/estimating-local-constants';

import { Estimate } from '../../../../common/types/estimate';
import { OperationType } from '../../shared/operationToFill';
import { EstimatePartOutput } from '../gql-output-types';
import { removeAuxPartFromParts } from '../utils/removeAuxPartFromParts';
import { GET_CLAIM } from './useGetClaim';

const SET_PART_OPERATION = gql`
  mutation setPartOperation(
    $id: String
    $partOperationInput: PartOperationInput
    $clientId: String
  ) {
    setPartOperation(id: $id, partOperationInput: $partOperationInput, clientId: $clientId) {
      id
      parts {
        ${EstimatePartOutput}
        auxParts {
          ${EstimatePartOutput}
        }
      }
    }
  }
`;

export interface UpdatePartOperationInput {
	panel: string;
	partSelection: string;
	partOptions: string[];
	operation: OperationType;
	vehicleIdentifier?: string;
	language?: string;
	make?: string;
	remove?: boolean;
	calculateOverlap?: boolean;
}

export const useUpdatePartOperation = (setIsDirty: any, validFlags: ValidFlag[]) => {
	const [setPartOperation, { loading, error }] = useMutation(SET_PART_OPERATION);

	const callPartOperation = (claim: any, input: UpdatePartOperationInput, estimate: Estimate = claim.estimate) => {
		setIsDirty(true);

		let carAge = 0;
		if (claim.claimDetails.dateOfAccident && claim.vehicle.dateOfFirstRegistration) {
			try {
				const dateOfAccident = moment(claim.claimDetails.dateOfAccident);
				const dateOfRegistration = moment(claim.vehicle.dateOfFirstRegistration);
				carAge = dateOfAccident.diff(dateOfRegistration, 'years', true);
			} catch {
				console.warn('Error calculating carAge');
			}
		}
		const partOperationInput = {
			vehicleIdentifier: input.vehicleIdentifier ?? claim.vehicle?.vehicleIdentifier,
			make: input.make ?? claim.vehicle?.make,
			// TODO: align RDP and API naming convention for partIds etc
			panel: input.panel,
			language: input.language ?? claim.estimate?.info.customerLanguage,
			partId: input.partSelection,
			optionIds: input.partOptions ?? [],
			operation: input.operation,
			carAge,
			remove: input.remove ?? false,
			calculateOverlap: true,
			estimate: {
				id: estimate.id,
				parts: removeAuxPartFromParts(estimate.parts),
				paint: estimate.paint,
				info: estimate.info,
			},
			flagIds: validFlags.map((vf) => vf.id),
		};

		return setPartOperation({
			variables: {
				id: claim.id,
				partOperationInput,
				clientId: claim.clientId,
			},
			update: (proxy: any, { data: { setPartOperation } }: any) => updateClaim(proxy, setPartOperation, claim.id),
		});
	};

	return {
		addPartOperation: callPartOperation,
		editOperation: callPartOperation,
		removePartOperation: callPartOperation,
		loading,
		error,
	};
};

function updateClaim(proxy: any, response: Pick<Estimate, 'parts'>, claimId: string) {
	const data = proxy.readQuery({
		query: GET_CLAIM,
		variables: { id: claimId },
	});

	proxy.writeQuery({
		id: claimId,
		query: GET_CLAIM,
		data: {
			claim: {
				...data.claim.estimate,
				estimate: {
					...data.claim.estimate,
					parts: response.parts,
				},
			},
		},
	});
}
