import * as React from 'react';

function SvgDeleteSolid(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.889 3.5h2.889a.71.71 0 01.51.22c.136.14.212.331.212.53s-.076.39-.211.53a.71.71 0 01-.511.22h-.722v8.25c0 .597-.229 1.169-.635 1.591a2.127 2.127 0 01-1.532.659H5.11a2.127 2.127 0 01-1.532-.659 2.295 2.295 0 01-.635-1.591V5h-.722a.709.709 0 01-.51-.22.765.765 0 01-.212-.53c0-.199.076-.39.212-.53.135-.141.319-.22.51-.22h2.89v-.75c0-.597.227-1.169.634-1.591A2.127 2.127 0 017.278.5h1.444c.575 0 1.126.237 1.532.659.407.422.635.994.635 1.591v.75zM6.767 2.22a.765.765 0 00-.211.53v.75h2.888v-.75a.765.765 0 00-.211-.53.709.709 0 00-.51-.22H7.277a.709.709 0 00-.51.22zm2.73 9.686a.28.28 0 00.186-.081l.641-.641a.256.256 0 00.082-.187.243.243 0 00-.076-.194L8.972 9.444l1.296-1.296a.256.256 0 00.082-.187.243.243 0 00-.076-.195l-.578-.578a.243.243 0 00-.195-.075.24.24 0 00-.194.074L8.011 8.483 6.697 7.17a.243.243 0 00-.194-.076.256.256 0 00-.187.082l-.64.64a.28.28 0 00-.082.187c0 .07.028.132.083.187L6.99 9.504 5.68 10.815a.24.24 0 00-.074.194c0 .07.028.133.083.188l.578.578c.05.05.112.077.187.082a.28.28 0 00.187-.081l1.311-1.311 1.359 1.358c.05.05.112.078.187.083z"
				fill="#D25459"
			/>
		</svg>
	);
}

export default SvgDeleteSolid;
