import * as React from 'react';

export const FBumper = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="FBUMPER"
		stroke="#000"
		d="M285.005 35.103c1.914 3.38 4.199 29.548 4.199 29.548h-8.78l-3.817-10.968H171.634l-3.6 10.968h-8.997s3.817-27.231 5.726-31.013c1.908-3.781 3.271-5.342 3.271-5.342h110.329s4.728 3.428 6.642 6.807z"
		{...props}
	/>
);
