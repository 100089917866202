import * as React from 'react';

import { Vid } from './styles';

interface PhotoProps {
	src: string;
}

const Video: React.FC<PhotoProps> = ({ src }) => <Vid src={src} controls />;

export { Video };
