import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';
import { NotificationChannel } from '@tractable/estimating-local-constants';

import { useClientConfigCtx } from '../../hooks/useClientConfigCtx';
import useClientTranslations from '../../hooks/useClientTranslations';
import { GenericErrorPage } from '../GenericError';
import { SendAppLinkSMS, SendAppLinkSMSInstructions } from '../Notifications';
import { useGetClaimNotificationsQuery } from '../Notifications/Actions/generated/useGetClaimNotifications.generated';
import { Box, DeleteClaimButton, Header, Image, PageLayout, Submit, SummaryCard, Text } from '../shared';
import { NavIcon } from '../shared/Navbar';
import { ClaimDetails } from './ClaimDetails';
import { LinkOpened } from './LinkOpened';
import { PhotosUploaded } from './PhotosUploaded';
import { WebappLink } from './WebappLink';
import { useSummary } from './useSummary';

const getPolicyholderGuideImage = (policyholderGuideImageUrl: string) => require(`${policyholderGuideImageUrl}`);

const Summary: React.FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { config } = useClientConfigCtx();
	const { state } = useLocation();
	const { data } = state ?? { data: {} };
	const {
		getWebappLinkProps,
		getLinkOpenedProps,
		getPhotosUploadedProps,
		getSubmitProps,
		showDeleteButton,
		claim,
		error,
		loading,
	} = useSummary({ id, interval: 10000, ...(data.phone ? { phone: data.phone } : {}) });
	const { t } = useClientTranslations();

	const { data: getClaimNotifications, loading: loadingClaimNotifications } = useGetClaimNotificationsQuery({
		variables: { claimId: id },
		fetchPolicy: 'network-only',
	});

	const policyholderGuideImageUrl = config.ui.policyholderGuide ? `./images/${config.ui.policyholderGuide}` : null;

	if (error) return <GenericErrorPage error={error} />;
	if (loading) return null;
	logEvent('View claim', { claimStatus: claim.status, claimId: claim.id });

	const sections = showDeleteButton
		? [
				<DeleteClaimButton key={id} id={id} status={claim.status}>
					{t('Delete request')}
				</DeleteClaimButton>,
		  ]
		: null;

	const indices = {
		webappLink: 1,
		linkOpened: 2,
		photosUploaded: 3,
	};

	const isSmsEnabled =
		config.notifications.enabled &&
		config.notifications.settings &&
		config.notifications.settings.find((x) => x.type === NotificationChannel.SMS && x.role === 'PolicyHolder');

	return (
		<PageLayout
			navigationIcon={NavIcon.BACK}
			navigationHeader=""
			navigationOnClick={() => navigate('/')}
			header={() => <Header title={`${t('Request')} - ${claim.claimDetails.claimId}`} sections={sections} />}
		>
			<Box m={4} display="flex" flexDirection="column" alignItems="center">
				<ClaimDetails width={0.6} claim={claim} />
				{policyholderGuideImageUrl && (
					<Box mt={4} width="100%" overflowY="scroll">
						<Image width="auto" height="500px" src={getPolicyholderGuideImage(policyholderGuideImageUrl)} />
					</Box>
				)}
				<Box m={4} width={0.5}>
					<Text fontSize={3}>{t('Send link to policyholder')}</Text>
					<SummaryCard title={`${indices.webappLink}. ${t('Click the link below to copy')}`}>
						{isSmsEnabled && <SendAppLinkSMSInstructions />}
						<WebappLink {...getWebappLinkProps()} />
						{isSmsEnabled && !loadingClaimNotifications ? (
							<SendAppLinkSMS
								claimId={id}
								appLink={getWebappLinkProps().link}
								smsNotifications={getClaimNotifications?.claimNotifications?.notifications?.filter(
									({ channel }) => channel === NotificationChannel.SMS
								)}
							/>
						) : null}
					</SummaryCard>
					<LinkOpened
						{...getLinkOpenedProps({
							title: `${indices.linkOpened}. ${t('Link Opened')}`,
						})}
					/>
					<PhotosUploaded
						{...getPhotosUploadedProps({
							title: `${indices.photosUploaded}. ${t('Policyholder Photography')}`,
						})}
					/>
					<Box display="flex" justifyContent="center">
						<Submit {...getSubmitProps()} style={{ backgroundColor: '#3B25C4' }}>
							{t('Continue')}
						</Submit>
					</Box>
				</Box>
			</Box>
		</PageLayout>
	);
};

export { Summary };
