import { map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ValidFlag } from '@tractable/estimating-local-constants';

import { Box } from '../../shared';
import { Quickview, SelectMenu, Selector } from '../../shared/Quickview';
import { OperationType } from '../../shared/operationToFill';
import { useQuickview } from '../hooks/useQuickview';

interface Props {
	claim: any;
	isEditing: boolean;
	setIsDirty: any;
	validFlags: ValidFlag[];
}

// TODO: This needs to be configurable per client
// since PZU is only live client who can do edits it is fine to keep it
// PZU specific for now
const OPERATIONS: OperationType[] = ['REPAIR', 'REPLACE', 'BLEND', 'REPAIR_PAINT'];

export const CarDiagram: React.FC<Props> = ({ claim, isEditing, setIsDirty, validFlags }) => {
	const { t } = useTranslation('EstimatingPortal');
	const { quickviewProps, selectMenuProps, selectors } = useQuickview(claim, setIsDirty, validFlags);

	return (
		<Box data-test-id="car-diagram">
			<Box m="0 auto">
				<Quickview {...quickviewProps(claim)} leftText={t('QuickviewLeft')} rightText={t('QuickviewRight')}>
					{isEditing &&
						map(selectors, (s) => (
							<Selector key={`selector-${s.panel}`} {...s} text={t(`${s.panel}_SELECTOR`)}>
								{({ close }) => (
									<SelectMenu
										title={t(s.panel)}
										partTitle={t('Part')}
										operationTitle={t('Operation')}
										submitTitle={t('Add part')}
										optionsTitle={t('Options')}
										noPartsFoundText={t('No parts found')}
										loadingPartsText={t('Loading parts...')}
										operations={OPERATIONS.map((o) => ({
											title: t(o),
											type: o,
										}))}
										{...(selectMenuProps(claim, s.panel, close) as any)}
									/>
								)}
							</Selector>
						))}
				</Quickview>
			</Box>
		</Box>
	);
};
