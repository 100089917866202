import styled from 'styled-components';

const StyledTable = styled.table`
	background-color: white;
	width: 100%;
	max-width: 100%;
	min-width: 1200px;
	margin-bottom: 1rem;
	border-collapse: collapse;
	text-align: left;
	font-size: 1.4rem;
	thead {
		tr {
			border-top: 0.1rem solid #e2e2e2;
			border-bottom: 0.1rem solid #e2e2e2;
			height: 5rem;
		}
	}
	tbody {
		tr:last-child {
			cursor: default;
			height: 5rem;
			border-top: 0.1rem solid #e2e2e2;
			border-bottom: 0.1rem solid #e2e2e2;
			:hover {
				background: #f2f2f2;
			}
		}
	}
`;

export { StyledTable };
