import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import { useClientConfigCtx } from '../../../../../hooks/useClientConfigCtx';
import useClientTranslations from '../../../../../hooks/useClientTranslations';
import { formatCurrency } from '../../utils';
import { InputFieldSuffix } from './InputFieldSuffix';
import { TractableFormGroup } from './TractableFormGroup';

interface CurrencyInputProps {
	name: string;
	validation: {
		required: boolean;
		translationKey?: string;
	};
	label: {
		translationKey: string;
	};
	suffix?: string;
}

const required = (field: string, t: any) => (value: any) => value ? undefined : t(`{{field}} is required`, { field });

const Suffix = ({ suffix }: { suffix: string }) => <InputFieldSuffix>{suffix}</InputFieldSuffix>;

const CurrencyInput: React.FC<CurrencyInputProps> = ({ name, label, validation, suffix }) => {
	const { t } = useClientTranslations();
	const { config } = useClientConfigCtx();

	return (
		<Field
			name={name}
			validate={validation.required ? required(t(validation.translationKey), t) : undefined}
			format={(value: string) => {
				const localizedValue = formatCurrency(value, config.locale.symbol);
				return localizedValue === '0' ? '' : localizedValue;
			}}
		>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t(label.translationKey)}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
					labelFor={name}
				>
					<InputGroup {...input} name={name} id={name} rightElement={<Suffix suffix={suffix} />} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { CurrencyInput, required };
