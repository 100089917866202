import styled from 'styled-components';

const StyledPagination = styled.tr`
	background-color: #f2f2f2;
	height: 48px;
	td {
		text-align: center;
	}
`;

export { StyledPagination };
