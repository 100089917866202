"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEstimateAssessment = exports.AdditionalEstimateAssessmentTypes = exports.AssessmentType = void 0;
var AssessmentType;
(function (AssessmentType) {
    AssessmentType["YES_NO_QUESTIONS"] = "FNOL_YESNO";
    AssessmentType["NEGOTIATION"] = "NEGOTIATION";
    AssessmentType["CLASSIFICATION"] = "CLASSIFICATION";
    AssessmentType["DAMAGED_PARTS"] = "DAMAGED_PARTS";
    AssessmentType["DETECTED_PARTS"] = "DETECTED_PARTS";
    AssessmentType["DETAILED_ESTIMATE"] = "DETAILED_ESTIMATE";
    AssessmentType["ESTIMATE"] = "ESTIMATE";
    AssessmentType["IMAGE_PART_OPERATION"] = "IMAGE_PART_OPERATION";
    AssessmentType["PART_MAPPING"] = "PART_MAPPING";
    AssessmentType["PART_OPERATION"] = "PART_OPERATION";
    AssessmentType["POOLING"] = "POOLING";
    AssessmentType["REVIEW"] = "REVIEW";
    AssessmentType["VISUAL_CLASSIFICATION"] = "VISUAL_CLASSIFICATION";
    AssessmentType["TOTAL_LOSS"] = "TOTAL_LOSS";
    AssessmentType["APP_FEEDBACK"] = "APP_FEEDBACK";
    AssessmentType["PROCESSABILITY_TRIAGE"] = "PROCESSABILITY_TRIAGE";
    AssessmentType["ESTIMATE_DECISION"] = "ESTIMATE_DECISION";
    AssessmentType["LMH_DETAILED_ESTIMATE"] = "LMH_DETAILED_ESTIMATE";
    AssessmentType["ANIA_ESTIMATE"] = "ANIA_ESTIMATE";
    AssessmentType["ASO_ESTIMATE"] = "ASO_ESTIMATE";
    AssessmentType["COA_ESTIMATE_DISPLAY"] = "COA_ESTIMATE_DISPLAY";
    AssessmentType["PH_ESTIMATE_DECISION"] = "PH_ESTIMATE_DECISION";
    AssessmentType["VRM_CHECK"] = "VRM_CHECK";
    AssessmentType["MITCHELL_ESTIMATE"] = "MITCHELL_ESTIMATE";
    AssessmentType["MITCHELL_ESTIMATE_V2"] = "MITCHELL_ESTIMATE_V2";
    AssessmentType["MITCHELL_ESTIMATE_V3"] = "MITCHELL_ESTIMATE_V3";
    // When adding a new AssessmentType, make sure to update the Estimating Graveyard to delete it correctly
    // https://github.com/tractableai/gravedigger/blob/master/src/gravedigger/graveyard/estimating.py#L77
})(AssessmentType = exports.AssessmentType || (exports.AssessmentType = {}));
exports.AdditionalEstimateAssessmentTypes = [
    AssessmentType.LMH_DETAILED_ESTIMATE,
    AssessmentType.ANIA_ESTIMATE,
    AssessmentType.ASO_ESTIMATE,
    AssessmentType.MITCHELL_ESTIMATE,
    AssessmentType.MITCHELL_ESTIMATE_V2,
    AssessmentType.MITCHELL_ESTIMATE_V3,
];
function isEstimateAssessment(estimateAssessmentType) {
    return exports.AdditionalEstimateAssessmentTypes.includes(estimateAssessmentType);
}
exports.isEstimateAssessment = isEstimateAssessment;
