import * as React from 'react';

import { Wrapper } from './styles';

const Widget =
	(Component: any) =>
	// eslint-disable-next-line react/display-name
	({
		gridArea,
		title,
		stretch,
		background = '#F2F2F2',
		padding = '1rem',
		centerText = false,
		sticky,
		...otherProps
	}: any) =>
		(
			<Wrapper
				gridArea={gridArea}
				stretch={stretch}
				padding={padding}
				sticky={sticky}
				background={background}
				centerText={centerText}
			>
				{title && <h2>{title}</h2>}
				<section>
					<Component {...otherProps} />
				</section>
			</Wrapper>
		);

const SimpleWidget = Widget((props: any) => props.children);

export { Widget, SimpleWidget };
