import { keys } from 'lodash';

import { Panel } from '@tractable/estimating-local-constants';

import { Panels } from './';

export const defaultPanels: Panels = {
	BONNET: {
		operation: 'NOOP',
	},
	FLWING: {
		operation: 'NOOP',
	},
	FRWING: {
		operation: 'NOOP',
	},
	FBUMPER: {
		operation: 'NOOP',
	},
	BBUMPER: {
		operation: 'NOOP',
	},
	FLDOOR: {
		operation: 'NOOP',
	},
	FRDOOR: {
		operation: 'NOOP',
	},
	BLWING: {
		operation: 'NOOP',
	},
	BRWING: {
		operation: 'NOOP',
	},
	BOOTLID: {
		operation: 'NOOP',
	},
	RRDOOR: {
		operation: 'NOOP',
	},
	RLDOOR: {
		operation: 'NOOP',
	},
	LHEADLAMP: {
		operation: 'NOOP',
	},
	RHEADLAMP: {
		operation: 'NOOP',
	},
	LTAILLAMP: {
		operation: 'NOOP',
	},
	RTAILLAMP: {
		operation: 'NOOP',
	},
	GRILLE: {
		operation: 'NOOP',
	},
	LROCKERPANEL: {
		operation: 'NOOP',
	},
	RROCKERPANEL: {
		operation: 'NOOP',
	},
	ROOF: {
		operation: 'NOOP',
	},
	FRWHEEL: {
		operation: 'NOOP',
	},
	RRWHEEL: {
		operation: 'NOOP',
	},
	FLWHEEL: {
		operation: 'NOOP',
	},
	RLWHEEL: {
		operation: 'NOOP',
	},
	EXTRA: {
		operation: 'NOOP',
	},
	LMIRROR: {
		operation: 'NOOP',
	},
	RMIRROR: {
		operation: 'NOOP',
	},
	LAPILLAR: {
		operation: 'NOOP',
	},
	RAPILLAR: {
		operation: 'NOOP',
	},
	RWINDSHIELD: {
		operation: 'NOOP',
	},
	FWINDSHIELD: {
		operation: 'NOOP',
	},
};

export interface UsePanelsInput {
	readonly inputPanels: Panels;
}

export const usePanels = ({ inputPanels }: UsePanelsInput) => {
	const svgPanels = { ...defaultPanels, ...inputPanels };

	const panelProps = (panel: Panel) => ({
		panel,
		operation: svgPanels[panel].operation,
	});

	return {
		panels: keys(svgPanels) as Panel[],
		panelProps,
	};
};
