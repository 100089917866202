import styled from 'styled-components';

const Wrapper = styled.div`
	display: grid;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	grid-template-columns: 1fr 2fr 1fr;
	grid-template-rows: auto auto auto auto;
	align-items: start;
	grid-template-areas:
		't0 tl t4'
		't0 t2 t4'
		't0 t3 t4'
		't0 t5 t4';
`;

export { Wrapper };
