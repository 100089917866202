import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';
import { Detail as DetailComponent } from '../../shared/ClaimDetails';

const enum DetailType {
	iban = 'iban',
	name = 'name',
	ssn = 'ssn',
}
interface Detail {
	id: DetailType;
	component: React.ComponentType;
	transformer: (claim: any) => any;
}

export type DynamicDetail = {
	component: React.ComponentType;
	props: any;
};

interface UsePayee {
	id: string;
	interval?: number;
}

const GET_PAYEE = gql`
	query getPayee($id: String) {
		payee(id: $id) {
			iban
			ssn
			name
			phone
		}
	}
`;

const usePayee = ({ id }: UsePayee) => {
	const { config } = useClientConfigCtx();
	const { t } = useTranslation('EstimatingPortal');
	const {
		ui: { details: configDetails },
	} = config;
	const [details, setDetails] = useState<Detail[]>([]);
	const [payeeDetails, setPayeeDetails] = useState<DynamicDetail[][]>([[]]);

	const { data: getPayeeData, error: payeeError } = useQuery(GET_PAYEE, {
		variables: { id },
		skip: !configDetails.payee,
	});

	useEffect(() => {
		setDetails([
			{
				id: DetailType.iban,
				component: DetailComponent,
				transformer: (payee: any) => ({
					label: t('IBAN'),
					vertical: true,
					children: payee.iban,
				}),
			},
			{
				id: DetailType.name,
				component: DetailComponent,
				transformer: (payee: any) => ({
					label: t('Name'),
					vertical: true,
					children: payee.name,
				}),
			},
			{
				id: DetailType.ssn,
				component: DetailComponent,
				transformer: (payee: any) => ({
					label: t('SSN'),
					vertical: true,
					children: payee.ssn,
				}),
			},
		]);
	}, []);

	const detailsMapper = (subsection: string[]) =>
		subsection.map((id) => {
			const { transformer, component } = details.find((d: Detail) => d.id === id);

			return {
				component,
				props: transformer(getPayeeData.payee),
			};
		});

	useEffect(() => {
		if (details.length > 0 && getPayeeData) {
			setPayeeDetails(configDetails.payee.map(detailsMapper));
		}
	}, [details, getPayeeData]);

	return {
		payeeDetails,
		error: payeeError,
	};
};

export { usePayee };
