import * as React from 'react';
import { useState } from 'react';
import { useLayer } from 'react-laag';
import styled from 'styled-components';

import { Box } from '../Box';
import { Text } from '../Text';
import { SelectorType } from './';

interface Props extends SelectorType {
	readonly text?: string;
	readonly onClick?: () => void;
	children({ close }: { close: () => void }): React.ReactElement;
}

interface WrapperProps {
	isOpen: boolean;
}

const SelectorWrapper = styled(Box)<WrapperProps>`
	background: ${({ isOpen }) => (isOpen ? '#455CC7' : '#ffffff')};
	opacity: 0.9;
	border: 1px solid #3b25c4;
	border-radius: 3px;
	padding: 0 8px;
	&:hover {
		${({ isOpen }) => !isOpen && 'background-color: #dde7ff;'}
	}
`;

export const Selector: React.FC<Props> = ({ x, y, text, panel, onClick, children }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { triggerProps, layerProps, renderLayer } = useLayer({
		isOpen,
		onOutsideClick: () => setIsOpen(false),
		placement: 'bottom-start',
		auto: true,
	});
	const closeSelection = () => setIsOpen(false);
	return (
		<>
			<SelectorWrapper
				ref={triggerProps.ref}
				onClick={() => {
					onClick && onClick();
					setIsOpen(!isOpen);
				}}
				position="absolute"
				left={x}
				top={y}
				isOpen={isOpen}
			>
				<Text typography="small" color={`${isOpen ? '#ffffff' : '#3B25C4'}`}>
					{text || panel}
				</Text>
			</SelectorWrapper>
			{isOpen &&
				children &&
				renderLayer(
					<div ref={layerProps.ref} style={{ ...layerProps.style, marginTop: '8px' }}>
						{children && children({ close: closeSelection })}
					</div>
				)}
		</>
	);
};
