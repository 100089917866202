import * as React from 'react';

import { Box } from '../../Box';
import { Text } from '../../Text';
import { IndicatorsWrapper } from './IndicatorsWrapper';
import { ClaimStatusProps, Pill } from './Pill';

export interface Props {
	readonly title: string;
	readonly granularReason?: ClaimStatusProps;
}

export const ClaimHeader: React.FC<Props> = ({ title, children, granularReason }) => {
	return (
		<Box orientation="vertical" gap={8}>
			<Box orientation="horizontal" gap={8}>
				<Text typography="x-large" data-test-id="claim-header-title" bold>
					{title}
				</Text>
				{granularReason && <Pill {...granularReason} />}
			</Box>
			<IndicatorsWrapper>{children}</IndicatorsWrapper>
		</Box>
	);
};
