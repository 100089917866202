import styled from 'styled-components';

const Wrapper = styled.div`
	display: grid;
	grid-row-gap: 0.5rem;
	background: #f2f2f2;
	padding: 10px;
	h2 {
		font-weight: normal;
	}
`;

export { Wrapper };
