import * as React from 'react';

export const LMirror = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="LMIRROR"
		stroke="#000"
		d="M116.28 227.043c-2.748 1.235-6.55-1.302-6.55-1.302-.138-6.17-.825-19.154-.825-21.73 2.886 3.22 7.833 8.853 13.193 18.914 0 0-3.069 2.884-5.818 4.118z"
		{...props}
	/>
);
