import * as React from 'react';

function SvgUp({ fill = '#2A4759' }: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="12px" height="12px" viewBox="0 0 13 13" fill="none">
			<g clipPath="url(#up_svg__clip0)">
				<path
					d="M.708 9.565c.205.198.483.31.772.31.289 0 .566-.112.77-.31l3.928-3.778 3.872 3.778c.205.198.482.31.771.31.29 0 .566-.112.771-.31a1.065 1.065 0 00.324-.758 1.045 1.045 0 00-.324-.758L6.954 3.524a1.096 1.096 0 00-.776-.315 1.118 1.118 0 00-.777.315L.708 8.05a1.066 1.066 0 00-.323.758 1.045 1.045 0 00.323.758z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="up_svg__clip0">
					<path fill="#fff" transform="rotate(180 6.15 6.25)" d="M0 0h12.3v12H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgUp;
