import * as React from 'react';

import { SimpleWidget } from '../../shared';
import { Done, NoDecision, OutOfHours, OutOfScope, Processed, Processing } from './Status';
import {
	FnolStatusDetail as FnolStatusDetailEnum,
	FnolStatus as FnolStatusEnum,
	UseFnolIndicator,
	useFNOLIndicator,
} from './useFNOLIndicator';

export type FNOLIndicatorProps = {
	id: string;
	widget: any;
};

export const resolveWidget = ({
	FnolStatus,
	FnolStatusDetail,
	IsTotalLoss,
	NeedsInspection,
}: UseFnolIndicator): React.ReactElement => {
	if (FnolStatus === FnolStatusEnum.PROCESSING) {
		return <Processing />;
	}
	if (FnolStatus === FnolStatusEnum.PROCESSED) {
		return <Processed isTL={IsTotalLoss} needsInspection={NeedsInspection} />;
	}
	if (FnolStatus === FnolStatusEnum.DONE) {
		return <Done />;
	}
	if (FnolStatus === FnolStatusEnum.NO_DECISION) {
		return <NoDecision />;
	}
	if (FnolStatus === FnolStatusEnum.FAILED && FnolStatusDetail === FnolStatusDetailEnum.OUT_OF_HOURS) {
		return <OutOfHours />;
	}
	if (
		FnolStatus === FnolStatusEnum.OUT_OF_SCOPE &&
		FnolStatusDetail === FnolStatusDetailEnum.MISSING_OUT_OF_SCOPE_TAG
	) {
		return null;
	}
	return <OutOfScope category={FnolStatusDetail} />;
};

export const FNOLIndicator: React.FC<FNOLIndicatorProps> = ({ id, widget }) => {
	const resolverArgs = useFNOLIndicator(id);

	return <SimpleWidget {...widget}>{resolveWidget(resolverArgs)}</SimpleWidget>;
};
