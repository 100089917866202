import { PartCategory, PartType } from '@tractable/estimating-local-constants';

import { AuxPart, Estimate, EstimateInfo, EstimatePart } from '../../../../generated/graphql';
import { OperationType } from '../../shared/operationToFill';

export interface DetailedPartSection {
	description: string;
	belongsTo: string;
	general: {
		cost: number;
	};
	stripRefit: {
		rate: number;
		hours: number;
		cost: number;
	};
	panelRepair: {
		rate: number;
		hours: number;
		cost: number;
	};
	paintRepair: {
		rate: number;
		hours: number;
		cost: number;
	};
	paintMaterial: {
		cost: number;
	};
	auxParts: {
		cost: number;
		parts: {
			name: string;
			displayName?: string;
			cost: number;
			operation: OperationType;
		}[];
	};
	replacementParts: {
		cost: number;
	};
	partIdentifier: string;
	partSupplier: string;
	partCategory: PartCategory;
	partType: PartType;
	partOptions: string[];
	partSelection: string;
	operation: OperationType;
	id: string;
}

export interface PartWithAux extends EstimatePart {
	auxParts?: AuxPart[];
}

export const calculateDetailedSection = (part: PartWithAux, info: EstimateInfo): DetailedPartSection => {
	const stripRefit = {
		rate: info.stripRefitLaborRate,
		hours: part.stripRefitLaborHours,
		cost: info.stripRefitLaborRate * (part.stripRefitLaborHours ?? 0),
	};
	const panelRepair = {
		rate: info.repairLaborRate,
		hours: part.repairLaborHours,
		cost: info.repairLaborRate * (part.repairLaborHours ?? 0),
	};
	const paintRepair = {
		rate: info.paintLaborRate,
		hours: part.paintLaborHours,
		cost: info.paintLaborRate * (part.paintLaborHours ?? 0),
	};

	const auxPartsBreakdown = (part.auxParts ?? []).map((auxPart) => {
		return {
			name: auxPart.name,
			displayName: auxPart.customerDisplayName,
			operation: auxPart.operation as OperationType,
			cost:
				info.stripRefitLaborRate * (auxPart.stripRefitLaborHours ?? 0) +
				info.repairLaborRate * (auxPart.repairLaborHours ?? 0) +
				info.paintLaborRate * (auxPart.paintLaborHours ?? 0) +
				(auxPart.paintMaterialCost ?? 0) +
				(auxPart.replacementPartCost ?? 0),
		};
	});

	const auxCost = auxPartsBreakdown.reduce((acc, auxPart) => acc + auxPart.cost, 0);

	const cost =
		panelRepair.cost +
		stripRefit.cost +
		paintRepair.cost +
		auxCost +
		(part.paintMaterialCost ?? 0) +
		(part.replacementPartCost ?? 0);

	return {
		description: part.customerDisplayName,
		general: {
			cost,
		},
		stripRefit,
		panelRepair,
		paintRepair,
		paintMaterial: {
			cost: part.paintMaterialCost,
		},
		replacementParts: {
			cost: part.replacementPartCost,
		},
		auxParts: {
			parts: auxPartsBreakdown,
			cost: auxCost,
		},
		belongsTo: part.belongsTo ?? part.name,
		partIdentifier: part.displayPartIdentifier,
		partSupplier: part.partSupplier,
		partCategory: part.partCategory as PartCategory,
		partType: part.partType as PartType,
		partOptions: part.partOptions,
		partSelection: part.partSelection,
		id: part.name,
		operation: part.operation as OperationType,
	};
};

export function calculatePaintSection(paint: Estimate['paint'], info: EstimateInfo) {
	const additionalLaborHours = paint.additionalLaborHours * info.paintLaborRate;
	const additionalPaintMaterialCost = paint.additionalMaterialCost;

	return {
		total: {
			description: 'Additional paint operations',
			cost: additionalLaborHours + additionalPaintMaterialCost,
			id: 'additional-paint-operations',
		},
		paintLabor: {
			description: 'Additional paint labour',
			hours: paint.additionalLaborHours,
			cost: additionalLaborHours,
		},
		paintMaterial: {
			description: 'Additional paint material',
			cost: additionalPaintMaterialCost,
		},
	};
}
