"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sumLaborAndFixedOrMaterialCost = exports.calculateOperations = void 0;
const filters_1 = require("../filters");
/**
 * Calculates the Operations totals for non-migrated estimates. Legacy - EST-13315 remove when additional hours/costs have been migrated
 * @param estimate: estimate to sum up
 * @param defaultRate - additional hours' rate is optional, so we need a default rate to use if its missing. Can be removed once migrated over to new operations.
 * @returns all totals related to operations
 */
function calculateLegacyOperations(estimate, defaultRate) {
    const additionalCosts = estimate.additionalCosts ?? [];
    const additionalHours = estimate.additionalHours ?? [];
    // Add up all additional costs
    const additionalCostsTotal = additionalCosts.reduce((acc, { cost }) => acc + cost, 0);
    // Add up all additional hours costs
    const additionalHoursCostTotal = additionalHours.reduce((acc, { hours, rate }) => acc + hours * (rate ?? defaultRate), 0);
    return {
        additionalCostsTotal,
        additionalHoursCostTotal,
        operationsTotal: additionalCostsTotal + additionalHoursCostTotal,
    };
}
/**
 * Calculates totals associated with operations: broken down into hours (custom operations), costs (custom operations),
 * @param estimate
 * @param defaultRate - Legacy (EST-13315) old additional hours had rate as optional, so we need a default rate. Can be removed once migrated over to new operations.
 * @returns all totals related to operations
 */
function calculateOperations(estimate, defaultRate) {
    /* No operations or costs or hours */
    if (!estimate?.operations?.length && !estimate?.additionalCosts?.length && !estimate?.additionalHours?.length) {
        return {
            additionalCostsTotal: 0,
            additionalHoursCostTotal: 0,
            operationsTotal: 0,
        };
    }
    /* Legacy Code Path - no operations but additional hours and additional costs */
    //TODO: EST-13315 remove when migration away from additional hours/costs is done
    if (!estimate.operations?.length) {
        return calculateLegacyOperations(estimate, defaultRate);
    }
    /* Operations exists (assume that it contains everything additional hours/costs contains) */
    // Only pure "cost" operations
    const operations = estimate.operations ?? [];
    const additionalCostsTotal = (0, filters_1.filterAdditionalCostOperations)(operations).reduce(addFixedOrMaterialCost, 0);
    // Includes just hours
    const additionalHoursCostTotal = (0, filters_1.filterAdditionalHoursOperations)(operations).reduce(addLaborCost, 0);
    // Includes known and hours+cost operations
    const operationsTotal = operations.reduce(reduceLaborAndFixedOrMaterialCost, 0);
    return {
        additionalCostsTotal,
        additionalHoursCostTotal,
        operationsTotal,
    };
}
exports.calculateOperations = calculateOperations;
/**
 * Reducer function (accumulator) that adds up the "cost" part of the operation
 * @param acc - the accumulated value
 * @param operation - the estimate operation to add to the accumulated value
 * @returns the sum of the previous value and the new operation
 */
function addFixedOrMaterialCost(acc, { cost, quantity = 0 }) {
    const sum = cost ?? 0;
    return acc + sum * quantity;
}
/**
 * Reducer function (accumulator) that adds up the "labor" part of the operation.
 * @param acc - the accumulated value
 * @param operation - the estimate operation to add to the accumulated value
 * @returns the sum of the previous value and the new operation
 */
function addLaborCost(acc, { labor, quantity = 0 }) {
    const hours = labor?.hours ?? 0;
    const rate = labor?.rate ?? 0;
    return acc + hours * rate * quantity;
}
/**
 * Function that sums up the "labor" and cost part of the operation
 * @param operation - the estimate operation to be totalled
 * @returns sum of the operations costs
 */
function sumLaborAndFixedOrMaterialCost({ cost, labor, quantity = 0 }) {
    let sum = 0;
    if (labor?.hours && labor?.rate) {
        sum += labor.hours * labor.rate;
    }
    if (cost) {
        sum += cost;
    }
    return sum * quantity;
}
exports.sumLaborAndFixedOrMaterialCost = sumLaborAndFixedOrMaterialCost;
/**
 * Reducer function (accumulator) that adds up the "labor" and cost part of operations
 * @param acc - the accumulated value
 * @param operation - the estimate operation to add to the accumulated value
 * @returns the sum of the previous value and the new operation
 */
function reduceLaborAndFixedOrMaterialCost(acc, op) {
    return acc + sumLaborAndFixedOrMaterialCost(op);
}
