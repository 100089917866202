import { Panel, PartCategory } from '@tractable/estimating-local-constants';

import { OperationType } from '../../shared/operationToFill';
import { PartWithAux } from './calculate-detailed-section';

const operationOrder = ['REPAIR_PAINT', 'BLEND', 'REPAIR', 'REPLACE_AUXILIARY', 'REPLACE'];

/**
 * Return a hash of Tractable panel to { operation } with precedence based on (1) part category and (2) operation
 * @param parts list of parts in estimate
 */
export function extractPanelOperations(parts: PartWithAux[]) {
	// const panels = new Set(parts.map(part => part.belongsTo ?? part.name))
	const highestPanels: { [k in string]: PartWithAux } = {};
	for (let i = 0; i < parts.length; i += 1) {
		const part = parts[i];
		const panel = part.belongsTo ?? part.name;

		if (isHigherOrder(part, highestPanels[panel])) {
			highestPanels[panel] = part;
		}
	}

	const panels = Object.keys(highestPanels);
	const panelOperations: { [k in Panel]?: { operation: OperationType } } = {};
	for (let i = 0; i < panels.length; i += 1) {
		const panel = panels[i] as Panel;
		panelOperations[panel] = {
			operation: highestPanels[panel].operation as OperationType,
		};
	}

	return panelOperations;
}

export function isHigherOrder(candidate: PartWithAux, base: PartWithAux) {
	if (!candidate) {
		return false;
	}

	if (!operationOrder.includes(candidate.operation)) {
		return false;
	}
	if (!base) {
		return true;
	}

	if (base.partCategory !== candidate.partCategory) {
		return base.partCategory !== PartCategory.MAIN;
	}

	// TODO: type this better?

	const baseOperation = operationOrder.indexOf(base.operation);
	const candidateOperation = operationOrder.indexOf(candidate.operation);

	return candidateOperation >= baseOperation;
}
