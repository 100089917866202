import * as React from 'react';

import { Text } from '../Text';
import { CurrencyField } from './CurrencyField';
import { EditableField } from './EditableField';
import { TData, TRow } from './Table';
import { Currency, formatCurrencyWithoutSymbol } from './formatCurrency';
import { Decimal, formatDecimal } from './formatDecimal';

export interface SectionRowProps {
	isEditable?: boolean;
	description: string;
	cost?: number;
	hours?: number;
	updateField?: (field: string) => (value: string) => void;
	isCostEditable?: boolean;
	dataTestId?: string;
	currency: Currency;
	decimal?: Decimal;
	hideCost?: boolean;
}

export const SectionRow: React.FC<SectionRowProps> = ({
	isEditable = false,
	updateField,
	description,
	cost,
	isCostEditable = false,
	hours,
	dataTestId,
	currency,
	decimal,
	hideCost,
}) => {
	return (
		<TRow bgc="#f5f5f5" data-test-id={dataTestId}>
			<TData />
			<TData colSpan={4}>
				<Text typography="standard" color="#0B1F2C" p="0 0 0 16px">
					{description}
				</Text>
			</TData>
			<TData align="right">
				{hours !== null && hours !== undefined && isEditable ? (
					<EditableField
						value={formatDecimal(hours, decimal)}
						localeSymbol={currency.symbol}
						updateField={updateField && updateField('hours')}
					/>
				) : (
					<Text typography="standard" color="#0B1F2C">
						{hours && formatDecimal(hours, decimal)}
					</Text>
				)}
			</TData>
			<TData align="right">
				{!hideCost &&
					(cost !== null && cost !== undefined && isCostEditable && isEditable ? (
						<CurrencyField
							value={formatCurrencyWithoutSymbol(cost, currency)}
							localeSymbol={currency.symbol}
							updateField={updateField && updateField('cost')}
						/>
					) : (
						<Text typography="standard" color="#0B1F2C">
							{cost && formatCurrencyWithoutSymbol(cost, currency)}
						</Text>
					))}
			</TData>
			<TData />
		</TRow>
	);
};
