import * as React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';
import { Currency, formatCurrency } from './Currency';

export interface MaxSpendProps {
	readonly maxSpend: number;
	readonly currency?: Currency;
}

export const MaxSpend: React.FC<MaxSpendProps> = ({ maxSpend, currency }) => {
	return (
		<Box>
			<Text typography="micro" color="#12191F">
				{formatCurrency(maxSpend, currency)}
			</Text>
		</Box>
	);
};
