export enum FieldType {
	NOTIFIED_USERS = 'NOTIFIED_USERS',
	CLIENT_CLAIM_ID = 'CLIENT_CLAIM_ID',
	POLICYHOLDER_NAME = 'POLICYHOLDER_NAME',
	POLICY_MAX_SPEND = 'POLICY_MAX_SPEND',
	SHINKA_AMOUNT = 'SHINKA_AMOUNT',
	COMPANY_NAME = 'COMPANY_NAME',
	DATE_OF_ACCIDENT = 'DATE_OF_ACCIDENT',
	DATE_OF_INSPECTION = 'DATE_OF_INSPECTION',
	DATE_OF_ACCIDENT_NO_PICKER = 'DATE_OF_ACCIDENT_NO_PICKER',
	DATE_OF_FIRST_REGISTRATION = 'DATE_OF_FIRST_REGISTRATION',
	REGISTRATION = 'REGISTRATION',
	REGISTRATION_NO_LENGTH_LIMIT = 'REGISTRATION_NO_LENGTH_LIMIT',
	REGISTRATION_YEAR_MONTH = 'REGISTRATION_YEAR_MONTH',
	MODEL_SUBTYPE_CODE = 'MODEL_SUBTYPE_CODE',
	MODEL_SUBTYPE_CODE_LONG = 'MODEL_SUBTYPE_CODE_LONG',
	VIN = 'VIN',
	MAKE = 'MAKE',
	MODEL = 'MODEL',
	YEAR = 'YEAR',
	BRAND = 'BRAND',
	MILEAGE = 'MILEAGE',
	CIRCUMSTANCES = 'CIRCUMSTANCES',
	CLAIM_POLICY = 'CLAIM_POLICY',
	CLAIM_REGION = 'CLAIM_REGION',
	LABOUR_RATE_FOR_CASH_SETTLEMENT = 'LABOUR_RATE_FOR_CASH_SETTLEMENT',
	LABOUR_RATE_FOR_ADDITIONAL_ESTIMATE = 'LABOUR_RATE_FOR_ADDITIONAL_ESTIMATE',
	PH_PHONE = 'PH_PHONE',
}
