import { NotificationChannel, UserRole } from '@tractable/estimating-local-constants';
import { Locale } from '@tractable/estimating-local-localization';

import { PortalClientConfig } from '../../config/schema';
import { SelectorType } from './selectorType';

export interface DynamicCreateClaimField {
	readonly field: string;
	readonly estimateType: string;
}
export interface PolicyConfig {
	readonly policy: string;
	readonly totalLossEstimate?: string;
	readonly dynamicCreateClaimFields?: DynamicCreateClaimField[];
}

export interface PublicClientConfig {
	authentication: {
		magicLinkEnabled: boolean;
		cognitoEnabled: boolean;
	};
	environment: string;
	language: string;
	languageLoadPath: string;
	locizeProjectId: string;
	aws: {
		cognito: PortalClientConfig['client']['aws']['cognito'];
	};
}

export const ColumnMap = {
	claimId: 'Claim ID',
	registration: 'Registration',
	vehicle: 'Vehicle',
	createdBy: 'Created By',
	requested: 'Requested',
	status: 'Status',
	bodyShop: 'Body Shop',
	carOwnerFirstName: 'First name',
	carOwnerLastName: 'Last name',
	carOwnerPhone: 'Phone',
	carOwnerEmail: 'Email',
};

export const Columns: (keyof typeof ColumnMap)[] = [
	'claimId',
	'registration',
	'vehicle',
	'createdBy',
	'requested',
	'status',
];

type BaseUI = Omit<PortalClientConfig['client']['ui'], 'selectors'>;
export interface UI extends BaseUI {
	selectors: SelectorType[];
	tableColumns?: (keyof typeof ColumnMap)[];
}

export interface NotificationSetting {
	role: UserRole;
	type: NotificationChannel;
}

export interface Notifications {
	enabled: boolean;
	settings?: NotificationSetting[];
}

export type PrivateClientConfig = {
	readonly amplitudeApiKey: string;
	readonly clientId: string;
	readonly locizeProjectId: string;
	readonly locale: Locale;
	readonly ui: UI;
	readonly notifications: Notifications;
	readonly enablePayeeInfo?: boolean;
} & Pick<PortalClientConfig['client'], 'brands' | 'policies' | 'language'> &
	Pick<PortalClientConfig, 'defaultPdfType' | 'featureFlags'>;
