import * as Types from '../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendAppLinkSmsMutationVariables = Types.Exact<{
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
  recipient?: Types.InputMaybe<Types.Scalars['String']>;
  messageBody?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SendAppLinkSmsMutation = { __typename?: 'Mutation', sendNotification?: { __typename?: 'ClaimNotifications', id?: string | null, notifications?: Array<{ __typename?: 'Notification', status?: string | null } | null> | null } | null };


export const SendAppLinkSmsDocument = gql`
    mutation sendAppLinkSMS($claimId: String, $recipient: String, $messageBody: String) {
  sendNotification(
    claimId: $claimId
    type: "phAppLinkReady"
    channel: "SMS"
    recipient: $recipient
    messageBody: $messageBody
  ) {
    id
    notifications {
      status
    }
  }
}
    `;
export type SendAppLinkSmsMutationFn = Apollo.MutationFunction<SendAppLinkSmsMutation, SendAppLinkSmsMutationVariables>;

/**
 * __useSendAppLinkSmsMutation__
 *
 * To run a mutation, you first call `useSendAppLinkSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAppLinkSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAppLinkSmsMutation, { data, loading, error }] = useSendAppLinkSmsMutation({
 *   variables: {
 *      claimId: // value for 'claimId'
 *      recipient: // value for 'recipient'
 *      messageBody: // value for 'messageBody'
 *   },
 * });
 */
export function useSendAppLinkSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendAppLinkSmsMutation, SendAppLinkSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAppLinkSmsMutation, SendAppLinkSmsMutationVariables>(SendAppLinkSmsDocument, options);
      }
export type SendAppLinkSmsMutationHookResult = ReturnType<typeof useSendAppLinkSmsMutation>;
export type SendAppLinkSmsMutationResult = Apollo.MutationResult<SendAppLinkSmsMutation>;
export type SendAppLinkSmsMutationOptions = Apollo.BaseMutationOptions<SendAppLinkSmsMutation, SendAppLinkSmsMutationVariables>;