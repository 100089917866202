"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationChannel = void 0;
/**
 * Notification channels that are used to contact Estimating Users
 * (Policy Holders)
 * TODO: migrate email cc notifications
 * https://tractable.atlassian.net/browse/EST-10089
 */
var NotificationChannel;
(function (NotificationChannel) {
    NotificationChannel["SMS"] = "SMS";
    NotificationChannel["EMAIL"] = "Email";
})(NotificationChannel = exports.NotificationChannel || (exports.NotificationChannel = {}));
