import * as Types from '../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetQuattroruoteLinkQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetQuattroruoteLinkQuery = { __typename?: 'Query', quattroruoteLink?: string | null };


export const GetQuattroruoteLinkDocument = gql`
    query getQuattroruoteLink($id: String) {
  quattroruoteLink(claimId: $id)
}
    `;

/**
 * __useGetQuattroruoteLinkQuery__
 *
 * To run a query within a React component, call `useGetQuattroruoteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuattroruoteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuattroruoteLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuattroruoteLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetQuattroruoteLinkQuery, GetQuattroruoteLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuattroruoteLinkQuery, GetQuattroruoteLinkQueryVariables>(GetQuattroruoteLinkDocument, options);
      }
export function useGetQuattroruoteLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuattroruoteLinkQuery, GetQuattroruoteLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuattroruoteLinkQuery, GetQuattroruoteLinkQueryVariables>(GetQuattroruoteLinkDocument, options);
        }
export type GetQuattroruoteLinkQueryHookResult = ReturnType<typeof useGetQuattroruoteLinkQuery>;
export type GetQuattroruoteLinkLazyQueryHookResult = ReturnType<typeof useGetQuattroruoteLinkLazyQuery>;
export type GetQuattroruoteLinkQueryResult = Apollo.QueryResult<GetQuattroruoteLinkQuery, GetQuattroruoteLinkQueryVariables>;