import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    color: #0b1f2c;
  }

  p {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3 {
    margin: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export { GlobalStyle };
