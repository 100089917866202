import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../../Box';

export const IndicatorsWrapper: React.FC = ({ children }) => {
	return (
		<BoxWithSeparators orientation="horizontal" gap={8}>
			{children}
		</BoxWithSeparators>
	);
};

// Adds dots between all elements after the first
const BoxWithSeparators = styled(Box)`
	> *:not(:nth-child(1)):before {
		content: '·';
		margin: 0 10px;
	}
`;
