import styled from 'styled-components';

const Wrapper = styled.div`
	.main {
		padding: 0 50px;
		margin: 10px 0;
		position: relative;
	}
`;

export { Wrapper };
