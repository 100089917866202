import * as React from 'react';

import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { CurrencyInput } from './Shared';

const ShinkaAmount: React.FC = () => {
	const { config } = useClientConfigCtx();

	return (
		<CurrencyInput
			suffix={config.ui.shinkaAmountSuffix}
			name="shinkaAmount"
			validation={{
				required: false,
			}}
			label={{
				translationKey: 'Shinka',
			}}
		/>
	);
};

export { ShinkaAmount };
