import * as React from 'react';

import { Panel as PanelProps } from '@tractable/estimating-local-constants';

import { OperationType } from '../../operationToFill';
import { usePanel } from './usePanel';

interface Props {
	readonly panel: PanelProps;
	readonly operation: OperationType;
}

export const Panel: React.FC<Props> = ({ panel, operation }) => {
	const { SVG, fill } = usePanel(panel, operation);
	return <SVG fill={fill} />;
};
