import styled from 'styled-components';
import {
	ColorProps,
	FontSizeProps,
	LayoutProps,
	SpaceProps,
	TypographyProps,
	WidthProps,
	color,
	fontSize,
	layout,
	space,
	typography,
	width,
} from 'styled-system';

type TableProps = SpaceProps & LayoutProps & FontSizeProps & ColorProps & TypographyProps;

const Table = styled.table<TableProps>`
	${space}
	${layout}
  ${fontSize}
  ${color}
  ${typography}
`;

type TableHeaderProps = SpaceProps & WidthProps & FontSizeProps & ColorProps;

const TableHeader = styled.tr<TableHeaderProps>`
	& > th {
		text-align: start;
	}
	${space}
	${width}
  ${fontSize}
  ${color}
`;

type TableRowProps = SpaceProps & WidthProps & FontSizeProps & ColorProps;

const TableRow = styled.tr<TableRowProps>`
	${space}
	${width}
  ${fontSize}
  ${color}
`;

Table.defaultProps = {
	fontSize: 2,
	lineHeight: 'regular',
};

export { Table, TableProps, TableHeader, TableRow };
