const requestErrorHandler = ({ graphQLErrors, networkError }: any) => {
	const errors = [...(graphQLErrors || []), ...(networkError?.result.errors || [])];

	return errors.reduce((acc: any, error: Error) => {
		switch (true) {
			case /CLAIM_ALREADY_EXISTS/.test(error.message):
				acc.clientClaimId = 'Claim with provided ID already exists';
				return acc;
			case /FAILED_VEHICLE_DECODING/.test(error.message):
				acc.registration = 'Could not find vehicle';
				acc.vin = 'Could not find vehicle';
				return acc;
			case /INVALID_REQUEST/.test(error.message):
				acc.invalidRequest = 'Please double check what you submitted';
				return acc;
			case /MODEL_SUBTYPE_CODE_NOT_VALID/.test(error.message):
				acc.modelSubtypeCodeLong = 'No matching frame codes found';
				return acc;
			case /AUTHENTICATION/.test(error.message):
				acc.invalidRequest = 'Authentication Error';
				return acc;
			case /OUT_OF_HOURS/.test(error.message):
				acc.ooh = 'Unable to create a claim outside service hours';
				return acc;
			case /UNEXPECTED_SERVER_ERROR/.test(error.message):
				acc.unexpectedServerError = 'Something went wrong while creating the claim';
				return acc;
			default:
				return acc;
		}
	}, {});
};

export { requestErrorHandler };
