import * as React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';
import { EditableField } from './EditableField';
import { TData, TRow } from './Table';
import * as remove from './delete.png';
import { Currency, formatCurrencyWithoutSymbol } from './formatCurrency';

export interface SectionRowProps {
	isEditable?: boolean;
	description: string;
	cost: number;
	updateField?: (field: string) => (value: string) => void;
	removeSection?: () => void;
	currency: Currency;
	dataTestId?: string;
}

export const GeneralCost: React.FC<SectionRowProps> = ({
	isEditable = false,
	updateField,
	removeSection,
	description,
	cost,
	currency,
	dataTestId,
}) => {
	return (
		<TRow>
			<TData />
			<TData colSpan={4} align="left">
				{isEditable ? (
					<EditableField
						value={description}
						textAlign="left"
						updateField={updateField && updateField('descriptor')}
						freetext
						dataTestId={`${dataTestId}-input`}
					/>
				) : (
					<Text typography="standard" color="#0B1F2C" data-test-id={`${dataTestId}-text`}>
						{description}
					</Text>
				)}
			</TData>
			<TData />
			<TData align="right">
				{isEditable ? (
					<EditableField
						value={cost.toFixed(2)}
						updateField={updateField && updateField('cost')}
						localeSymbol={currency.symbol}
						dataTestId={`${dataTestId}-cost-input`}
					/>
				) : (
					<Text typography="standard" color="#0B1F2C" data-test-id={`${dataTestId}-cost-text`}>
						{cost && formatCurrencyWithoutSymbol(cost, currency)}
					</Text>
				)}
			</TData>
			<TData>
				{removeSection && (
					<Box
						alignItems="center"
						justifyContent="center"
						onClick={() => removeSection()}
						data-test-id={`${dataTestId}-delete-button`}
						cursor="pointer"
					>
						<img width="16px" height="16px" src={remove} alt="" />
					</Box>
				)}
			</TData>
		</TRow>
	);
};
