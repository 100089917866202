import * as React from 'react';

function SvgWarningRound(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 13 12" fill="none" {...props}>
			<circle cx={6.911} cy={6} fill="#fff" r={5.625} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.888 2.023a5.625 5.625 0 10-7.955 7.954 5.625 5.625 0 007.955-7.954zM6.91 9.75a.937.937 0 100-1.875.937.937 0 000 1.875zm0-7.5a.938.938 0 00-.938.937V6a.938.938 0 001.875 0V3.187a.937.937 0 00-.937-.937z"
				fill="#2A4759"
			/>
		</svg>
	);
}

export default SvgWarningRound;
