import gql from 'graphql-tag';

const query = gql`
	query getPagination {
		pagination @client {
			limit
			offset
		}
	}
`;

const updatePagination = (_: any, { dir }: { dir: string }, { cache }: any): null => {
	const { pagination } = cache.readQuery({ query });
	const offset =
		// eslint-disable-next-line no-nested-ternary
		dir === 'reset'
			? 0
			: dir === 'next'
			? pagination.offset + pagination.limit
			: Math.max(0, pagination.offset - pagination.limit);
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const new_pagination = { ...pagination, offset };
	cache.writeQuery({ query, data: { pagination: new_pagination } });
	return null;
};

export { updatePagination };
