import gql from 'graphql-tag';

interface Args {
	values: string[];
}

const query = gql`
	query InitialData {
		search
	}
`;

const newTag = (value: string, index: number) => ({
	id: index,
	value,
	__typename: 'SearchTag',
});

const updateSearchTags = (_: any, { values }: Args, { cache }: any): null => {
	const data = {
		search: values.map(newTag),
	};

	cache.writeQuery({ query, data });

	return null;
};

export { updateSearchTags };
