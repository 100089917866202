"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = void 0;
var NotificationType;
(function (NotificationType) {
    NotificationType["READY_CLAIM"] = "readyClaim";
    NotificationType["ESTIMATE_APPROVED"] = "estimateApproved";
    NotificationType["PH_APP_COMPLETED"] = "phAppCompleted";
    NotificationType["PH_APP_LINK_READY"] = "phAppLinkReady";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
