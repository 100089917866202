"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstimateDecision = void 0;
var EstimateDecision;
(function (EstimateDecision) {
    EstimateDecision["NOT_YET_COMPLETE"] = "NOT_YET_COMPLETE";
    EstimateDecision["REJECTED_OOS"] = "REJECTED_OOS";
    EstimateDecision["REJECTED_VIN"] = "REJECTED_VIN";
    EstimateDecision["ACCEPTED"] = "ACCEPTED";
})(EstimateDecision = exports.EstimateDecision || (exports.EstimateDecision = {}));
