import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import * as React from 'react';

import { StyledButton } from './styles';

interface ButtonProps {
	children: React.ReactNode;
	hasMore: boolean;
	dir: string;
}

const UPDATE_PAGINATION = gql`
	mutation updatePagination($dir: String!) {
		updatePagination(dir: $dir) @client
	}
`;

const GET_PAGINATION = gql`
	query getPagination {
		pagination @client {
			limit
			offset
		}
	}
`;

const Button: React.FC<ButtonProps> = ({ dir, children, hasMore }) => {
	const [savePagination] = useMutation(UPDATE_PAGINATION);
	const { data, loading } = useQuery(GET_PAGINATION);

	if (loading) return null;

	const { pagination } = data;

	const disabled = (dir === 'previous' && pagination.offset === 0) || (dir === 'next' && !hasMore);

	return (
		<StyledButton disabled={disabled} onClick={() => savePagination({ variables: { dir } })}>
			{children}
		</StyledButton>
	);
};

export default Button;
