import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface DetailProps {
	label: string;
	value: any;
}

const StyledP = styled.p`
	display: grid;
	grid-template-columns: 40% auto;
	margin: 0.2rem 0;
	align-items: center;
	.label {
		font-weight: 600;
	}
`;

const Detail: React.FC<DetailProps> = ({ label, value }) => {
	const { t } = useTranslation('EstimatingPortal');

	return value ? (
		<StyledP>
			<span className="label">{t(label)}</span>
			{value}
		</StyledP>
	) : null;
};

export { Detail };
