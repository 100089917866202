import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../../Box';
import { TextSpan } from '../../TextSpan';

export enum colorEnum {
	red = 'red',
	grey = 'grey',
}
export interface ClaimStatusProps {
	readonly text: string;
	readonly color?: colorEnum;
}

const PillStyled = styled(Box)`
	justify-content: center;
	padding: 0 10px;
	border-radius: 16px;
	display: block;
`;

export const Pill: React.FC<ClaimStatusProps> = ({ text, color = colorEnum.red }) => {
	const pillbg = color === colorEnum.red ? '#FFF1EE' : '#D7E3EB';
	const pillcolor = color === colorEnum.red ? '#BF332E' : '#0B1F2C';

	return (
		<Box orientation="vertical" justifyContent="center">
			<PillStyled orientation="vertical" background={pillbg}>
				<TextSpan typography="small" data-test-id="claim-status-granular-reason" color={pillcolor}>
					{text}
				</TextSpan>
			</PillStyled>
		</Box>
	);
};
