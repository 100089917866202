import * as Types from '../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetValidFlagsQueryVariables = Types.Exact<{
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetValidFlagsQuery = { __typename?: 'Query', getValidFlags?: Array<{ __typename?: 'ValidFlag', id?: string | null, object?: string | null, subject?: string | null, multiplier?: number | null, type?: string | null, overwrite?: string | null } | null> | null };


export const GetValidFlagsDocument = gql`
    query getValidFlags($claimId: String) {
  getValidFlags(claimId: $claimId) {
    id
    object
    subject
    multiplier
    type
    overwrite
  }
}
    `;

/**
 * __useGetValidFlagsQuery__
 *
 * To run a query within a React component, call `useGetValidFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidFlagsQuery({
 *   variables: {
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useGetValidFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetValidFlagsQuery, GetValidFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValidFlagsQuery, GetValidFlagsQueryVariables>(GetValidFlagsDocument, options);
      }
export function useGetValidFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValidFlagsQuery, GetValidFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValidFlagsQuery, GetValidFlagsQueryVariables>(GetValidFlagsDocument, options);
        }
export type GetValidFlagsQueryHookResult = ReturnType<typeof useGetValidFlagsQuery>;
export type GetValidFlagsLazyQueryHookResult = ReturnType<typeof useGetValidFlagsLazyQuery>;
export type GetValidFlagsQueryResult = Apollo.QueryResult<GetValidFlagsQuery, GetValidFlagsQueryVariables>;