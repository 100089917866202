import * as React from 'react';
import { useEffect, useState } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { components } from 'react-select';
import styled from 'styled-components';

import { Box } from '../../Box';
import { ErrorNotification } from '../../ErrorNotification';
import { Text } from '../../Text';
import { OperationType } from '../../operationToFill';
import { Checkbox } from './Checkbox';
import Spinner from './Spinner';
import { Part, calculateAvailableOptions } from './availableOptions';

const { LoadingIndicator: BaseLoadingIndicator } = components;

interface Props {
	readonly title: string;
	readonly partTitle: string;
	readonly operationTitle: string;
	readonly submitTitle: string;
	readonly optionsTitle: string;
	readonly noPartsFoundText: string;
	readonly loadingPartsText: string;
	readonly operations: {
		title: string;
		type: OperationType;
	}[];
	readonly isLoadingParts: boolean;
	readonly isLoadingAddPart: boolean;
	readonly errorFetchingParts?: string;
	readonly errorAddingPart?: string;
	readonly parts: Part[];
	readonly selectedParts: string[];
	readonly onSubmit: (o: SubmitOutput) => void;
}

export interface SubmitOutput {
	partId: string;
	optionIds: string[];
	operation: OperationType;
}

const Wrapper = styled.div`
	display: inline-flex;
	width: 340px;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
`;

const Label = styled.label`
	display: flex;
	gap: 8px;
`;

interface ButtonProps {
	selected: boolean;
}
const Button = styled.button<ButtonProps>`
	height: 32px;
	background: #ffffff;
	color: ${({ selected }) => (selected ? '#3B25C4' : '1px solid #484848')};
	border: ${({ selected }) => (selected ? '1px solid #3B25C4' : '1px solid #C2C2C2')};
	box-shadow: 0px 1px 0px rgba(11, 31, 44, 0.1);
	border-radius: 4px;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
`;

const SubmitButton = styled.button`
	color: white;
	background: #3b25c4;
	box-shadow: 0px 1px 0px rgba(11, 31, 44, 0.16);
	border-radius: 4px;
	border: none;
	height: 32px;
	padding: 0 16px;
	cursor: pointer;
	min-width: 100px;
	&:disabled {
		cursor: not-allowed;
		background: #c2c2c2;
	}
`;

interface OptionWrapperProps {
	isSelected: boolean;
}
const OptionWrapper = styled(Box)<OptionWrapperProps>`
	cursor: pointer;
	padding: 5px 10px;
	border-left: 3px solid ${({ isSelected }) => (isSelected ? '#3B25C4' : 'white')};
`;

const LoadingIndicator = (props: any) => (props.isFocused ? <BaseLoadingIndicator {...props} /> : null);

const errorNotice = (text: string | undefined) => (text ? <ErrorNotification text={text} /> : null);

const CustomOption = ({ innerProps, data }: any) => (
	<OptionWrapper {...innerProps} isSelected={data.isSelected}>
		<Text typography="small">{data.label}</Text>
	</OptionWrapper>
);

export const SelectMenu: React.FC<Props> = ({
	title,
	partTitle,
	operationTitle,
	submitTitle,
	noPartsFoundText,
	onSubmit,
	operations,
	isLoadingParts,
	isLoadingAddPart,
	loadingPartsText,
	errorFetchingParts,
	errorAddingPart,
	parts,
	selectedParts,
}) => {
	const [part, setPart] = useState<OptionTypeBase>();
	const [optionIds, setOptionIds] = useState<string[]>([]);
	const [operation, setOperation] = useState<OperationType | null>();

	const selectedPart = parts.find((p) => p.id === part?.value);

	const toggleOption = (id: string) => {
		optionIds.includes(id) ? setOptionIds(optionIds.filter((o) => o !== id)) : setOptionIds([...optionIds, id]);
	};

	useEffect(() => {
		setOptionIds([]);
	}, [part]);

	const availableOptions = selectedPart ? calculateAvailableOptions(optionIds, selectedPart.combinations) : [];

	return (
		<Wrapper>
			<Box height="48px" alignItems="center" p="0 24px" borderBottom="1px solid #D6D6D6;">
				<Text typography="standard">{title}</Text>
			</Box>
			<Box orientation="vertical" p="16px 24px" gap={16}>
				<Box orientation="vertical" gap={8}>
					<Text typography="small" bold color="#484848">
						{partTitle}
					</Text>
					<Select
						isLoading={isLoadingParts}
						isClearable={true}
						loadingMessage={() => loadingPartsText}
						components={{ LoadingIndicator, Option: CustomOption }}
						noOptionsMessage={() => noPartsFoundText}
						placeholder=""
						value={part}
						options={parts.map((p) => ({
							value: p.id,
							label: p.name,
							isSelected: selectedParts.includes(p.id),
						}))}
						onChange={setPart}
					/>
					{errorNotice(errorFetchingParts)}
				</Box>
				{selectedPart && (
					<Box orientation="vertical" gap={8}>
						{selectedPart.options.map((o) => {
							const isDisabled = !availableOptions.includes(o.id);
							return (
								<Label key={o.id}>
									<Checkbox
										checked={optionIds.includes(o.id)}
										onChange={() => !isDisabled && toggleOption(o.id)}
										disabled={isDisabled}
									/>
									<Text typography="small" color={isDisabled ? '#C1C1C1' : '#182026'}>
										{o.name}
									</Text>
								</Label>
							);
						})}
					</Box>
				)}
				<Box orientation="vertical" gap={8}>
					<Text typography="small" bold color="#484848">
						{operationTitle}
					</Text>
					{operations.map((o) => (
						<Button key={o.type} onClick={() => setOperation(o.type)} selected={operation === o.type}>
							<Text typography="small">{o.title}</Text>
						</Button>
					))}
				</Box>
				<Box>
					<SubmitButton
						disabled={!(part && operation) || isLoadingAddPart}
						onClick={() =>
							part &&
							operation &&
							onSubmit({
								partId: part.value,
								optionIds,
								operation,
							})
						}
					>
						{isLoadingAddPart ? (
							<Spinner width="20px" />
						) : (
							<Text typography="standard" bold>
								{submitTitle}
							</Text>
						)}
					</SubmitButton>
				</Box>
				{errorNotice(errorAddingPart)}
			</Box>
		</Wrapper>
	);
};
