import * as React from 'react';
import styled from 'styled-components';

import { Gallery } from '../../shared';
import { ImageDownloader } from '../../shared/ImageDownloader';
import { SimpleWidget } from '../../shared/Widget';
import { useImagesGallery } from './useImagesGallery';

interface ImagesGalleryProps {
	id: string;
	widget: any;
	setSelectedAsset: any;
}

const Image = styled.div`
	width: 100%;
	cursor: pointer;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: url('${(props: any) => props.url}');
`;

const ImagesGallery: React.FC<ImagesGalleryProps> = ({ id, widget, setSelectedAsset }) => {
	const { images } = useImagesGallery(id, setSelectedAsset);

	if (!images) return null;

	return (
		<SimpleWidget {...widget}>
			<Gallery columns={4} rows={Math.ceil(images.length / 4)}>
				{({ onMouseOut, onMouseOver }) =>
					images.map((imageProps, i) => <Image key={i} {...imageProps({ onMouseOut, onMouseOver })} />)
				}
			</Gallery>
			<ImageDownloader id={id} />
		</SimpleWidget>
	);
};

export { ImagesGallery };
