import styled from 'styled-components';

const Wrapper = styled.div`
	display: grid;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(6, auto);
	align-items: start;
	grid-template-areas:
		'tl tl tl tl r r r r r r r r'
		'f1 f1 f1 f1 r r r r r r r r'
		'f2 f2 f2 f2 r r r r r r r r'
		'f3 f3 f3 f3 r r r r r r r r'
		'f4 f4 f4 f4 r r r r r r r r'
		'f5 f5 f5 f5 r r r r r r r r'
		'f6 f6 f6 f6 r r r r r r r r';
`;

export { Wrapper };
