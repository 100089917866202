import { Icon } from '@blueprintjs/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '../../../shared';

interface ProcessedProps {
	isTL: boolean;
	needsInspection: boolean;
}

const Processed: React.FC<ProcessedProps> = ({ isTL, needsInspection }) => {
	const { t } = useTranslation('EstimatingPortal');
	const repairableLabel = needsInspection ? t('Repairable (Needs Inspection)') : t('Repairable');

	return isTL ? (
		<Box display="flex" alignItems="center" color="reds.0">
			<Icon icon="trash" iconSize={24} />
			<Text fontSize={4} fontWeight={5} m={2}>
				{t('Total Loss')}
			</Text>
		</Box>
	) : (
		<Box display="flex" alignItems="center" color="oranges.0">
			<Icon icon="wrench" iconSize={24} />
			<Text fontSize={4} fontWeight={5} m={2}>
				{repairableLabel}
			</Text>
		</Box>
	);
};

export { Processed };
