import * as React from 'react';

function SvgCarSteeringWheel(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 17 16" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.623 6.222A7.618 7.618 0 008.213.5C4.677.5 1.594 2.85.734 6.2a7.176 7.176 0 000 3.554c.678 2.722 2.846 4.85 5.629 5.528a7.628 7.628 0 003.624 0c2.78-.67 4.95-2.79 5.636-5.505a7.175 7.175 0 000-3.555zM8.206 7.977a.762.762 0 00-.771.753c0 .416.345.753.77.753a.762.762 0 00.772-.753.762.762 0 00-.771-.753zm5.713-2.26c-.942-2.273-3.202-3.762-5.713-3.764-2.512.001-4.772 1.49-5.714 3.765H13.92zM2.037 7.978c.003-.252.02-.503.054-.753H3.58V8.73H2.091a6.076 6.076 0 01-.054-.753zm.455 2.26a6.098 6.098 0 003.4 3.32v-1.815c0-.832-.69-1.506-1.542-1.506H2.492zm6.485 3.712c-.256.033-.514.05-.771.053a6.517 6.517 0 01-.771-.053v-1.454h1.542v1.454zm-1.65-2.96h1.758a3.049 3.049 0 012.205-2.153V7.224H5.122v1.612a3.049 3.049 0 012.205 2.153zm3.192 2.568v-1.815c0-.832.69-1.506 1.542-1.506h1.858a6.098 6.098 0 01-3.4 3.321zm2.313-4.827h1.488c.034-.25.051-.501.054-.753a6.082 6.082 0 00-.054-.753h-1.488V8.73z"
				fill="#45677E"
				opacity={0.6}
			/>
		</svg>
	);
}

export default SvgCarSteeringWheel;
