import * as React from 'react';

import { Wrapper } from './styles';

const SidebarItem: React.FC<any> = ({ title, children, ...otherProps }) => (
	<Wrapper>
		{title && <h2>{title}</h2>}
		<div>{children(otherProps)}</div>
	</Wrapper>
);

export { SidebarItem };
