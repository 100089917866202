import * as React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';
import { EditableField } from './EditableField';
import { TData, TRow } from './Table';
import * as remove from './delete.png';
import { Currency, formatCurrencyWithoutSymbol } from './formatCurrency';
import { Decimal, formatDecimal } from './formatDecimal';

export interface SectionRowProps {
	removeSection?: () => void;
	description: string;
	hours?: number;
	rate?: number;
	quantity?: number;
	cost?: number;
	total: number;
	currency: Currency;
	dataTestId?: string;
	decimal?: Decimal;
	isEditable?: boolean;
	isDescriptionEditable: boolean;
	updateField?: {
		displayDescription: (value: string) => void;
		cost: (value: string) => void;
		hours: (value: string) => void;
	};
	hideCost?: boolean;
}

export const OperationRow: React.FC<SectionRowProps> = ({
	removeSection,
	description,
	rate,
	quantity,
	total,
	hours,
	cost,
	currency,
	isDescriptionEditable,
	isEditable,
	updateField,
	dataTestId,
	decimal,
	hideCost,
}) => {
	const noFractions: Decimal = {
		...decimal,
		options: {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		},
	};

	return (
		<TRow>
			<TData />
			<TData colSpan={1} align="left">
				{isEditable && isDescriptionEditable ? (
					<EditableField
						value={description}
						textAlign="left"
						updateField={updateField && updateField.displayDescription}
						freetext
						dataTestId={`${dataTestId}-input`}
					/>
				) : (
					<Text typography="standard" color="#0B1F2C" data-test-id={`${dataTestId}-text`}>
						{description}
					</Text>
				)}
			</TData>
			<TData align="right">
				{!hideCost &&
					(cost != null && isEditable ? (
						<EditableField
							value={cost.toFixed(2)}
							updateField={updateField && updateField.cost}
							localeSymbol={currency.symbol}
							dataTestId={`${dataTestId}-cost-input`}
						/>
					) : (
						<Text typography="standard" color="#0B1F2C" data-test-id={`${dataTestId}-cost-text`}>
							{cost && formatCurrencyWithoutSymbol(cost, currency)}
						</Text>
					))}
			</TData>
			<TData align="right">
				{hours != null && isEditable ? (
					<EditableField
						value={formatDecimal(hours, decimal)}
						localeSymbol={currency.symbol}
						updateField={updateField && updateField.hours}
					/>
				) : (
					<Text typography="standard" color="#0B1F2C">
						{hours != null && formatDecimal(hours, decimal)}
					</Text>
				)}
			</TData>
			<TData align="right">
				<Text typography="standard" color="#0B1F2C">
					{rate && formatDecimal(rate, decimal)}
				</Text>
			</TData>
			<TData align="right">
				<Text typography="standard" color="#0B1F2C">
					{quantity ? formatDecimal(quantity, noFractions) : formatDecimal(1, noFractions)}
				</Text>
			</TData>
			<TData align="right">
				<Text typography="standard" color="#0B1F2C">
					{!hideCost && total && formatCurrencyWithoutSymbol(total, currency)}
				</Text>
			</TData>
			<TData>
				{removeSection && (
					<Box
						alignItems="center"
						justifyContent="center"
						onClick={() => removeSection()}
						data-test-id={`${dataTestId}-delete-button`}
						cursor="pointer"
					>
						<img width="16px" height="16px" src={remove} alt="" />
					</Box>
				)}
			</TData>
		</TRow>
	);
};
