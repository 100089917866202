import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { Box } from '../../../shared/Box';
import { Text } from '../../../shared/Text';
import { Spinner } from '../Spinner';
import * as icon from './noestimate.png';

interface NoEstimateProps {
	status: string;
}

const NoEstimate: React.FC<NoEstimateProps> = ({ status }) => {
	const { t } = useTranslation('EstimatingPortal');
	const {
		config: {
			ui: { showEstimate, showPOI },
		},
	} = useClientConfigCtx();
	return (
		<Box
			height="440px"
			justifyContent="center"
			alignItems="center"
			background="#F5F5F5"
			m="0 0 0 32px"
			orientation="vertical"
			gap={16}
		>
			{!status || status === 'IN_PROGRESS' ? (
				<>
					<Spinner width="50px" />
					<Text typography="large" bold color="#8F8F8F">
						{t('Generating estimate')}...
					</Text>
				</>
			) : (
				<>
					<img src={icon} alt="" />
					<Text typography="large" bold color="#8F8F8F">
						{t(!showEstimate && showPOI ? 'No vehicle diagram' : 'No Estimate')}
					</Text>
					<Text typography="small" color="#8F8F8F">
						{t(
							!showEstimate && showPOI
								? 'The vehicle diagram cannot be generated because the claim is out of scope.'
								: 'An estimate cannot be generated because the claim is out of scope.'
						)}
					</Text>
				</>
			)}
		</Box>
	);
};

export { NoEstimate };
