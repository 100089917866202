import styled from 'styled-components';
import {
	ColorProps,
	FlexboxProps,
	LayoutProps,
	OverflowProps,
	SpaceProps,
	TypographyProps,
	color,
	flexbox,
	layout,
	space,
	typography,
} from 'styled-system';

type BoxProps = SpaceProps & LayoutProps & ColorProps & FlexboxProps & TypographyProps & OverflowProps;

const Box: React.FC<BoxProps> = styled.div<BoxProps>`
	${space}
	${layout}
  ${color}
  ${flexbox}
  ${typography}
`;

export { Box, BoxProps };
