export interface Currency {
	readonly symbol: string;
	readonly options: {
		readonly currencyDisplay: string;
		readonly currency: string;
	};
}

export function formatCurrencyWithoutSymbol(num: number, currencyObject?: Currency): string {
	if (!currencyObject) return num.toFixed(2);

	const {
		symbol,
		options: { currency },
	} = currencyObject;

	const nf = new Intl.NumberFormat(symbol, {
		style: 'currency',
		currency,
	});

	const parts = nf.formatToParts(num);

	return parts
		.filter((p) => p.type !== 'currency')
		.reduce((acc, p) => `${acc}${p.value}`, '')
		.trim();
}
