import * as React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';
import { Currency } from './Currency';
import { MaxSpend } from './MaxSpend';
import { Notification } from './Notification';
import { PercentageDisplay } from './PercentageDisplay';
import { ProgressBar } from './ProgressBar';

export interface SpendDetailsProps {
	readonly maxSpend: number;
	readonly maxSpendText: string;
	readonly repairCost: number;
	readonly notifications: Notification[];
	readonly currency?: Currency;
}

interface NotificationSelector {
	readonly maxSpend: number;
	readonly repairCost: number;
	readonly notifications: Notification[];
}

function notificationSelector({ repairCost, maxSpend, notifications }: NotificationSelector): React.ReactNode {
	const ratio = repairCost / maxSpend;
	const notification = notifications.reverse().find((n) => ratio > n.threshold);
	if (notification) {
		return <Notification level={notification.level} text={notification.text} />;
	} else {
		return null;
	}
}

export const SpendDetails: React.FC<SpendDetailsProps> = ({
	maxSpend,
	maxSpendText,
	repairCost,
	notifications,
	currency,
}) => {
	return (
		<Box orientation="vertical" width="100%" gap={4}>
			<Box height="20px" alignItems="center" gap={8}>
				<Text typography="micro" color="#68727A">
					{maxSpendText}
				</Text>
				{notificationSelector({ repairCost, maxSpend, notifications })}
			</Box>
			<Box orientation="horizontal" justifyContent="space-between" alignItems="center" width="100%" gap={8}>
				<PercentageDisplay repairCost={repairCost} maxSpend={maxSpend} />
				<ProgressBar maxSpend={maxSpend} repairCost={repairCost} />
				<MaxSpend maxSpend={maxSpend} currency={currency} />
			</Box>
		</Box>
	);
};
