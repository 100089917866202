import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ColumnMap } from '../../../../../common';
import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { formatName } from '../../../shared/formatName';
import { StyledRow } from './styles';

interface RowProps {
	columns: (keyof typeof ColumnMap)[];
	createdAt: string;
	vehicle: {
		registration: string;
		name: string;
	};
	claimDetails: {
		user: {
			givenName: string;
			familyName: string;
		};
		claimId: string;
		nextStageUrl: string;
		stage: {
			name: string;
			title: string;
		};
	};
	formatDate: (s: string) => string;
	carOwner?: Partial<{
		firstName: string;
		lastName: string;
		email: string;
		phoneNumber: string;
	}>;
	bodyShop?: Partial<{
		reference: string;
		name: string;
		address: Partial<{
			street: string;
			city: string;
			province: string;
			postalCode: string;
			country: string;
		}>;
	}>;
}

const Row: React.FC<RowProps> = ({
	columns,
	createdAt,
	vehicle: { registration, name },
	claimDetails: { user, claimId, stage, nextStageUrl },
	carOwner,
	bodyShop,
	formatDate,
}) => {
	const { t } = useTranslation('EstimatingPortal');
	const navigate = useNavigate();

	const {
		firstName: coFirstName = '',
		lastName: coLastName = '',
		email: coEmail = '',
		phoneNumber: coPhoneNum = '',
	} = !carOwner ? {} : carOwner;

	const { name: bodyShopName = '' } = bodyShop ?? {};

	const { config } = useClientConfigCtx();

	return (
		<StyledRow id="requestsRow" onClick={() => navigate(nextStageUrl)}>
			<td />
			{columns.map((columnId) => {
				switch (columnId) {
					case 'claimId':
						return <td key={columnId}>{claimId}</td>;
					case 'registration':
						return <td key={columnId}>{registration}</td>;
					case 'vehicle':
						return <td key={columnId}>{name}</td>;
					case 'createdBy':
						return (
							<td key={columnId}>
								{user
									? formatName(config.locale.nameFormat, {
											firstName: user.givenName,
											lastName: user.familyName,
									  })
									: ''}
							</td>
						);
					case 'requested':
						return <td key={columnId}>{formatDate(createdAt)}</td>;
					case 'status':
						return <td key={columnId}>{t(stage.title)}</td>;
					case 'bodyShop':
						return <td key={columnId}>{bodyShopName}</td>;
					case 'carOwnerFirstName':
						return <td key={columnId}>{coFirstName}</td>;
					case 'carOwnerLastName':
						return <td key={columnId}>{coLastName}</td>;
					case 'carOwnerEmail':
						return <td key={columnId}>{coEmail}</td>;
					case 'carOwnerPhone':
						return <td key={columnId}>{coPhoneNum}</td>;
					default:
						console.warn(`Non-implemented column type: ${columnId}`);
						return null;
				}
			})}
			<td />
		</StyledRow>
	);
};

export { Row };
