import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const minLength = 2;
const maxLength = 8;

const required = (field: string, t: any) => (value: any) => {
	if (!value) {
		return t('{{field}} is required', { field });
	}

	if (value.length > maxLength || value.length < minLength) {
		return t('Should be between {{minLength}} and {{maxLength}} characters long', { minLength, maxLength });
	}

	if (!/^[a-zA-Z0-9]*$/.test(value)) {
		return t(`Should be alphanumeric (A-Z or 0-9) characters only`);
	}

	return undefined;
};

const normalize = (value: any) => value.replace(/[\W]+/g, '').toUpperCase();

const Registration: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="registration" validate={required(t('Registration number'), t)} parse={normalize}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Registration number')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder={t('RegistrationExample')} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { Registration, minLength, maxLength };
