"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityState = void 0;
var ActivityState;
(function (ActivityState) {
    ActivityState["ACTIVE"] = "ACTIVE";
    ActivityState["ARCHIVED"] = "ARCHIVED";
    ActivityState["ANONYMIZED"] = "ANONYMIZED";
    ActivityState["DELETED"] = "DELETED";
})(ActivityState = exports.ActivityState || (exports.ActivityState = {}));
