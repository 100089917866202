import { DefaultTheme, Interpolation, ThemeProps, ThemedStyledProps } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-types
export type VariantHelper<F extends Function> = F extends (arg1: infer U) => unknown ? Omit<U, 'theme'> : never;

type VariantHelperReturn<P, D extends DefaultTheme> = (props: P) => Interpolation<ThemeProps<D>>[];

type ObjectKeysAsStringUnion<T> = {
	[P in keyof T]: NarrowString<keyof T[P]>;
};

type NarrowString<V> = V extends 'true' | 'false' ? boolean : AssureIndexType<V>;

type AssureIndexType<T> = T extends number | string ? T : never;

// TODO: Would be nice to export this one, but so far it only works when using the extend keyword
// When exporting and using VariantHelperOptions we are loosing some typings and opening up a gap
// in our types. Especially when it comes to working with ReactText that needs strings as keys.
interface VariantHelperStylingObject<D extends DefaultTheme> {
	[k: string]: {
		[k: string]: Interpolation<ThemeProps<D>>;
	};
}

export function variantHelper<
	T extends VariantHelperStylingObject<D>,
	D extends DefaultTheme = DefaultTheme,
	P = ThemedStyledProps<ObjectKeysAsStringUnion<T>, D>
>(stylingObject: T): VariantHelperReturn<P, D> {
	const options = Object.keys(stylingObject);

	return (props: P) => {
		const interpolations: Interpolation<ThemeProps<D>>[] = [];

		options.forEach((option) => {
			const variant = (
				props as unknown as {
					[k: string]: string | boolean | number;
				}
			)[option];

			let interpolation: Interpolation<ThemeProps<D>>;
			if (typeof variant === 'boolean') {
				interpolation = stylingObject[option][variant ? 'true' : 'false'];
			} else {
				interpolation = stylingObject[option][variant];
			}

			interpolations.push(interpolation);
		});

		return interpolations;
	};
}
