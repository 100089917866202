import { CognitoAuthOptions } from 'amazon-cognito-auth-js';

export type CognitoOptions = Required<
	Pick<CognitoAuthOptions, 'ClientId' | 'UserPoolId' | 'AppWebDomain' | 'RedirectUriSignIn' | 'RedirectUriSignOut'>
>;

export const COGNITO_DEFAULT_OPTIONS = {
	AdvancedSecurityDataCollectionFlag: true,
	TokenScopesArray: ['email', 'profile', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
} as const;
