import * as React from 'react';

import { Box } from '../shared';
import { GetClaimNotificationsQuery } from './Actions/generated/useGetClaimNotifications.generated';
import { SMSStatus } from './SMSStatus';
import SendAppLinkSMSInput from './SendAppLinkSMSInput';

export type SendAppLinkSMSProps = {
	appLink: string;
	claimId: string;
	smsNotifications?: GetClaimNotificationsQuery['claimNotifications']['notifications'];
};

const SendAppLinkSMS: React.FC<SendAppLinkSMSProps> = ({ appLink, claimId, smsNotifications }) => (
	<Box mt={3}>
		<Box mb={3}>
			<SendAppLinkSMSInput appLink={appLink} claimId={claimId} />
		</Box>
		<SMSStatus notifications={smsNotifications} />
	</Box>
);

export default SendAppLinkSMS;
