"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoaSqsEventType = void 0;
var CoaSqsEventType;
(function (CoaSqsEventType) {
    CoaSqsEventType["SESSION_STARTED"] = "SessionStarted";
    CoaSqsEventType["START_CLASSIFICATION"] = "StartClassification";
    CoaSqsEventType["SESSION_INPUT_COMPLETED"] = "SessionInputCompleted";
    CoaSqsEventType["SESSION_EXPIRED"] = "SessionExpired";
    CoaSqsEventType["ACCEPTED_OFFER"] = "AcceptedOffer";
    CoaSqsEventType["DECLINED_OFFER"] = "DeclinedOffer";
    CoaSqsEventType["NEGOTIATED_OFFER"] = "NegotiatedOffer";
})(CoaSqsEventType = exports.CoaSqsEventType || (exports.CoaSqsEventType = {}));
