import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrivateClientConfig } from '../../../../common';
import { useLocalisedDate } from '../../../utils';
import { Detail as DetailComponent, Question as QuestionComponent } from '../../shared/ClaimDetails';
import { Currency, formatCurrency } from '../../shared/Costbar';
import { formatInteger } from '../../shared/EstimateDetails';

const enum DetailType {
	vehicleModel = 'vehicleModel',
	vehicleName = 'vehicleName',
	vehicleVin = 'vehicleVin',
	valuation = 'valuation',
	modelSubtypeCode = 'modelSubtypeCode',
	registrationNumber = 'registrationNumber',
	registrationMonthYear = 'registrationMonthYear',
	dateOfFirstRegistration = 'dateOfFirstRegistration',
	vehicleMileage = 'vehicleMileage',
	dateOfAccident = 'dateOfAccident',
	policyholderName = 'policyholderName',
	policyMaxSpend = 'policyMaxSpend',
	airbagsDeployedQuestion = 'airbagsDeployedQuestion',
	carDrivableQuestion = 'carDrivableQuestion',
	windscreenShatteredQuestion = 'windscreenShatteredQuestion',
	pointOfImpact = 'pointOfImpact',
	additionalDamage = 'additionalDamage',
	policy = 'policy',
	shinkaAmount = 'shinkaAmount',
}

interface Detail {
	id: DetailType;
	component: React.ComponentType;
	transformer: (claim: any) => any;
}

export type DynamicDetail = {
	component: React.ComponentType;
	props: any;
};

export const useDetails = (claim: any, currency: Currency, config: PrivateClientConfig) => {
	const {
		ui: { details: configDetails },
	} = config;

	const { t } = useTranslation('EstimatingPortal');
	const { formatDate, formatDataYearMonth } = useLocalisedDate();

	const [details, setDetails] = useState<Detail[]>([]);
	const [vehicleDetails, setVehicleDetails] = useState<DynamicDetail[][]>([]);
	const [claimDetails, setClaimDetails] = useState<DynamicDetail[][]>([]);

	useEffect(() => {
		setDetails([
			{
				id: DetailType.vehicleModel,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Model'),
					children: claim.vehicle.model,
				}),
			},
			{
				id: DetailType.vehicleName,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Vehicle'),
					children: claim.vehicle.name,
				}),
			},
			{
				id: DetailType.vehicleVin,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('VIN'),
					children: claim.vehicle.vin,
				}),
			},
			{
				id: DetailType.vehicleMileage,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Mileage'),
					children: formatInteger(claim.vehicle.mileage, currency.symbol),
				}),
			},
			{
				id: DetailType.valuation,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Actual car value'),
					children: claim.vehicle.valuation && formatCurrency(claim.vehicle.valuation, currency),
				}),
			},
			{
				id: DetailType.additionalDamage,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Additional damage'),
					children: t(claim.additionalDamage ? 'Yes' : 'No'),
				}),
			},
			{
				id: DetailType.modelSubtypeCode,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Model ID'),
					children: claim.vehicle.modelSubtypeCode,
				}),
			},
			{
				id: DetailType.registrationNumber,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Registration'),
					children: claim.vehicle.registration,
				}),
			},
			{
				id: DetailType.registrationMonthYear,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Registration year month'),
					children: formatDataYearMonth(claim.vehicle.year, claim.vehicle.month),
				}),
			},
			{
				id: DetailType.dateOfFirstRegistration,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Date of first registration'),
					children: formatDate(claim.vehicle.dateOfFirstRegistration, true),
				}),
			},
			{
				id: DetailType.dateOfAccident,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Date of accident'),
					children: formatDate(claim.claimDetails.dateOfAccident, true),
				}),
			},
			{
				id: DetailType.policyholderName,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Policyholder name'),
					children: claim.policyholderName,
				}),
			},
			{
				id: DetailType.pointOfImpact,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Reported Points of impact'),
					vertical: true,
					children: claim.claimDetails.poi
						.split(',')
						.map((poi: string) => t(poi.trim()))
						.join(', '),
				}),
			},
			{
				id: DetailType.airbagsDeployedQuestion,
				component: QuestionComponent,
				transformer: (claim: any) => {
					const q = claim.yesNo.find((e: any) => e.questionId === 'airbags-deployed');

					if (!q) {
						// return empty object will be hidden
						return {};
					}

					return {
						question: {
							question: t(q.question),
							response: t(q.response),
							typicalAnswer: t(q.typicalAnswer),
						},
					};
				},
			},
			{
				id: DetailType.carDrivableQuestion,
				component: QuestionComponent,
				transformer: (claim: any) => {
					const q = claim.yesNo.find((e: any) => e.questionId === 'is-drivable');

					if (!q) {
						// return empty object will be hidden
						return {};
					}

					return {
						question: {
							question: t(q.question),
							response: t(q.response),
							typicalAnswer: t(q.typicalAnswer),
						},
					};
				},
			},
			{
				id: DetailType.windscreenShatteredQuestion,
				component: QuestionComponent,
				transformer: (claim: any) => {
					const q = claim.yesNo.find((e: any) => e.questionId === 'windshield-shattered');

					if (!q) {
						// return empty object will be hidden
						return {};
					}

					return {
						question: {
							question: t(q.question),
							response: t(q.response),
							typicalAnswer: t(q.typicalAnswer),
						},
					};
				},
			},
			{
				id: DetailType.policyMaxSpend,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Policy max spend'),
					children: formatCurrency(claim.policyMaxSpend, currency),
				}),
			},
			{
				id: DetailType.policy,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Policy'),
					children: t(`${claim.clientId}_${claim.policy}`),
				}),
			},
			{
				id: DetailType.shinkaAmount,
				component: DetailComponent,
				transformer: (claim: any) => ({
					label: t('Shinka'),
					children: claim.shinkaAmount == null ? '--' : formatCurrency(claim.shinkaAmount, currency),
				}),
			},
		]);
	}, []);

	const detailsMapper = (subsection: string[]) =>
		subsection.map((id) => {
			const { transformer, component } = details.find((d: Detail) => d.id === id);

			return {
				component,
				props: transformer(claim),
			};
		});

	useEffect(() => {
		if (details.length > 0) {
			setVehicleDetails(configDetails.vehicle.map(detailsMapper));
			setClaimDetails(configDetails.claim.map(detailsMapper));
		}
	}, [details]);

	return {
		vehicleDetails,
		claimDetails,
	};
};
