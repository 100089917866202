import { Button, Center, Group, Input, InputWrapper, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import * as React from 'react';

import useClientTranslations from '../../../hooks/useClientTranslations';
import { useLocale } from '../../Edit/hooks/useLocale';
import { Box } from '../../shared';
import { ErrorRound } from '../../shared/Icons';
import { useSendAppLinkSmsMutation } from './generated/useSendAppLinkSMS.generated';

export type SendAppLinkSMSInputProps = {
	appLink: string;
	claimId: string;
};

const isNumeric = (value: string): boolean => /^\d+$/.test(value);

const useStyles = createStyles(() => ({
	input: {
		flexGrow: 1,
	},
}));

const PHONE_NUMBER_LENGTH = 11;

const SendAppLinkSMSInput: React.FC<SendAppLinkSMSInputProps> = ({ claimId, appLink }) => {
	const { classes } = useStyles();
	const { t } = useClientTranslations();
	const { countryCode } = useLocale();

	const [sendAppLinkSMS, { loading, error }] = useSendAppLinkSmsMutation();

	const form = useForm({
		initialValues: { phoneNumber: '' },

		validate: {
			phoneNumber: (value) =>
				!isValidPhoneNumber(value, countryCode as CountryCode) ? t('Enter valid 11-digit phone number') : null,
		},
	});

	const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		if (value.length === 0 || (isNumeric(value) && value.length <= PHONE_NUMBER_LENGTH)) {
			form.setFieldValue('phoneNumber', value);
		}
	};

	const onSubmit = (values: typeof form.values): void => {
		sendAppLinkSMS({
			variables: {
				// TODO: the library used to parsePhoneNumber and formatInternational convert it and
				// makes invalid for the SMS service
				// recipient: parsePhoneNumber(values.phoneNumber, countryCode as CountryCode).formatInternational(),
				recipient: values.phoneNumber,
				claimId,
				messageBody: t('appLinkSMS {{link}}', { link: appLink }),
			},
		});
	};

	const apiError = error ? t('Unable to send SMS. Try again in 1 minute.') : null;
	const errorMessage = form.errors['phoneNumber'] || apiError;

	return (
		<form onSubmit={form.onSubmit(onSubmit)}>
			<InputWrapper
				id="app-link-phone-number"
				label={t('Phone number')}
				error={
					errorMessage && (
						<Center inline>
							<ErrorRound />
							<Box ml={1}>{errorMessage}</Box>
						</Center>
					)
				}
			>
				<Group align={'flex-end'}>
					<Input
						disabled={loading}
						className={classes.input}
						id="app-link-phone-number"
						onChange={onChange}
						value={form.values.phoneNumber}
					/>

					<Button loading={loading} type="submit">
						{t('Send link')}
					</Button>
				</Group>
			</InputWrapper>
		</form>
	);
};

export default SendAppLinkSMSInput;
