import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryCard, SummaryCardProps, Text } from '../../shared';

type LinkOpenedProps = SummaryCardProps;

const LinkOpened: React.FC<LinkOpenedProps> = ({ ...restProps }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<SummaryCard {...restProps}>
			<Text>{t('The Policy Holder has opened the link')}</Text>
		</SummaryCard>
	);
};

export { LinkOpened };
