import { Icon } from '@blueprintjs/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessabilityTriageOutcome } from '@tractable/estimating-local-constants';

import { Box, Text } from '../../../shared';
import { FnolStatusDetail } from '../useFNOLIndicator';

interface Props {
	category: undefined | FnolStatusDetail | ProcessabilityTriageOutcome;
}

const OutOfScope: React.FC<Props> = ({ category }) => {
	const { t } = useTranslation('EstimatingPortal');
	const title =
		category === FnolStatusDetail.OUT_OF_SCOPE_VALUATION_UNAVAILABLE
			? 'Unable to determine total loss'
			: 'Out of scope';

	return (
		<Box display="flex" flexDirection="column">
			<Box display="flex" alignItems="center">
				<Icon icon="remove-column" iconSize={24} />
				<Text fontSize={4} fontWeight={5} m={2}>
					{t(title)}
				</Text>
			</Box>
			{category && (
				<Box>
					<Text fontSize={0} marginLeft={4}>
						{t(category)}
					</Text>
				</Box>
			)}
		</Box>
	);
};

export { OutOfScope };
