import { InputGroup, Intent } from '@blueprintjs/core';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { useLocale } from '../../../Edit/hooks/useLocale';
import { TractableFormGroup } from './Shared';

const validLength = (countryCode: CountryCode) => {
	const defaultLength = 13;
	const numberMaplengthMap: { [key in CountryCode]?: number } = {
		IT: 13,
		ES: 12,
		GB: 12,
	};
	return numberMaplengthMap[countryCode] ?? defaultLength;
};

export const validatePhone = (field: string, t: any, countryCode: CountryCode) => (value: any) => {
	// Phone is optional. Empty phone number is valid
	if (!value || value === '') return undefined;
	// Validator throws and error if the length is less than 5
	try {
		const phone = parsePhoneNumber(value, countryCode);
		// if the country doesn't match the format is incorrect. isValidPhone defaults to UK locale
		// libphonenumber doesn't properly check lengths by country
		return phone.country !== countryCode || validLength(countryCode) !== value.length
			? t(`{{field}} is incorrect`, { field })
			: undefined;
	} catch (error) {
		return t(`{{field}} is incorrect`, { field });
	}
};

const PolicyholderPhone: React.FC = () => {
	const { t } = useClientTranslations();
	const { countryCode } = useLocale();

	return (
		<Field name="policyholderPhone" validate={validatePhone(t('Policyholder phone'), t, countryCode as CountryCode)}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Policyholder phone')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder="" />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { PolicyholderPhone };
