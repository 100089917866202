import { Button } from '@mantine/core';
import gql from 'graphql-tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { useGetQuattroruoteLinkQuery } from './generated/EditQuattroruoteButton.generated';

interface Props {
	readonly clientId: string;
	readonly claimId: string;
}

export const EditQuattroruoteEstimateButton: React.FC<Props> = ({ clientId, claimId }) => {
	const { t } = useTranslation('EstimatingPortal');
	const { data, loading, refetch } = useGetQuattroruoteLinkQuery({
		variables: { id: claimId },
		fetchPolicy: 'no-cache',
	});
	const onClick = (): void => {
		logEvent('Click on QR link', {
			clientId: clientId,
			requestId: claimId,
		});
		refetch();
	};
	// In case if the claimId is missed do not show the button
	// in general shouldn't happen
	if (!claimId) {
		return null;
	}
	return (
		<Button
			variant="outline"
			component="a"
			href={data?.quattroruoteLink}
			target="_blank"
			rel="noreferrer"
			data-test-id="edit-in-quattroruote"
			onClick={onClick}
			loading={loading}
		>
			{t('Edit in Quattroroute')}
		</Button>
	);
};

export const GET_QUATTRORUOTE_LINK = gql`
	query getQuattroruoteLink($id: String) {
		quattroruoteLink(claimId: $id)
	}
`;
