import * as Types from '../../../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetEstimateDecisionMutationVariables = Types.Exact<{
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
  estimateDecision?: Types.InputMaybe<Types.EstimateDecision>;
}>;


export type SetEstimateDecisionMutation = { __typename?: 'Mutation', setEstimateDecision?: { __typename?: 'Claim', id?: string | null, stage?: string | null, status?: string | null, estimateDecision?: Types.EstimateDecision | null, nextStage?: string | null } | null };


export const SetEstimateDecisionDocument = gql`
    mutation setEstimateDecision($claimId: String, $estimateDecision: EstimateDecision) {
  setEstimateDecision(claimId: $claimId, estimateDecision: $estimateDecision) {
    id
    stage
    status
    estimateDecision
    nextStage
  }
}
    `;
export type SetEstimateDecisionMutationFn = Apollo.MutationFunction<SetEstimateDecisionMutation, SetEstimateDecisionMutationVariables>;

/**
 * __useSetEstimateDecisionMutation__
 *
 * To run a mutation, you first call `useSetEstimateDecisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEstimateDecisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEstimateDecisionMutation, { data, loading, error }] = useSetEstimateDecisionMutation({
 *   variables: {
 *      claimId: // value for 'claimId'
 *      estimateDecision: // value for 'estimateDecision'
 *   },
 * });
 */
export function useSetEstimateDecisionMutation(baseOptions?: Apollo.MutationHookOptions<SetEstimateDecisionMutation, SetEstimateDecisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEstimateDecisionMutation, SetEstimateDecisionMutationVariables>(SetEstimateDecisionDocument, options);
      }
export type SetEstimateDecisionMutationHookResult = ReturnType<typeof useSetEstimateDecisionMutation>;
export type SetEstimateDecisionMutationResult = Apollo.MutationResult<SetEstimateDecisionMutation>;
export type SetEstimateDecisionMutationOptions = Apollo.BaseMutationOptions<SetEstimateDecisionMutation, SetEstimateDecisionMutationVariables>;