import { Intent } from '@blueprintjs/core';
import { MultiSelect, Tooltip, createStyles } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import { AlertCircle } from 'tabler-icons-react';

import { useClientConfigCtx } from '../../../../../hooks/useClientConfigCtx';
import useClientTranslations from '../../../../../hooks/useClientTranslations';
import { TractableFormGroup as FormGroup } from '../Shared';
import { NotifiedUser } from './NotifiedUser';
import { useGetLatestClaimUsers } from './useGetLatestClaimUsers';
import { useGetUsers } from './useGetUsers';

const useStyles = createStyles(() => ({
	wrapper: {
		borderRadius: '3px',
	},
	input: {
		background: '#ffffff',
		border: 'none',
		boxShadow:
			'0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%)',
	},
	searchInput: {
		'&::placeholder': {
			color: '#B3B3B3',
			opacity: 1,
		},
		'&:focus': {
			outline: '0',
		},
		'&:hover ': {
			outline: '0',
		},
	},
	value: {
		background: '#F5F6F7',
		fontSize: '14px',
		fontWeight: 400,
	},
	defaultValueLabel: {
		color: '#182026',
		fontSize: '14px',
	},
	dropdown: {
		border: '1px solid #E9ECF0',
		marginTop: '-5px',
	},
	tooltip: {
		position: 'absolute',
		left: '-29px',
		top: '5px',
	},
	tooltipBody: {
		color: '#ffffff',
		backgroundColor: '#1C3444',
	},
	tooltipArrow: {
		backgroundColor: '#1C3444',
	},
}));

const TractableFormGroup = styled(FormGroup)`
	label {
		padding-right: 20px;
	}
`;

/**
 * Define the default empty array outside the function so that it
 * is memoised by default.
 *
 * On each render we'll have the same instance of the array.
 */
export const DEFAULT_VALUE: NotifiedUser[] = [];

const NotifiedUsers: React.FC = () => {
	/**
	 * Query and params to get the users to show in the multiselect
	 */
	const { config } = useClientConfigCtx();
	const showInternalUsers = config?.ui?.notifiedUsers?.showInternalUsers || false;

	const [emailQuery, setEmailQuery] = useState<string | undefined>();
	const [debounced] = useDebouncedValue(emailQuery, 500);

	const { users, loading } = useGetUsers({ showInternalUsers, emailQuery: debounced });

	/**
	 * Managing state of the field
	 */
	const { input, meta } = useField<NotifiedUser[]>('notifiedUsers', { type: 'select' });

	const onChange = (selectedIds: string[]): void => {
		// Combine fetched and selected users so that selected users continue to show in select even when not matching query
		const allUsers = [...users, ...(input.value || DEFAULT_VALUE)];

		const selectedUsers = selectedIds.map((selectedId) => allUsers.find(({ id }) => id === selectedId));

		input.onChange(selectedUsers);
	};

	// Multi select component requires a memoised value. `input.value` is memoised.
	const multiselectValue = useMemo(() => (input.value || DEFAULT_VALUE).map(({ id }) => id), [input.value]);

	/**
	 * Prefill
	 */
	const { latestClaimUsers, loading: isLoadingLatestClaimUsers } = useGetLatestClaimUsers();

	// This state ensures the field is only prefilled on initial render (once `useGetLatestClaimUsers` has completed).
	const [isStatePrefilled, setIsStatePrefilled] = useState<boolean>(false);

	useEffect(() => {
		if (!isLoadingLatestClaimUsers && !isStatePrefilled) {
			input.onChange(latestClaimUsers);

			setIsStatePrefilled(true);
		}
	}, [latestClaimUsers, isLoadingLatestClaimUsers, input, isStatePrefilled]);

	/**
	 * Loading/not found message and translations
	 */
	const { t } = useClientTranslations();

	let notFoundMessage = t('form.notified_users.not_found');
	if (loading || isLoadingLatestClaimUsers) {
		notFoundMessage = t('form.notified_users.loading');
	}

	const { classes } = useStyles();

	return (
		<TractableFormGroup
			label={t('form.notified_users.label')}
			inline
			intent={Intent.DANGER}
			helperText={meta.invalid && meta.touched && meta.error}
		>
			<Tooltip
				classNames={{
					root: classes.tooltip,
					body: classes.tooltipBody,
					arrow: classes.tooltipArrow,
				}}
				wrapLines
				width={290}
				withArrow
				transition="fade"
				transitionDuration={200}
				label={t('form.notified_users.tooltip')}
			>
				<AlertCircle size={22} strokeWidth={2} color={'#3B25C4'} style={{ transform: 'rotate(180deg)' }} />
			</Tooltip>
			<MultiSelect
				classNames={{
					wrapper: classes.wrapper,
					input: classes.input,
					value: classes.value,
					defaultValueLabel: classes.defaultValueLabel,
					dropdown: classes.dropdown,
					searchInput: classes.searchInput,
				}}
				disabled={isLoadingLatestClaimUsers}
				value={multiselectValue}
				onChange={onChange}
				searchable
				onSearchChange={setEmailQuery}
				data={[...users, ...(input.value || DEFAULT_VALUE)].map(({ id, email }) => ({ value: id, label: email }))}
				placeholder={t('form.notified_users.placeholder')}
				nothingFound={notFoundMessage}
				limit={20}
				clearButtonLabel={t('form.notified_users.clear')}
				clearable
			/>
		</TractableFormGroup>
	);
};

export default NotifiedUsers;
