import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnMap } from '../../../../../common';
import { StyledHeader } from './styles';

interface Props {
	columns: (keyof typeof ColumnMap)[];
}

const Header: React.FC<Props> = ({ columns }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<thead>
			<StyledHeader>
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<th />
				{columns.map((columnId) => (
					<th key={columnId}>{t(ColumnMap[columnId])}</th>
				))}
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<th />
			</StyledHeader>
		</thead>
	);
};

export { Header };
