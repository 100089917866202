import * as React from 'react';

import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { CurrencyInput } from './Shared';

const PolicyMaxSpend: React.FC = () => {
	const { config } = useClientConfigCtx();

	return (
		<CurrencyInput
			suffix={config.ui.policyMaxSpendSuffix}
			name="policyMaxSpend"
			validation={{
				required: true,
				translationKey: 'Policy max spend',
			}}
			label={{
				translationKey: 'Policy max spend',
			}}
		/>
	);
};

export { PolicyMaxSpend };
