import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';
import { ValidFlag } from '@tractable/estimating-local-constants';

import { ErrorNotification } from '../../shared/ErrorNotification';
import { OperationType } from '../../shared/operationToFill';
import { useUpdatePartOperation } from './useUpdatePartOperation';

export const useOperation = (claim: any, setIsDirty: () => void, validFlags: ValidFlag[]) => {
	const { t } = useTranslation('EstimatingPortal');
	const [sectionOperationLoading, setSectionOperationLoading] = useState<string | null>(null);
	const { editOperation, error } = useUpdatePartOperation(setIsDirty, validFlags);

	const setOperation =
		(sectionId: string) => (panel: string, operation: OperationType, partOptions: string[], partSelection: string) => {
			setSectionOperationLoading(sectionId);
			editOperation(claim, {
				panel,
				operation,
				partOptions,
				partSelection,
			})
				.then(() => {
					setSectionOperationLoading(null);
				})
				.catch(() => {
					setSectionOperationLoading(null);
				});
		};

	useEffect(() => {
		if (error) {
			logEvent('Edit - error', {
				errorMessage: error.message,
				claimId: claim.id,
			});
			toast(<ErrorNotification text={t('Unable to update operation. Please try again.')} />, {
				position: 'bottom-left',
				draggable: false,
				hideProgressBar: true,
				autoClose: 4000,
			});
		}
	}, [error]);

	return {
		sectionOperationLoading,
		setOperation,
	};
};
