import { HTMLSelect, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { FieldProps } from '../types';
import { required } from '../utils';
import { TractableFormGroupSmall } from './Shared';

export interface RegionOption {
	regionKey: string;
	displayLabel: string;
}

interface RegionProps {
	readonly regions: RegionOption[];
}

const ClaimRegion: React.FC<RegionProps> = ({ regions }) => {
	const { t } = useClientTranslations();

	return (
		<Field component="select" name="region" validate={required(t('Region'), t)}>
			{({ input, meta }: FieldProps) => (
				<TractableFormGroupSmall
					label={t('Region')}
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && meta.error}
				>
					<HTMLSelect {...input}>
						<option disabled value="" style={{ display: 'none' }}>
							{' '}
						</option>
						{regions.map((option, index) => (
							<option value={option.regionKey} key={index}>
								{option.displayLabel}
							</option>
						))}
					</HTMLSelect>
				</TractableFormGroupSmall>
			)}
		</Field>
	);
};

export { ClaimRegion };
