import { useEffect } from 'react';

import {
	deDE,
	enCA,
	enGB,
	enUS,
	esES,
	fr,
	frFR,
	itIT,
	jaJP,
	nlNL,
	plPL,
	ptBr,
	ptPT,
	thTH,
	zhHans,
} from '@tractable/estimating-local-localization/lib/locales';

import { DelightedSurveyObj } from '../../../../../common/types/delightedSurvey';

export type DelightedSurveyFeedbackProps = {
	clientId: string;
	language: string;
	initialDelay?: number;
	minTimeOnPage?: number;
};

const SurveyLanguageMapping: Record<string, string> = {
	[zhHans.symbol]: 'zh-CN',
	[nlNL.symbol]: 'nl',
	[enGB.symbol]: 'en',
	[enUS.symbol]: 'en',
	[enCA.symbol]: 'en',
	[frFR.symbol]: 'fr',
	[fr.symbol]: 'fr',
	[deDE.symbol]: 'de',
	[itIT.symbol]: 'it',
	[jaJP.symbol]: 'ja',
	[plPL.symbol]: 'pl',
	[ptBr.symbol]: 'pt-BR',
	[ptPT.symbol]: 'pt',
	[esES.symbol]: 'es',
	[thTH.symbol]: 'th',
};

export const useDelightedSurvey = ({
	clientId,
	language,
	initialDelay = 0,
	minTimeOnPage = 0,
}: DelightedSurveyFeedbackProps): void => {
	const surveyType = 'delightedCsat4';
	const surveyUrl = `https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/1Q8Xpb9mzqpmFosR/${surveyType}.js`;

	useEffect(() => {
		if (!window[surveyType as keyof Window]) {
			(window[surveyType as keyof Window] as DelightedSurveyObj) = {
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				survey: () => {},
			};

			const script = document.createElement('script');
			script.src = surveyUrl;
			script.id = 'delighted-survey';
			script.onload = () => {
				(window[surveyType as keyof Window] as DelightedSurveyObj).survey({
					name: clientId,
					initialDelay,
					minTimeOnPage,
					properties: {
						locale: SurveyLanguageMapping[language],
						clientId,
						environment: process.env.NODE_ENV,
					},
				});
			};
			document.body.appendChild(script);
		}
	}, []);
};
