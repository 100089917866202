import * as React from 'react';

function SvgNegotiated(props: React.SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width="17px" height="17px" viewBox="0 0 17 17" fill="none" {...props}>
			<circle cx={6.911} cy={6} fill="#fff" r={5.625} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.99935 17.3957C8.83268 17.3957 8.66949 17.3679 8.50977 17.3123C8.35004 17.2568 8.21463 17.1734 8.10352 17.0623L0.936849 9.89567C0.825738 9.78456 0.742405 9.64914 0.686849 9.48942C0.631294 9.3297 0.603516 9.1665 0.603516 8.99984C0.603516 8.83317 0.631294 8.66998 0.686849 8.51025C0.742405 8.35053 0.825738 8.21512 0.936849 8.104L8.10352 0.937337C8.21463 0.826226 8.35004 0.742893 8.50977 0.687337C8.66949 0.631782 8.83268 0.604004 8.99935 0.604004C9.16602 0.604004 9.32921 0.631782 9.48893 0.687337C9.64865 0.742893 9.78407 0.826226 9.89518 0.937337L17.0618 8.104C17.173 8.21512 17.2563 8.35053 17.3118 8.51025C17.3674 8.66998 17.3952 8.83317 17.3952 8.99984C17.3952 9.1665 17.3674 9.3297 17.3118 9.48942C17.2563 9.64914 17.173 9.78456 17.0618 9.89567L9.89518 17.0623C9.78407 17.1734 9.64865 17.2568 9.48893 17.3123C9.32921 17.3679 9.16602 17.3957 8.99935 17.3957ZM5.41602 12.5832L8.99935 16.1665L16.166 8.99984L8.99935 1.83317L1.83268 8.99984L5.41602 12.5832ZM8.99935 13.104L13.1035 8.99984L8.99935 4.89567L8.10352 5.7915L10.6868 8.37484H4.89518V9.62484H10.6868L8.10352 12.2082L8.99935 13.104Z"
				fill="#2A4759"
			/>
		</svg>
	);
}

export default SvgNegotiated;
