import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';
import { Decimal, EstimateDetails, Section, SectionRow } from '../../shared/EstimateDetails';
import { Currency } from '../../shared/EstimateDetails/formatCurrency';

interface Props {
	totals: any;
	showMainPartSubtitle: boolean;
	currency: Currency;
	decimal: Decimal;
	collapsedSections: string[];
	toggleSection: (s: string) => void;
	totalNumberOfParts: number;
}

const getCurrencySymbol = (currency: Currency) => {
	const nf = new Intl.NumberFormat(currency.symbol, currency.options);
	const parts = nf.formatToParts(1234);

	const symbol = parts.find((p) => p.type === 'currency');

	return symbol ? symbol.value : '';
};

export const TotalsTable: React.FC<Props> = ({
	totals,
	showMainPartSubtitle,
	currency,
	decimal,
	collapsedSections,
	toggleSection,
	totalNumberOfParts,
}) => {
	const { t } = useTranslation('EstimatingPortal');
	const [showInternalDamage, setShowInternalDamage] = useState<boolean>(false);
	const [hideAllPartPriceData, setHideAllPartPriceData] = useState<boolean>(false);

	const currencySymbol = getCurrencySymbol(currency);
	const { config } = useClientConfigCtx();

	useEffect(() => {
		setShowInternalDamage(config.ui?.showInternalDamage ?? false);
		setHideAllPartPriceData(config.ui?.hideAllPartPriceData ?? false);
	}, []);

	// We want to hide part price totals if they reveal pricing of individual parts, e.g. the
	// estimating only has one part in it.
	const hidePartPriceTotals = hideAllPartPriceData && totalNumberOfParts <= 1;

	return (
		<EstimateDetails headers={[t('Hours'), t('Cost', { currencySymbol })]}>
			<Section
				description={t('Part operations')}
				showMainPartSubtitle={showMainPartSubtitle}
				isOpen={!collapsedSections.includes('part-operations')}
				toggleSection={() => toggleSection('part-operations')}
				currency={currency}
				cost={
					totals.stripRefitRepair.cost +
					totals.panelRepair.cost +
					totals.partCostAdjustments.reduce((acc: any, v: any) => acc + v.cost, 0) +
					totals.replacementParts.cost
				}
			>
				<SectionRow
					description={t('Part cost')}
					decimal={decimal}
					currency={currency}
					{...totals.replacementParts}
					dataTestId="total-part-cost"
					hideCost={hidePartPriceTotals}
				/>
				{totals.partCostAdjustments.map((adj: any) => (
					<SectionRow
						key={adj.description}
						description={t(adj.description)}
						decimal={decimal}
						currency={currency}
						cost={adj.cost}
						hideCost={hideAllPartPriceData}
					/>
				))}
				<SectionRow
					description={t('Panel repair labour')}
					decimal={decimal}
					currency={currency}
					{...totals.panelRepair}
				/>
				<SectionRow
					description={t('Strip/refit labour')}
					decimal={decimal}
					currency={currency}
					{...totals.stripRefitRepair}
				/>
			</Section>
			<Section
				description={t('Paint operations')}
				showMainPartSubtitle={showMainPartSubtitle}
				isOpen={!collapsedSections.includes('paint-operations')}
				toggleSection={() => toggleSection('paint-operations')}
				cost={totals.paintRepair.cost + totals.paintMaterial.cost}
				currency={currency}
			>
				<SectionRow description={t('Paint labour')} currency={currency} decimal={decimal} {...totals.paintRepair} />
				<SectionRow description={t('Paint material')} currency={currency} decimal={decimal} {...totals.paintMaterial} />
			</Section>
			{showInternalDamage && (
				<Section
					description={t('Internal damage estimate')}
					showMainPartSubtitle={showMainPartSubtitle}
					isOpen={false}
					toggleSection={() => null}
					cost={totals.additionalPartsDisplayCostTotal}
					currency={currency}
				/>
			)}
			<Section
				dataTestId="total-excluding-tax"
				description={t('Excl. Tax')}
				showMainPartSubtitle={showMainPartSubtitle}
				isOpen={false}
				toggleSection={() => null}
				cost={totals.totalExcludingTax}
				currency={currency}
			/>
			<Section
				description={t('Grand total')}
				showMainPartSubtitle={showMainPartSubtitle}
				isOpen={false}
				toggleSection={() => null}
				cost={totals.totalIncludingTax}
				currency={currency}
			/>
		</EstimateDetails>
	);
};
