import { FormGroup } from '@blueprintjs/core';
import styled from 'styled-components';

export const TractableFormGroup = styled(FormGroup)`
	label {
		width: 20rem;
	}
	&.bp3-inline > .bp3-form-content {
		width: 50%;
	}
	.bp3-form-content {
		position: relative;
	}
	.bp3-popover-target {
		width: 100%;
	}
	.bp3-input {
		height: 34px;
	}
`;
