import { TFunction } from 'i18next';

import { EstimateDecision } from '../../../../../../generated/graphql';
import { SettlementActionProps } from '../SettlementAction';
import { useSetEstimateDecisionMutation } from './generated/useSetEstimateDecision.generated';

export const getOptions = (
	t: TFunction,
	setEstimateDecision: ReturnType<typeof useSetEstimateDecisionMutation>[0],
	claimId: string,
	onlyAccept?: boolean
): Record<string, SettlementActionProps['options']> => {
	if (onlyAccept) {
		return {
			review: [
				{
					text: t('Accept'),
					onClick: () =>
						setEstimateDecision({
							variables: {
								claimId,
								estimateDecision: EstimateDecision.Accepted,
							},
						}),
					dataTestId: 'accept-estimate-button',
				},
			],
		};
	}
	return {
		review: [
			{
				text: t('Accept'),
				onClick: () =>
					setEstimateDecision({
						variables: {
							claimId,
							estimateDecision: EstimateDecision.Accepted,
						},
					}),
				dataTestId: 'accept-estimate-button',
			},
			{
				text: t('Reject due to OOS'),
				onClick: () =>
					setEstimateDecision({
						variables: {
							claimId,
							estimateDecision: EstimateDecision.RejectedOos,
						},
					}),
				dataTestId: 'reject-estimate-oos-button',
			},
			{
				text: t('Reject due to VIN'),
				onClick: () =>
					setEstimateDecision({
						variables: {
							claimId,
							estimateDecision: EstimateDecision.RejectedVin,
						},
					}),
				dataTestId: 'reject-estimate-vin-button',
			},
		],
	};
};
