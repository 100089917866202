import styled from 'styled-components';

const Vid = styled.video`
  display: block;
  width: 100%
  height: auto;
  margin: 0 auto;
`;

export { Vid };
