import * as React from 'react';
import styled from 'styled-components';

interface Props {
	checked: boolean;
	disabled: boolean;
	onChange: () => void;
}

const Icon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 2px;
`;
const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

export interface CheckboxProps {
	checked: boolean;
	disabled: boolean;
}
const StyledCheckbox = styled.div<CheckboxProps>`
	display: inline-block;
	width: 16px;
	height: 16px;
	background: ${({ checked }) => (checked ? '#3B25C4' : 'white')};
	transition: all 150ms;
	border: 1px solid ${({ disabled }) => (disabled ? '#C1C1C1' : '#8f8f8f')};
	border-radius: 3px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	// Hide checkbox visually but remain accessible to screen readers.
	// Source: https://polished.js.org/docs/#hidevisually
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

export const Checkbox: React.FC<Props> = ({ checked, disabled, ...props }) => {
	return (
		<CheckboxContainer>
			<HiddenCheckbox checked={checked} disabled={disabled} {...props} />
			<StyledCheckbox checked={checked} disabled={disabled}>
				<Icon viewBox="0 0 24 24">
					<polyline points="20 6 9 17 4 12" />
				</Icon>
			</StyledCheckbox>
		</CheckboxContainer>
	);
};
