import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { Box } from '../../shared/Box';
import { ImageDownloader } from '../../shared/ImageDownloader';
import { ImageViewer } from '../../shared/ImageViewer/ImageViewer';
import { sortImages } from '../../shared/sortImages';

interface Props {
	claim: any;
}
const ImagesWrapper = styled(Box)`
	position: sticky;
	top: 70px;
`;
export const Images: React.FC<Props> = ({ claim: { id, images } }) => {
	const mappedImages = useMemo(
		() =>
			sortImages(images).map((i) => ({
				original: i.path,
				thumbnail: i.path,
			})),
		[]
	);

	return (
		<ImagesWrapper orientation="vertical">
			<Box justifyContent="flex-end">
				<ImageDownloader id={id} />
			</Box>
			<ImageViewer images={mappedImages} />
		</ImagesWrapper>
	);
};
