import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useClientConfigCtx } from '../../hooks/useClientConfigCtx';
import { AssetViewer } from '../Review/AssetViewer';

export const PDFView: React.FC = () => {
	const { config } = useClientConfigCtx();
	const { id } = useParams();
	const navigate = useNavigate();

	const showEstimate = !!config.ui?.showEstimate;

	// Guard against when config is not yet available.
	if (typeof config.ui?.showEstimate !== 'undefined' && !showEstimate) {
		navigate('/');
	}

	return (
		<AssetViewer
			id={id}
			selectedAsset={null}
			widget={{ sticky: '60px', gridArea: 'f2', padding: false }}
			showEstimate={showEstimate}
		/>
	);
};
