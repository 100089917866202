import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { map } from 'lodash';
import { useEffect, useState } from 'react';

import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';
import { sortImages } from '../../shared/sortImages';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			images {
				id
				path
				imageCategory
			}
		}
	}
`;

const useImagesGallery = (id: string, setSelectedAsset: any): { images: any[] } => {
	const { config } = useClientConfigCtx();
	const showEstimate = !!config.ui.showEstimate;

	const [images, setImages] = useState(null);
	const { loading, data } = useQuery(GET_CLAIM, {
		variables: { id },
	});

	useEffect(() => {
		if (!loading && data?.claim?.images) {
			const sortedImages = sortImages(data.claim.images);

			const imagesProps = map(sortedImages, (image: any) => ({ onMouseOut, onMouseOver }: any) => ({
				key: image.path,
				url: image.path,
				onMouseOver: () => onMouseOver(() => setSelectedAsset({ path: image.path, type: 'image' })),
				// If showEstimate config is false, don't unselect image on mouse out event.
				onMouseOut: () => (showEstimate ? onMouseOut(() => setSelectedAsset(null)) : null),
			}));

			setImages(imagesProps);
			const firstImage = sortedImages[0];
			if (!showEstimate && firstImage?.path) {
				setSelectedAsset({ path: firstImage.path, type: 'image' });
			}
		}
	}, [loading, data, showEstimate]);

	return {
		images,
	};
};

export { useImagesGallery };
