import axios, { AxiosRequestConfig } from 'axios';

import { authMethodHeader } from '@tractable/estimating-local-api-authentication';

import { PrivateClientConfig, PublicClientConfig } from '../common';
import { getCurrentToken } from './auth';

const getAuthenticationHeaders = async () => {
	const { token, method } = await getCurrentToken();

	return {
		Authorization: `Bearer ${token}`,
		[authMethodHeader]: method,
	};
};

/**
 * Fetches public client configuration from the Portal backend.
 *
 * Public config is anything that is safe to be exposed over the internet and is
 * generally only required in order to load the app prior to checking if the
 * user is logged in/redirecting them to login. This is where the minority of
 * client config should come from.
 */
export async function getPublicClientConfig(): Promise<PublicClientConfig> {
	const { data } = await axios.get<PublicClientConfig>('/api/config/public');

	return data;
}

/**
 * Fetches private client configuration from the Portal backend.
 *
 * Private config is anything that is NOT safe to be exposed over the internet
 * and is required in order to perform any action in the app after the user has
 * successfully logged in. This is where the majority of client config should
 * come from.
 */
export async function getPrivateClientConfig(controller?: AbortController): Promise<PrivateClientConfig> | never {
	const headers = await getAuthenticationHeaders();
	const options: AxiosRequestConfig<any> = {
		headers,
	};

	if (controller) {
		options.signal = controller.signal;
	}

	const { data } = await axios.get<PrivateClientConfig>('/api/config/private', options);

	return data;
}
