import * as React from 'react';

import { PhEstimateDecision } from '@tractable/estimating-local-constants';
import { Totals } from '@tractable/estimating-local-estimate-parsing';

import { PrivateClientConfig } from '../../../../../common';
import { EstimateDecision, SettlementDecision } from '../../../../../generated/graphql';
import useClientTranslations from '../../../../hooks/useClientTranslations';
import { FieldType } from '../../../Claim/Create/Fields/FieldType';
import { Box } from '../../../shared/Box';
import {
	ClaimHeader,
	Detail,
	DetailSection,
	PhDecisionIndicator,
	ShinkaIndicator,
	VrmCheckIndicator,
} from '../../../shared/ClaimDetails';
import { Currency } from '../../../shared/Costbar/Currency';
import { GetClaimQuery } from '../../hooks/generated/useGetClaim.generated';
import { DynamicDetail, useDetails } from '../../hooks/useDetails';
import { usePayee } from '../../hooks/usePayee';
import { showShinkaIndicator } from '../../utils/showShinkaIndicator';

interface Props {
	claim: any;
	currency: Currency;
	config: PrivateClientConfig;
	totals: Totals;
	settlement: GetClaimQuery['claim']['settlement'];
}

const getEstimateDecision = ({
	phDecision,
	estimateDecision,
	settlement,
	config,
}: {
	phDecision: PhEstimateDecision;
	estimateDecision: EstimateDecision;
	settlement: GetClaimQuery['claim']['settlement'];
	config: PrivateClientConfig;
}): PhEstimateDecision => {
	if (settlement.decision === SettlementDecision.OutOfScope || settlement.decision === SettlementDecision.TotalLoss) {
		return PhEstimateDecision.OFFER_UNAVAILABLE;
	}
	// If it is split journey and Estimate decision is accepted means we are awaiting PH acceptance
	if (
		config.enablePayeeInfo &&
		estimateDecision === EstimateDecision.Accepted &&
		phDecision === PhEstimateDecision.OFFER_UNAVAILABLE
	) {
		return PhEstimateDecision.NOT_YET_COMPLETE;
	}
	return phDecision;
};

export const ClaimDetails: React.FC<Props> = ({ claim, currency, config, totals, settlement }) => {
	const { t } = useClientTranslations();
	const { claimDetails, vehicleDetails } = useDetails(claim, currency, config);
	const { payeeDetails } = usePayee(claim);
	const showDecisionIndicator = !!config.ui?.showDecisionIndicator;
	const showVrmCheckIndicator = !!config.ui?.showVrmCheckIndicator;
	const isShowShinkaIndicator = showShinkaIndicator(
		config.ui.createClaimFields.includes(FieldType.SHINKA_AMOUNT),
		claim?.shinkaAmount,
		totals?.totalIncludingTax
	);
	const outOfScopeReason: { text: string } = settlement.outOfScopeReason && {
		text: t(settlement.outOfScopeReason),
	};
	const noTotalLossDecisionReason: { text: string } = settlement.noTotalLossDecisionReason && {
		text: t(settlement.noTotalLossDecisionReason),
	};

	const reason: { title: string; granularReason: { text: string } } = {
		title: t(settlement.decision),
		granularReason: outOfScopeReason || noTotalLossDecisionReason,
	};

	return (
		<Box p="16px 0 32px 0" borderBottom="1px solid #D7E3EB" gap={16} orientation="vertical">
			<ClaimHeader {...reason}>
				{/* Shinka must be the first indicator */}
				{isShowShinkaIndicator && <ShinkaIndicator title={t('Shinka applicable')} />}
				{showDecisionIndicator && claim.claimDetails.phDecision && (
					<PhDecisionIndicator
						title={t(
							getEstimateDecision({
								phDecision: claim.claimDetails.phDecision.decision,
								estimateDecision: claim.estimateDecision,
								settlement,
								config,
							})
						)}
						decision={getEstimateDecision({
							phDecision: claim.claimDetails.phDecision.decision,
							estimateDecision: claim.estimateDecision,
							settlement,
							config,
						})}
						counterOffer={claim.claimDetails.phDecision.counterOffer}
					/>
				)}
				{showVrmCheckIndicator && claim.vrmCheck && (
					<VrmCheckIndicator title={t(claim.vrmCheck.outcome)} outcome={claim.vrmCheck.outcome} />
				)}
			</ClaimHeader>
			<Box gap={16}>
				<DetailSection data-test-id="vehicle-details" orientation="horizontal" gap={16}>
					{vehicleDetails.map((subsection: DynamicDetail[], i) => (
						<Box orientation="vertical" gap={8} key={i}>
							{subsection.map(({ component, props }, key) => React.createElement(component, { ...props, key }))}
						</Box>
					))}
				</DetailSection>
				<DetailSection data-test-id="claim-details" orientation="horizontal" gap={16}>
					{claimDetails.map((subsection: DynamicDetail[], i) => (
						<Box orientation="vertical" gap={8} key={i}>
							{subsection.map(({ component, props }, key) => React.createElement(component, { ...props, key }))}
						</Box>
					))}
				</DetailSection>
				{claim.claimDetails.phDecision && config.ui?.details?.payee && (
					<DetailSection data-test-id="payee-details" orientation="horizontal" gap={16}>
						{payeeDetails.map((subsection: DynamicDetail[], i) => (
							<Box orientation="vertical" gap={8} key={i}>
								{subsection.map(
									({ props }, key) =>
										props.children && (
											<Detail label={props.label} vertical={props.vertical} key={key}>
												{props.children}
											</Detail>
										)
								)}
							</Box>
						))}
					</DetailSection>
				)}
			</Box>
		</Box>
	);
};
