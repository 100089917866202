import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from 'react';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

const GET_IMAGES_LINK = gql`
	query getClaim($id: String) {
		downloadImages(id: $id)
	}
`;

const STAGES: any = {
	READY: 'ready',
	PROCESSING: 'processing',
	DONE: 'done',
	ERROR: 'error',
};

const useImageDownloader = (id: string) => {
	const client = useApolloClient();
	const [stage, setStage] = useState(STAGES.READY);
	const [data, setData] = useState(null);

	const getters = {
		[STAGES.READY]: (props: any = {}) => ({
			onClick: () => {
				logEvent('Download photo', { claimId: id });
				setStage(STAGES.PROCESSING);
				return client
					.query({ query: GET_IMAGES_LINK, variables: { id } })
					.then(({ data: { downloadImages } }: any) => {
						setData(downloadImages);
						setStage(STAGES.DONE);
					})
					.catch(() => setStage(STAGES.ERROR));
			},
			...props,
		}),
		[STAGES.PROCESSING]: () => ({}),
		[STAGES.DONE]: () => ({
			href: data,
		}),
		[STAGES.ERROR]: () => ({}),
	};

	return {
		url: data,
		stage,
		getIndicatorProps: getters[stage],
	};
};

export { useImageDownloader, STAGES };
