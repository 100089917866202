import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

export interface DeleteClaimProps {
	mutate: any;
}

const DELETE_CLAIM = gql`
	mutation deleteClaim($id: String) {
		deleteClaim(id: $id)
	}
`;

export const withDeleteClaim = graphql(DELETE_CLAIM);
