import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ValidFlag } from '@tractable/estimating-local-constants';
import { calculateTotals } from '@tractable/estimating-local-estimate-parsing';

import { CarDiagram, EstimateTable, TotalsTable } from '../';
import { Claim } from '../../../../../generated/graphql';
import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { Box } from '../../../shared/Box';
import { Costbar, NotificationLevel, SpendDetails, TotalCost } from '../../../shared/Costbar';
import { LaborRate } from '../../../shared/EstimateDetails';
import { useEditing } from '../../hooks/useEditing';
import { useExpandableSections } from '../../hooks/useExpandableSections';
import { useGetValidFlags } from '../../hooks/useGetValidFlags';
import { useLocale } from '../../hooks/useLocale';
import { getAdditionalEstimate } from '../../utils/getAdditionalEstimate';

type UpdateType = (id: string) => {
	rate: (value: string) => void;
	field: (name: string) => (property: string) => () => (value: string) => void;
	paint: (field: string) => (value: string) => void;
	additionalCost: (index: number) => (field: string) => (value: string) => void;
	additionalHours: (index: number) => (field: string) => (value: string) => void;
};

interface EstimateProps {
	readonly showEstimate: boolean;
	readonly showPOI: boolean;
	claim: Claim;
	update: UpdateType;
	isEditing: boolean;
}

const StickyWrapper = styled(Box)`
	position: sticky;
	top: 0px;
	background: white;
	padding: 8px 0;
	z-index: 1;
`;

export const EstimateView: React.FC<EstimateProps> = ({ showEstimate, showPOI, claim, update, isEditing }) => {
	const { decimal, currency } = useLocale();
	const { t } = useTranslation('EstimatingPortal');
	const { config } = useClientConfigCtx();
	const { toggleSection, collapsedSections } = useExpandableSections();
	const { setIsDirty } = useEditing();
	const { id } = useParams();
	const { simplifiedCostbar } = config.ui;

	const {
		data: validFlags,
		// error
	} = useGetValidFlags(id);

	const totals = calculateTotals(
		claim.estimate,
		config.clientId,
		validFlags.getValidFlags.filter((f: { type: string }) => f.type === 'estimate') as ValidFlag[]
	);

	const totalNumberOfParts = claim?.estimate?.parts?.length ?? 0;
	const additionalEstimateProps = getAdditionalEstimate(config?.policies, claim, totals.totalIncludingTax);
	const grandTotalText = additionalEstimateProps.useAdditionalEstimateFlag ? 'Cash settlement total' : 'Grand total';

	return (
		<>
			{showEstimate && (
				<>
					{claim?.maximumRepairSpend && !simplifiedCostbar && (
						<StickyWrapper>
							<Costbar
								repairCost={additionalEstimateProps.grandTotal ?? 0}
								repairCostText={t(additionalEstimateProps.grandTotalText)}
								currency={currency}
							>
								<SpendDetails
									repairCost={additionalEstimateProps.grandTotal ?? 0}
									maxSpend={claim.maximumRepairSpend}
									maxSpendText={t('Max spend')}
									notifications={[
										{
											text: t('approaching-total-loss-warning'),
											level: NotificationLevel.Warning,
											threshold: 0.85,
										},
										{
											text: t('total-loss-warning'),
											level: NotificationLevel.Alert,
											threshold: 1,
										},
									]}
									currency={currency}
								/>
							</Costbar>
						</StickyWrapper>
					)}
					{(simplifiedCostbar || !claim?.maximumRepairSpend || additionalEstimateProps.useAdditionalEstimateFlag) && (
						<TotalCost currency={currency} grandTotal={totals.totalIncludingTax} grandTotalText={t(grandTotalText)} />
					)}
				</>
			)}

			{showPOI && (
				<CarDiagram
					claim={claim}
					isEditing={isEditing}
					setIsDirty={setIsDirty}
					validFlags={validFlags.getValidFlags as ValidFlag[]}
				/>
			)}

			{showEstimate && (
				<Box orientation="vertical">
					<Box justifyContent="space-between" alignItems="flex-end">
						<LaborRate
							text={t('Labour rate')}
							isEditable={isEditing}
							info={claim.estimate.info as { stripRefitLaborRate: number }}
							update={update(claim.id).rate}
							currency={currency}
						/>
					</Box>
					<Box gap={32} orientation="vertical" m="0 0 100px 0">
						<EstimateTable
							claim={claim}
							currency={currency}
							decimal={decimal}
							isEditing={isEditing}
							collapsedSections={collapsedSections}
							toggleSection={toggleSection}
							setIsDirty={setIsDirty}
							validFlags={validFlags.getValidFlags as ValidFlag[]}
						/>
						<TotalsTable
							totalNumberOfParts={totalNumberOfParts}
							totals={totals}
							showMainPartSubtitle={claim.estimate.info.estimateType !== 'SIMPLIFIED'}
							currency={currency}
							decimal={decimal}
							collapsedSections={collapsedSections}
							toggleSection={toggleSection}
						/>
					</Box>
				</Box>
			)}
		</>
	);
};
