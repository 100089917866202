import * as React from 'react';

import { NavIcon } from '../Navbar';
import { Navigation } from '../Navigation';
import { Wrapper } from './styles';

interface PageLayoutProps {
	header(): any;
	navigationOnClick?: () => void;
	navigationHeader?: string;
	navigationIcon?: NavIcon;
	children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
	header,
	navigationOnClick,
	navigationHeader,
	navigationIcon,
	children,
}) => (
	<Wrapper>
		<Navigation icon={navigationIcon} header={navigationHeader} onClick={navigationOnClick} />
		{header && header()}
		<section className="main">{children}</section>
	</Wrapper>
);

export { PageLayout };
