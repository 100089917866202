import { TextArea as textarea } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const TextArea = styled(textarea)`
	width: 100%;
	height: 14rem !important;
	resize: none;
`;

const Circumstances: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="circumstances">
			{({ input }) => (
				<TractableFormGroup label={t('Accident circumstances')}>
					<TextArea large {...input} placeholder={t('CircumstancesExample')} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { Circumstances };
