import { get, map, reduce } from 'lodash';
import * as React from 'react';

import { useLocalisedDate } from '../../../utils';
import { SimpleTable, SimpleTableHeader, SimpleTableRow, TableProps } from '../../shared';

interface IProps {
	claim: any;
}

type ClaimDetailsProps = IProps & TableProps;

const optionalHeaders: any = [
	{ field: 'vehicle.vin', label: 'VIN' },
	{ field: 'vehicle.registration', label: 'Registration' },
	{ field: 'claimDetails.dateOfAccident', label: 'Date of accident' },
];

const headers = (claim: any) => {
	const optional = reduce(
		optionalHeaders,
		(acc: any, { field, label }: any) => {
			if (get(claim, field)) {
				return [...acc, label];
			}

			return acc;
		},
		[]
	);

	return ['Make', 'Model', ...optional];
};

const ClaimDetails: React.FC<ClaimDetailsProps> = ({ claim, ...restProps }) => {
	const { formatDate } = useLocalisedDate();

	const mapping: any = {
		Registration: (data: any) => data.vehicle.registration,
		VIN: (data: any) => data.vehicle.vin,
		Make: (data: any) => data.vehicle.make,
		Model: (data: any) => data.vehicle.model,
		'Date of accident': (data: any) => formatDate(data.claimDetails.dateOfAccident, true),
	};
	const rowsMapper = (values: any) => (header: string) => ({
		value: mapping[header](values),
		header,
	});

	const usedHeaders = headers(claim);
	const rows = map(usedHeaders, rowsMapper(claim));
	return (
		<SimpleTable header {...restProps}>
			<SimpleTableHeader values={usedHeaders} />
			<SimpleTableRow rows={rows} />
		</SimpleTable>
	);
};

export { ClaimDetails };
