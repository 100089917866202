import styled from 'styled-components';
import {
	BorderProps,
	ColorProps,
	LayoutProps,
	SpaceProps,
	TypographyProps,
	border,
	color,
	layout,
	space,
	typography,
} from 'styled-system';

type TextProps = SpaceProps & LayoutProps & TypographyProps & ColorProps & BorderProps;

const Text = styled.p<TextProps>`
	${space}
	${layout}
  ${typography}
  ${color}
  ${border}
`;

Text.defaultProps = {
	fontSize: 2,
};

export { Text };
