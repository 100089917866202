import * as React from 'react';

export const Extra = () => (
	<g id="empty_svg__empty_svg__EXTRA">
		<ellipse
			id="car_svg__car_svg__Ellipse_1"
			stroke="#000"
			rx={17.31}
			ry={17.15}
			transform="matrix(0 1 1 0 387.269 155.538)"
		/>
		<ellipse
			id="car_svg__car_svg__Ellipse_3"
			stroke="#000"
			rx={16.88}
			ry={17.59}
			transform="matrix(0 1 1 0 389.467 370.638)"
		/>
		<path id="car_svg__car_svg__Vector_16" stroke="#000" d="M383.311 184.968v8.656" />
		<path
			id="car_svg__car_svg__Vector_22"
			fill="#fff"
			stroke="#000"
			d="M334.938 287.108l-27.265 4.112c0 6.989 2.035 31.659 6.511 50.572l19.94 11.101.814-65.785z"
		/>
		<path
			id="car_svg__car_svg__Vector_24"
			stroke="#000"
			d="M347.251 349.829v-14.646a2.198 2.198 0 00-4.397 0v14.646a2.199 2.199 0 104.397 0z"
		/>
		<path
			id="car_svg__car_svg__Vector_68"
			stroke="#000"
			d="M347.251 275.388v-14.646a2.198 2.198 0 00-4.397 0v14.646a2.199 2.199 0 104.397 0z"
		/>
		<path id="car_svg__car_svg__Vector_45" stroke="#000" d="M368.359 447.243l9.674-49.339" />
		<path
			id="car_svg__car_svg__Vector_43"
			fill="#fff"
			stroke="#000"
			d="M334.058 387.517v-25.201l-18.469-11.154c3.694 11.154 9.85 24.788 18.469 36.355z"
		/>
		<path
			id="car_svg__car_svg__fl-mirror"
			fill="#fff"
			stroke="#000"
			d="M335.583 278.742l2.873-75.596c-12.45 13.484-29.141 44.949-30.783 80.5l27.91-4.904z"
		/>
		<path
			id="car_svg__car_svg__Vector_75"
			stroke="#000"
			d="M264.916 344.659c-.863-15.687-1.499-33.943-1.092-47.148m1.092 47.148c.095 1.725 1.953 16.954 9.28 36.799l2.73-25.874m-12.01-10.925l12.01 10.925m0-2.3l1.092-60.948m-1.092 60.948l-12.01-10.925m12.01 10.925v2.3m-13.102-61.523l14.194-5.175m-14.194 5.175c-.074 1.848.061 1.468 0 3.45m0-3.45c-.057-21.159 10.405-56.54 15.705-71.872l-1.511 66.697m0 0v3.45m-14.194 5.175l14.194-5.175"
		/>
		<path id="car_svg__car_svg__Vector_79" stroke="#000" d="M277.946 290.297l9.499-4.054m-10.554 68.349l11.433 9.554" />
		<path id="car_svg__car_svg__Vector_80" stroke="#000" d="M274.252 381.458c-.495 3.246-4.947 8.872-7.916 12.118" />
		<path id="car_svg__car_svg__Vector_83" stroke="#000" d="M279.529 202.28v20.774" />
		<path
			id="car_svg__car_svg__Vector_70"
			stroke="#000"
			d="M200.813 91.484l-15.391 90.887m62.445-90.454l12.753 90.022"
		/>
		<path id="car_svg__car_svg__Vector_82" stroke="#000" d="M167.832 202.28l.88 20.774" />
		<path id="car_svg__car_svg__Vector_81" stroke="#000" d="M174.428 380.592c.67 3.573 3.078 8.038 6.596 11.253" />
		<path
			id="car_svg__car_svg__Vector_76"
			stroke="#000"
			d="M184.147 344.592c.904-15.715 1.569-34.005 1.143-47.234m-1.143 47.234c-.1 1.728-2.046 16.985-9.719 36.866l-2.858-25.921m12.577-10.945l-12.577 10.945m0-2.304l-1.144-61.059m1.144 61.059l12.577-10.945m-12.577 10.945v2.304m13.72-61.635l-14.864-5.184m14.864 5.184c.077 1.851-.064 1.471 0 3.456m0-3.456c.06-21.198-11.028-57.218-16.579-72.579l1.715 67.395m0 0v3.456m14.864 5.184l-14.864-5.184"
		/>
		<path
			id="car_svg__car_svg__Vector_74"
			stroke="#000"
			d="M159.037 198.385V395.09c1.975 3.63 8.619 19.877 8.619 34.387 0 7.45 5.064 12.379 25.319 16.047 0 0 19.026 1.708 31.244 1.719 12.429.012 31.784-1.719 31.784-1.719 7.721-1.338 24.241-4.012 25.318-16.047 0 0 1.616-28.656 7.003-34.387V196.221"
		/>
		<path id="car_svg__car_svg__Vector_77" stroke="#000" d="M170.47 290.571l-12.313-5.194" />
		<path id="car_svg__car_svg__Vector_78" stroke="#000" d="M171.35 353.759l-12.313 10.387" />
		<path id="car_svg__car_svg__Vector_16_2" stroke="#000" d="M64.428 184.968v8.656" />
		<path
			id="car_svg__car_svg__Vector_22_2"
			fill="#fff"
			stroke="#000"
			d="M112.423 287.108l27.265 4.112c0 6.989-2.035 31.659-6.511 50.572l-19.94 11.101-.814-65.785z"
		/>
		<path
			id="car_svg__car_svg__Vector_24_2"
			stroke="#000"
			d="M100.11 349.829v-14.645a2.199 2.199 0 114.398 0v14.645a2.2 2.2 0 01-4.398 0z"
		/>
		<path
			id="car_svg__car_svg__Vector_68_2"
			stroke="#000"
			d="M100.11 275.388v-13.78a2.2 2.2 0 014.398 0v13.78a2.2 2.2 0 01-4.398 0z"
		/>
		<path id="car_svg__car_svg__Vector_45_2" stroke="#000" d="M79.428 447.097l-10-50.193" />
		<path
			id="car_svg__car_svg__Vector_43_2"
			fill="#fff"
			stroke="#000"
			d="M113.303 387.517v-25.2l18.469-11.155c-3.693 11.155-9.85 24.788-18.469 36.355z"
		/>
		<path
			id="car_svg__car_svg__fl-mirror_2"
			fill="#fff"
			stroke="#000"
			d="M111.778 278.794l-2.873-74.784c12.45 13.34 29.141 44.466 30.783 79.635l-27.91-4.851z"
		/>
		<path id="car_svg__car_svg__Vector_91" stroke="#000" d="M287.445 196.221l-7.522 6.059H168.708l-9.671-6.059" />
		<path id="car_svg__car_svg__Vector_87" stroke="#000" d="M288.324 471.047l-29.023 2.164h-68.459l-30.046-1.731" />
		<path id="car_svg__car_svg__Vector_88" stroke="#000" d="M285.686 486.195h-27.265v-12.984" />
		<path id="car_svg__car_svg__Vector_89" stroke="#000" d="M191.579 473.211v12.984" />
		<path id="car_svg__car_svg__Vector_90" stroke="#000" d="M192.019 486.195h-29.464" />
		<path
			id="car_svg__car_svg__fbumper-grille"
			stroke="#000"
			d="M259.522 39.213s-50.234.109-73.098 0m-1.881 2.932l4.998-7.79h66.52l4.999 7.485-76.517.305z"
		/>
		<path id="car_svg__car_svg__Vector_27" stroke="#000" d="M287.445 47.339H161.676" />
		<path id="car_svg__car_svg__Vector_29" stroke="#000" d="M258.421 36.952H188.06" />
		<path id="car_svg__car_svg__Vector_85" stroke="#000" d="M180.202 42.145l2.582-6.06H171.35v6.06h8.852z" />
		<path id="car_svg__car_svg__Vector_86" stroke="#000" d="M268.039 42.145l-2.582-6.06h10.719l.715 6.06h-8.852z" />
		<path id="car_svg__car_svg__Line_1303" stroke="#000" d="M0-.5h46.61" transform="matrix(1 0 0 -1 199.494 61.188)" />
		<path id="car_svg__car_svg__Line_1304" stroke="#000" d="M0-.5h43.1" transform="matrix(1 0 0 -1 202.133 56.86)" />
		<ellipse
			id="car_svg__car_svg__Ellipse_1_2"
			cx={60.09}
			cy={156.4}
			stroke="#000"
			rx={17.31}
			ry={17.15}
			transform="rotate(90 60.093 156.404)"
		/>
		<ellipse
			id="car_svg__car_svg__Ellipse_3_2"
			cx={57.89}
			cy={370.64}
			stroke="#000"
			rx={16.88}
			ry={17.59}
			transform="rotate(90 57.894 370.638)"
		/>
	</g>
);
