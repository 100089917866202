import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { signOut } from '../../../auth';
import { NavIcon, Navbar } from '../Navbar';

interface NavigationProps {
	onClick: () => void;
	header: string;
	icon: NavIcon;
}

const Wrapper = styled.div`
	width: 100%;
	background: white;
	z-index: 1;
	border-bottom: 1px solid #dadfe3;
`;

const Navigation: React.FC<NavigationProps> = ({ header, icon, onClick }) => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<Wrapper>
			<Navbar
				title={t(header)}
				navigation={{
					icon,
					onClick,
				}}
				signout={{
					text: t('Sign out'),
					onClick: signOut,
				}}
			/>
		</Wrapper>
	);
};

export { Navigation };
