import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@blueprintjs/core';
import gql from 'graphql-tag';
import * as React from 'react';
import styled from 'styled-components';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import useClientTranslations from '../../../hooks/useClientTranslations';
import { Filter } from './Filter';
import { SearchBar, Wrapper } from './styles';

const FilterWrapper = styled.div`
	display: flex;
`;
interface StatusProps {
	updateSearchTags: (params: any) => any;
	setTag: (params: any) => any;
	tag: string;
	data: {
		search: {
			value: string;
		}[];
		filters: {
			id: string;
			title: string;
			selected: boolean;
		}[];
	};
}

export const GET_CLAIM_FILTERS = gql`
	query getClaimFilters {
		search @client {
			value
		}
		filters @client {
			id
			title
			selected
		}
	}
`;

const UPDATE_PAGINATION = gql`
	mutation updatePagination($dir: String!) {
		updatePagination(dir: $dir) @client
	}
`;

const UPDATE_SEARCH_TAGS = gql`
	mutation updateSearchTags($values: [String]) {
		updateSearchTags(values: $values) @client
	}
`;

const Status: React.FC<StatusProps> = ({ tag, setTag }) => {
	const { t } = useClientTranslations();
	const [updateSearchTags] = useMutation(UPDATE_SEARCH_TAGS);
	const [savePagination] = useMutation(UPDATE_PAGINATION);
	const { data, loading } = useQuery(GET_CLAIM_FILTERS);

	if (loading) return null;

	const { filters, search } = data;

	const activeFilters = filters
		.filter((f) => f.selected)
		.map((f) => f.title)
		.join(', ');
	const searchContent = search.map((s) => s.value).join(', ');
	logEvent('Find claim - filter', { filterCategory: activeFilters });
	logEvent('Find claim - search', { searchCategory: searchContent });
	return (
		<Wrapper>
			<FilterWrapper>
				{filters.map((filter) => (
					<Filter key={filter.id} {...filter} lastSelected={filters.filter((f) => f.selected).length === 1} />
				))}
			</FilterWrapper>
			<SearchBar
				placeholder={t('searchPlaceholder')}
				values={search.map((s) => s.value)}
				leftIcon="search"
				inputValue={tag}
				onInputChange={(e: any) => setTag(e.target.value)}
				rightElement={
					(!(search.length === 0) || tag !== '') && (
						<Button
							icon="cross"
							minimal
							onClick={() => {
								updateSearchTags({ variables: { values: [] } });
								setTag('');
							}}
						/>
					)
				}
				onChange={(values: string[]) => {
					updateSearchTags({ variables: { values } });
					setTag('');
					savePagination({ variables: { dir: 'reset' } });
				}}
			/>
		</Wrapper>
	);
};

export { Status };
