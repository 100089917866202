import * as React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';

export interface Props {
	readonly label: string;
	readonly vertical?: boolean;
}

export const Detail: React.FC<Props> = ({ label, vertical, children }) => {
	return (
		<Box gap={8} orientation={vertical ? 'vertical' : 'horizontal'}>
			<Text typography="small" bold color="#45677E">
				{label}:
			</Text>
			<Text typography="small" color="#45677E">
				{children || '--'}
			</Text>
		</Box>
	);
};
