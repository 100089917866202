export interface Decimal {
	readonly symbol: string;
	readonly options: {
		readonly minimumFractionDigits: number;
		readonly maximumFractionDigits: number;
	};
}

export function formatDecimal(num: number, decimalObject?: Decimal): string {
	if (!decimalObject) return num.toFixed(2);
	return new Intl.NumberFormat(decimalObject.symbol, decimalObject.options).format(num);
}

export function formatInteger(num: number, locale?: string): string {
	if (!locale) return num.toFixed(0);
	return new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(num);
}
