import { OperationType } from '../operationToFill';

export { Quickview } from './Quickview';
export * from './SelectMenu';
export { Selector } from './Selector';

export * from '../../../../common/types/selectorType';

export type Panels = {
	[key: string]: {
		operation: OperationType;
	};
};
