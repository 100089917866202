import * as React from 'react';

export const BLWing = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="BLWING"
		stroke="#000"
		d="M52.7 399.907c.27-.047-.282.052 0 0zm0 0c20.26 0 35.114-10.61 34.731-31.014-.413-22.035-21.087-28.564-27.29-28.564l-.928-7.345 47.652 27.34 30.184-16.322c-3.308 16.322-20.26 53.048-21.087 71.819l-2.068 22.852-4.134.816-4.135 1.632v-26.116c-6.203 4.897-13.397 16.813-14.059 26.933l-12.404 5.304H63.863c-2.067-1.768-6.45-7.263-7.443-15.098l-3.72-32.237z"
		{...props}
	/>
);
