"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRole = void 0;
/**
 * Roles that Estimating product supports
 */
var UserRole;
(function (UserRole) {
    UserRole["PolicyHolder"] = "PolicyHolder";
    UserRole["ClaimHandler"] = "ClaimHandler";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
