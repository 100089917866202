import * as React from 'react';

import { VrmCheckOutcome } from '@tractable/estimating-local-constants';

import { TextWithStatusIcon } from './TextWithStatusIcon';
import { StatusIcons } from './types';

const statusMapping: { [key: string]: any } = {
	[VrmCheckOutcome.MATCHED]: StatusIcons.Check,
	[VrmCheckOutcome.NOT_MATCHED]: StatusIcons.Cross,
	[VrmCheckOutcome.UNABLE_TO_ASSESS]: StatusIcons.Unavailable,
};

export interface VrmCheckProps {
	readonly title: string;
	readonly outcome: VrmCheckOutcome;
}

export const VrmCheckIndicator: React.FC<VrmCheckProps> = ({ title, outcome }) => {
	return <TextWithStatusIcon icon={statusMapping[outcome]} title={title} id="vrm-check-outcome" />;
};
