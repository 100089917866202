import styled from 'styled-components';

interface IProps {
	gridArea?: string;
	stretch?: boolean;
	padding?: any;
	background?: string;
	sticky?: string;
	centerText?: boolean;
}

const Wrapper = styled.div<IProps>`
  display: grid;
  position: ${(props) => props.sticky && 'sticky'};
  top: ${(props) => props.sticky};
  grid-area: ${(props) => props.gridArea}};
  grid-row-gap: 0.5rem;
  background: ${(props) => props.background};
  padding: ${(props) => (props.padding ? props.padding : false)};
  text-align: ${(props) => (props.centerText ? 'center' : null)};
  align-items: center;
  height: ${(props) => props.stretch && '100%'};
  h2 {
    font-weight: normal;
  }
`;

export { Wrapper };
