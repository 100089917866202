import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Text } from '../Primitives';
import { STAGES, useImageDownloader } from './useImageDownloader';

interface IProps {
	id: string;
}

const Generator = styled(Text as any)`
	color: blue;
	cursor: pointer;
	margin: 0;
`;

const ImageDownloader: React.FC<any> = ({ id }: IProps) => {
	const { url, stage, getIndicatorProps } = useImageDownloader(id);
	const { t } = useTranslation('EstimatingPortal');
	useEffect(() => {
		if (stage === STAGES.DONE) {
			window.location.assign(url);
		}
	}, [stage]);

	return (
		<Box m={2}>
			{stage === STAGES.READY && <Generator {...getIndicatorProps()}>{t('ZIP images')}</Generator>}
			{stage === STAGES.PROCESSING && <Text {...getIndicatorProps()} m={0}>{`${t('Generating')}...`}</Text>}
			{stage === STAGES.DONE && (
				<Generator as="a" {...getIndicatorProps()}>
					{t('Download all images')}
				</Generator>
			)}
			{stage === STAGES.ERROR && <Text {...getIndicatorProps()}>Error: 42</Text>}
		</Box>
	);
};

export { ImageDownloader };
