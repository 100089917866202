import * as React from 'react';

function SvgDown({ fill = '#2A4759' }: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="12px" height="12px" viewBox="0 0 13 13" fill="none">
			<path
				d="M11.592 3.435a1.108 1.108 0 00-.77-.31c-.29 0-.567.112-.772.31L6.123 7.213 2.251 3.435a1.108 1.108 0 00-.771-.31c-.29 0-.567.112-.772.31a1.066 1.066 0 00-.323.758 1.045 1.045 0 00.323.758l4.638 4.525a1.096 1.096 0 00.777.315 1.118 1.118 0 00.777-.315l4.692-4.525a1.065 1.065 0 00.324-.758 1.045 1.045 0 00-.324-.758z"
				fill={fill}
			/>
		</svg>
	);
}

export default SvgDown;
