import * as React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { getSeparator } from './getSeperator';
import { useUpdateInput } from './useUpdateInput';

interface InputProps {
	$isEditing: boolean;
	width: string;
	$textAlign: string;
}
const Input = styled(MaskedInput)<InputProps>`
	width: ${({ width }) => width};
	text-align: ${({ $textAlign }) => $textAlign};
	border: 1px solid #b8c8d4;
	border-radius: 2px;
	color: #0b1f2c;
	font-weight: normal;
	font-family: Rubik, sans-serif;
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	background-color: ${({ $isEditing }) => ($isEditing ? 'white' : '#F5F5F5')};
	&:hover {
		border: 1px solid #a0b4c3;
	}
	&:focus {
		border: 1px solid #a0b4c3;
		outline: 0;
	}
`;

export interface Props {
	value: string;
	width?: string;
	localeSymbol?: string;
	updateField?: (value: string) => void;
	textAlign?: string;
	freetext?: boolean;
	dataTestId?: string;
}

const defaultMaskOptions = {
	prefix: '',
	allowDecimal: true,
	includeThousandsSeparator: false,
	decimalLimit: 2,
	allowNegative: false,
	allowLeadingZeroes: false,
};

export const EditableField: React.FC<Props> = ({
	value,
	updateField,
	localeSymbol,
	freetext = false,
	textAlign = 'right',
	width = '70%',
	dataTestId,
}) => {
	const decimalSymbol = localeSymbol ? getSeparator(localeSymbol, 'decimal') : undefined;

	const { isEditing, currentValue, handleSave, onFocus, handleKeyDown, setIsEditing, handleOnChange } = useUpdateInput({
		value,
		decimalSymbol,
		updateField,
		freetext,
	});

	const mask = createNumberMask({
		...defaultMaskOptions,
		decimalSymbol,
	});

	return (
		<Input
			type="text"
			mask={freetext ? false : mask}
			width={width}
			$textAlign={textAlign}
			$isEditing={isEditing}
			value={currentValue}
			onBlur={handleSave}
			onFocus={onFocus}
			onKeyDown={handleKeyDown}
			onClick={() => setIsEditing(true)}
			onChange={(e) => handleOnChange(e.target.value)}
			data-test-id={dataTestId}
		/>
	);
};
