import { useEffect, useState } from 'react';

import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { AssetType } from '../../../shared/AssetType';
import { useGetClaimQuery } from '../../hooks/generated/useGetClaim.generated';

const useGetPdfAsset = (id: string) => {
	const [asset, setAsset] = useState(null);
	const { config } = useClientConfigCtx();
	const { loading, data, error, stopPolling } = useGetClaimQuery({
		variables: { id },
		fetchPolicy: 'network-only',
		pollInterval: 10000,
	});

	useEffect(() => {
		if (!loading && !error && data) {
			// Check if the claim is in a published state where a PDF can be available
			const pdfPublished = !!data.claim.claimDetails?.isPublishedStage;
			if (pdfPublished) {
				stopPolling();
				// If it is a payee journey and default pdf is ania we give precedence to existing 3p pdf
				if (config.enablePayeeInfo && config.defaultPdfType === 'ania') {
					const aniaEstimatePdf = data.claim.assets.find(
						(asset) => asset.type === AssetType.ANIA_ESTIMATE_PDF && asset.valid
					);
					if (!aniaEstimatePdf) {
						setAsset(data.claim.claimDetails?.validAsset);
					} else {
						setAsset(aniaEstimatePdf);
					}
				} else {
					// If a PDF exists then get the valid PDF to display; otherwise keep the status as 'PROCESSING'
					setAsset(data.claim.claimDetails?.validAsset);
				}
			}
		}
	}, [data, loading]);

	return {
		error,
		asset,
	};
};

export { useGetPdfAsset };
