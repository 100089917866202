import * as React from 'react';

export const RRockerpanel = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RROCKERPANEL"
		stroke="#000"
		d="M391.666 310.479l-4.397 29.863 7.936.433.419-155.374-4.45.161.492 124.917z"
		{...props}
	/>
);
