import * as React from 'react';

function SvgCrossCircle() {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.99967 0.333496C3.31301 0.333496 0.333008 3.3135 0.333008 7.00016C0.333008 10.6868 3.31301 13.6668 6.99967 13.6668C10.6863 13.6668 13.6663 10.6868 13.6663 7.00016C13.6663 3.3135 10.6863 0.333496 6.99967 0.333496ZM6.99967 12.3335C4.05967 12.3335 1.66634 9.94016 1.66634 7.00016C1.66634 4.06016 4.05967 1.66683 6.99967 1.66683C9.93967 1.66683 12.333 4.06016 12.333 7.00016C12.333 9.94016 9.93967 12.3335 6.99967 12.3335ZM9.39301 3.66683L6.99967 6.06016L4.60634 3.66683L3.66634 4.60683L6.05967 7.00016L3.66634 9.3935L4.60634 10.3335L6.99967 7.94016L9.39301 10.3335L10.333 9.3935L7.93967 7.00016L10.333 4.60683L9.39301 3.66683Z"
				fill="#BF332E"
			/>
		</svg>
	);
}

export default SvgCrossCircle;
