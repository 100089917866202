"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIRECT_ASSURANCE = exports.AXA_FRANCE = void 0;
exports.AXA_FRANCE = [
    'partCostAdjustmentsTotal',
    'additionalCostsTotal',
    'paintMaterialCost',
    'replacementPartsCost',
];
exports.DIRECT_ASSURANCE = [
    'partCostAdjustmentsTotal',
    'additionalCostsTotal',
    'paintMaterialCost',
    'replacementPartsCost',
];
