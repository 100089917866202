// HACK: this allows the codegen to use the enum values from constants in the typing
// used in codegen.ts

export {
	TaxStrategies,
	EstimateOperationTriggerType,
	EstimateOperationType,
	EstimateAdjustmentType,
	EstimateAdjustmentTrigger,
	AdjustmentStrategyName,
} from '@tractable/estimating-local-constants';
