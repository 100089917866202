import { FormGroup } from '@blueprintjs/core';
import styled from 'styled-components';

export const TractableFormGroupSmall = styled(FormGroup)`
	justify-content: space-between;
	div {
		width: 113px;
	}
	label {
		line-height: 1 !important;
		width: 20rem;
	}
`;
