import * as React from 'react';

function SvgWarningCircleRed(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="10px" height="10px" viewBox="0 0 10 10" fill="none" {...props} overflow="visible">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.58398 6.24998H5.41732V7.08331H4.58398V6.24998ZM4.58398 2.91665H5.41732V5.41665H4.58398V2.91665ZM4.99648 0.833313C2.69648 0.833313 0.833984 2.69998 0.833984 4.99998C0.833984 7.29998 2.69648 9.16665 4.99648 9.16665C7.30065 9.16665 9.16732 7.29998 9.16732 4.99998C9.16732 2.69998 7.30065 0.833313 4.99648 0.833313ZM5.00065 8.33331C3.15898 8.33331 1.66732 6.84165 1.66732 4.99998C1.66732 3.15831 3.15898 1.66665 5.00065 1.66665C6.84232 1.66665 8.33398 3.15831 8.33398 4.99998C8.33398 6.84165 6.84232 8.33331 5.00065 8.33331Z"
				fill="#BF332E"
			/>
		</svg>
	);
}

export default SvgWarningCircleRed;
