import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';

import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			stage
			status
			processabilityTriageOutcome
			claimDetails {
				claimId
				isPublishedStage
				validAsset {
					id
					path
					type
					valid
				}
			}
			statusHistory {
				fromStage
				fromStatus
				toStage
				toStatus
			}
			assets {
				path
				type
				valid
			}
		}
	}
`;

const STATUS: any = {
	PROCESSING: 'PROCESSING',
	FAILED_OTL: 'FAILED_OTL',
	FAILED_OOH: 'FAILED_OOH',
	FAILED_OTHER: 'FAILED_OTHER',
	DONE: 'DONE',
};

const useAssetViewer = (id: string) => {
	const [status, setStatus] = useState(STATUS.PROCESSING); // Initialise status as 'PROCESSING'
	const [asset, setAsset] = useState(null);
	const { config } = useClientConfigCtx();
	const oosRenderEstimate = config.ui?.oosRenderEstimate;

	const { loading, data, error, stopPolling } = useQuery(GET_CLAIM, {
		variables: { id },
		fetchPolicy: 'network-only',
		pollInterval: 10000,
	});

	useEffect(() => {
		if (!loading && !error) {
			const overrideShowPdf = oosRenderEstimate?.find((reason) => reason === data?.claim.processabilityTriageOutcome);
			// Check if status indicates Failure; if it hasnt failed then check PDF logic to display
			if (overrideShowPdf) {
				stopPolling();
				setStatus(STATUS.DONE); // Assume status is DONE since PDF exists
				setAsset(data.claim.claimDetails?.validAsset);
			} else if (
				data.claim.status === 'FAILED_OBVIOUS_TOTAL_LOSS' ||
				data.claim?.processabilityTriageOutcome === 'OBVIOUS_TOTAL_LOSS'
			) {
				setStatus(STATUS.FAILED_OTL);
			} else if (
				data.claim.status === 'FAILED_OUT_OF_HOURS' ||
				data.claim?.processabilityTriageOutcome === 'OUT_OF_HOURS'
			) {
				setStatus(STATUS.FAILED_OOH);
			} else if (data.claim.status.includes('FAIL')) {
				setStatus(STATUS.FAILED_OTHER);
			} else {
				// Check if the claim is in a published state where a PDF can be available
				const pdfExists = data.claim.claimDetails.isPublishedStage;
				// If a PDF exists then get the valid PDF to display; otherwise keep the status as 'PROCESSING'
				if (pdfExists) {
					stopPolling();
					setStatus(STATUS.DONE); // Assume status is DONE since PDF exists
					setAsset(data.claim.claimDetails?.validAsset);
				}
			}
		}
	}, [data, loading, error]);

	const getStatusProps = () => {
		if (status === STATUS.FAILED_OTL || status === STATUS.FAILED_OOH)
			return {
				icon: 'TL',
				description: 'This claim will not be estimated by Tractable',
			};

		if (status === STATUS.FAILED_OTHER)
			return {
				icon: 'TL',
				description: 'This claim cannot be estimated by Tractable',
			};

		if (status === STATUS.PROCESSING)
			return {
				icon: 'PROCESSING',
				description: 'The estimate will appear here if Tractable can process this claim',
			};
	};

	return {
		error,
		getStatusProps,
		asset,
	};
};

export { useAssetViewer };
