export type StripRefit = { hours?: number; cost?: number };
export type PaintMaterial = { cost?: number };
export type PaintRepair = StripRefit;
export type PanelRepair = StripRefit;
export type AuxiliaryElements = { cost?: number };
export type ReplacementParts = { cost?: number };

export { LaborRate } from './LaborRate';
export { EstimateDetails } from './EstimateDetails';
export * from './Section';
export { SectionRow } from './SectionRow';
export { PartRow } from './PartRow';
export { GeneralCost } from './GeneralCost';
export { GeneralHours } from './GeneralHours';
export * from './Operation';
export * from './formatDecimal';
export { OperationRow } from './OperationRow';

export interface Part {
	id: string;
	paintMaterial?: PaintMaterial;
	stripRefit?: StripRefit;
	paintRepair?: PaintRepair;
	panelRepair?: PanelRepair;
	auxiliaryElements?: AuxiliaryElements;
	replacementParts?: ReplacementParts;
}
