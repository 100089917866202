import * as React from 'react';
import styled from 'styled-components';

import SvgErrorRound from './Icons/ErrorRound';
import { Text } from './Text';

interface ErrorNotificationProps {
	text: string;
}

const ErrorIconWrapper = styled.span`
	vertical-align: -0.125em;
`;

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({ text }) => {
	return (
		<Text typography="micro" color="#D24559">
			<ErrorIconWrapper>
				<SvgErrorRound />
			</ErrorIconWrapper>{' '}
			{text}
		</Text>
	);
};
