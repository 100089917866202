import { gql } from '@apollo/client';

function claimsGqlQuery(hasCarOwner = false) {
	return `claims {
				id
				createdAt
				claimDetails {
					claimId
					user {
						givenName
						familyName
					}
					nextStageUrl
					stage {
						name
						title
					}
				}
				vehicle {
					vin
					name
					registration
				}
				${
					hasCarOwner
						? `carOwner {
					firstName
					lastName
					email
					phoneNumber
				}`
						: ''
				}
				bodyShop {
					reference
					name
					address {
						street
						city
						province
						postalCode
						country
					}
				}
			}
			hasMore`;
}

export const GET_CLAIMS = gql`
	query getClaims($filters: [FilterInput], $pagination: PaginationInput, $search: [SearchInput], $type: String) {
		claims(filters: $filters, pagination: $pagination, search: $search, type: $type) {
			${claimsGqlQuery()}
		}
	}
`;

export const GET_CCP_CLAIMS = gql`
	query getClaims($filters: [FilterInput], $pagination: PaginationInput, $search: [SearchInput], $type: String) {
		ccpClaims(filters: $filters, pagination: $pagination, search: $search, type: $type) {
			${claimsGqlQuery(true)}
		}
	}
`;
