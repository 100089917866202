import { ApolloError, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { NotifiedUser } from './NotifiedUser';

const DEFAULT_VALUE: NotifiedUser[] = [];

type GetLatestClaimUsersResult = {
	latestClaimUsers: NotifiedUser[];
	loading: boolean;
	error?: ApolloError;
};

/**
 * Fetches the notified users that were specified for the latest claim
 * the currently logged in user created.
 *
 * 1. For the user signed in to the portal
 * 2. Get the latest claim they created
 * 3. Return the notified users on that claim.
 */
export function useGetLatestClaimUsers(): GetLatestClaimUsersResult {
	const { data, loading, error } = useQuery<{ latestClaimUsers: NotifiedUser[] }>(
		gql`
			query getLatestClaimUsers {
				latestClaimUsers {
					id
					email
				}
			}
		`,
		{
			fetchPolicy: 'no-cache',
		}
	);

	const latestClaimUsers = data?.latestClaimUsers || DEFAULT_VALUE;

	return { latestClaimUsers, loading, error };
}
