import * as React from 'react';

export const RRDoor = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RRDOOR"
		stroke="#000"
		d="M391.666 282.065h-9.942l-26.861-1.016-52.467 7.752c.138 10.319 3.784 42.376 7.916 55.004l30.1 16.879 48.176-27.699 3.078-22.506v-28.414z"
		{...props}
	/>
);
