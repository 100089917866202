import { Icon, Tooltip } from '@blueprintjs/core';
import * as React from 'react';

import { Wrapper } from './styles';

interface CostProps {
	primary?: boolean;
	currency: any;
	value: number;
	title: string;
	tooltip?: any;
}

const Cost: React.FC<CostProps> = ({ value, title, tooltip, primary, currency }) => (
	<Wrapper predicted={primary}>
		<span className="title">{title}</span>
		{tooltip && (
			<Tooltip content={tooltip}>
				<Icon icon="info-sign" iconSize={15} />
			</Tooltip>
		)}
		<span className="value">{value && value.toLocaleString(currency.symbol, currency.options)}</span>
	</Wrapper>
);

export { Cost };
