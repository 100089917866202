import * as React from 'react';

export const LHeadlamp = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="LHEADLAMP"
		stroke="#000"
		d="M80.856 92.89l6.622 30.148 7.45 9.236-2.639-36.787-6.053-.558-5.38-2.04z"
		{...props}
	/>
);
