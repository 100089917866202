import * as React from 'react';

export const RMirror = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RMIRROR"
		stroke="#000"
		d="M331.081 226.178c2.748 1.235 6.55-1.302 6.55-1.302.138-6.171.825-19.155.825-21.73-2.886 3.219-7.833 8.853-13.193 18.913 0 0 3.069 2.884 5.818 4.119z"
		{...props}
	/>
);
