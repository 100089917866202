import * as React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import './ImageViewer.css';

export interface ImageViewerProps {
	images: ReactImageGalleryItem[];
}

const ImageGalleryConfig = {
	showPlayButton: false,
};

export const ImageViewer: React.FC<ImageViewerProps> = ({ images }) => {
	return <ImageGallery items={images} {...ImageGalleryConfig} />;
};
