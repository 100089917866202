import * as React from 'react';

export const Roof = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="car_svg__car_svg__ROOF"
		stroke="#000"
		d="M187.096 266.112c-1.368-7.306-4.752-18.388-4.752-18.388s25.637-8.223 42.216-8.223 41.777 7.79 41.777 7.79-2.918 11.521-4.313 18.821c-5.881 30.77-1.617 80.413-1.617 80.413s-9.164 8.1-36.656 8.1c-27.491 0-35.577-8.1-35.577-8.1s4.684-49.625-1.078-80.413z"
		{...props}
	/>
);
