"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssessorType = void 0;
var AssessorType;
(function (AssessorType) {
    AssessorType["POLICYHOLDER"] = "POLICYHOLDER";
    AssessorType["INTERNAL_EXPERT"] = "EXPERT";
    AssessorType["AI"] = "AI";
    AssessorType["EXTERNAL_EXPERT"] = "EXTERNAL_EXPERT";
    AssessorType["SYSTEM"] = "SYSTEM";
    AssessorType["SPECIALIST"] = "SPECIALIST";
    AssessorType["USER"] = "USER";
})(AssessorType = exports.AssessorType || (exports.AssessorType = {}));
