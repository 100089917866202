import { map } from 'lodash';
import * as React from 'react';

import { TableRow } from '..';

interface SimpleTableRowProps {
	rows: {
		value: string;
		header: string;
	}[];
}

const SimpleTableRow: React.FC<SimpleTableRowProps> = ({ rows, ...restProps }) => (
	<TableRow {...restProps}>
		{map(rows, (row: { value: string; header: string }, i: number) => (
			<td key={i} data-test-id={`${row.header}-text`}>
				{row.value}
			</td>
		))}
	</TableRow>
);

export { SimpleTableRow };
