"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidLabourRateInput = exports.LabourRateInputType = void 0;
var LabourRateInputType;
(function (LabourRateInputType) {
    LabourRateInputType["REGIONAL_LABOUR_RATE"] = "regionalLabourRate";
    LabourRateInputType["USER_DEFINED_LABOUR_RATE"] = "userDefinedLabourRate";
    LabourRateInputType["STATIC_LABOUR_RATE"] = "staticLabourRate";
})(LabourRateInputType = exports.LabourRateInputType || (exports.LabourRateInputType = {}));
function isValidLabourRateInput(labourRateInput) {
    return Object.values(LabourRateInputType).includes(labourRateInput);
}
exports.isValidLabourRateInput = isValidLabourRateInput;
