import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface QuestionProps {
	label: string;
	value: any;
}

const StyledP = styled.p`
	display: grid;
	grid-template-columns: 80% auto;
	margin: 0.2rem 0;
	align-items: center;
	.label {
		font-weight: 600;
	}
`;

const Question: React.FC<QuestionProps> = ({ label, value }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		value && (
			<StyledP>
				<span className="label">{t(label)}</span>
				{t(value)}
			</StyledP>
		)
	);
};

export { Question };
