"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalvageStrategy = void 0;
var SalvageStrategy;
(function (SalvageStrategy) {
    SalvageStrategy["FullRetail"] = "FULL_RETAIL";
    SalvageStrategy["SalvageThresholds"] = "SALVAGE_THRESHOLDS";
    SalvageStrategy["SalvageByMake"] = "SALVAGE_BY_MAKE";
    SalvageStrategy["PolicyMax"] = "POLICY_MAX";
})(SalvageStrategy = exports.SalvageStrategy || (exports.SalvageStrategy = {}));
