import { map } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

interface ListProps {
	items: {
		key: string;
		label: string;
		value: string;
	};
	itemRenderer: any;
}

const Wrapper = styled.div``;

const List: React.FC<ListProps> = ({ items, itemRenderer }) => (
	<Wrapper>{map(items, (value) => itemRenderer(value))}</Wrapper>
);

export { List };
