import { useQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import * as React from 'react';

import { Columns } from '../../../../common';
import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';
import { useLocalisedDate } from '../../../utils';
import { GenericError } from '../../GenericError';
import { GET_CCP_CLAIMS, GET_CLAIMS } from './GQLQueries';
import { Header } from './Header';
import { NoData } from './NoData';
import { Pagination } from './Pagination';
import { Row } from './Row';
import { StyledTable } from './styles';
import { Claims } from './types';

interface ClaimProps {
	claims?: Claims;
	ccpClaims?: Claims;
}
interface TableProps {
	search: {
		value: string;
	}[];
	filters: {
		id: string;
		title?: string;
		selected: string;
	}[];
	pagination: {
		offset: number;
		limit: number;
	};
}

const Table: React.FC<TableProps> = ({ filters, pagination, search }) => {
	const { formatDate } = useLocalisedDate();
	const {
		config: {
			ui: { tableColumns = Columns, fetchCcpClaims },
		},
	} = useClientConfigCtx();

	const GQLQueryToUse = fetchCcpClaims ? GET_CCP_CLAIMS : GET_CLAIMS;

	const { loading, error, data } = useQuery<ClaimProps>(GQLQueryToUse, {
		variables: {
			filters,
			pagination,
			search,
		},
		fetchPolicy: 'cache-and-network',
	});

	if (loading) return <Spinner />;
	if (error) return <GenericError data={error} />;

	const { claims = [], hasMore = false } = data.ccpClaims ?? data.claims;

	if (claims.length < 1) return <NoData />;

	return (
		<div style={{ overflowX: 'scroll' }}>
			<StyledTable id="requestsTable">
				<Header columns={tableColumns} />
				<tbody id="requestsTableBody">
					{claims.map((claim) => (
						<Row columns={tableColumns} key={claim.id} formatDate={formatDate} {...claim} />
					))}
					<Pagination hasMore={hasMore} />
				</tbody>
			</StyledTable>
		</div>
	);
};
export { Table };
