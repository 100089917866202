import styled from 'styled-components';
import {
	BorderProps,
	ColorProps,
	LayoutProps,
	SpaceProps,
	TypographyProps,
	border,
	color,
	layout,
	space,
	typography,
} from 'styled-system';

type ButtonProps = SpaceProps & LayoutProps & TypographyProps & ColorProps & BorderProps;

const Button = styled.button<ButtonProps>`
	cursor: pointer;
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	${space}
	${layout}
  ${typography}
  ${color}
  ${border}
`;

Button.defaultProps = {
	fontSize: 2,
	width: 6,
	height: 4,
	borderRadius: 0,
	m: 0,
};

const Submit = styled(Button as any)``;

Submit.defaultProps = {
	color: 'white',
	bg: 'blues.0',
};

export { Button, Submit };
