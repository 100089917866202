import * as React from 'react';
import styled from 'styled-components';

import { Gallery, SimpleWidget } from '../../shared';
import { useVideosGallery } from './useVideosGallery';

interface VideosGalleryProps {
	id: string;
	widget: any;
}

const Video = styled.video`
	width: 100%;
	height: auto;
	cursor: pointer;
`;

const VideosGallery: React.FC<VideosGalleryProps> = ({ id, widget }) => {
	const { videos } = useVideosGallery(id);

	if (!videos) return null;

	return (
		<SimpleWidget {...widget}>
			<Gallery columns={1} rows={videos.length} customHeight="auto">
				{({ onMouseOut, onMouseOver }) =>
					videos.map((videoProps) => {
						const { src, ...vidProps } = videoProps({
							onMouseOut,
							onMouseOver,
						});
						return (
							<Video key={vidProps.key} {...vidProps} controls>
								<source src={src} />
							</Video>
						);
					})
				}
			</Gallery>
		</SimpleWidget>
	);
};

export { VideosGallery };
