import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';
import { Panel, ValidFlag } from '@tractable/estimating-local-constants';

import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';
import { SelectorType, SubmitOutput } from '../../shared/Quickview';
import { filterValidEstimateParts } from '../../shared/utils';
import { extractPanelOperations } from '../utils/extractPanelOperations';
import { useGetParts } from './useGetParts';
import { useUpdatePartOperation } from './useUpdatePartOperation';

export const useQuickview = (claim: any, setIsDirty: any, validFlags: ValidFlag[]) => {
	const { config } = useClientConfigCtx();
	const { selectors } = config.ui;
	const { getParts, parts, loading, error: errorGetParts } = useGetParts(claim);
	const [errorFetchingParts, setErrorFetchingParts] = useState<string>(null);
	const {
		addPartOperation,
		loading: loadingAddPart,
		error: errorAddPart,
	} = useUpdatePartOperation(setIsDirty, validFlags);
	const [errorAddingPart, setErrorAddingPart] = useState<string>(null);

	const { t } = useTranslation('EstimatingPortal');

	useEffect(() => {
		if (errorGetParts) {
			setErrorFetchingParts(t('Unable to load parts. Please try again.'));
			logEvent('Edit - error', {
				errorMessage: errorGetParts.message,
				claimId: claim.id,
			});
		} else {
			setErrorFetchingParts(null);
		}
	}, [errorGetParts]);

	useEffect(() => {
		if (errorAddPart) {
			logEvent('Edit - error', {
				errorMessage: errorAddPart.message,
				claimId: claim.id,
			});
			setErrorAddingPart(t('Unable to add part. Please try again.'));
		} else {
			setErrorAddingPart(null);
		}
	}, [errorAddPart]);

	const panels = selectors.map((selector: SelectorType) => selector.panel);

	const quickviewProps = (claim: any) => {
		const validParts = filterValidEstimateParts(panels, claim.estimate.parts);
		return {
			inputPanels: extractPanelOperations(validParts),
		};
	};

	const selectMenuProps = (claim: any, panel: Panel, close: () => void) => ({
		onSubmit: (submitOutput: SubmitOutput) => {
			logEvent('Edit - add part', {
				panelName: panel,
				partId: submitOutput.partId,
				operation: submitOutput.operation,
				claimId: claim.id,
			});
			// TODO: how do we reset the addpart error once we've closed the modal?
			addPartOperation(claim, {
				panel,
				partSelection: submitOutput.partId,
				partOptions: submitOutput.optionIds,
				operation: submitOutput.operation,
			}).then(() => {
				close();
			}); // pass in part info fo failure case);
		},
		isLoadingParts: loading,
		isLoadingAddPart: loadingAddPart,
		errorFetchingParts,
		errorAddingPart,
		parts: (parts?.getParts ?? []) as any,
		selectedParts: claim.estimate?.parts?.map((p: any) => p.partSelection) ?? [],
	});

	return {
		quickviewProps,
		selectMenuProps,
		selectors: map(selectors, (s) => ({
			...s,
			onClick: () => getParts(s.panel),
		})),
	};
};
