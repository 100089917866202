import * as React from 'react';

import { Box } from '../Box';
import SvgDown from '../Icons/Down';
import SvgUp from '../Icons/Up';
import { Spinner } from '../Spinner';
import { Text } from '../Text';
import { TBottomData, TData, TRow, TTopData } from './Table';
import * as remove from './delete.png';
import { Currency, formatCurrencyWithoutSymbol } from './formatCurrency';

export interface SectionProps {
	description: string;
	panel?: string;
	cost: number;
	showMainPartSubtitle?: boolean;
	isOpen: boolean;
	isEditing?: boolean;
	isDeleting?: boolean;
	operation?: () => React.ReactNode;
	toggleSection: () => void;
	removeSection?: () => void;
	dataTestId?: string;
	currency: Currency;
}

export const Section: React.FC<SectionProps> = ({
	description,
	panel,
	cost,
	isOpen,
	operation,
	toggleSection,
	removeSection,
	showMainPartSubtitle = true,
	isEditing = false,
	isDeleting = false,
	dataTestId,
	currency,
	children,
}) => {
	const Icon = children ? (isOpen ? SvgUp : SvgDown) : null;

	const onSectionRemove = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		removeSection && removeSection();
	};

	return (
		<>
			{!showMainPartSubtitle ? (
				<TRow onClick={toggleSection} cursor="pointer">
					<TData align="center">{Icon && <Icon />}</TData>
					<TData colSpan={3}>
						<Text typography="standard" color="#0B1F2C">
							{description}
						</Text>
					</TData>
					<TData colSpan={2}>{operation && operation()}</TData>
					<TData align="right">
						<Text typography="standard" color="#0B1F2C" data-test-id={dataTestId}>
							{formatCurrencyWithoutSymbol(cost, currency)}
						</Text>
					</TData>
					<TData>
						{isEditing && removeSection && (
							<Box alignItems="center" justifyContent="center" onClick={(e) => !isDeleting && onSectionRemove(e)}>
								{isDeleting ? (
									<Spinner width="16px" />
								) : (
									// eslint-disable-next-line jsx-a11y/alt-text
									<img width="16px" height="16px" src={remove} />
								)}
							</Box>
						)}
					</TData>
				</TRow>
			) : (
				<>
					<TRow onClick={toggleSection} cursor="pointer" height="30px" data-test-id={dataTestId}>
						<TTopData align="center">{Icon && <Icon />}</TTopData>
						<TTopData colSpan={6}>
							<Text typography="standard" color="#0B1F2C">
								{description}
							</Text>
						</TTopData>
						<TTopData>
							{isEditing && removeSection && (
								<Box
									alignItems="center"
									justifyContent="center"
									onClick={(e) => !isDeleting && onSectionRemove(e)}
									data-test-id={`${dataTestId}-delete-button`}
								>
									{isDeleting ? (
										<Spinner width="16px" />
									) : (
										// eslint-disable-next-line jsx-a11y/alt-text
										<img width="16px" height="16px" src={remove} />
									)}
								</Box>
							)}
						</TTopData>
					</TRow>
					<TRow onClick={toggleSection} cursor="pointer" height="30px">
						<TBottomData />
						<TBottomData colSpan={2}>
							<Text typography="standard" color="#676767">
								{panel}
							</Text>
						</TBottomData>
						<TBottomData colSpan={3}>{operation && operation()}</TBottomData>
						<TBottomData align="right">
							<Text typography="standard" color="#0B1F2C">
								{formatCurrencyWithoutSymbol(cost, currency)}
							</Text>
						</TBottomData>
						<TBottomData />
					</TRow>
				</>
			)}
			{isOpen && children}
		</>
	);
};
