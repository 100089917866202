import * as React from 'react';

function SvgUnavailable(props: React.SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width="17px" height="17px" viewBox="0 0 17 17" fill="none" {...props}>
			<circle cx={6.911} cy={6} fill="#fff" r={5.625} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.99935 16.8333C7.84657 16.8333 6.76324 16.6146 5.74935 16.1771C4.73546 15.7396 3.85352 15.1458 3.10352 14.3958C2.35352 13.6458 1.75977 12.7639 1.32227 11.75C0.884766 10.7361 0.666016 9.65277 0.666016 8.49999C0.666016 7.34721 0.884766 6.26388 1.32227 5.24999C1.75977 4.2361 2.35352 3.35416 3.10352 2.60416C3.85352 1.85416 4.73546 1.26041 5.74935 0.822906C6.76324 0.385406 7.84657 0.166656 8.99935 0.166656C10.1521 0.166656 11.2355 0.385406 12.2493 0.822906C13.2632 1.26041 14.1452 1.85416 14.8952 2.60416C15.6452 3.35416 16.2389 4.2361 16.6764 5.24999C17.1139 6.26388 17.3327 7.34721 17.3327 8.49999C17.3327 9.65277 17.1139 10.7361 16.6764 11.75C16.2389 12.7639 15.6452 13.6458 14.8952 14.3958C14.1452 15.1458 13.2632 15.7396 12.2493 16.1771C11.2355 16.6146 10.1521 16.8333 8.99935 16.8333ZM8.99935 15.5833C10.9716 15.5833 12.6452 14.8958 14.0202 13.5208C15.3952 12.1458 16.0827 10.4722 16.0827 8.49999C16.0827 7.65277 15.9368 6.84027 15.6452 6.06249C15.3535 5.28471 14.9438 4.57638 14.416 3.93749L4.43685 13.9167C5.06185 14.4583 5.76671 14.8715 6.55143 15.1562C7.33615 15.441 8.15213 15.5833 8.99935 15.5833ZM3.60352 13.0625L13.5618 3.10416C12.923 2.56249 12.2146 2.14582 11.4368 1.85416C10.6591 1.56249 9.84657 1.41666 8.99935 1.41666C7.02713 1.41666 5.35352 2.10416 3.97852 3.47916C2.60352 4.85416 1.91602 6.52777 1.91602 8.49999C1.91602 9.34721 2.06879 10.1632 2.37435 10.9479C2.6799 11.7326 3.08963 12.4375 3.60352 13.0625Z"
				fill="#2A4759"
			/>
		</svg>
	);
}

export default SvgUnavailable;
