import * as Types from '../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPartsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
  partsInput?: Types.InputMaybe<Types.PartsInput>;
  clientId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetPartsQuery = { __typename?: 'Query', getParts?: Array<{ __typename?: 'RDAPIPart', id?: string | null, name?: string | null, partCategory?: string | null, options?: Array<{ __typename?: 'RDAPIOption', id?: string | null, name?: string | null, price?: number | null } | null> | null, combinations?: Array<{ __typename?: 'RDAPICombination', id?: string | null, friends?: Array<string | null> | null } | null> | null } | null> | null };


export const GetPartsDocument = gql`
    query getParts($id: String, $partsInput: PartsInput, $clientId: String) {
  getParts(id: $id, partsInput: $partsInput, clientId: $clientId) {
    id
    name
    partCategory
    options {
      id
      name
      price
    }
    combinations {
      id
      friends
    }
  }
}
    `;

/**
 * __useGetPartsQuery__
 *
 * To run a query within a React component, call `useGetPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      partsInput: // value for 'partsInput'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetPartsQuery(baseOptions?: Apollo.QueryHookOptions<GetPartsQuery, GetPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartsQuery, GetPartsQueryVariables>(GetPartsDocument, options);
      }
export function useGetPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartsQuery, GetPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartsQuery, GetPartsQueryVariables>(GetPartsDocument, options);
        }
export type GetPartsQueryHookResult = ReturnType<typeof useGetPartsQuery>;
export type GetPartsLazyQueryHookResult = ReturnType<typeof useGetPartsLazyQuery>;
export type GetPartsQueryResult = Apollo.QueryResult<GetPartsQuery, GetPartsQueryVariables>;