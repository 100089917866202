import gql from 'graphql-tag';

import { useGetPartsLazyQuery } from './generated/useGetParts.generated';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: used for gql hook generation
const GET_PARTS = gql`
	query getParts($id: String, $partsInput: PartsInput, $clientId: String) {
		getParts(id: $id, partsInput: $partsInput, clientId: $clientId) {
			id
			name
			partCategory
			options {
				id
				name
				price
			}
			combinations {
				id
				friends
			}
		}
	}
`;

export function useGetParts(claim: any) {
	const [getPartsQuery, { data: parts, loading, error }] = useGetPartsLazyQuery();

	function getParts(panel: string) {
		return getPartsQuery({
			variables: {
				id: claim.id,
				partsInput: {
					panel,
					vehicleIdentifier: claim.vehicle.vehicleIdentifier,
					country: claim.estimate.info.repairDataRegion.countryCode,
					language: claim.estimate.info.customerLanguage,
					flags: claim.estimate.info.flags,
				},
				clientId: claim.clientId,
			},
		});
	}

	return { getParts, parts, loading, error };
}
