import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import gql from 'graphql-tag';

import { getCurrentToken } from '../../auth';
import { defaults, resolvers } from './resolvers';

const QUERY = gql`
	query InitialData {
		filters {
			title
			id
			selected
		}
		pagination {
			limit
			offset
		}
		search
	}
`;

interface Options {
	uri: string;
}

const cache = new InMemoryCache({
	typePolicies: {
		EstimatePart: {
			keyFields: false,
		},
		ClaimDetails: {
			keyFields: ['claimId'],
		},
		VehicleDetails: {
			keyFields: ['vin', 'registration', 'name'],
		},
	},
});

const authLink = setContext(async (_, { headers }) => {
	const { token, method } = await getCurrentToken();
	return {
		headers: {
			authorization: token ? `Bearer ${token}` : '',
			'tractable-authentication-method': method,
			...headers,
		},
	};
});

// This is removing __typename from cashed objects when sending mutation
// https://github.com/apollographql/apollo-feature-requests/issues/6
const cleanTypeName = new ApolloLink((operation, forward) => {
	if (operation.variables) {
		const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
		operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
	}
	return forward(operation).map((data) => data);
});

const client = ({ uri }: Options) => {
	const httpLink = new HttpLink({ uri });
	return new ApolloClient({
		link: ApolloLink.from([
			cleanTypeName,
			apolloLogger,
			onError(({ graphQLErrors, networkError }) => {
				if (graphQLErrors)
					graphQLErrors.map(({ message, locations, path }) =>
						console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
					);
				if (networkError) console.log(`[Network error]: ${networkError}`);
			}),
			authLink,
			httpLink,
		]),
		resolvers,
		cache,
	});
};

cache.writeQuery({
	query: QUERY,
	data: defaults,
});

export { client };
