"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobTaskType = void 0;
var JobTaskType;
(function (JobTaskType) {
    JobTaskType["IMAGE_CLASSIFICATION"] = "IMAGE_CLASSIFICATION";
    JobTaskType["DAMAGED_PARTS_CLASSIFICATION"] = "DAMAGED_PARTS_CLASSIFICATION";
    JobTaskType["DECODE_VEHICLE"] = "DECODE_VEHICLE";
    JobTaskType["GENERATE_ESTIMATE"] = "GENERATE_ESTIMATE";
    JobTaskType["GENERATE_CCP_PDF"] = "GENERATE_CCP_PDF";
    JobTaskType["GENERATE_FNOL_PDF"] = "GENERATE_FNOL_PDF";
    JobTaskType["GENERATE_LMH_DETAILED_ESTIMATE"] = "GENERATE_LMH_DETAILED_ESTIMATE";
    JobTaskType["GENERATE_TOTAL_LOSS"] = "GENERATE_TOTAL_LOSS";
    JobTaskType["GENERATE_PDF"] = "GENERATE_PDF";
    JobTaskType["HUMAN_IN_THE_LOOP"] = "HUMAN_IN_THE_LOOP";
    JobTaskType["HUMAN_PROCESSABILITY_TRIAGE"] = "HUMAN_PROCESSABILITY_TRIAGE";
    JobTaskType["HUMAN_ESTIMATE_REVIEW"] = "HUMAN_ESTIMATE_REVIEW";
    JobTaskType["HUMAN_TRIAGE_TOTAL_LOSS"] = "HUMAN_TRIAGE_TOTAL_LOSS";
    JobTaskType["POLICYHOLDER_CLASSIFICATION_INPUT"] = "POLICYHOLDER_CLASSIFICATION_INPUT";
    JobTaskType["POLICYHOLDER_ESTIMATE_INPUT"] = "POLICYHOLDER_ESTIMATE_INPUT";
    JobTaskType["CLAIM_HANDLER_ESTIMATE_REVIEW"] = "CLAIM_HANDLER_ESTIMATE_REVIEW";
    JobTaskType["SETTLEMENT"] = "SETTLEMENT";
    JobTaskType["COA_ESTIMATE_DISPLAY"] = "COA_ESTIMATE_DISPLAY";
    JobTaskType["PROCESS_ESTIMATE"] = "PROCESS_ESTIMATE";
    JobTaskType["PUBLISH_ESTIMATE"] = "PUBLISH_ESTIMATE";
    JobTaskType["VRM_CHECK"] = "VRM_CHECK";
    // For testing only
    JobTaskType["NO_OP"] = "NO_OP";
    JobTaskType["FAILURE"] = "FAILURE";
})(JobTaskType = exports.JobTaskType || (exports.JobTaskType = {}));
