import * as React from 'react';

import { Box } from '../../Box';
import SvgStar from '../../Icons/Star';
import { Text } from '../../Text';

export interface ShinkaIndicatorProps {
	readonly title: string;
}

export const ShinkaIndicator: React.FC<ShinkaIndicatorProps> = ({ title }) => {
	return (
		<Box orientation="horizontal" alignItems="center" gap={4} background="#EDF5FF" p="2px 8px" borderRadius="20px">
			<SvgStar />
			<Text typography="small" color="#0050D8" id="shinka-indicator">
				{title}
			</Text>
		</Box>
	);
};
