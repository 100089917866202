import { Icon } from '@blueprintjs/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '../../../shared';

const Done: React.FC = () => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<Box display="flex" alignItems="center" color="blues.0">
			<Icon icon="tick" iconSize={24} />
			<Text fontSize={4} fontWeight={5} m={2}>
				{t('Done')}
			</Text>
		</Box>
	);
};

export { Done };
