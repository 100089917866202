import * as React from 'react';

export const RWindshield = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RWINDSHIELD"
		stroke="#000"
		d="M268.096 391.845l-7.668-45.627c-6.5 6.89-52 15.255-72.5 0l-7 45.627c12.5 13.917 58.5 24.743 87.168 0z"
		{...props}
	/>
);
