import gql from 'graphql-tag';

import { useGetValidFlagsQuery } from './generated/useGetValidFlags.generated';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: used for gql hook generation
const GET_VALID_FLAGS = gql`
	query getValidFlags($claimId: String) {
		getValidFlags(claimId: $claimId) {
			id
			object
			subject
			multiplier
			type
			overwrite
		}
	}
`;

/**
 * useGetValidFlags gets the valid flags
 *
 * @param claimId the claim id
 * @param rdpEnabled
 */
export const useGetValidFlags = (claimId: string) => {
	return useGetValidFlagsQuery({
		variables: { claimId },
	});
};
