import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const CompanyName: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="companyName">
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Company Name')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder={t('Company Name')} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { CompanyName };
