import * as React from 'react';

function SvgWrench(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M20.7555 17.7732L13.2956 10.3133C14.0597 8.39087 13.6653 6.12334 12.1126 4.56235C10.223 2.67274 7.28175 2.50022 5.18675 4.02833L8.34157 7.19138L7.17494 8.34979L4.02833 5.20318C2.50022 7.28996 2.67274 10.2394 4.56235 12.1208C6.09047 13.6489 8.31693 14.0515 10.223 13.3367L17.7075 20.8212C18.0279 21.1416 18.5455 21.1416 18.8659 20.8212L20.7555 18.9316C21.0841 18.6194 21.0841 18.1018 20.7555 17.7732ZM18.2908 19.0877L10.5187 11.3157C10.0176 11.6854 9.45891 11.9072 8.87559 11.9893C7.75826 12.1537 6.58341 11.8168 5.72898 10.9624C4.94849 10.1901 4.587 9.15493 4.64451 8.13618L7.18316 10.6748L10.6666 7.19138L8.12796 4.65273C9.14671 4.59522 10.1737 4.95671 10.9542 5.72898C11.8415 6.61628 12.1783 7.84042 11.9729 8.9824C11.8743 9.56571 11.6278 10.1079 11.2499 10.5927L19.0138 18.3565L18.2908 19.0877Z"
				fill="#5292A2"
			/>
		</svg>
	);
}

export default SvgWrench;
