import * as React from 'react';

export const FRDoor = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="FRDOOR"
		stroke="#000"
		d="M334.797 199.608c-26.246 30.781-32.669 72.313-32.393 89.231l52.326-7.777c11.905.41 35.964 1.228 36.96 1.228l-.464-86.069c-35.714-12.688-49.37-8.074-56.429 3.387z"
		{...props}
	/>
);
