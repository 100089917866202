import { useState } from 'react';

export function useExpandableSections() {
	const [collapsedSections, setCollapsedSections] = useState<string[]>([]);

	function toggleSection(section: string) {
		collapsedSections.includes(section)
			? setCollapsedSections(collapsedSections.filter((p) => p !== section))
			: setCollapsedSections([...collapsedSections, section]);
	}

	return {
		toggleSection,
		collapsedSections,
	};
}
