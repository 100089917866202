import * as React from 'react';

function SvgErrorRound() {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.33301 9.00016H7.66634V10.3335H6.33301V9.00016ZM6.33301 3.66683H7.66634V7.66683H6.33301V3.66683ZM6.99301 0.333496C3.31301 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31301 13.6668 6.99301 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99301 0.333496ZM6.99967 12.3335C4.05301 12.3335 1.66634 9.94683 1.66634 7.00016C1.66634 4.0535 4.05301 1.66683 6.99967 1.66683C9.94634 1.66683 12.333 4.0535 12.333 7.00016C12.333 9.94683 9.94634 12.3335 6.99967 12.3335Z"
				fill="#D25459"
			/>
		</svg>
	);
}

export default SvgErrorRound;
