import * as React from 'react';

import { Wrapper } from './styles';

interface IProps {
	columns: number;
	rows: number;
	customHeight?: string;
	children: (props: any) => any;
}
interface IState {
	outTimeout: any;
	overTimeout: any;
	onMouseOut?: (props: any) => any;
	onMouseOver?: (props: any) => any;
}

class Gallery extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.onMouseOut = this.onMouseOut.bind(this);
		this.onMouseOver = this.onMouseOver.bind(this);

		this.state = {
			overTimeout: null,
			outTimeout: null,
			onMouseOver: this.onMouseOver,
			onMouseOut: this.onMouseOut,
		};
	}

	onMouseOver(callback: any) {
		const { outTimeout } = this.state;

		clearTimeout(outTimeout);
		if (callback) {
			const overTimeout = setTimeout(() => callback(), 500);
			this.setState(() => ({ overTimeout }));
		}
	}

	onMouseOut(callback: any) {
		const { overTimeout } = this.state;

		clearTimeout(overTimeout);
		if (callback) {
			const outTimeout = setTimeout(() => callback(), 500);
			this.setState(() => ({ outTimeout }));
		}
	}

	render() {
		const { columns, rows, customHeight, children } = this.props;
		return (
			<Wrapper columns={columns} rows={rows} customHeight={customHeight}>
				{children({ ...this.state })}
			</Wrapper>
		);
	}
}

export { Gallery };
