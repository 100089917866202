import { get } from 'lodash';
import styled from 'styled-components';

export interface BoxProps {
	readonly orientation?: 'vertical' | 'horizontal';
	readonly gap?: number;
	readonly width?: string;
	readonly height?: string;
	readonly minWidth?: string;
	readonly maxWidth?: string;
	readonly m?: string;
	readonly p?: string;
	readonly cursor?: string;
	readonly bgc?: string;
	readonly flex?: string;
	readonly alignItems?: string;
	readonly background?: string;
	readonly border?: string;
	readonly borderBottom?: string;
	readonly borderTop?: string;
	readonly borderLeft?: string;
	readonly borderRight?: string;
	readonly borderRadius?: string;
	readonly justifyContent?: string;
	readonly position?: string;
	readonly top?: string;
	readonly bottom?: string;
	readonly left?: string;
	readonly right?: string;
}

export const Box = styled.div<BoxProps>`
	display: flex;
	${(props) => props.orientation === 'vertical' && `flex-direction: column;`}
	${(props) => props.orientation === 'horizontal' && `flex-direction: row;`}
  gap: ${(props) => (props.gap ? `${props.gap}px` : null)};
	cursor: ${(props) => props.cursor};
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	min-width: ${(props) => props.minWidth};
	max-width: ${(props) => props.maxWidth};
	margin: ${(props) => props.m};
	padding: ${(props) => props.p};
	border: ${(props) => props.border};
	background: ${(props) => props.background};
	border-bottom: ${(props) => props.borderBottom};
	border-top: ${(props) => props.borderTop};
	border-left: ${(props) => props.borderLeft};
	border-right: ${(props) => props.borderRight};
	flex: ${(props) => props.flex};
	align-items: ${(props) => props.alignItems};
	border-radius: ${(props) => props.borderRadius};
	position: ${(props) => props.position};
	top: ${(props) => props.top};
	bottom: ${(props) => props.bottom};
	left: ${(props) => props.left};
	right: ${(props) => props.right};
	background-color: ${(props) => props.bgc && get(props.theme.colors, props.bgc, props.bgc)};
	justify-content: ${(props) => props.justifyContent};
`;
