import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBox, StyledFilter } from './styles';

interface FilterProps {
	id: string;
	title: string;
	selected: boolean;
	lastSelected: boolean;
	mutate(args: any): void;
}

const Filter: React.FC<FilterProps> = ({ id, title, lastSelected, selected, mutate }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<StyledFilter>
			<CheckBox
				type="checkbox"
				checked={selected}
				disabled={selected && lastSelected}
				onChange={() => mutate({ variables: { id, value: !selected } })}
			/>
			{t(title)}
		</StyledFilter>
	);
};

export { Filter };
