import gql from 'graphql-tag';

interface Args {
	id: string;
	value: string;
}

const query = gql`
	query InitialData {
		filters {
			title
			id
			selected
		}
	}
`;

const updateFilter = (_: any, { id, value }: Args, { cache }: any): null => {
	const data = cache.readQuery({ query });
	const filters = data.filters.map((f) => (f.id === id ? { ...f, selected: value } : f));
	cache.writeQuery({ query, data: { filters } });
	return null;
};

export { updateFilter };
