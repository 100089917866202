import * as React from 'react';

export const RBumper = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RBUMPER"
		stroke="#000"
		d="M164.115 490.64c-1.914-3.38-4.198-29.547-4.198-29.547l29.01 1.134 2.672 7.186h67.947l2.672-7.186 27.865-1.134s-3.817 27.23-5.726 31.012c-1.908 3.782-3.271 5.343-3.271 5.343H170.757s-4.727-3.429-6.642-6.808z"
		{...props}
	/>
);
