import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryCard, SummaryCardProps, Text } from '../../shared';

type PhotosUploadedProps = SummaryCardProps;

const PhotosUploaded: React.FC<PhotosUploadedProps> = ({ ...restProps }) => {
	const { t } = useTranslation('EstimatingPortal');
	return (
		<SummaryCard {...restProps}>
			<Text>{t('Photos uploaded')}</Text>
		</SummaryCard>
	);
};

export { PhotosUploaded };
