import { useQuery } from '@apollo/client';
import { showNotification } from '@mantine/notifications';
import gql from 'graphql-tag';
import { omit } from 'lodash';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationMark } from 'tabler-icons-react';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { Text } from '../../shared/Text';
import { GET_CLAIM } from '../hooks/useGetClaim';
import { useSetEstimaticsExportMutation } from './generated/EstimaticsExporter.generated';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: used for gql hook generation
const SET_ESTIMATICS_EXPORT = gql`
	mutation setEstimaticsExport($claimId: String, $endpoint: String, $exportInput: EstimaticsExportInput) {
		setEstimaticsExport(claimId: $claimId, endpoint: $endpoint, exportInput: $exportInput) {
			crwFile
		}
	}
`;
interface Props {
	claimId: string;
	endpoint: string;
}
export interface VehicleResource {
	id?: string;
	vin?: string;
	registration?: string;
	distanceTravelled?: number;
	distanceUnit?: any;
	valuation?: number;
	currency?: string;
	make?: string;
	model?: string;
	modelSubtypeCode?: string;
	year?: number;
	month?: number;
	numberOfDoors?: number;
	repairDataIdentifiers?: any;
	vehicleType?: string;
	dateOfFirstRegistration?: Date;
}

interface VehiclePortalInput extends Omit<VehicleResource, 'repairDataIdentifiers'> {
	vehicleIdentifier: string;
}
// Estimatics Exporter expects the vehicle object that Estimating API returns.
// In the Portal we use a simplified key for the vehicle identifier, so we are putting it
// back to the original shape.
const transformVehicleInput = (portalVehicle: VehiclePortalInput): VehicleResource => {
	const vehicle = omit(portalVehicle, 'vehicleIdentifier');
	return {
		...vehicle,
		repairDataIdentifiers: {
			autovista: [portalVehicle.vehicleIdentifier],
		},
	};
};
export const EstimaticsExporter: React.FC<Props> = ({ claimId, endpoint }) => {
	const elementRef = useRef(null);
	const { t } = useTranslation('EstimatingPortal');
	const { loading: claimLoading, data: claimData } = useQuery(GET_CLAIM, {
		variables: { id: claimId },
		fetchPolicy: 'network-only',
	});
	const [setEstimaticsExport, { error }] = useSetEstimaticsExportMutation({
		onCompleted: (data) => {
			elementRef.current.href = `data:application/xhtml+xml;base64,${data.setEstimaticsExport.crwFile}`;
			elementRef.current.download = `${claimData.claim.clientClaimId}.crw`;
			elementRef.current.click();
		},
	});
	if (claimLoading) return <></>;
	if (error) {
		showNotification({
			icon: <ExclamationMark />,
			color: 'red',
			autoClose: 4000,
			message: 'Something went wrong. Unable to export estimate for Carwert.',
		});
	}

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		logEvent('Edit - export estimate for Carwert', { claimId });

		// Only make requests if it is not fetched already, as calling estimatics exporter
		// incurs a small cost per request from autovista.
		if (!elementRef?.current.href) {
			event.preventDefault();
			const { clientClaimId, policy, estimate, vehicle } = claimData.claim;
			setEstimaticsExport({
				variables: {
					claimId,
					endpoint,
					exportInput: {
						id: claimId,
						clientClaimId,
						policy,
						estimate,
						vehicle: transformVehicleInput(vehicle) as any,
					},
				},
			});
		}
	};

	return (
		<Text
			as="a"
			ref={elementRef}
			typography="standard"
			color="#3B25C4"
			p="0 8px"
			onClick={handleClick}
			data-test-id="export-for-carwert-button"
		>
			{t('Export to Carwert')}
		</Text>
	);
};
