import { ApolloError, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { NotifiedUser } from './NotifiedUser';

const GET_USERS = gql`
	query getUsers($showInternalUsers: Boolean, $emailQuery: String) {
		users(showInternalUsers: $showInternalUsers, emailQuery: $emailQuery) {
			id
			email
		}
	}
`;

type GetUsersQuery = {
	showInternalUsers?: boolean;
	emailQuery?: string;
};

type GetUsersResult = {
	users: NotifiedUser[];
	loading: boolean;
	error?: ApolloError;
};

export function useGetUsers({ showInternalUsers, emailQuery }: GetUsersQuery): GetUsersResult {
	const { data, loading, error } = useQuery(GET_USERS, {
		variables: { showInternalUsers, emailQuery },
		fetchPolicy: 'no-cache',
	});

	const users = data?.users || [];

	return { users, loading, error };
}
