"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailAssessmentOption = void 0;
var EmailAssessmentOption;
(function (EmailAssessmentOption) {
    EmailAssessmentOption["OK_LESS_THAN_THRESHOLD"] = "OK_LESS_THAN_THRESHOLD";
    EmailAssessmentOption["OK_GREATER_THAN_THRESHOLD"] = "OK_GREATER_THAN_THRESHOLD";
    EmailAssessmentOption["NOT_OK_OUT_OF_SCOPE"] = "NOT_OK_OUT_OF_SCOPE";
    EmailAssessmentOption["NOT_OK_OTHER_DAMAGE"] = "NOT_OK_OTHER_DAMAGE";
})(EmailAssessmentOption = exports.EmailAssessmentOption || (exports.EmailAssessmentOption = {}));
