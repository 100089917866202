import { HTMLSelect, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { FieldProps } from '../types';
import { required } from '../utils';
import { TractableFormGroup } from './Shared';

interface BrandProps {
	readonly brands: string[];
}

const Brand: React.FC<BrandProps> = ({ brands }) => {
	const { t } = useClientTranslations();

	return (
		<Field component="select" name="brand" validate={required(t('Brand'), t)}>
			{({ input, meta }: FieldProps) => (
				<TractableFormGroup
					label={t('Brand')}
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && meta.error}
				>
					<HTMLSelect {...input}>
						{brands.map((option, index) => (
							<option value={option} key={index}>
								{option}
							</option>
						))}
					</HTMLSelect>
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { Brand };
