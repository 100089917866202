export const required = (field: string, t: any) => (value: any) =>
	value ? undefined : t(`{{field}} is required`, { field });

export const containsValidCharacters = (text: string) => /^[\d\s,.]*$/.test(text);

export function getCurrencySymbolCharacter(localeSymbol: string, currencyCode: string): string {
	return new Intl.NumberFormat(localeSymbol, {
		style: 'currency',
		currency: currencyCode,
	})
		.format(0)
		.replace(/[\s\d,.]*/g, '');
}

export const formatCurrency = (value = '', locale = 'en-US'): string => {
	const sanitizedValue = value.replace(/\D/g, '');
	return new Intl.NumberFormat(locale, {
		useGrouping: true,
	}).format(Number(sanitizedValue));
};
