import * as React from 'react';

export const BRWing = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="BRWING"
		stroke="#000"
		d="M394.662 399.908c-.27-.047.281.052 0 0zm0 0c-20.261 0-35.116-10.61-34.733-31.014.414-22.035 21.088-28.564 27.29-28.564l1.241-7.345-47.964 27.34-30.184-16.323c3.307 16.323 20.26 53.048 21.087 71.82l2.067 22.852 4.135.816 4.135 1.632.238-25.906c6.202 4.897 13.159 16.602 13.821 26.722l12.404 5.305h15.299c2.067-1.768 6.45-7.264 7.442-15.098l3.722-32.237z"
		{...props}
	/>
);
