import * as React from 'react';

function SvgIndicatorSeparator() {
	return (
		<svg width="1" height="17" viewBox="0 0 1 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<line x1="0.5" y1="0.5" x2="0.499999" y2="16.5" stroke="#A0A9B0" />
		</svg>
	);
}

export default SvgIndicatorSeparator;
