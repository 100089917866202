"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimEvent = void 0;
var ClaimEvent;
(function (ClaimEvent) {
    ClaimEvent["ESTIMATE_READY"] = "ESTIMATE_READY";
    ClaimEvent["SENT_TO_HIL"] = "SENT_TO_HIL";
    ClaimEvent["SENT_TO_HIL_TRIAGE"] = "SENT_TO_HIL_TRIAGE";
    ClaimEvent["SENT_TO_HIL_REVIEW"] = "SENT_TO_HIL_REVIEW";
    ClaimEvent["TOTAL_LOSS"] = "TOTAL_LOSS";
    ClaimEvent["NOT_TOTAL_LOSS"] = "NOT_TOTAL_LOSS";
    ClaimEvent["PROCESSABLE"] = "PROCESSABLE";
    ClaimEvent["NOT_PROCESSABLE"] = "NOT_PROCESSABLE";
    ClaimEvent["COA_ESTIMATE_DISPLAY_ENABLED"] = "COA_ESTIMATE_DISPLAY_ENABLED";
    ClaimEvent["COA_ESTIMATE_DISPLAY_DISABLED"] = "COA_ESTIMATE_DISPLAY_DISABLED";
    ClaimEvent["NEGOTIATION_STARTED"] = "NEGOTIATION_STARTED";
    ClaimEvent["NEGOTIATION_DONE"] = "NEGOTIATION_DONE";
    ClaimEvent["PH_OFFER_ACCEPTED"] = "PH_OFFER_ACCEPTED";
    ClaimEvent["PH_OFFER_DECLINED"] = "PH_OFFER_DECLINED";
    ClaimEvent["PH_OFFER_NEGOTIATED"] = "PH_OFFER_NEGOTIATED";
    ClaimEvent["CLAIM_CREATED"] = "CLAIM_CREATED";
    ClaimEvent["VEHICLE_CREATED"] = "VEHICLE_CREATED";
    ClaimEvent["INCOMPLETE_SESSION_EXPIRED"] = "INCOMPLETE_SESSION_EXPIRED";
    ClaimEvent["CLAIM_PUBLISHED_PROCESSABLE"] = "CLAIM_PUBLISHED_PROCESSABLE";
    ClaimEvent["CLAIM_PUBLISHED_NON_PROCESSABLE"] = "CLAIM_PUBLISHED_NON_PROCESSABLE";
    ClaimEvent["ESTIMATE_DECISION"] = "ESTIMATE_DECISION";
    ClaimEvent["CLAIM_HANDLER_ESTIMATE_REVIEW_STARTED"] = "CLAIM_HANDLER_ESTIMATE_REVIEW_STARTED";
    ClaimEvent["CLAIM_HANDLER_ESTIMATE_REVIEW_DONE"] = "CLAIM_HANDLER_ESTIMATE_REVIEW_DONE";
    ClaimEvent["PUBLISH_CAR_OWNER_ESTIMATE"] = "PUBLISH_CAR_OWNER_ESTIMATE";
})(ClaimEvent = exports.ClaimEvent || (exports.ClaimEvent = {}));
