"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssessmentStatus = void 0;
var AssessmentStatus;
(function (AssessmentStatus) {
    AssessmentStatus["UNKNOWN"] = "UNKNOWN";
    AssessmentStatus["ACTIVE"] = "ACTIVE";
    AssessmentStatus["OBSELETE"] = "OBSELETE";
    AssessmentStatus["PENDING"] = "PENDING";
})(AssessmentStatus = exports.AssessmentStatus || (exports.AssessmentStatus = {}));
