export const useStyledComponentsThemeOverride = () => {
	const styledComponentsThemeOverride = {
		mode: 'default',
		radii: [3],
		sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512],
		space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
		fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
		fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
		lineHeights: {
			solid: 1,
			regular: 1.25,
			title: 1.5,
		},
		colors: {
			white: '#ffffff',
			grays: ['#f2f2f2', '#A3A3A3'],
			blues: ['#2381BD'],
			reds: ['#E0094B'],
			oranges: ['#FF9901'],
			black: '#000',
			spinner: '#3B25C4',
			primary: {
				dark: {
					60: '#45677E',
					70: '#38596E',
				},
			},
			accents: {
				amber: '#ED870D',
				salmon: '#D25459',
			},
		},
	};

	return { styledComponentsThemeOverride };
};
