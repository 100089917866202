import { Panel } from '@tractable/estimating-local-constants';

import { OperationType, operationToFill } from '../../operationToFill';
import {
	BLWing,
	BRWing,
	Bonnet,
	Bootlid,
	Extra,
	FBumper,
	FLDoor,
	FLWheel,
	FLWing,
	FRDoor,
	FRWheel,
	FRWing,
	FWinshield,
	Grille,
	LAPillar,
	LHeadlamp,
	LMirror,
	LRockerpanel,
	LTaillamp,
	RAPillar,
	RBumper,
	RHeadlamp,
	RLDoor,
	RLWheel,
	RMirror,
	RRDoor,
	RRWheel,
	RRockerpanel,
	RTaillamp,
	RWindshield,
	Roof,
} from './index';

const panelToSVG = {
	BONNET: Bonnet,
	FLWING: FLWing,
	FRWING: FRWing,
	FBUMPER: FBumper,
	BBUMPER: RBumper,
	FLDOOR: FLDoor,
	FRDOOR: FRDoor,
	BLWING: BLWing,
	BRWING: BRWing,
	BOOTLID: Bootlid,
	RRDOOR: RRDoor,
	RLDOOR: RLDoor,
	LHEADLAMP: LHeadlamp,
	RHEADLAMP: RHeadlamp,
	LTAILLAMP: LTaillamp,
	RTAILLAMP: RTaillamp,
	GRILLE: Grille,
	ROOF: Roof,
	LMIRROR: LMirror,
	RMIRROR: RMirror,
	LROCKERPANEL: LRockerpanel,
	RROCKERPANEL: RRockerpanel,
	EXTRA: Extra,
	RAPILLAR: RAPillar,
	LAPILLAR: LAPillar,
	FWINDSHIELD: FWinshield,
	RWINDSHIELD: RWindshield,
	FRWHEEL: FRWheel,
	RRWHEEL: RRWheel,
	FLWHEEL: FLWheel,
	RLWHEEL: RLWheel,
};

export const usePanel = (panel: Panel, operation: OperationType) => {
	const { background } = operationToFill(operation);

	return {
		fill: background,
		SVG: panelToSVG[panel],
	};
};
