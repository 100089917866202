const filters = [
	{
		title: 'Awaiting photos',
		id: 'AWAITING_PHOTOS',
		selected: true,
		__typename: 'Filter',
	},
	{
		title: 'In progress',
		id: 'IN_PROGRESS',
		selected: true,
		__typename: 'Filter',
	},
	{
		title: 'AI decision available',
		id: 'AI_DECISION_AVAILABLE',
		selected: true,
		__typename: 'Filter',
	},
];

export { filters };
