import * as React from 'react';

import { Text } from '../Text';
import { CurrencyField } from './CurrencyField';
import { TData, TRow } from './Table';
import { Currency, formatCurrencyWithoutSymbol } from './formatCurrency';

export interface SectionRowProps {
	readonly isEditable?: boolean;
	readonly description: string;
	readonly cost?: number;
	readonly hours?: number;
	readonly updateField?: (field: string) => (value: string) => void;
	readonly isCostEditable?: boolean;
	readonly partSupplier?: string;
	readonly displayPartIdentifier?: string;
	readonly partType?: string;
	readonly dataTestId?: string;
	readonly currency: Currency;
	readonly hidePartPrice?: boolean;
}

export const PartRow: React.FC<SectionRowProps> = ({
	isEditable = false,
	updateField,
	description,
	cost,
	isCostEditable = false,
	displayPartIdentifier,
	partSupplier,
	partType,
	dataTestId,
	currency,
	hidePartPrice,
}) => {
	if (!cost) return null;

	return (
		<TRow bgc="#f5f5f5" data-test-id={dataTestId}>
			<TData />
			<TData>
				<Text typography="standard" color="#0B1F2C" p="0 0 0 16px">
					{description}
				</Text>
			</TData>
			<TData align="left" colSpan={4}>
				<Text typography="standard" color="#0B1F2C">
					{`${displayPartIdentifier}\u00A0\u00A0${partType}\u00A0\u00A0${partSupplier}`}
				</Text>
			</TData>
			<TData align="right">
				{!hidePartPrice &&
					(cost && isCostEditable && isEditable ? (
						<CurrencyField
							value={cost.toFixed(2)}
							localeSymbol={currency.symbol}
							updateField={updateField && updateField('cost')}
						/>
					) : (
						<Text typography="standard" color="#0B1F2C">
							{cost && formatCurrencyWithoutSymbol(cost, currency)}
						</Text>
					))}
			</TData>
			<TData />
		</TRow>
	);
};
