import { InputGroup, Intent } from '@blueprintjs/core';
import { TFunction } from 'i18next';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const required = (field: string, t: TFunction) => (value: any) => {
	if (!value) {
		return t('{{field}} is required', { field });
	}

	const year = Number(value);
	if (!year) {
		return t('Should be a valid 4 digit number');
	}

	const min = 1900;
	const max = new Date().getFullYear() + 1;
	if (year < min || year > max) {
		return t('Should be between {{min}} and {{max}}', { min, max });
	}

	return undefined;
};

const Year: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="year" validate={required(t('Year'), t)}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Year')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder="ex: 2015" />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { Year };
