import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

const isInteger = (field: string, t: any) => (value: any) =>
	Number.isInteger(Number.parseInt(value, 10)) ? undefined : t(`{{field}} is required`, { field });

const Mileage: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="mileage" validate={isInteger(t('Mileage'), t)}>
			{({ input, meta }) => (
				<TractableFormGroup
					label="Mileage"
					inline
					intent={Intent.DANGER}
					helperText={meta.invalid && meta.touched && t(meta.error)}
				>
					<InputGroup {...input} placeholder="ex: 42009" />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { Mileage };
