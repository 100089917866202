"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientClaimStatus = void 0;
var ClientClaimStatus;
(function (ClientClaimStatus) {
    ClientClaimStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ClientClaimStatus["OUT_OF_SCOPE"] = "OUT_OF_SCOPE";
    ClientClaimStatus["OUT_OF_HOURS"] = "OUT_OF_HOURS";
    ClientClaimStatus["DONE"] = "DONE";
})(ClientClaimStatus = exports.ClientClaimStatus || (exports.ClientClaimStatus = {}));
