import * as React from 'react';

function SvgWarningTriangleOrange(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="10px" height="10px" viewBox="0 0 10 10" fill="none" {...props} overflow="visible">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.99935 2.9125L8.13685 8.33333H1.86185L4.99935 2.9125ZM4.99935 1.25L0.416016 9.16667H9.58268L4.99935 1.25ZM5.41602 7.08333H4.58268V7.91667H5.41602V7.08333ZM5.41602 4.58333H4.58268V6.25H5.41602V4.58333Z"
				fill="#E66F0E"
			/>
		</svg>
	);
}

export default SvgWarningTriangleOrange;
