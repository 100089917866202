import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

export const required = (field: string, t: any) => (value: any) => {
	if (!value) {
		return t('{{field}} is required', { field });
	}

	return undefined;
};

export const normalize = (value: any) => value.toUpperCase();

const RegistrationNoLengthLimit: React.FC = () => {
	const { t } = useClientTranslations();

	return (
		<Field name="registrationNoLengthLimit" validate={required(t('Registration number'), t)} parse={normalize}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Registration number')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder={t('RegistrationExample')} />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { RegistrationNoLengthLimit };
