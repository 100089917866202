import * as Types from '../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetEstimaticsExportMutationVariables = Types.Exact<{
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
  endpoint?: Types.InputMaybe<Types.Scalars['String']>;
  exportInput?: Types.InputMaybe<Types.EstimaticsExportInput>;
}>;


export type SetEstimaticsExportMutation = { __typename?: 'Mutation', setEstimaticsExport?: { __typename?: 'Carwert', crwFile?: string | null } | null };


export const SetEstimaticsExportDocument = gql`
    mutation setEstimaticsExport($claimId: String, $endpoint: String, $exportInput: EstimaticsExportInput) {
  setEstimaticsExport(
    claimId: $claimId
    endpoint: $endpoint
    exportInput: $exportInput
  ) {
    crwFile
  }
}
    `;
export type SetEstimaticsExportMutationFn = Apollo.MutationFunction<SetEstimaticsExportMutation, SetEstimaticsExportMutationVariables>;

/**
 * __useSetEstimaticsExportMutation__
 *
 * To run a mutation, you first call `useSetEstimaticsExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEstimaticsExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEstimaticsExportMutation, { data, loading, error }] = useSetEstimaticsExportMutation({
 *   variables: {
 *      claimId: // value for 'claimId'
 *      endpoint: // value for 'endpoint'
 *      exportInput: // value for 'exportInput'
 *   },
 * });
 */
export function useSetEstimaticsExportMutation(baseOptions?: Apollo.MutationHookOptions<SetEstimaticsExportMutation, SetEstimaticsExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEstimaticsExportMutation, SetEstimaticsExportMutationVariables>(SetEstimaticsExportDocument, options);
      }
export type SetEstimaticsExportMutationHookResult = ReturnType<typeof useSetEstimaticsExportMutation>;
export type SetEstimaticsExportMutationResult = Apollo.MutationResult<SetEstimaticsExportMutation>;
export type SetEstimaticsExportMutationOptions = Apollo.BaseMutationOptions<SetEstimaticsExportMutation, SetEstimaticsExportMutationVariables>;