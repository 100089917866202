import * as React from 'react';

export const LTaillamp = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="LTAILLAMP"
		stroke="#000"
		d="M105.468 415.27v25.955l-14.04.825c2.586-18.952 14.779-27.604 14.04-26.78z"
		{...props}
	/>
);
