import * as React from 'react';

import { Detail, List } from '../../shared';
import { SimpleWidget } from '../../shared/Widget';
import { useVehicle } from './useVehicle';

interface CarDetailsProps {
	id: string;
	widget: any;
}

const CarDetails: React.FC<CarDetailsProps> = ({ id, widget }) => {
	const { loading, details } = useVehicle(id);

	if (loading) return null;

	return (
		<SimpleWidget {...widget}>
			<List items={details} itemRenderer={(itemProps: any) => <Detail {...itemProps} />} />
		</SimpleWidget>
	);
};

export { CarDetails };
