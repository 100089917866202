"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleType = void 0;
var VehicleType;
(function (VehicleType) {
    // PZU-specific bodyStyles - maintained to avoid breaking changes
    VehicleType["ASIAN_SEDAN"] = "ASIAN_SEDAN";
    VehicleType["EUROPEAN_SEDAN"] = "EUROPEAN_SEDAN";
    VehicleType["PICKUP"] = "PICKUP";
    VehicleType["VAN"] = "VAN";
    // Standardized bodyStyles
    VehicleType["CONVERTIBLE"] = "CONVERTIBLE";
    VehicleType["COUPE"] = "COUPE";
    VehicleType["EXTENDED_VAN"] = "EXTENDED_VAN";
    VehicleType["HATCHBACK"] = "HATCHBACK";
    VehicleType["JEEP"] = "JEEP";
    VehicleType["MINIVAN"] = "MINIVAN";
    VehicleType["SEDAN"] = "SEDAN";
    VehicleType["SUV"] = "SUV";
    VehicleType["TRUCK"] = "TRUCK";
    VehicleType["WAGON"] = "WAGON";
})(VehicleType = exports.VehicleType || (exports.VehicleType = {}));
