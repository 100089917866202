import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, PageLayout } from '../shared';
import { NavIcon } from '../shared/Navbar';

export const GenericError: React.FC<{ data: any }> = ({ data = {} }) => {
	const { t } = useTranslation('EstimatingPortal');

	const error =
		data.error?.networkError?.result?.errors[0].extensions.code ||
		data.error?.error?.response?.data?.error?.message ||
		'An error occured while processing your request';

	return <NonIdealState icon="error" title={t(error)} />;
};

export const GenericErrorPage: React.FC<{ error: any }> = ({ error }) => {
	const { t } = useTranslation('EstimatingPortal');

	return (
		<PageLayout navigationIcon={NavIcon.BACK} navigationHeader="" header={() => <Header title={t('Error')} />}>
			<GenericError data={{ error }} />
		</PageLayout>
	);
};
