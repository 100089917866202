import * as React from 'react';

function SvgWarningRoundRed(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="15px" height="15px" viewBox="0 0 15 15" fill="none" {...props} overflow="visible">
			<circle cx={6.911} cy={6} fill="#fff" r={5.625} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.00036 2.66683C6.58583 2.66683 5.22924 3.22873 4.22901 4.22893C3.22879 5.22912 2.66687 6.58568 2.66687 8.00016C2.66687 8.70055 2.80482 9.39407 3.07286 10.0411C3.34089 10.6882 3.73375 11.2762 4.22901 11.7714C4.72427 12.2666 5.31224 12.6595 5.95932 12.9275C6.60641 13.1955 7.29996 13.3335 8.00036 13.3335C8.70077 13.3335 9.39432 13.1955 10.0414 12.9275C10.6885 12.6595 11.2765 12.2666 11.7717 11.7714C12.267 11.2762 12.6598 10.6882 12.9279 10.0411C13.1959 9.39407 13.3339 8.70055 13.3339 8.00016C13.3339 6.58568 12.7719 5.22912 11.7717 4.22893C10.7715 3.22873 9.4149 2.66683 8.00036 2.66683ZM3.28618 3.28612C4.53646 2.03588 6.2322 1.3335 8.00036 1.3335C9.76853 1.3335 11.4643 2.03588 12.7146 3.28612C13.9648 4.53636 14.6672 6.23205 14.6672 8.00016C14.6672 8.87564 14.4948 9.74255 14.1597 10.5514C13.8247 11.3602 13.3336 12.0952 12.7146 12.7142C12.0955 13.3333 11.3605 13.8243 10.5517 14.1594C9.7428 14.4944 8.87587 14.6668 8.00036 14.6668C7.12486 14.6668 6.25793 14.4944 5.44906 14.1594C4.6402 13.8243 3.90525 13.3333 3.28618 12.7142C2.6671 12.0952 2.17602 11.3602 1.84098 10.5514C1.50594 9.74255 1.3335 8.87564 1.3335 8.00016C1.3335 6.23205 2.0359 4.53636 3.28618 3.28612ZM8.00036 4.66683C8.36857 4.66683 8.66705 4.96531 8.66705 5.3335V8.00016C8.66705 8.36835 8.36857 8.66683 8.00036 8.66683C7.63216 8.66683 7.33368 8.36835 7.33368 8.00016V5.3335C7.33368 4.96531 7.63216 4.66683 8.00036 4.66683ZM7.33368 10.6668C7.33368 10.2986 7.63216 10.0002 8.00036 10.0002H8.00703C8.37523 10.0002 8.67372 10.2986 8.67372 10.6668C8.67372 11.035 8.37523 11.3335 8.00703 11.3335H8.00036C7.63216 11.3335 7.33368 11.035 7.33368 10.6668Z"
				fill="#D25459"
			/>
		</svg>
	);
}

export default SvgWarningRoundRed;
