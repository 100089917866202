import * as React from 'react';

function SvgStar() {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.3335 10.9999L7.00016 8.96659L9.66683 10.9999L8.60016 7.71659L11.2668 5.83325H8.01683L7.00016 2.44992L5.96683 5.83325H2.71683L5.3835 7.71659L4.3335 10.9999ZM7.00016 13.6666C6.08905 13.6666 5.22794 13.4916 4.41683 13.1416C3.60572 12.7916 2.89739 12.3138 2.29183 11.7083C1.68627 11.1027 1.2085 10.3944 0.858496 9.58325C0.508496 8.77214 0.333496 7.91103 0.333496 6.99992C0.333496 6.0777 0.508496 5.21103 0.858496 4.39992C1.2085 3.58881 1.68627 2.88325 2.29183 2.28325C2.89739 1.68325 3.60572 1.20825 4.41683 0.858252C5.22794 0.508252 6.08905 0.333252 7.00016 0.333252C7.92239 0.333252 8.78905 0.508252 9.60016 0.858252C10.4113 1.20825 11.1168 1.68325 11.7168 2.28325C12.3168 2.88325 12.7918 3.58881 13.1418 4.39992C13.4918 5.21103 13.6668 6.0777 13.6668 6.99992C13.6668 7.91103 13.4918 8.77214 13.1418 9.58325C12.7918 10.3944 12.3168 11.1027 11.7168 11.7083C11.1168 12.3138 10.4113 12.7916 9.60016 13.1416C8.78905 13.4916 7.92239 13.6666 7.00016 13.6666ZM7.00016 12.6666C8.57794 12.6666 9.91683 12.1138 11.0168 11.0083C12.1168 9.9027 12.6668 8.56659 12.6668 6.99992C12.6668 5.42214 12.1168 4.08325 11.0168 2.98325C9.91683 1.88325 8.57794 1.33325 7.00016 1.33325C5.4335 1.33325 4.09739 1.88325 2.99183 2.98325C1.88627 4.08325 1.3335 5.42214 1.3335 6.99992C1.3335 8.56659 1.88627 9.9027 2.99183 11.0083C4.09739 12.1138 5.4335 12.6666 7.00016 12.6666Z"
				fill="#0050D8"
			/>
		</svg>
	);
}

export default SvgStar;
