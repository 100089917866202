"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstimateOperationTriggerType = exports.EstimateOperationType = void 0;
var EstimateOperationType;
(function (EstimateOperationType) {
    EstimateOperationType["CUSTOM"] = "CUSTOM";
    EstimateOperationType["AIM_HEADLAMPS"] = "AIM_HEADLAMPS";
    EstimateOperationType["CAR_COVER"] = "CAR_COVER";
    EstimateOperationType["FLEX_ADDITIVE"] = "FLEX_ADDITIVE";
    EstimateOperationType["GLASS_KIT"] = "GLASS_KIT";
    EstimateOperationType["EPC_HAZARDOUS_WASTE"] = "EPC_HAZARDOUS_WASTE";
    EstimateOperationType["POST_REPAIR_SCAN"] = "POST_REPAIR_SCAN";
    EstimateOperationType["PRE_REPAIR_SCAN"] = "PRE_REPAIR_SCAN";
    EstimateOperationType["RESTORE_CORROSION_PROTECTION"] = "RESTORE_CORROSION_PROTECTION";
    EstimateOperationType["ROPE_GLASS"] = "ROPE_GLASS";
    EstimateOperationType["TYRE_DISPOSAL"] = "TYRE_DISPOSAL";
    EstimateOperationType["TYRE_MOUNT_AND_BALANCE"] = "TYRE_MOUNT_AND_BALANCE";
    EstimateOperationType["WHEEL_ALIGNMENT"] = "WHEEL_ALIGNMENT";
})(EstimateOperationType = exports.EstimateOperationType || (exports.EstimateOperationType = {}));
var EstimateOperationTriggerType;
(function (EstimateOperationTriggerType) {
    EstimateOperationTriggerType["MANUAL"] = "MANUAL";
    EstimateOperationTriggerType["VEHICLE"] = "VEHICLE";
    EstimateOperationTriggerType["CLIENT"] = "CLIENT";
    EstimateOperationTriggerType["PART_OPERATION"] = "PART_OPERATION";
})(EstimateOperationTriggerType = exports.EstimateOperationTriggerType || (exports.EstimateOperationTriggerType = {}));
