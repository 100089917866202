import * as Types from '../../../../../../generated/graphql/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportToGtMotiveMutationVariables = Types.Exact<{
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
  exportInput?: Types.InputMaybe<Types.EstimaticsExportGtMotiveInput>;
}>;


export type ExportToGtMotiveMutation = { __typename?: 'Mutation', exportToGTMotive?: { __typename?: 'GTMotiveEstimateReceivedResponse', gtMotiveRequestUid?: string | null } | null };

export type GetGtMotiveEstimateQueryVariables = Types.Exact<{
  clientId?: Types.InputMaybe<Types.Scalars['String']>;
  claimId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetGtMotiveEstimateQuery = { __typename?: 'Query', getGTMotiveEstimate?: { __typename?: 'GTMotiveEstimateDoneResponse', message?: string | null, gtMotiveEstimateAccessUrl?: string | null, mismatchedParts?: boolean | null } | null };


export const ExportToGtMotiveDocument = gql`
    mutation exportToGTMotive($claimId: String, $exportInput: EstimaticsExportGTMotiveInput) {
  exportToGTMotive(claimId: $claimId, exportInput: $exportInput) {
    gtMotiveRequestUid
  }
}
    `;
export type ExportToGtMotiveMutationFn = Apollo.MutationFunction<ExportToGtMotiveMutation, ExportToGtMotiveMutationVariables>;

/**
 * __useExportToGtMotiveMutation__
 *
 * To run a mutation, you first call `useExportToGtMotiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportToGtMotiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportToGtMotiveMutation, { data, loading, error }] = useExportToGtMotiveMutation({
 *   variables: {
 *      claimId: // value for 'claimId'
 *      exportInput: // value for 'exportInput'
 *   },
 * });
 */
export function useExportToGtMotiveMutation(baseOptions?: Apollo.MutationHookOptions<ExportToGtMotiveMutation, ExportToGtMotiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportToGtMotiveMutation, ExportToGtMotiveMutationVariables>(ExportToGtMotiveDocument, options);
      }
export type ExportToGtMotiveMutationHookResult = ReturnType<typeof useExportToGtMotiveMutation>;
export type ExportToGtMotiveMutationResult = Apollo.MutationResult<ExportToGtMotiveMutation>;
export type ExportToGtMotiveMutationOptions = Apollo.BaseMutationOptions<ExportToGtMotiveMutation, ExportToGtMotiveMutationVariables>;
export const GetGtMotiveEstimateDocument = gql`
    query getGTMotiveEstimate($clientId: String, $claimId: String) {
  getGTMotiveEstimate(clientId: $clientId, claimId: $claimId) {
    message
    gtMotiveEstimateAccessUrl
    mismatchedParts
  }
}
    `;

/**
 * __useGetGtMotiveEstimateQuery__
 *
 * To run a query within a React component, call `useGetGtMotiveEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGtMotiveEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGtMotiveEstimateQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useGetGtMotiveEstimateQuery(baseOptions?: Apollo.QueryHookOptions<GetGtMotiveEstimateQuery, GetGtMotiveEstimateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGtMotiveEstimateQuery, GetGtMotiveEstimateQueryVariables>(GetGtMotiveEstimateDocument, options);
      }
export function useGetGtMotiveEstimateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGtMotiveEstimateQuery, GetGtMotiveEstimateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGtMotiveEstimateQuery, GetGtMotiveEstimateQueryVariables>(GetGtMotiveEstimateDocument, options);
        }
export type GetGtMotiveEstimateQueryHookResult = ReturnType<typeof useGetGtMotiveEstimateQuery>;
export type GetGtMotiveEstimateLazyQueryHookResult = ReturnType<typeof useGetGtMotiveEstimateLazyQuery>;
export type GetGtMotiveEstimateQueryResult = Apollo.QueryResult<GetGtMotiveEstimateQuery, GetGtMotiveEstimateQueryVariables>;