import * as React from 'react';

function SvgCheck(): JSX.Element {
	return (
		<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.32826 9.72927L1.85326 6.25426L0.669922 7.42926L5.32826 12.0876L15.3283 2.0876L14.1533 0.912598L5.32826 9.72927Z"
				fill="#2E8367"
			/>
		</svg>
	);
}

export default SvgCheck;
