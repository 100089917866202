import { get } from 'lodash';
import styled, { css } from 'styled-components';

import { variantHelper } from './variantHelper';

interface TextSpanProps {
	readonly m?: string;
	readonly p?: string;
	readonly bold?: boolean;
	readonly underline?: boolean;
	readonly color?: string;
	readonly cursor?: string;
	readonly typography: 'micro' | 'small' | 'standard' | 'large';
}

const typography = variantHelper({
	typography: {
		micro: css`
			font-family: Rubik, sans-serif;
			font-style: normal;
			font-size: 12px;
			line-height: 16px;
		`,
		small: css`
			font-family: Rubik, sans-serif;
			font-style: normal;
			font-size: 14px;
			line-height: 20px;
		`,
		standard: css`
			font-family: Rubik, sans-serif;
			font-style: normal;
			font-size: 16px;
			line-height: 24px;
		`,
		large: css`
			font-family: Rubik, sans-serif;
			font-style: normal;
			font-size: 20px;
			line-height: 32px;
		`,
	},
});

export const TextSpan = styled.span<TextSpanProps>`
	margin: ${(props) => props.m || 0};
	padding: ${(props) => props.p || 0};
	cursor: ${(props) => props.cursor};
	color: ${(props) => props.color && get(props.theme.colors, props.color, props.color)};
	font-weight: ${(props) => (props.bold ? '500' : 'normal')};
	${(props) => props.underline && `text-decoration: underline;`}
	${typography}
`;
