import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Viewer } from '../../../../shared';
import { Box, Image, Text } from '../../../../shared/Primitives';
import { SimpleWidget } from '../../../../shared/Widget';
import * as estimateProcessing from './images/estimate-processing.png';

interface PdfViewerProps {
	asset: {
		path: string;
		type: string;
	};
	isPdfPresent: boolean;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ asset, isPdfPresent }) => {
	const { t } = useTranslation('EstimatingPortal');

	if (!asset) return null;

	if (isPdfPresent) return <Viewer selectedAsset={asset} />;

	return (
		<SimpleWidget widget={{ sticky: '60px', gridArea: 'r', padding: false }}>
			<Box display="flex" alignItems="center" flexDirection="column" my={6} mx={4} color="grays.1">
				<Image src={estimateProcessing} width="150px" mb={5} />
				<Text mx={4} fontSize={3} textAlign="center">
					{t('The estimate will appear here if Tractable can process this claim')}
				</Text>
			</Box>
		</SimpleWidget>
	);
};

export { PdfViewer };
