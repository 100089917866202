import { Button, Menu, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as React from 'react';

import { Down } from '../../../shared/Icons';

export enum NegotiationState {
	EDITING_DISABLED,
	ESTIMATE_READY,
	ESTIMATE_REJECTED,
	ESTIMATE_ACCEPTED,
	NEGOTIATION_READY,
	NEGOTIATION_REJECTED,
	NEGOTIATION_ACCEPTED,
}

export interface NegotiationOption {
	text: string;
	onClick: () => void;
	dataTestId?: string;
}

export interface SettlementActionProps {
	text: string;
	state: NegotiationState;
	dataTestId?: string;
	options: NegotiationOption[];
	loading?: boolean;
}

const colours: Record<NegotiationState, string> = {
	[NegotiationState.EDITING_DISABLED]: '#3b25c4',
	[NegotiationState.ESTIMATE_READY]: '#3b25c4',
	[NegotiationState.ESTIMATE_ACCEPTED]: '#048a4e',
	[NegotiationState.ESTIMATE_REJECTED]: '#b34247',
	[NegotiationState.NEGOTIATION_READY]: '#3b25c4',
	[NegotiationState.NEGOTIATION_REJECTED]: '#b34247',
	[NegotiationState.NEGOTIATION_ACCEPTED]: '#048a4e',
};

export const SettlementAction: React.FC<SettlementActionProps> = ({
	text,
	state,
	dataTestId,
	options,
	loading = false,
}) => {
	const [opened, handlers] = useDisclosure(false);

	return (
		<Menu
			opened={!!options.length && opened}
			onOpen={handlers.open}
			onClose={handlers.close}
			control={
				<Button
					data-test-id={dataTestId}
					rightIcon={options.length ? <Down fill="white" /> : null}
					onClick={handlers.toggle}
					styles={(theme) => ({
						root: {
							backgroundColor: colours[state],
							'&:hover': {
								backgroundColor: theme.fn.darken(colours[state], 0.05),
							},
						},
					})}
					loading={loading}
				>
					<Text>{text}</Text>
				</Button>
			}
		>
			{options.map((option) => (
				<Menu.Item key={option.dataTestId} onClick={option.onClick} data-test-id={option.dataTestId}>
					<Text>{option.text}</Text>
				</Menu.Item>
			))}
		</Menu>
	);
};
