import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';

export const useLocale = () => {
	const { config } = useClientConfigCtx();

	return {
		currency: {
			symbol: config.locale.symbol,
			options: config.locale.options.currency,
		},
		decimal: {
			symbol: config.locale.symbol,
			options: config.locale.options.decimal,
		},
		locale: config.locale.symbol,
		countryCode: config.locale.symbol.split('-')[1],
	};
};
