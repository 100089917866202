import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js';
import axios from 'axios';
import * as jwtDecode from 'jwt-decode';

import { AuthMethods } from '@tractable/estimating-local-api-authentication';

import { PublicClientConfig } from '../common';
import { COGNITO_DEFAULT_OPTIONS, CognitoOptions } from '../common/types/cognito';

export interface UserFromToken {
	readonly givenName?: string;
	readonly familyName?: string;
}

interface TokenContext {
	readonly token: string;
	readonly method: Method;
}

type Method = AuthMethods.CognitoToken | AuthMethods.PortalToken;

let auth: CognitoAuth;

// 25 days between forced reload
const HOURS_LOGOUT_THRESHOLD = 600;

export async function initAuth(config: PublicClientConfig) {
	const { cognitoEnabled, magicLinkEnabled } = config.authentication;
	if (magicLinkEnabled) {
		const urlObj = new URL(window.location.href);
		const isMagicPortalLink = checkMagicPortalLink(urlObj);
		if (isMagicPortalLink) {
			const { page, claimId } = await extractUrlParams(urlObj);
			return magicPortalLink(urlObj, page, claimId);
		}

		const validToken = checkPortalToken();
		if (validToken) {
			return;
		}
	}

	if (cognitoEnabled) {
		await initCognitoAuth(config.aws.cognito);
		return;
	}

	throw new Error('Unauthorized');
}

export async function logoutCloseToRefreshExpiry() {
	if (checkPortalToken()) {
		return;
	}

	const session = auth.getSignInUserSession();
	const token = jwtDecode(session.getAccessToken().getJwtToken());
	const authTime = token.auth_time;
	const hoursSinceLastLogin = Math.floor((Date.now() / 1000 - authTime) / 3600);
	if (hoursSinceLastLogin > HOURS_LOGOUT_THRESHOLD) {
		auth.signOut();
	}
}

export function signOut() {
	if (checkPortalToken()) {
		localStorage.removeItem('portalToken');
		localStorage.removeItem('portalTokenExpirationDate');
	}

	if (auth) {
		auth.signOut();
	}

	setTimeout(() => {
		window.location.replace('/');
	}, 1000);
}

export async function getCurrentToken(): Promise<TokenContext> {
	if (checkPortalToken()) {
		const token = localStorage.getItem('portalToken');
		const method = AuthMethods.PortalToken;
		return { token, method };
	}

	const user = auth.getCachedSession();
	if (!user.isValid()) {
		await refreshSession();
	}
	const session = auth.getSignInUserSession();
	// TODO: Error handling - What happens if this fails?
	const token = session.getIdToken().getJwtToken();
	const method = AuthMethods.CognitoToken;
	return { token, method };
}

export function getUserFromToken(): UserFromToken {
	if (checkPortalToken()) {
		const token = localStorage.getItem('portalToken');
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { given_name, family_name } = jwtDecode(token);
		return { givenName: given_name, familyName: family_name };
	}

	const token = auth.getSignInUserSession().getIdToken().getJwtToken() as any;
	const obj = jwtDecode(token);
	return { givenName: obj.given_name, familyName: obj.family_name };
}

async function refreshSession() {
	return new Promise<any>((res, rej) => {
		// The aws lib will invoke these callbacks after refreshing the session
		auth.userhandler = {
			onFailure: rej,
			onSuccess: res,
		};
		auth.getSession();
	});
}

function checkPortalToken(): boolean {
	const token = localStorage.getItem('portalToken');
	if (!token) {
		return false;
	}

	const expirationDate = parseInt(localStorage.getItem('portalTokenExpirationDate'), 10);
	if (!expirationDate) {
		return false;
	}

	const now = Math.floor(new Date().valueOf() / 1000);
	if (now > expirationDate) {
		localStorage.removeItem('portalToken');
		localStorage.removeItem('portalTokenExpirationDate');
		return false;
	}

	return true;
}

export function checkMagicPortalLink(urlObj: URL): boolean {
	const urlSearchParams = urlObj.searchParams;

	const regex = new RegExp(/^\/(finished\/)?(edit\/)?(summary\/)?[0-9a-z-]*\/auth$/i);
	const result = regex.test(urlObj.pathname);
	if (!result) {
		return false;
	}

	const params = ['signature', 'userId', 'expires'];

	return params.reduce((match, curr) => match && urlSearchParams.has(curr), true);
}

export async function extractUrlParams(urlObj: URL) {
	// eslint-disable-next-line security/detect-unsafe-regex
	const regex = new RegExp(/^(\/[a-z]*)?\/([0-9a-z-]*)\/auth$/i);
	const result = regex.exec(urlObj.pathname);

	const page = result[1] ?? '/summary';
	const claimId = result[2];

	return { page, claimId };
}

async function magicPortalLink(urlObj: URL, page: string, claimId: string) {
	const urlSearchParams = urlObj.searchParams;

	const userId = urlSearchParams.get('userId');
	const expires = parseInt(urlSearchParams.get('expires'), 10);
	const signature = decodeURIComponent(urlSearchParams.get('signature'));
	const reqBody = {
		claimId,
		userId,
		expires,
		signature,
	};

	let token: string;
	try {
		token = await axios.post<string>('/api/auth', reqBody).then((r) => r.data);
	} catch (e) {
		localStorage.removeItem('portalToken');
		localStorage.removeItem('portalTokenExpirationDate');
		alert('Your link has expired, please use a new link or sign in on the next page');
		window.location.replace('/');
		return;
	}

	const { exp } = jwtDecode(token);
	localStorage.setItem('portalToken', token);
	localStorage.setItem('portalTokenExpirationDate', exp);

	const redirectUrl = `${page}/${claimId}`;
	window.location.replace(redirectUrl);
}

async function initCognitoAuth(config: CognitoOptions) {
	auth = new CognitoAuth({
		...config,
		...COGNITO_DEFAULT_OPTIONS,
	});

	auth.useCodeGrantFlow();
	return new Promise<CognitoAuthSession>((res, rej) => {
		// See if we have a valid session
		if (auth.getCachedSession().isValid()) {
			res(auth.getCachedSession());
		} else {
			// The aws lib will invoke these callbacks after creating a session from one of the
			// following scenarios
			auth.userhandler = {
				onFailure: rej,
				onSuccess: res,
			};
			// Perhaps we are handling a redirect from the AWS login page.
			// This is hacky because cognito-auth-js does not provide a nice API
			if (window.location.href.includes(auth.getCognitoConstants().CODE)) {
				auth.parseCognitoWebResponse(window.location.href);
				// Create a new session. Yes "getSession" creates a session.
				// This will create a session from a redirect token if it exists
			} else {
				auth.getSession();
			}
		}
	}).catch(() => {
		auth.signOut();
	});
}
