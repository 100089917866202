import * as React from 'react';

import { Detail, List } from '../../shared';
import { SimpleWidget } from '../../shared/Widget';
import { useClaimDetails } from './useClaimDetails';

interface ClaimDetailsProps {
	id: string;
	widget: any;
}

const ClaimDetails: React.FC<ClaimDetailsProps> = ({ id, widget }) => {
	const { loading, details } = useClaimDetails(id);

	if (loading) return null;

	return (
		<SimpleWidget {...widget}>
			<List items={details} itemRenderer={(itemProps: any) => <Detail {...itemProps} />} />
		</SimpleWidget>
	);
};

export { ClaimDetails };
