import * as React from 'react';

export const RHeadlamp = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RHEADLAMP"
		stroke="#000"
		d="M366.6 93.215l-6.622 29.716-7.45 9.236 2.897-36.542 5.794-.804 5.381-1.606z"
		{...props}
	/>
);
