import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { useLocalisedDate } from '../../../utils';

const GET_CLAIM = gql`
	query getClaim($id: String) {
		claim(id: $id) {
			id
			claimDetails {
				claimId
				dateOfAccident
			}
			companyName
		}
	}
`;

const useClaimDetails = (id: string) => {
	const [details, setDetails] = useState(null);
	const { loading, data } = useQuery(GET_CLAIM, {
		variables: { id },
		fetchPolicy: 'network-only',
	});

	const { formatDate } = useLocalisedDate();

	const blueprint: any = {
		'Claim ID': (claim: any) => claim.claimId,
		'Company Name': (claim: any) => claim.companyName,
		'Date of accident': (claim: any) => formatDate(claim.dateOfAccident, true),
	};

	useEffect(() => {
		if (!loading) {
			const claimDetails = {
				...data.claim.claimDetails,
				companyName: data.claim.companyName,
			};
			const transformedDetails = map(blueprint, (transformer: any, label: string) => ({
				key: v4(),
				label,
				value: transformer(claimDetails),
			}));
			setDetails(transformedDetails);
		}
	}, [loading, data]);

	return {
		loading,
		details,
	};
};

export { useClaimDetails };
