import styled from 'styled-components';

const StyledRow = styled.tr`
	cursor: pointer;
	margin: 1rem 0;
	height: 3rem;

	:hover {
		background: #e7eeff;
	}

	td {
		padding: 0.4rem 0;
	}

	td.status {
		text-transform: uppercase;
	}
`;

export { StyledRow };
