import * as moment from 'moment';

type DateFormat = string | string[];

const isDate = (value: string, dateFormat: DateFormat) => {
	if (Array.isArray(dateFormat)) {
		return dateFormat.some((format) => moment(value, format, true).isValid());
	}

	return moment(value, dateFormat, true).isValid();
};

export const containsValidCharacters = (text: string) => /^[\d/]*$/.test(text);

export const validateDate = (text: string, dateFormat: string) => (value: string) =>
	isDate(value, dateFormat) ? undefined : text;

export const parse = (dateFormat?: DateFormat) => (v: string) => {
	if (!isDate(v, dateFormat)) {
		return v;
	}

	if (Array.isArray(dateFormat)) {
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < dateFormat.length; i++) {
			const m = moment(v, dateFormat[i], true);
			if (m.isValid()) {
				return m;
			}
		}

		return v;
	}

	return moment(v, dateFormat, true);
};

export const format = (dateFormat: string) => (v: string) =>
	isDate(v, dateFormat) ? moment(v).format(dateFormat).toString() : v ?? '';

export const fixDateFromMoment = (date: any) => moment.utc([date.year(), date.month(), date.date(), 0, 0, 0]).toDate();

export const fixDateFromDate = (date: Date): Date =>
	moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0]).toDate();
