"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DamagedPart = void 0;
var DamagedPart;
(function (DamagedPart) {
    DamagedPart["FLWING"] = "flwing";
    DamagedPart["FRWING"] = "frwing";
    DamagedPart["FBUMPER"] = "fbumper";
    DamagedPart["BONNET"] = "bonnet";
    DamagedPart["FLDOOR"] = "fldoor";
    DamagedPart["FRDOOR"] = "frdoor";
    DamagedPart["GRILLE"] = "grille";
    DamagedPart["LHEADLAMP"] = "lheadlamp";
    DamagedPart["RHEADLAMP"] = "rheadlamp";
    DamagedPart["RLDOOR"] = "rldoor";
    DamagedPart["RRDOOR"] = "rrdoor";
    DamagedPart["BLWING"] = "blwing";
    DamagedPart["BRWING"] = "brwing";
    DamagedPart["LTAILLAMP"] = "ltaillamp";
    DamagedPart["RTAILLAMP"] = "rtaillamp";
    DamagedPart["BBUMPER"] = "bbumper";
    DamagedPart["BOOTLID"] = "bootlid";
    DamagedPart["LMIRROR"] = "lmirror";
    DamagedPart["RMIRROR"] = "rmirror";
})(DamagedPart = exports.DamagedPart || (exports.DamagedPart = {}));
