import { MantineTheme, MantineThemeOverride } from '@mantine/core';

export const useMantineThemeOverride = () => {
	const mantineThemeOverride: MantineThemeOverride = {
		colors: {
			tractable: [
				'#F4F2FF', // 5
				'#E2DEFF', // 10
				'#CAC2FA', // 20
				'#AFA3F7', // 30
				'#8978F0', // 40
				'#4C39C3', // 50
				'#3B25C4', // 60
				'#2D1E94', // 70
				'#1E1366', // 80
				'#1E1366', // 90
			],
		},
		primaryColor: 'tractable',
		colorScheme: 'light',
		fontFamily: '"Source Sans Pro", sans-serif',
	};

	const getStylesOverride = () => ({
		Button: (theme: MantineTheme) => ({
			subtle: {
				color: theme.colors.tractable[5],
				'&:hover': {
					color: theme.colors.tractable[5],
					backgroundColor: theme.colors.tractable[0],
					textDecoration: 'none',
				},
			},
			outline: {
				color: theme.colors.tractable[5],
				'&:hover': {
					color: theme.colors.tractable[5],
					backgroundColor: theme.colors.tractable[0],
				},
			},
			filled: {
				color: 'white',
				'&:hover': {
					color: 'white',
					backgroundColor: theme.colors.tractable[9],
					textDecoration: 'none',
				},
			},
		}),
	});

	return { mantineThemeOverride, getStylesOverride };
};
