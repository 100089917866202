import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import { Filter } from './Component';

interface IProps {
	lastSelected: boolean;
}

const UPDATE_FILTER = gql`
	mutation updateFilter($id: String!, $value: String!) {
		updateFilter(id: $id, value: $value) @client
	}
`;

export default compose<IProps, any>(graphql(UPDATE_FILTER))(Filter);
