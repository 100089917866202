"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateSalvage = exports.calculateTotalLossDecision = void 0;
const estimating_local_constants_1 = require("@tractable/estimating-local-constants");
function calculateTotalLossDecision(claimId, vehicle, estimateValue, additionalParts, salvageStrategy, salvageValuationThresholds, additionalDamageAmount = 0, totalLossReviewStrategy = estimating_local_constants_1.TotalLossReviewStrategy.TotalLoss, logger, policyMaxSpend) {
    const log = logger ?? console.log;
    try {
        log('Request to calculate total loss', {
            estimateValue,
            additionalParts,
            salvageStrategy,
            salvageValuationThresholds,
            valuation: vehicle.valuation,
            est_claim_id: claimId,
            policyMaxSpend,
        });
        const valuation = vehicle.valuation ?? 0;
        if ((!isNaN(estimateValue) && valuation) ||
            (salvageStrategy == estimating_local_constants_1.SalvageStrategy.PolicyMax && !isNaN(estimateValue) && policyMaxSpend)) {
            const salvage = calculateSalvage(valuation, salvageStrategy, salvageValuationThresholds, vehicle?.make);
            const estimate = estimateValue + additionalDamageAmount;
            const additionalPartsTotal = getAdditionalPartsTotal(additionalParts);
            const [decision, decisionState, decisionStateFlags] = getDecision(estimate, salvage, valuation, additionalPartsTotal, salvageStrategy, totalLossReviewStrategy, policyMaxSpend);
            log('Calculating TL decision based on estimate', {
                estimateValue: estimate,
                additionalDamageAmount: additionalDamageAmount,
                salvage,
                valuation,
                est_claim_id: claimId,
                additionalPartsTotal,
                policyMaxSpend,
            });
            const totalLossDecision = {
                decision,
                estimateValue: estimate,
                valuation,
                decisionState,
                decisionStateFlags,
            };
            return totalLossDecision;
        }
        log('Could not calculate TL Decision due to missing required fields (estimateValue, valuation or policyMaxSpend)', {
            est_claim_id: claimId,
            estimateValue,
            valuation,
        });
    }
    catch (e) {
        log('Could not make TL Decision', {
            est_claim_id: claimId,
            err: e,
        });
    }
    return null;
}
exports.calculateTotalLossDecision = calculateTotalLossDecision;
function getDecision(estimateValue, salvageValue, retailValue, additionalPartsTotal, salvageStrategy, totalLossReviewStrategy, policyMaxSpend) {
    let maximumSpend = retailValue - salvageValue;
    if (salvageStrategy == estimating_local_constants_1.SalvageStrategy.PolicyMax && policyMaxSpend) {
        maximumSpend = policyMaxSpend;
    }
    if (additionalPartsTotal) {
        let isTotalLoss = estimateValue + additionalPartsTotal.minTotal > maximumSpend;
        let isRepairable = estimateValue + additionalPartsTotal.maxTotal <= maximumSpend;
        if (salvageStrategy == estimating_local_constants_1.SalvageStrategy.PolicyMax) {
            const total = estimateValue + additionalPartsTotal.maxTotal;
            isTotalLoss = total > maximumSpend;
            isRepairable = total <= maximumSpend;
        }
        if (isTotalLoss) {
            return [true, estimating_local_constants_1.TotalLossDecisionState.TotalLoss, estimating_local_constants_1.TotalLossDecisionStateFlags.InternalDamage];
        }
        else if (isRepairable) {
            return [false, estimating_local_constants_1.TotalLossDecisionState.Repairable, estimating_local_constants_1.TotalLossDecisionStateFlags.InternalDamage];
        }
        else if (totalLossReviewStrategy === estimating_local_constants_1.TotalLossReviewStrategy.Repairable) {
            return [false, estimating_local_constants_1.TotalLossDecisionState.Review, estimating_local_constants_1.TotalLossDecisionStateFlags.InternalDamage];
        }
        // default as TotalLossReviewStrategy.TotalLoss
        return [true, estimating_local_constants_1.TotalLossDecisionState.Review, estimating_local_constants_1.TotalLossDecisionStateFlags.InternalDamage];
    }
    return [
        estimateValue >= maximumSpend,
        estimateValue >= maximumSpend ? estimating_local_constants_1.TotalLossDecisionState.TotalLoss : estimating_local_constants_1.TotalLossDecisionState.Repairable,
        estimating_local_constants_1.TotalLossDecisionStateFlags.None,
    ];
}
function getAdditionalPartsTotal(additionalParts) {
    if (additionalParts && additionalParts.length > 0) {
        const minTotal = additionalParts.reduce((acc, additionalPart) => {
            return acc + additionalPart.minCost;
        }, 0);
        const maxTotal = additionalParts.reduce((acc, additionalPart) => {
            return acc + additionalPart.maxCost;
        }, 0);
        return { minTotal, maxTotal };
    }
    return null;
}
function calculateSalvage(retailValue, strategy = estimating_local_constants_1.SalvageStrategy.FullRetail, salvageValuationThresholds, make) {
    switch (strategy) {
        case estimating_local_constants_1.SalvageStrategy.FullRetail:
            return 0;
        case estimating_local_constants_1.SalvageStrategy.PolicyMax:
            return 0;
        case estimating_local_constants_1.SalvageStrategy.SalvageThresholds:
            return calculateSalvageByValuationThresholds(retailValue, salvageValuationThresholds);
        case estimating_local_constants_1.SalvageStrategy.SalvageByMake:
        default:
            return calculateSalvageByMake(retailValue, make);
    }
}
exports.calculateSalvage = calculateSalvage;
function isBetween(start, test, end) {
    return start < test && test <= end;
}
function calculateSalvageByMake(retailValue, make) {
    const specialMakes = ['Audi', 'Bmw', 'Jaguar', 'Land', 'Mercedes', 'Volkswagen', 'Lexus', 'Mini'];
    if (!make) {
        throw new Error(`Unable to calculateSalvageByMake, make is not defined`);
    }
    //magic function - mirrors python-estimating-consumers/estimating/triage_utils.py
    const isSpecial = specialMakes.includes(make);
    if (retailValue === 0) {
        return 0;
    }
    else if (isBetween(0, retailValue, 500)) {
        return 75;
    }
    else if (isBetween(500, retailValue, 1000)) {
        return 120;
    }
    else if (isBetween(1000, retailValue, 2000)) {
        return retailValue * 0.2;
    }
    else if (isBetween(2000, retailValue, 3000)) {
        return retailValue * 0.25;
    }
    else if (isBetween(3000, retailValue, 5000)) {
        if (!isSpecial) {
            return retailValue * 0.25;
        }
        return retailValue * 0.3;
    }
    else if (isBetween(5000, retailValue, 10000)) {
        if (!isSpecial) {
            return retailValue * 0.3;
        }
        return retailValue * 0.35;
    }
    else if (retailValue > 10000) {
        if (!isSpecial) {
            return retailValue * 0.35;
        }
        return retailValue * 0.42;
    }
    throw new Error(`Case not handled - valuation: ${retailValue}, make: ${make}`);
}
function calculateSalvageByValuationThresholds(retailValue, valuationThresholds) {
    if (retailValue === 0) {
        return 0;
    }
    for (const threshold of valuationThresholds) {
        if (isBetween(threshold.start, retailValue, threshold.stop ?? Number.MAX_SAFE_INTEGER)) {
            return retailValue * threshold.modifier;
        }
    }
    throw new Error(`Valuation not within any of the valuation thresholds - valuation: ${retailValue}`);
}
