import * as React from 'react';

export const RTaillamp = (props: React.SVGProps<SVGPathElement>) => (
	<path
		id="RTAILLAMP"
		stroke="#000"
		d="M341.974 415.216l-.44 25.968 14.512.865c-2.586-18.951-14.811-27.657-14.072-26.833z"
		{...props}
	/>
);
