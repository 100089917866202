import * as React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';
import { Currency, formatCurrency } from './Currency';

export interface RepairCostProps {
	readonly repairCost: number;
	readonly repairCostText: string;
	readonly currency?: Currency;
}

export const RepairCost: React.FC<RepairCostProps> = ({ repairCost, repairCostText, currency }) => {
	return (
		<Box orientation="vertical" minWidth="120px" maxWidth="180px">
			<Text typography="micro" color="#68727A">
				{repairCostText}
			</Text>
			<Text typography="large" color="#12191F">
				{formatCurrency(repairCost, currency)}
			</Text>
		</Box>
	);
};
