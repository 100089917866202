import * as React from 'react';
import { useState } from 'react';
import { useLayer } from 'react-laag';
import styled from 'styled-components';

import { Box } from '../Box';
import SvgDown from '../Icons/Down';
import SvgUp from '../Icons/Up';
import { Text } from '../Text';
import { OperationType, operationToFill } from '../operationToFill';

export interface OperationProps {
	readonly operation: OperationType;
	readonly operationText?: string;
	readonly children: (close: () => void) => React.ReactNode;
}

const OperationWrapper = styled(Box)`
	gap: 4px;
	align-items: center;
	padding: 2px 8px;
	border-radius: 12px;
`;

const MenuWrapper = styled(Box)`
	margin: 9px 0 0 0;
`;

const List = styled.ul`
	margin: 0;
	padding: 0;
	text-indent: 0;
	list-style-type: none;
	background-color: white;
	border-radius: 3px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	overflow: hidden;
`;

interface ListItemProps {
	readonly isSelected?: boolean;
}
const ListItem = styled.li<ListItemProps>`
	margin: 0;
	padding: 10px;
	width: 140px;
	display: flex;
	gap: 5px;
	cursor: pointer;
	border-left: 3px solid white;
	${(props) => props.isSelected && 'border-left: 3px solid #3B25C4;'}
	&:hover {
		border-left: 3px solid ${(props) => (props.isSelected ? '#3B25C4' : '#f5f5f5')};
		background-color: #f5f5f5;
	}
`;

interface OperationItemProps {
	text: string;
	isSelected: boolean;
	onClick: () => void;
}
export const OperationItem: React.FC<OperationItemProps> = ({ onClick, text, isSelected }) => {
	return (
		<ListItem
			onClick={(e) => {
				e.stopPropagation();
				onClick();
			}}
			isSelected={isSelected}
		>
			<Text typography="standard">{text}</Text>
		</ListItem>
	);
};

export const OperationText: React.FC<OperationProps> = ({ operation, children }) => {
	const { background, color } = operationToFill(operation);
	return (
		<Box>
			<OperationWrapper background={background}>
				<Text typography="micro" color={color}>
					{children}
				</Text>
			</OperationWrapper>
		</Box>
	);
};

export const Operation: React.FC<OperationProps> = ({ operation, operationText, children }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { triggerProps, layerProps, renderLayer } = useLayer({
		isOpen,
		onOutsideClick: () => setIsOpen(false),
		placement: 'bottom-start',
		auto: true,
	});
	const { background, color } = operationToFill(operation);
	return (
		<>
			<Box>
				<OperationWrapper
					ref={triggerProps.ref}
					background={background}
					onClick={(e) => {
						e.stopPropagation();
						setIsOpen(!isOpen);
					}}
				>
					<Text typography="micro" color={color}>
						{operationText}
					</Text>
					{isOpen ? <SvgUp fill={color} /> : <SvgDown fill={color} />}
				</OperationWrapper>
			</Box>
			{isOpen &&
				renderLayer(
					<MenuWrapper
						ref={layerProps.ref}
						style={{
							...layerProps.style,
						}}
					>
						<List>{children && children(() => setIsOpen(false))}</List>
					</MenuWrapper>
				)}
		</>
	);
};
